import React from "react";
import {addDays} from "../../helpers";

import {Select, Menu, Switch} from "antd";
import FulfillmentPerformanceChart from "./Charts/FulfillmentPerformanceChart";
import PostageByStateChart from "./Charts/PostageByStateChart";
import DaysToDeliveryByStateChart from "./Charts/DaysToDeliveryByStateChart";
import moment from "moment"
const Option = Select;

var dateFormat = require("dateformat");

class ChartRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRangeSelected: 7,
      showOutliers: true,
      postageByStateSort: "Average_Smart_Post_Cost",
      startDate: new Date(),
      showOrderCounts: true,
      endDate: new Date(),
      chartViews: [
        {
          name: "Fulfillment Performance",
          active: true
        }, {
          name: "Postage By State (SP Only)",
          active: false
        }, {
          name: "Delivery Times By State (SP Only)",
          active: false
        }
      ]
    };
    this.handleChange = this
      .handleChange
      .bind(this);
    this.changeSelectedTimeRange = this
      .changeSelectedTimeRange
      .bind(this);
    this.changePostageByStateSort = this
      .changePostageByStateSort
      .bind(this);
  }

  getDates(startDate, endDate) {
    var dates = [];
    var currentDate = startDate;
    currentDate.setHours(0);
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  }
  handleChange(dateArray) {
    if (dateArray[0]) {
      this.setState({
        startDate: dateArray[0].toDate(),
        endDate: dateArray[1].toDate()
      });
    }
  }
  changeSelectedTimeRange(e) {
    this.setState({timeRangeSelected: e});
  }
  changePostageByStateSort(e) {
    this.setState({postageByStateSort: e});
  }

  getShipmentDataForTheDay(day, workerName) {
    var newShipmentsReceived = 0;
    var newShipmentsShipped = 0;
    var totalPostageCost = 0;


    var startOfDay = day.setHours(0, 0, 0, 0);
    var endOfDay = day.setHours(23, 59, 59, 999);

    startOfDay = new Date(startOfDay);
    endOfDay = new Date(endOfDay);
    
    this
      .props
      .orders
      .map(function (order) {
        var dateReceived = new Date(order.order.date_charged);
        if (dateReceived >= startOfDay && dateReceived < endOfDay) {
          newShipmentsReceived += 1;
        }
        var dateShipped = new Date(order.order.date_pick_packed);
        if (dateShipped >= startOfDay && dateShipped < endOfDay) {
          
          if (order.order.pick_packed_by === workerName) {
            newShipmentsShipped += 1;
          }
          if (order.order.cost_of_shipment) {
            totalPostageCost += parseFloat(order.order.cost_of_shipment);
          }
        }

        return order;
      });

    return {newShipmentsReceived: newShipmentsReceived, newShipmentsShipped: newShipmentsShipped, totalPostageCost: totalPostageCost};
  }

  renderData() {
    var uniquePickPackers = []
    this.props.orders.map(function(order) {
      if (order.order.pick_packed_by) {
        uniquePickPackers.push(order.order.pick_packed_by)
      }
      return order
    })
    uniquePickPackers = [...new Set(uniquePickPackers)];
    var colorArray = [
      "#1890ff",
      "#1165b3",
      "#0e5699",
      "#0c4880",
      "#a3d3ff",
    ]

    var colorCount = 0
    uniquePickPackers = uniquePickPackers.map(function(packer) {
      var packerObject = {
        workerName: packer,
        color: colorArray[colorCount],
        lineKey: `Orders_Shipped(${packer})`
      }
      colorCount += 1
      return packerObject
    })
    
    var data = [];
    var dateRange = [];
    var today = new Date();
    var pastDate = today.setDate(today.getDate() - this.state.timeRangeSelected);
    pastDate = new Date(pastDate);
    today = new Date();

    if (this.state.timeRangeSelected === "Date Range") {
      dateRange = this.getDates(this.state.startDate, this.state.endDate);
    } else {
      dateRange = this.getDates(pastDate, today);
    }

    var that = this;
    dateRange.map(function (date) {
      var dataPoint = {

        Orders_Recieved: that
          .getShipmentDataForTheDay(date)
          .newShipmentsReceived,
  
      };
      dataPoint["date"] = dateFormat(date, "mmm d (ddd)").toString();
      dataPoint["raw_date"] = date;
      data.push(dataPoint);
      return dataPoint;
    });
    
    // Orders_Shipped: 
    data = data.map(function(dataPoint) {
        uniquePickPackers.map(function(packerObject) {
        dataPoint[`Orders_Shipped(${packerObject.workerName})`] = that
        .getShipmentDataForTheDay(dataPoint.raw_date, packerObject.workerName)
        .newShipmentsShipped 
        return packerObject
      })
      return dataPoint
    })

    
  
    var chartData = {
      data: data,
      workerArray: uniquePickPackers
    }
    return chartData;
  }

  renderStateData() {
    var data = [];
    var today = new Date();
    var pastDate = today.setDate(today.getDate() - this.state.timeRangeSelected);
    pastDate = new Date(pastDate);
    today = new Date();

    var startOfDay = pastDate.setHours(0, 0, 0, 0);
    var endOfDay = today.setHours(23, 59, 59, 999);

    startOfDay = new Date(startOfDay);
    endOfDay = new Date(endOfDay);

    var stateChecker = [];
    this
      .props
      .orders
      .map(function (order) {
        var state = order.address.state;
        var dateCharged = new Date(order.order.date_pick_packed);
        if (dateCharged >= startOfDay && dateCharged < endOfDay) {
          // Get State Counts
          if (!stateChecker.includes(state)) {
            stateChecker.push(state);
          }
        }
        return order;
      });
    stateChecker = stateChecker.sort();

    var totalCountForWeightedAverage = 0
    stateChecker.map(function (state) {
      var dataObject = {};
      dataObject["name"] = state;
      dataObject["count"] = 0;
      dataObject["totalPostage"] = 0;
      dataObject["Average_Smart_Post_Cost"] = 0;
      
      dataObject["Average_Days_To_Arrive"] = 0;
      dataObject["Real_Count"] = 0;

      data.push(dataObject);
      return state;
    });
    var that = this;
    this
      .props
      .orders
      .map(function (order) {
        var dateCharged = new Date(order.order.date_pick_packed);
        if (dateCharged >= startOfDay && dateCharged < endOfDay) {
          data
            .map(function (stateObject) {
              if (order.order.service === "fedex_smartpost_parcel_select") {
                if (order.address.state === stateObject.name) {
                    if (order.order.tracking_information) {
                        var shipDate = moment(order.order.tracking_information.ship_date)
                        var actualDeliveryDate = moment(order.order.tracking_information.actual_delivery_date)
                         var daysToDelivery = ((shipDate.diff(actualDeliveryDate, 'hours') / 24) * -1).toFixed(2)
                       
                       stateObject["Real_Count"] += 1
                       stateObject["Average_Days_To_Arrive"] += parseFloat(daysToDelivery)
                    }
                }

                if (that.state.showOutliers) {
                  totalCountForWeightedAverage += 1
                  if (order.address.state === stateObject.name) {
                    stateObject.count += 1;
                    stateObject["Average_Smart_Post_Cost"] += parseFloat(order.order.cost_of_shipment);
                    stateObject["totalPostage"] += parseFloat(order.order.cost_of_shipment);
                  }
                } else {
                  if (order.order.cost_of_shipment <= 14) {
                    totalCountForWeightedAverage += 1
                    if (order.address.state === stateObject.name) {
                      stateObject.count += 1;
                      stateObject["Average_Smart_Post_Cost"] += parseFloat(order.order.cost_of_shipment);
                      stateObject["totalPostage"] += parseFloat(order.order.cost_of_shipment);
                    }
                  }
                }
              }
              return stateObject;
            });
        }
        return order;
      });

    data = data.map(function (dataObject) {
      dataObject.Average_Smart_Post_Cost = parseFloat((dataObject.Average_Smart_Post_Cost / dataObject.count).toFixed(2))
      dataObject["Orders_Sent_To_State"] = dataObject.count
      dataObject["Average_Days_To_Delivery"] = dataObject.Average_Days_To_Arrive / dataObject.Real_Count
      dataObject.Weighted_Average_Smart_Post_Cost = parseFloat((dataObject.Average_Smart_Post_Cost * (dataObject.count / (totalCountForWeightedAverage / 50))).toFixed(2))
      return dataObject
    })

    var totalCount = 0;
    var totalPostage = 0;

    data.map(function (dataObject) {
      if (dataObject.Average_Smart_Post_Cost) {
        totalCount += dataObject.count
        totalPostage += dataObject.totalPostage
        
      }
      return dataObject
    })

    data = data.filter(function (dataObject) {
      return dataObject.Average_Smart_Post_Cost
    })
    if (this.state.postageByStateSort) {
      data = data.sort(function (a, b) {
        var keyA = a[that.state.postageByStateSort],
          keyB = b[that.state.postageByStateSort];
        // Compare the 2 dates
        if (!keyA) {
          return -1
        }
        if (!keyB) {
          return -1
        }
        if (keyA < keyB) 
          return -1;
        if (keyA > keyB) 
          return 1;
        return 0;
      });

    }
    var stateData = {
      data: data,
      averagePostage: (totalPostage / totalCount).toFixed(2)
    }
    // console.log(stateData)
    return stateData;
  }

  setChartView(chartViewName) {
    var currentChartViews = this.state.chartViews;
    this
      .state
      .chartViews
      .map(function (chartView) {
        if (chartView.name === chartViewName) {
          chartView.active = true;
        } else {
          chartView.active = false;
        }

        return chartView;
      });
    this.setState({chartViews: currentChartViews});
  }
  renderChartViews() {
    var that = this;
    return this
      .state
      .chartViews
      .map(function (chartView) {
        return (
          <Menu.Item
            key={chartView.name}
            onClick={() => that.setChartView(chartView.name)}>
            {chartView.name}
          </Menu.Item>
        );
      });
  }
  getActiveChartView() {
    var activeCharView = null;
    this
      .state
      .chartViews
      .map(function (chartView) {
        if (chartView.active) {
          activeCharView = chartView.name;
        }
        return chartView;
      });
    return activeCharView;
  }

  renderChart() {
    var activeCharView = this.getActiveChartView();

    if (activeCharView === "Fulfillment Performance") {
      return <FulfillmentPerformanceChart data={this.renderData()}/>
    }
    if (activeCharView === "Postage By State (SP Only)") {
      return <PostageByStateChart
        data={this.renderStateData()}
        showOrderCounts={this.state.showOrderCounts}/>
    }
    if (activeCharView === "Delivery Times By State (SP Only)") {
      return <DaysToDeliveryByStateChart
        data={this.renderStateData()}
        />
    }

  }

  render() {
    return (
      <div className="chart-row">
        <Menu selectedKeys={[this.getActiveChartView()]} mode="horizontal">
          {this.renderChartViews()}
        </Menu>
        {this.getActiveChartView() === "Postage By State (SP Only)" && (
          <div
            className="time-select-container"
            style={{
            top: "185px"
          }}>
            <Switch
              checkedChildren="Outliers Visible"
              unCheckedChildren="Outliers Hidden"
              defaultChecked
              onChange={() => this.setState({
              showOutliers: !this.state.showOutliers
            })}/>
          </div>
        )}
        {this.getActiveChartView() === "Postage By State (SP Only)" && (
          <div
            className="time-select-container"
            style={{
            top: "215px"
          }}>
            <Switch
              checkedChildren="Order Counts Visible"
              unCheckedChildren="Order Counts Hidden"
              defaultChecked
              onChange={() => this.setState({
              showOrderCounts: !this.state.showOrderCounts
            })}/>
          </div>
        )}
        {this.getActiveChartView() !== "Home Page Funnel"
          ? (
            <div
              className="time-select-container"
              style={{
              top: "105px"
            }}>
              <Select
                value={this.state.timeRangeSelected}
                onChange={this.changeSelectedTimeRange}>

                <Option value={7}>7 Days Back</Option>
                <Option value={31}>30 Days Back</Option>
                <Option value={new Date().getDate() - 1}>This Month</Option>
                <Option value={90}>90 Days Back</Option>
                <Option value={365}>365 Days Back</Option>
              </Select>
            </div>
          )
          : null}
        {this.getActiveChartView() === "Postage By State (SP Only)"
          ? (
            <div
              className="time-select-container"
              style={{
              top: "145px"
            }}>
              <Select
                value={this.state.postageByStateSort}
                onChange={this.changePostageByStateSort}>

                <Option value={null}>Sort By State</Option>
                <Option value={"Average_Smart_Post_Cost"}>Sort By Average Postage</Option>
                <Option value={"Weighted_Average_Smart_Post_Cost"}>Sort By Weighted Average Postage</Option>
                <Option value={"count"}>Sort By Order Count</Option>

              </Select>
            </div>
          )
          : null}
        {this.renderChart()}
      </div>
    );
  }
}

export default ChartRow