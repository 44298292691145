import React from "react";
import {
  Drawer,
  Typography,
  Skeleton,
  Button,
  Modal,
  Divider,
  Tag,
  Icon,
  Card,
  Col,
  Row,
  List
} from "antd";
import {
  resetCustomerDetails,
  fetchCustomerDetails
} from "../../actions/actionsCustomers";
import {
  getScreenWidth,
  convertBooleanToTag,
  renderSubscriptionStatusFromSubscription,
  renderOrderStatus,
  renderVDLShipmentStatus
} from "../../helpers";
import DescriptionItem from "../Common/DescriptionItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import ResetPasswordView from "./ResetPasswordView";
import UserAddressView from "./UserAddressView";
import UserPaymentMethodView from "./UserPaymentMethodView";
import ChangeUserEmail from "./ChangeUserEmail";
import AddGiftCardValueView from "./AddGiftCardValueView";
import AddRewardPointsView from "./AddRewardPointsView";
import EditRewardsPointsNotes from "./EditRewardsPointsNotes"

var dateFormat = require("dateformat");
const { Text } = Typography;

class DetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReward: null
    };
  }

  showModal = (selectedReward) => {
    this.setState({
      selectedReward: selectedReward,
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      selectedReward: null,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      selectedReward: null,
    });
  };

  componentWillMount() {
    this.props.resetCustomerDetails();
    if (this.props.customerActive) {
      this.props.fetchCustomerDetails(this.props.customerID);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.customerActive) {
      if (this.props.customerID !== nextProps.customerID) {
        setTimeout(this.props.resetCustomerDetails(), 500);
        this.props.fetchCustomerDetails(nextProps.customerID);
      }
    }
  }

  //   Exact the address from the user customer address set
  getAddressFromID(pathname) {
    if (this.props.customerDetail) {
      var addressSet = this.props.customerDetail.addresses;
      var addressID = pathname.split("/");
      var selectedAddress = null;
      addressID = addressID[addressID.length - 1];
      addressSet.map(function(address) {
        if (address.address_id === addressID) {
          selectedAddress = address;
        }
        return address;
      });

      return selectedAddress;
    }
  }

  //   Exact the payment method from the user customer address set
  getPaymentMethodFromID(pathname) {
    if (this.props.customerDetail) {
      var paymentMethodSet = this.props.customerDetail.payment_methods;
      var paymentMethodID = pathname.split("/");
      var selectedPaymentMethod = null;
      paymentMethodID = paymentMethodID[paymentMethodID.length - 1];
      paymentMethodSet.map(function(paymentMethod) {
        if (paymentMethod.payment_method_id === paymentMethodID) {
          selectedPaymentMethod = paymentMethod;
        }
        return paymentMethod;
      });

      return selectedPaymentMethod;
    }
  }

  renderChildModalView(pathname) {
    var inititalValues;
    if (this.props.customerDetail) {
      if (pathname.includes("reset")) {
        return <ResetPasswordView customerDetail={this.props.customerDetail} />;
      } else if (pathname.includes("add-address")) {
        return <UserAddressView customerDetail={this.props.customerDetail} initialValues={{
          by_pass_address_validation: "false"
        }}/>;
      } else if (pathname.includes("change-email")) {
        return <ChangeUserEmail customerDetail={this.props.customerDetail} />;
      } else if (pathname.includes("edit-gift-card-value")) {
        return <AddGiftCardValueView customerDetail={this.props.customerDetail} />;
      }  else if (pathname.includes("edit-rewards-points")) {
        return <AddRewardPointsView customerDetail={this.props.customerDetail} />;
      } else if (pathname.includes("edit-address")) {
        inititalValues = this.getAddressFromID(this.props.location.pathname);
        if (inititalValues) {
          inititalValues.soft_deleted = inititalValues.soft_deleted.toString();
          inititalValues.by_pass_address_validation = "false"
          return (
            <UserAddressView
              edit={true}
              customerDetail={this.props.customerDetail}
              initialValues={inititalValues}
            />
          );
        }
      } else if (pathname.includes("edit-payment-method")) {
        inititalValues = this.getPaymentMethodFromID(
          this.props.location.pathname
        );
        if (inititalValues) {
          inititalValues.soft_deleted = inititalValues.soft_deleted.toString();
          return (
            <UserPaymentMethodView
              customerDetail={this.props.customerDetail}
              initialValues={inititalValues}
            />
          );
        }
      }
    }
  }

  getTitle(pathname) {
    if (pathname.includes("reset")) {
      return "Reset Password";
    } else if (pathname.includes("add-address")) {
      return "Add Address";
    } else if (pathname.includes("edit-address")) {
      return "Edit Address";
    } else if (pathname.includes("edit-payment-method")) {
      return "Edit Payment Method";
    } else if (pathname.includes("change-email")) {
      return "Change Customer Email";
    } else if (pathname.includes("gift-card")) {
      return "Edit Gift Card Credit";
    } else if (pathname.includes("rewards-points")) {
      return "Edit Rewards Points";
    }
  }

  render() {
    return (
      <Drawer
        placement="right"
        width={getScreenWidth(false)}
        closable={true}
        onClose={() => this.props.closeModal()}
        visible={this.props.customerActive}
      >
        {
          this.state.selectedReward && (
            <Modal
              title="Edit Reward Notes"
              visible={this.state.selectedReward}
              footer={null}
              okText={null}
              onCancel={this.handleCancel}
            >
              <EditRewardsPointsNotes 
                customerDetail={this.props.customerDetail}
                handleCancel={this.handleCancel}
                selectedReward={this.state.selectedReward}
                initialValues={{
                  reward_redemption_notes: this.state.selectedReward.notes ? this.state.selectedReward.notes : ""
                }}
              />
            </Modal>
          )
        }

        {this.props.customerDetail ? (
          <div className="modal-wrapper">
            <Drawer
              title={this.getTitle(this.props.location.pathname)}
              width={getScreenWidth(true)}
              closable={true}
              onClose={() => this.props.closeChildModal()}
              visible={this.props.childModalOpen}
            >
              {this.renderChildModalView(this.props.location.pathname)}
            </Drawer>
            <Text strong className="main-header">
              Customer ({this.props.customerDetail.details.random_user_id})
            </Text>
            <Text className="section-header">Details</Text>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Email"
                  content={this.props.customerDetail.details.email}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Full Name"
                  content={
                    this.props.customerDetail.details.first_name +
                    " " +
                    this.props.customerDetail.details.last_name
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Phone Number"
                  content={this.props.customerDetail.details.phone_number}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Gender"
                  content={
                    <Tag>
                      {this.props.customerDetail.details.gender.toUpperCase()}
                    </Tag>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Date Joined"
                  content={dateFormat(
                    this.props.customerDetail.details.date_joined,
                    " mmmm dS yyyy"
                  )}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Stripe Id"
                  content={
                    <a
                      href={
                        "https://dashboard.stripe.com/customers/" +
                        this.props.customerDetail.details.stripe_id
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.customerDetail.details.stripe_id}
                    </a>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Gift Card Credit Left"
                  content={ "$" +  this.props.customerDetail.details.gift_card_value}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Rewards Points Left"
                  content={ this.props.customerDetail.details.rewards_points}
                />
              </Col>
            </Row>
            {this.props.customerDetail.referral_store && (
              <Row>
                <Divider />
                <Text className="section-header">Referral Details</Text>
                <Col span={12}>
                  <DescriptionItem
                    title="User ID"
                    content={
                      <Tag color="cyan">
                        {
                          this.props.customerDetail.referral_store
                            .referral_user_facing_id
                        }
                      </Tag>
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Credit Gained"
                    content={
                      this.props.customerDetail.referral_store
                        .calculate_credit_gained
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Credit Used"
                    content={
                      this.props.customerDetail.referral_store
                        .calculate_credit_used
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Credit Left"
                    content={
                      this.props.customerDetail.referral_store
                        .calculate_credit_left
                    }
                  />
                </Col>
              </Row>
            )}
            {this.props.customerDetail.subscriptions &&
              this.props.customerDetail.subscriptions.length > 0 && (
                <Row>
                  <Divider />
                  <Text className="section-header">Subscriptions</Text>
                  <List
                    itemLayout="horizontal"
                    bordered
                    dataSource={this.props.customerDetail.subscriptions}
                    renderItem={item => (
                      <List.Item
                        actions={[
                          <Link
                            to={`/portal/subscriptions/${item.transaction_id}`}
                          >
                            See Details <Icon type="right" />
                          </Link>
                        ]}
                      >
                        <List.Item.Meta
                          title={`ID: ${item.transaction_id}`}
                          description={
                            <div>
                              <DescriptionItem
                                title="Total"
                                content={`$${item.cart.total}`}
                              />

                              <DescriptionItem
                                title="Status"
                                content={renderSubscriptionStatusFromSubscription(
                                  item.transaction_status,
                                  item.subscription_on_hold
                                )}
                              />
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Row>
              )}
            {this.props.customerDetail.order_history &&
              this.props.customerDetail.order_history.length > 0 && (
                <Row>
                  <Divider />
                  <Text className="section-header">Order History</Text>
                  <List
                    itemLayout="horizontal"
                    bordered
                    dataSource={this.props.customerDetail.order_history}
                    renderItem={item => (
                      <List.Item
                        actions={[
                          <Link to={`/portal/orders/${item.transaction_id}`}>
                            See Details <Icon type="right" />
                          </Link>
                        ]}
                      >
                        <List.Item.Meta
                          title={`ID: ${item.transaction_id}`}
                          description={
                            <div>
                              <DescriptionItem
                                title="Total"
                                content={`$${item.cart.total}`}
                              />
                              <DescriptionItem
                                title="Status"
                                content={renderOrderStatus(
                                  item.transaction_status
                                )}
                              />
                              <DescriptionItem
                                title="Date Charged"
                                content={item.date_charged}
                              />
                              <DescriptionItem
                                title="Shipment Status"
                                content={renderVDLShipmentStatus(
                                  item.shipment_status,
                                  item.vanguard_shipment
                                )}
                              />
                              <DescriptionItem
                                title="Auto Charge"
                                content={convertBooleanToTag(
                                  item.cart.auto_generated_by_subscription
                                )}
                              />
                              {
                                item.points_earned && (
                                    <DescriptionItem
                                      title="Rewards Points Gained"
                                      content={parseInt(item.cart.total * 10) }
                                    />
                                  )
                              }
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Row>
              )}
            {this.props.customerDetail.reward_redemptions &&
              this.props.customerDetail.reward_redemptions.length > 0 && (
                <Row>
                  <Divider />
                  <Text className="section-header">Reward Redemption History</Text>
                  <List
                    itemLayout="horizontal"
                    bordered
                    dataSource={this.props.customerDetail.reward_redemptions}
                    renderItem={item => (
                      <List.Item
                      actions={[
                        <Button type="primary" onClick={() => this.showModal(item)} icon="edit"  shape="circle" ghost/>
              
                      ]}
                      extra={
                        <img
                          width={110}
                          alt="logo"
                          src={item.selected_reward_image}
                        />
                      }
                      >
                        <List.Item.Meta
                          title={`Reward: ${item.selected_reward_title}`}
                          description={
                            <div>
                              <DescriptionItem
                                title="Total Points Spent"
                                content={`${item.spent_points}`}
                              />
                              <DescriptionItem
                                title="Date Redeemed"
                                content={`${item.date_created}`}
                              />
                              {
                                item.notes && (
                                    <DescriptionItem
                                      title="Notes"
                                      content={`${item.notes}`}
                                    />
                                )
                              }
            
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Row>
              )}
            {this.props.customerDetail.addresses && (
              <Row>
                <Divider />
                <Text className="section-header">Addresses</Text>
                <List
                  grid={{ gutter: 8, column: 2 }}
                  dataSource={this.props.customerDetail.addresses}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        actions={[
                          <Link
                            to={`/portal/customers/${
                              this.props.customerDetail.details.random_user_id
                            }/edit-address/${item.address_id}`}
                          >
                            <Icon type="edit" />
                          </Link>
                        ]}
                        size={"small"}
                      >
                        <DescriptionItem title="ID" content={item.address_id} />
                        <DescriptionItem
                          title="Full Name"
                          content={item.full_name}
                        />
                        <DescriptionItem
                          title="Address"
                          content={item.address}
                        />
                        <DescriptionItem
                          title="Apt Number"
                          content={item.apt_number}
                        />
                        <DescriptionItem title="City" content={item.city} />
                        <DescriptionItem title="State" content={item.state} />
                        <DescriptionItem
                          title="Zip Code"
                          content={item.zip_code}
                        />
                        <DescriptionItem
                          title="Phone Number"
                          content={item.phone}
                        />
                        <DescriptionItem
                          title="Deleted"
                          content={convertBooleanToTag(item.soft_deleted)}
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </Row>
            )}
            {this.props.customerDetail.payment_methods && (
              <Row>
                <Divider />
                <Text className="section-header">Payment Methods</Text>
                <List
                  grid={{ gutter: 8, column: 2 }}
                  dataSource={this.props.customerDetail.payment_methods}
                  renderItem={item => (
                    <List.Item>
                      <Card
                        actions={item.card_type !== "cc-paypal" && [
                          <Link
                            to={`/portal/customers/${
                              this.props.customerDetail.details.random_user_id
                            }/edit-payment-method/${item.payment_method_id}`}
                          >
                            <Icon type="edit" />
                          </Link>
                        ]}
                        size={"small"}
                      >
                        <DescriptionItem
                          title="ID"
                          content={item.payment_method_id}
                        />
                        {
                          item.card_type === "cc-paypal" ? (
                            <div>
                                <DescriptionItem
                                  title="Pay Pal Email"
                                  content={item.pay_pal_payer_email}
                                />
                                <DescriptionItem
                                  title="Billing Agreemtn ID"
                                  content={item.pay_pal_billing_agreement_id}
                                />
                            </div>
                          ) : (
                            <div>
                                <DescriptionItem
                                  title="Last Four Digits"
                                  content={item.last_four_digits}
                                />
                                <DescriptionItem
                                  title="Exp. Date"
                                  content={item.expiration_date}
                                />
                                <DescriptionItem
                                  title="Billing Zip Code"
                                  content={item.zip_code}
                                />
                                <DescriptionItem
                                  title="Stripe ID"
                                  content={
                                    <a
                                      href={
                                        "https://dashboard.stripe.com/cards/" +
                                        item.stripe_id
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {item.stripe_id}
                                    </a>
                                  }
                                />
                            </div>
                          )
                        }
                        <DescriptionItem
                          title="Card Type"
                          content={<Tag>{item.card_type.toUpperCase()}</Tag>}
                        />
                        <DescriptionItem
                          title="Deleted"
                          content={convertBooleanToTag(item.soft_deleted)}
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </Row>
            )}
            {this.props.customerDetail.gift_cards.length > 0 && (
              <Row>
                <Divider />
                <Text className="section-header">Redeemed Gift Cards</Text>
                <List
                  grid={{ gutter: 8, column: 2 }}
                  dataSource={this.props.customerDetail.gift_cards}
                  renderItem={item => (
                    <List.Item>
                      <Card>
                        {
                          item.date_redeemed && (
                              <DescriptionItem
                                  title="Date Redeemed"
                                  content={dateFormat(
                                    item.date_redeemed,
                                    " mmmm dS yyyy"
                                  )}
                              />
                          )
                        }
                        <DescriptionItem
                            title={"Value"}
                            content={"$" + item.value}
                        />
                        <DescriptionItem
                            title={"Cancelled"}
                            content={convertBooleanToTag(item.cancelled)}
                        />
                        <DescriptionItem
                            title={"Originating Order ID"}
                            content={          <Link to={`/portal/orders/${item.originating_order_id}`}>
                            See Details <Icon type="right" />
                          </Link>}
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </Row>
            )}
            <div className="bottom-bar">
              <Link
                to={`/portal/customers/${
                  this.props.customerDetail.details.random_user_id
                }/edit-gift-card-value`}
              >
                <Button
                  type="primary"
                  onClick={this.closeModal}
                  style={{ marginRight: 8 }}
                  ghost
                >
                  Edit Gift Card Credit
                </Button>
              </Link>
              <Link
                to={`/portal/customers/${
                  this.props.customerDetail.details.random_user_id
                }/edit-rewards-points`}
              >
                <Button
                  type="primary"
                  onClick={this.closeModal}
                  style={{ marginRight: 8 }}
                  ghost
                >
                  Edit Rewards Points
                </Button>
              </Link>
              <Link
                to={`/portal/customers/${
                  this.props.customerDetail.details.random_user_id
                }/change-email`}
              >
                <Button
                  type="primary"
                  onClick={this.closeModal}
                  style={{ marginRight: 8 }}
                  ghost
                >
                  Change Email
                </Button>
              </Link>
              <Link
                to={`/portal/customers/${
                  this.props.customerDetail.details.random_user_id
                }/add-address`}
              >
                <Button
                  ghost
                  type="primary"
                  onClick={this.closeModal}
                  style={{ marginRight: 8 }}
                >
                  Add Address
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="modal-wrapper">
            <Skeleton active paragraph />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  return {
    customerDetail: state.customers.customer_detail
  };
}

export default connect(
  mapStateToProps,
  { resetCustomerDetails, fetchCustomerDetails }
)(DetailView);
