import React from "react";
import { connect } from "react-redux";

class B2BOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrder: null,
            searchFilter: "",

            childModalOpen: false,
            createSampleViewOpen: false
        };
    }

    render() {
        return (
            <div className="page-container">
                <div className="table-view">


                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        orderDetail: state.orders.orderDetail,
    };
}

export default connect(
    mapStateToProps,
    {  }
)(B2BOrders);
