import React from "react";
import { Divider, Button, Form, Spin, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  datePickerField,
  selectField,
  plainNumberField
} from "../Form/FieldRenders";

import { Field, reduxForm, getFormValues, change } from "redux-form";
import DeleteItem from "../../components/Common/DeleteItem";
import {
  subscriptionStatusArray,
  frequencyArray,
  productArray,
  trueFalseArray,
  productGroupArray,
  onHoldReasonsArray,
  subscriptionCancellationReasonsArray,
  subscriptionCancellationSavesReasonsArray
} from "../../helpers";
import { editSubscription } from "../../actions/actionsSubscriptions";
import moment from "moment";

var productCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

function renderValidate(values, errors) {
  var noSubscriptionItems = true;

  productCount.map(function(count) {
    if (values[`one_time_purchase_${count}`] === "false") {
      noSubscriptionItems = false;
    }
    return count;
  });

  if (noSubscriptionItems) {
    productCount.map(function(count) {
      errors[`one_time_purchase_${count}`] = "Need at least one Subscription";
      return count;
    });
  }

  productCount.map(function(count) {
    if (values[`one_time_purchase_${count}`] === "false") {
      noSubscriptionItems = false;
    }
    return count;
  });

  productCount.map(function(count) {
    if (values[`product_${count}`]) {
      if (!values[`quantity_${count}`]) {
        errors[`quantity_${count}`] = "Required";
      }
      if (!values[`promo_item_${count}`]) {
        errors[`promo_item_${count}`] = "Required";
      }
      if (!values[`skipped_item_${count}`]) {
        errors[`skipped_item_${count}`] = "Required";
      }
      if (!values[`promotional_item_for_life_${count}`]) {
        errors[`promotional_item_for_life_${count}`] = "Required";
      }
      if (!values[`one_time_purchase_${count}`]) {
        errors[`one_time_purchase_${count}`] = "Required";
      }
    }
    return count;
  });

  productCount.map(function(count) {
    if (values[`delayed_product_${count}`]) {
      if (!values[`delayed_quantity_${count}`]) {
        errors[`delayed_quantity_${count}`] = "Required";
      }
      if (!values[`delayed_promo_item_${count}`]) {
        errors[`delayed_promo_item_${count}`] = "Required";
      }
      if (!values[`delayed_promotional_item_for_life_${count}`]) {
        errors[`delayed_promotional_item_for_life_${count}`] = "Required";
      }
      if (!values[`delayed_one_time_purchase_${count}`]) {
        errors[`delayed_one_time_purchase_${count}`] = "Required";
      }
    }
    return count;
  });

  var valueArray = [];
  productCount.map(function(count) {
    valueArray.push(values[`product_${count}`]);
    valueArray.push(values[`delayed_product_${count}`]);
    return count;
  });
  valueArray = valueArray.filter(function(value) {
    return value;
  });
  let hasDuplicates = new Set(valueArray).size !== valueArray.length;
  if (hasDuplicates) {
    productCount.map(function(count) {
      errors[`product_${count}`] = "Items in subscription need to be unique";
      return count;
    });
    productCount.map(function(count) {
      errors[`delayed_product_${count}`] =
        "Delayed Items in subscription need to be unique";
      return count;
    });
  }

  return errors;
}

const validate = values => {
  const errors = {};

  if (!values.status) {
    errors.status = "Required";
  } else {
    if (values.status === "onHold") {
      if (!values.hold_reason) {
        errors.hold_reason = "Required if status is on hold";
      }
    } else if (values.status === "cancelled") {
      if (!values.cancellation_reason) {
        errors.cancellation_reason = "Required if status is cancelled";
      }
    }
  }
  
  if (values.cx_sub_date_saved) {
    if (!values.cx_sub_save_cancel_reason) {
        errors.cx_sub_save_cancel_reason = "This is required if you are adding in a cancellation save date";
      }
      if (!values.cx_sub_save_remedy) {
        errors.cx_sub_save_remedy = "This is required if you are adding in a cancellation save date";
    }
  }

  if (!values.frequency) {
    errors.frequency = "Required";
  }
  if (!values.shipping_address) {
    errors.shipping_address = "Required";
  }
  if (!values.payment_method) {
    errors.payment_method = "Required";
  }
  // Check the next shipment date
  if (values.next_shipment_date) {
    var dateString = moment(values.next_shipment_date).format("YYYY-MM-DD");
    if (dateString === "Invalid date") {
      errors.next_shipment_date = "The date needs to be valid. Either delete the date or select a valid date from the dropdown.";
    } else if (moment(values.next_shipment_date) <= moment()) {
      if (values.status === "running") {
        errors.next_shipment_date = "If the subscription is running, the next shipment date be in the future";
      }
    }
  }
  // The default products must be always their
  if (!values.product_1) {
    errors.product_1 = "Required";
  }
  if (!values.quantity_1) {
    errors.quantity_1 = "Required";
  }
  if (!values.one_time_purchase_1) {
    errors.one_time_purchase_1 = "Required";
  }
  if (!values.promo_item_1) {
    errors.promo_item_1 = "Required";
  }
  if (!values.promotional_item_for_life_1) {
    errors.promotional_item_for_life_1 = "Required";
  }
  // Return extra validation for products and delayed products
  return renderValidate(values, errors);
};

class EditView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      paymentMethods: []
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["transaction_id"] = this.props.orderDetail.transaction_id;
    if (props.next_shipment_date) {
      props.next_shipment_date = moment(props.next_shipment_date).format(
        "YYYY-MM-DD"
      );
    }
    
    if (props.cx_sub_date_saved) {
      props.cx_sub_date_saved = moment(props.cx_sub_date_saved).format(
        "YYYY-MM-DD"
      );
    }
    
    this.props.editSubscription(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customerDetail) {
      if (
        this.state.addresses.length === 0 ||
        this.state.paymentMethods.length === 0
      ) {
        var addressSet = nextProps.customerDetail.addresses;
        var paymentMethodSet = nextProps.customerDetail.payment_methods;

        var addresses = [];
        addressSet.map(function(address) {
          var addressObject = {
            value: address.address_id,
            text: `${address.address} ${address.city} ${address.state}, ${
              address.zip_code
            } `
          };
          addresses.push(addressObject);
          return address;
        });

        var paymentMethods = [];

        paymentMethodSet.map(function(paymentMethod) {
          var paymentMethodObject = {
            value: paymentMethod.payment_method_id,
            text: `Last Four: ${paymentMethod.last_four_digits} Exp: ${
              paymentMethod.expiration_date
            } Zip:${paymentMethod.zip_code}`
          };

          if (paymentMethod.pay_pal_billing_agreement_id) {
            paymentMethodObject = {
              value: paymentMethod.payment_method_id,
              text: `Pay Pal Email: ${paymentMethod.pay_pal_payer_email}`
            };
          }
          paymentMethods.push(paymentMethodObject);
          return paymentMethod;
        });

        this.setState({
          paymentMethods: paymentMethods,
          addresses: addresses
        });
      }
    }
  }

  removeItem(productID, quantityID, oneTimePurchase, promoItem) {
    this.props.change(productID, null);
    this.props.change(quantityID, null);
    this.props.change(oneTimePurchase, null);
    this.props.change(promoItem, null);
  }

  renderProductPickers(delayedItems) {
    var count = 0;
    var productNumbers = [];
    while (count <= 17) {
      count += 1;
      productNumbers.push(count.toString());
    }
    var that = this;
    return productNumbers.map(function(productNumber) {
      var required = false;
      if (productNumber === "1") {
        if (delayedItems) {
          required = false;
        } else {
          required = true;
        }
      }
      var delayedPrefix = "";
      if (delayedItems) {
        delayedPrefix = "delayed_";
      }
      return (
        <Row gutter={6} key={productNumber}>
          <Col span={8}>
            <Field
              label={`Product ${productNumber}`}
              name={`${delayedPrefix}product_${productNumber}`}
              allowClear={true}
              size={"large"}
              showSearch={false}
              required={required}
              optionGroups={productGroupArray}
              overRideTouched={!required}
              options={productArray}
              component={selectField}
            />
          </Col>
          <Col span={2}>
            <Field
              label={`Qty`}
              name={`${delayedPrefix}quantity_${productNumber}`}
              min={1}
              max={20}
              step={1}
              required={required}
              overRideTouched={!required}
              type={"number"}
              size="large"
              component={plainNumberField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`One Off Item`}
              name={`${delayedPrefix}one_time_purchase_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={
                "The product will not be added to their next subscription"
              }
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`Promo Item`}
              name={`${delayedPrefix}promo_item_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={"The first product will be free"}
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`Skipped Item`}
              name={`${delayedPrefix}skipped_item_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={"The first product will be free"}
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`Promo Item For Life`}
              name={`${delayedPrefix}promotional_item_for_life_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={"The first product will be free forever"}
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={2}>
            <DeleteItem
              removeItem={() =>
                that.removeItem(
                  `${delayedPrefix}product_${productNumber}`,
                  `${delayedPrefix}quantity_${productNumber}`,
                  `${delayedPrefix}one_time_purchase_${productNumber}`,
                  `${delayedPrefix}promo_item_${productNumber}`,
                  `${delayedPrefix}promotional_item_for_life_${productNumber}`
                )
              }
            />
          </Col>
        </Row>
      );
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Divider>Basic Information</Divider>
        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Row gutter={6}>
              <Col span={12}>
                <Field
                  label={"Status"}
                  name="status"
                  required={true}
                  size={"large"}
                  suffix="This can be manually overridden at any time."
                  options={subscriptionStatusArray}
                  component={selectField}
                />
              </Col>
              <Col span={12}>
                <Field
                  label={"Frequency"}
                  name="frequency"
                  required={true}
                  size={"large"}
                  suffix="This can be manually overridden at any time."
                  options={frequencyArray}
                  component={selectField}
                />
              </Col>

              {this.props.formValues &&
                this.props.formValues.status === "onHold" && (
                  <Col span={24}>
                    <Field
                      label={"Hold Reason"}
                      name="hold_reason"
                      required={true}
                      size={"large"}
                      options={onHoldReasonsArray}
                      component={selectField}
                    />
                  </Col>
                )}
              {this.props.formValues &&
                this.props.formValues.status === "cancelled" && (
                  <Col span={24}>
                    <Field
                      label={"Cancellation Reason"}
                      name="cancellation_reason"
                      required={true}
                      overRideTouched={true}
                      size={"large"}
                      component={selectField}
                      options={subscriptionCancellationReasonsArray}
                    />
                  </Col>
                )}
              <Col span={24}>
                <Field
                  label={"Next Shipment Date"}
                  name="next_shipment_date"
                  required={false}
                  overRideTouched={true}
                  showToday={true}
                  placeHolder={"Select Next Shipment Date"}
                  size={"large"}
                  component={datePickerField}
                />
                <p>
                  This will manually overide the frequency for the next order if
                  filled in.{" "}
                </p>
              </Col>
            </Row>
            <Divider>Subscription Save Details (Only Fill Out On Subscription Saves)</Divider>
            <Row gutter={3}>
            <Col span={8}>
                <Field
                  label={"Subscription Save Date"}
                  name="cx_sub_date_saved"
                  required={false}
                  overRideTouched={true}
                  showToday={true}
                  placeHolder={"Select Today If Saved"}
                  size={"large"}
                  component={datePickerField}
                />
              </Col>
            <Col span={8}>
                  <Field
                    label={"Reason Customer Wanted To Cancel"}
                    name="cx_sub_save_cancel_reason"
                    required={true}
                    overRideTouched={true}
                    size={"large"}
                    component={selectField}
                    options={subscriptionCancellationReasonsArray}
                  />
              </Col>
            <Col span={8}>
                  <Field
                    label={"Reason Why They Were Saved"}
                    name="cx_sub_save_remedy"
                    required={true}
                    overRideTouched={true}
                    size={"large"}
                    component={selectField}
                    options={subscriptionCancellationSavesReasonsArray}
                  />
              </Col>
            </Row>
            <Divider>Customer Details</Divider>
            <Row gutter={6}>
              <Col span={24}>
                <Field
                  label={`Address`}
                  name={`shipping_address`}
                  allowClear={true}
                  size={"large"}
                  showSearch={true}
                  required={true}
                  options={this.state.addresses}
                  component={selectField}
                />
              </Col>
              <Col span={24}>
                <Field
                  label={`Payment Method`}
                  name={`payment_method`}
                  allowClear={true}
                  size={"large"}
                  showSearch={true}
                  required={true}
                  options={this.state.paymentMethods}
                  component={selectField}
                />
              </Col>
            </Row>
            <Divider>Upcoming Products</Divider>
            <p style={{ textAlign: "center" }}>
              These products will ship in the upcoming order. To remove a
              product select No Product in the dropdown.
            </p>
            {this.renderProductPickers()}

            <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              ghost
              type="primary"
              disabled={pristine || submitting || invalid}
            >
              Save Changes
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

EditView = reduxForm({
  form: "EditSubscriptionForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    formValues: getFormValues("EditSubscriptionForm")(state),
    customerDetail: state.customers.customer_addresses_and_payment_methods
  };
}

export default connect(
  mapStateToProps,
  { editSubscription, change }
)(EditView);
