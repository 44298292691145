import React from "react";
import { Input, Form, InputNumber, Tooltip, Icon, DatePicker } from "antd";
import { Select } from "antd";
import moment from "moment";
const { Option, OptGroup } = Select;

function renderGroupOptions(options, groupName) {
  return options.map(function(option) {
    if (groupName === option.group) {
      return (
        <Option key={option.value} value={option.value}>
          {option.text}
        </Option>
      )
    } else {
      return null
    }
  })
}

function renderOptions(options, optionGroups) {
  if (optionGroups) {
    return optionGroups.map(function(optionGroup) {
      return (
        <OptGroup label={optionGroup} key={optionGroup}>
            {renderGroupOptions(options, optionGroup)}
        </OptGroup>
      )
    })
  }
  return options.map(function(option) {
    return (
      <Option key={option.value} value={option.value}>
        {option.text}
      </Option>
    );
  });
}

export const plainTextField = ({
  input,
  label,
  type,
  placeholder,
  required,
  size,
  suffix,
  overRideTouched,
  autoComplete,
  fluid,
  prefix,
  meta: { touched, error, active, autofilled }
}) => (
  <Form.Item
    label={label}
    required={required}
    help={(overRideTouched || touched) && error ? error : null}
    validateStatus={
      (overRideTouched || touched) && error && !active
        ? "error"
        : input.value && "success"
    }
    hasFeedback
  >
    <Input
      label={label}
      type={type}
      size={size}
      prefix={prefix}
      suffix={
        suffix && (
          <Tooltip title={suffix}>
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        )
      }
      autoComplete={autoComplete}
      required={required}
      placeholder={placeholder}
      fluid={fluid}
      {...input}
    />
  </Form.Item>
);

export const plainNumberField = ({
  input,
  label,
  placeholder,
  required,
  size,
  max,
  type,
  step,
  min,
  suffix,
  addonBefore,
  overRideTouched,
  autoComplete,
  fluid,

  meta: { touched, error, active, autofilled }
}) => (
  <Form.Item
    label={label}
    help={(overRideTouched || touched) && error ? error : null}
    required={required}
    validateStatus={
      (overRideTouched || touched) && error && !active
        ? "error"
        : input.value && "success"
    }
    hasFeedback
  >
    <InputNumber
      style={{ width: "100%" }}
      label={label}
      min={min ? min : 0}
      max={max}
      size={size}
      step={step}
      suffix={
        suffix && (
          <Tooltip title={suffix}>
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        )
      }
      type={type}
      addonBefore={addonBefore}
      autoComplete={autoComplete}
      required={required}
      placeholder={placeholder}
      fluid={fluid}
      {...input}
    />
  </Form.Item>
);

export const textAreaField = ({
  input,
  label,
  placeholder,
  required,
  rows,
  suffix,
  overRideTouched,
  step,
  meta: { touched, error, active, autofilled }
}) => (
  <Form.Item
    label={label}
    required={required}
    help={(overRideTouched || touched) && error ? error : null}
    validateStatus={
      (overRideTouched || touched) && error && !active
        ? "error"
        : input.value && "success"
    }
    hasFeedback
  >
    <Input.TextArea
      style={{ width: "100%" }}
      label={label}
      size={"large"}
      rows={rows}
      min={0}
      step={step}
      required={required}
      placeholder={placeholder}
      {...input}
    />
  </Form.Item>
);

export const selectField = ({
  input,
  label,
  options,
  overRideTouched,
  showSearch,
  required,
  optionGroups,
  suffix,
  mode,
  size,
  filterOption,
  meta: { touched, error, active, autofilled }
}) => (
  <Form.Item
    label={label}
    required={required}
    help={(overRideTouched || touched) && error ? error : null}
    validateStatus={
      (overRideTouched || touched) && error && !active
        ? "error"
        : input.value
        ? "success"
        : ""
    }
    hasFeedback
  >
    <Select
      {...input}
      style={{ width: "100%" }}
      size={size}
      mode={mode ? mode : "default"}
      required={required}
      filterOption={filterOption ? filterOption : true}
      suffixIcon={
        suffix && (
          <Tooltip title={suffix} placement="top">
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        )
      }
      showSearch={showSearch}
    >
      {renderOptions(options, optionGroups)}
    </Select>
  </Form.Item>
);

export const datePickerField = ({
  input,
  label,
  options,
  overRideTouched,
  required,
  suffix,
  placeHolder,
  showToday,
  size,
  meta: { touched, error, active, autofilled }
}) => (
  <Form.Item
    label={label}
    required={required}
    help={(overRideTouched || touched) && error ? error : null}
    validateStatus={
      (overRideTouched || touched) && error && !active
        ? "error"
        : input.value && "success"
    }
    hasFeedback
  >
    <DatePicker
      {...input}
      style={{ width: "100%" }}
      size={size}
      allowClear={true}
      placeholder={placeHolder}
      showToday={showToday}
      value={input.value ? moment(input.value) : null}
      required={required}
      suffixIcon={
        suffix && (
          <Tooltip title={suffix} placement="top">
            <Icon type="info-circle" style={{ color: "rgba(0,0,0,.45)" }} />
          </Tooltip>
        )
      }
      format="MM/DD/YYYY"
    />
  </Form.Item>
);
