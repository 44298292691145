import * as types from "../actions/types";

const INITIAL_STATE = {
  orders: null,
  orders_loading: false,
  orderDetail: null,
  orderDetailLoading: false,
  marketingData: null,
  replacement_boxes: null,
  mixpanelData: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_USER_ORDER_HISTORY_LOADING:
      return { ...state, orders_loading: !state.orders_loading };
    case types.SET_USER_ORDER_DETAILS_LOADING:
      return { ...state, orderDetailLoading: true };
    case types.FETCH_ALL_USER_ORDER_HISTORY_SUCCESS:
      return { ...state, orders: action.payload, orders_loading: false };
    case types.FETCH_ALL_REPLACEMENT_BOXES_SUCCESS:
      return { ...state, replacement_boxes: action.payload };
    case types.FETCH_USER_ORDER_DETAILS:
      return {
        ...state,
        orderDetail: action.payload,
        orderDetailLoading: false
      };
    case types.RESET_USER_ORDER_DETAILS:
      return { ...state, orderDetail: null };
    case types.FETCH_ALL_MARKETING_DATA_SUCCESS:
      return { ...state, marketingData: action.payload };
    case types.FETCH_ALL_MIXPANEL_DATA_SUCCESS:
      return { ...state, mixpanelData: action.payload };

    default:
      return state;
  }
}
