import React from "react";
import {Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import { textAreaField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { editUserProfileAdmin } from "../../actions/actionsCustomers";

const validate = values => {
  const errors = {};
  if (!values.gift_card_value) {
    errors.gift_card_value = "Required";
  }
  return errors;
};

class EditRewardRedemptionNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props.user_metric_id = this.props.selectedReward.metric_id;
    props.type = "edit_rewards_redemption_notes";
    props.random_user_id = this.props.customerDetail.details.random_user_id;
    this.props.editUserProfileAdmin(props, "put");
    this.props.handleCancel()
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ marginTop: "-14px" }}>
        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
          <Field
              label={"Reward Redemption Notes"}
              name="reward_redemption_notes"
              required={true}
              type={"text"}
              rows={10}
              size="large"
              component={textAreaField}
            />
            <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Save & Edit Notes
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

EditRewardRedemptionNotes = reduxForm({
  form: "EditRewardRedemptionNotes",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditRewardRedemptionNotes);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editUserProfileAdmin }
)(EditRewardRedemptionNotes);
