import React from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button } from "antd";

class DownloadCSV extends React.Component {
  exportData() {
    console.log(this.props.data);
  }
  render() {
    return (
      <div style={{ float: "right" }}>
        {!this.props.loggedInUser.customer_service_user && (
          <CSVLink
            data={this.props.data}
            filename={this.props.itemName + "_Export.csv"}
          >
            <Button ghost icon="export" type="primary">
              Export {this.props.itemName}
            </Button>
          </CSVLink>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  null
)(DownloadCSV);
