import React from "react";
import {connect} from "react-redux";
import Loader from "../../components/Common/Loader";
import { fetchAllFulfillmentOrders } from "../../actions/actionsFulfillment";
import { fetchInventoryCounts } from "../../actions/actionsInventory";
import DashboardRow from "../../components/Fulfillment/DashboardRow";
import DashboardRowInventoryCounts from "../../components/Fulfillment/DashboardRowInventoryCounts";
import ChartRow from "../../components/Fulfillment/ChartRow";


class Fulfillment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    if (!this.props.fulfillmentOrders) {
      this.props.fetchAllFulfillmentOrders();
    }
    if (!this.props.inventoryCounts) {
      this.props.fetchInventoryCounts();
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.fulfillmentOrders && this.props.inventoryCounts
            ? (
              <div>
                {!this.props.loggedInUser.customer_service_user && (
                  <div>
                    <DashboardRowInventoryCounts inventoryCounts={this.props.inventoryCounts} />
                    <DashboardRow orders={this.props.fulfillmentOrders} />
                  </div>
                )}
                <ChartRow orders={this.props.fulfillmentOrders} /> 
              </div>
            )
            : (<Loader/>)}
        </div> 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
      loggedInUser: state.auth.logged_in_user,
      fulfillmentOrders: state.fulfillment.fulfillment_orders,
      inventoryCounts: state.inventory.inventory_counts
    };
}

export default connect(
    mapStateToProps, 
    { fetchAllFulfillmentOrders, fetchInventoryCounts}
)(Fulfillment);
