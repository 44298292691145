import * as types from "../actions/types";

const INITIAL_STATE = {
  home_page_versions: null,
  home_page_versions_loading: false,
  home_page_versions_soft_loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_HOME_PAGE_VERSIONS_LOADING:
      return { ...state, home_page_versions_loading: true };
    case types.FETCH_ALL_HOME_PAGE_VERSIONS_SOFT_LOADING:
      return { ...state, home_page_versions_soft_loading: true };
    case types.FETCH_ALL_HOME_PAGE_VERSIONS_SUCCESS:
      return {
        ...state,
        home_page_versions: action.payload,
        home_page_versions_loading: false,
        home_page_versions_soft_loading: false
      };

    default:
      return state;
  }
}
