import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the redirect links into state
export function fetchAllRedirectLinksSuccess(data) {
  return { type: types.FETCH_ALL_REDIRECT_LINKS_SUCCESS, payload: data };
}

// Set the full redirect links loading
export function setAllRedirectLinksLoading() {
  return { type: types.FETCH_ALL_REDIRECT_LINKS_LOADING, payload: null };
}

// Set the full redirect links soft loading
export function setAllRedirectLinksSoftLoading() {
  return {
    type: types.FETCH_ALL_REDIRECT_LINKS_SOFT_LOADING,
    payload: null
  };
}

// Retrives All Of rediect link data
export function fetchAllRedirectLinks(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllRedirectLinksLoading());
    } else {
      dispatch(setAllRedirectLinksSoftLoading());
    }
    return axios({
      url: `${BASE_API_URL}get-all-redirect-links`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(
          fetchAllRedirectLinksSuccess(response.data.redirect_links)
        );
      })
      .catch(function() {
        message.error(
          "Hmmm, there seems to be an error retrieving the redirect links"
        );
      });
  };
}

// Create a home page version
export function createRedirectLink(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-redirect-link`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Redirect Link Created");
        dispatch(reset("RedirectLinkForm"));
        dispatch(fetchAllRedirectLinks(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/redirect-links/${response.data.redirect_link_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this redirect links"
          );
        }
      });
  };
}

// REdita redirect link
export function editRedirectLink(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-redirect-link`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Redirect Link Saved");
        dispatch(reset("RedirectLinkForm"));
        dispatch(fetchAllRedirectLinks(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/redirect-links/${response.data.redirect_link_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this redirect link"
          );
        }
      });
  };
}
