import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { convertBooleanToTag } from "../../helpers";
import { Button, Select } from "antd";
const { Option } = Select;

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this.reactTable.getResolvedState().sortedData;
    this.setState({ data: data });
  }

  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right", height: "31px" }}>
          <div style={{ margin: "10px", textAlign: "right", height: "31px" }}>
            <Link to={"/portal/user-management/create-user"}>
              <Button
                ghost
                icon="file-add"
                type="primary"
                style={{ marginRight: "5px" }}
              >
                Create Admin User
              </Button>
            </Link>
          </div>
        </div>
        <ReactTable
          data={this.props.adminUsers}
          ref={r => (this.reactTable = r)}
          noDataText="No Customers Found"
          filterable
          columns={[
            {
              Header: "ID",
              id: "random_user_id",
              accessor: d => d.random_user_id,
              Cell: row => (
                <Link to={"/portal/user-management/" + row.value}>
                  {row.value}
                </Link>
              )
            },
            {
              Header: "Email",
              id: "email",
              filterMethod: (filter, row) => {
                // console.log(row.user_email);
                var email = row.email;
                return email.toLowerCase().includes(filter.value.toLowerCase());
              },
              accessor: d => d.email
            },
            {
              Header: "Username",
              id: "username",
              filterMethod: (filter, row) => {
                // console.log(row.user_email);
                var username = row.username;
                return username
                  .toLowerCase()
                  .includes(filter.value.toLowerCase());
              },
              accessor: d => d.username
            },
            {
              Header: "Super User",
              id: "is_superuser",
              accessor: d => d.is_superuser,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["is_superuser"] === true;
                }
                return row["is_superuser"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Staff User",
              id: "is_staff",
              accessor: d => d.is_staff,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["is_staff"] === true;
                }
                return row["is_staff"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Fulfillment Super User",
              id: "fulfillment_super_user",
              accessor: d => d.fulfillment_super_user,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["fulfillment_super_user"] === true;
                }
                return row["fulfillment_super_user"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Fulfillment User",
              id: "fulfillment_user",
              accessor: d => d.fulfillment_user,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["fulfillment_user"] === true;
                }
                return row["fulfillment_user"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Customer Service User",
              id: "customer_service_user",
              accessor: d => d.customer_service_user,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["customer_service_user"] === true;
                }
                return row["customer_service_user"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Active",
              id: "is_active",
              accessor: d => d.is_active,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["is_active"] === true;
                }
                return row["is_active"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
