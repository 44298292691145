import React from "react";
import { Alert, Divider, Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import { plainNumberField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { discountSubscription } from "../../actions/actionsSubscriptions";

const validate = values => {
  const errors = {};

  if (!values.percentage_off) {
    errors.percentage_off = "Required";
  }
  if (values.percentage_off) {
    if (values.percentage_off > 100) {
      errors.percentage_off = "Values cannot be over 100%";
    }
  }
  return errors;
};

class DiscountView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["transaction_id"] = this.props.orderDetail.transaction_id;
    props["type_code"] = "add_discount";
    this.props.discountSubscription(props);
  }
  removeDiscount() {
    var data = {};
    data["transaction_id"] = this.props.orderDetail.transaction_id;
    data["type_code"] = "remove_discount";
    data["percentage_off"] = 0;
    this.props.discountSubscription(data);
  }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Danger Zone"
          description="You are about to discount a customer's subscription"
          type="error"
        />
        <br />
        {this.props.renderReusableCartInformation()}
        <Divider />
        {this.props.orderDetail.cart.discount ? (
          <div>
            <br />

            <Button
              loading={this.props.formLoading}
              block
              size="large"
              onClick={() => this.removeDiscount()}
              type="danger"
            >
              Remove Subscription
            </Button>
          </div>
        ) : (
          <Spin spinning={this.props.formLoading}>
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Field
                label={"Discount Percentage Ex 70"}
                name="percentage_off"
                required={true}
                suffix={"Add"}
                step={1}
                type={"number"}
                max={100}
                size="large"
                component={plainNumberField}
              />

              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="danger"
              >
                Discount Subscription
              </Button>
            </Form>
          </Spin>
        )}
      </div>
    );
  }
}

DiscountView = reduxForm({
  form: "DiscountForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(DiscountView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { discountSubscription }
)(DiscountView);
