import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the home page versions into state
export function fetchAllHomePageVersionsSuccess(data) {
  return { type: types.FETCH_ALL_HOME_PAGE_VERSIONS_SUCCESS, payload: data };
}

// Set the full home page version loading
export function setAllHomePageVersionsLoading() {
  return { type: types.FETCH_ALL_HOME_PAGE_VERSIONS_LOADING, payload: null };
}

// Set the full home page version soft loading
export function setAllHomePageVersionsSoftLoading() {
  return {
    type: types.FETCH_ALL_HOME_PAGE_VERSIONS_SOFT_LOADING,
    payload: null
  };
}

// Retrives All Of Subscriber Data
export function fetchAllHomePageVersions(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllHomePageVersionsLoading());
    } else {
      dispatch(setAllHomePageVersionsSoftLoading());
    }
    return axios({
      url: `${BASE_API_URL}get-all-home-page-versions`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(
          fetchAllHomePageVersionsSuccess(response.data.home_page_versions)
        );
      })
      .catch(function() {
        message.error(
          "Hmmm, there seems to be an error retrieving the home page versions"
        );
      });
  };
}

// Create a home page version
export function createHomePageVersion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-home-page-version`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Home Page Version Created");
        dispatch(reset("HomePageVersionForm"));
        dispatch(fetchAllHomePageVersions(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/home-page-versions/${response.data.version_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this home page version"
          );
        }
      });
  };
}

// Create a home page version
export function editHomePageVersion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-home-page-version`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Home Page Version Saved");
        dispatch(reset("HomePageVersionForm"));
        dispatch(fetchAllHomePageVersions(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/home-page-versions/${response.data.version_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this home page version"
          );
        }
      });
  };
}
