import * as types from "./types";
import {reset} from 'redux-form';

// Dispatches clearFormErrors for other comps to call it easily
export function clearFormErrorsDispatch() {
  return function(dispatch) {
    dispatch(clearFormErrors());
  };
}
// Clears all form errors
export function clearFormErrors() {
  return {
    type: types.FORM_CLEAR,
    payload: null
  };
}

// Clears all form errors
export function setFormFailureMessage(error) {
  return {
    type: types.FORM_FAILURE,
    payload: error
  };
}

// Clears all form errors
export function setFormSuccessMessage(message) {
  return {
    type: types.FORM_SUCCESS,
    payload: message
  };
}

// CallS Form Loading
export function setFormSubmittingTrue() {
  return {
    type: types.FORM_SUBMITTING,
    payload: null
  };
}

// Copies form values
export function setCopiedFormValues(values) {
  return function(dispatch) {
    dispatch(reset('HomePageVersionForm'));
    dispatch(reset('PromotionForm'));
    dispatch(reset('DiscountForm'));
    dispatch(setCopiedFormValuesDispatch(values));
  };
}
// Clears all form errors
export function setCopiedFormValuesDispatch(values) {
  return {
    type: types.SET_COPIED_VALUES,
    payload: values
  };
}

// Resets Copies form values
export function resetCopiedFormValues(values) {
  return function(dispatch) {
    dispatch(resetCopiedFormValuesDispatch(values));
  };
}
// Clears all form errors
export function resetCopiedFormValuesDispatch(values) {
  return {
    type: types.RESET_COPIED_VALUES,
    payload: values
  };
}
