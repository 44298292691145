import React from "react";
import { Alert, Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import { plainTextField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { resetPassword } from "../../actions/actionsCustomers";

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Required";
  } else {
    if (values.confirm_password !== values.password) {
      errors.confirm_password = "The passwords need to match";
    }
  }
  return errors;
};

class ResetPasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (this.props.adminReset) {
      props.random_user_id = this.props.customerDetail.random_user_id;
    } else {
      props.random_user_id = this.props.customerDetail.details.random_user_id;
    }
    this.props.resetPassword(props, this.props.adminReset);
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Head's Up"
          description="You are about to reset a customer's password"
          type="info"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Field
              label={"Password"}
              name="password"
              required={true}
              size="large"
              type="password"
              component={plainTextField}
            />
            <Field
              label={"Confirm Password"}
              name="confirm_password"
              required={true}
              size="large"
              type="password"
              component={plainTextField}
            />
            <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Reset Password
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

ResetPasswordView = reduxForm({
  form: "ResetPasswordForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ResetPasswordView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { resetPassword }
)(ResetPasswordView);
