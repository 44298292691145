import * as types from "../actions/types";

const INITIAL_STATE = {
  subscriptions: null,
  subscriptions_loading: false,
  upcoming_subscription_charges: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_SUBSCRIPTIONS_LOADING:
      return { ...state, subscriptions_loading: !state.subscriptions_loading };
    case types.FETCH_ALL_UPCOMING_SUBSCRIPTION_CHARGES_SUCCESS:
      return { ...state, upcoming_subscription_charges: action.payload };
    case types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        subscriptions_loading: false
      };

    default:
      return state;
  }
}
