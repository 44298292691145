import React from "react";
import { Icon, Button, Tooltip } from "antd";

class DeleteItem extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          marginTop: "42px"
        }}
      >
        <Tooltip placement="left" title={"Remove item"}>
          <Button
            shape="circle"
            type="danger"
            ghost
            onClick={this.props.removeItem}
          >
            <Icon type="delete" />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default DeleteItem;
