import React from "react";
import DataPoint from "../Common/DataPoint";

class DasboardRow extends React.Component {
  getDiscountsTypes(activeType) {
    var count = 0;
    this.props.discounts.map(function(discount) {
      if (activeType) {
        if (discount.number_of_redemptions !== 0) {
          count = count + 1;
        }
      } else {
        if (discount.number_of_redemptions === 0) {
          count = count + 1;
        }
      }
      return discount;
    });
    return count;
  }
  render() {
    return (
      <div className="dashboard-row">
        <DataPoint
          data={this.props.discounts.length}
          label={"Discounts"}
          positive={false}
        />
        <DataPoint
          data={this.getDiscountsTypes(true)}
          label={"Active"}
          positive={false}
        />
        <DataPoint
          data={this.getDiscountsTypes(false)}
          label={"InActive"}
          positive={false}
        />
      </div>
    );
  }
}

export default DasboardRow;
