import React from "react";
import { connect } from "react-redux";
import { fetchAllDiscounts } from "../../actions/actionsDiscounts";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/Discounts/DashboardRow";
import Table from "../../components/Discounts/Table";
import DiscountFormView from "../../components/Discounts/DiscountFormView";

class Discounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDiscount: null,
      searchFilter: "",
      childModalOpen: false,
      createDiscountViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
  }
  setSelectedDiscount(nextProps) {
    var discountID = nextProps.location.pathname.match(/\d+/);
    if (discountID) {
      discountID = discountID[0];
      var that = this;
      nextProps.discounts.map(function(discount) {
        if (discount.discount_id === discountID) {
          that.setState({ selectedDiscount: discount });
        }
        return discount;
      });
    } else {
      this.setState({ selectedDiscount: null });
    }
  }

  // Checks to see whether or not to open the secondery modal inside of the customer detail view
  checkIfChildModalOpen(pathname) {
    var createDiscountViewOpen = false;

    if (pathname.includes("create")) {
      createDiscountViewOpen = true;
    }

    this.setState({
      createDiscountViewOpen: createDiscountViewOpen
    });
  }

  //   Go back to the main Discounts page
  closeModal() {
    history.push("/portal/discounts");
    this.setState({
      selectedDiscount: null
    });
  }

  getTitle() {
    if (this.state.selectedDiscount) {
      return `Edit Discount (${this.state.selectedDiscount.discount_id})`;
    }
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the Discounts are not already populated, populate them
    if (!this.props.discounts) {
      this.props.fetchAllDiscounts();
    } else {
      this.setSelectedDiscount(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.discounts) {
      this.setSelectedDiscount(nextProps);
    }
  }

  getInitialValues(discount) {
    if (discount) {
      var initialValues = Object.assign({}, discount);

      initialValues.programatic_discount = discount.programatic_discount.toString();
      
      return initialValues;
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.discounts && !this.props.discountsLoading ? (
            <div>
              {!this.props.loggedInUser.customer_service_user && (
                <div>
                  <Divider orientation="left">Overall Stats </Divider>
                  <DashboardRow discounts={this.props.discounts} />
                </div>
              )}
              <Table discounts={this.props.discounts} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title="Create Discount"
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.createDiscountViewOpen}
        >
          <DiscountFormView 
            initialValues={this.props.copiedValues}
          />
        </Drawer>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedDiscount ? true : false}
        >
          <DiscountFormView
            initialValues={this.getInitialValues(this.state.selectedDiscount)}
            edit={true}
          />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    discounts: state.discounts.discounts,
    discountsLoading: state.discounts.discounts_loading,
    loggedInUser: state.auth.logged_in_user,
    discountDetail: state.discounts.discount_detail,
    copiedValues: state.formStatus.copied_values,
  };
}

export default connect(
  mapStateToProps,
  { fetchAllDiscounts }
)(Discounts);
