import React from "react";
import {
  Button,
  Form,
  Spin,
  Divider,
  Row,
  Col,
  Icon,
  Skeleton,
  Modal,
  Typography
} from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField
} from "../Form/FieldRenders";
import { Field, reduxForm, getFormValues } from "redux-form";
import { productArray, productGroupArray,  trueFalseArray, buttonColorArray, discountLockedTypes } from "../../helpers";
import {
  createPromotion,
  editPromotion
} from "../../actions/actionsPromotions";
import { fetchAllDiscounts } from "../../actions/actionsDiscounts";
import { setCopiedFormValues } from "../../actions/actionsFormStatus";
import history from "../../history";
import ModalTemplate from "../../assets/images/modal.png";
import ModalStep1 from "../../assets/images/step_1.gif";
import ModalStep2 from "../../assets/images/step_2.gif";

const validate = values => {
  const errors = {};
  if (!values.internal_title) {
    errors.internal_title = "Required";
  }
  if (!values.internal_description) {
    errors.internal_description = "Required";
  }
  if (!values.active) {
    errors.active = "Required";
  }

  if (!values.display_modal) {
    errors.display_modal = "Required";
  }

  if (values.product && values.discount) {
    errors.product = "You can't have both assigned.";
    errors.discount = "You can't have both assigned.";
  }
  if (values.product && !values.free_product_for_life) {
    errors.free_product_for_life = "You have to decide if this is free for life.";
  }
  if (values.product && !values.locked_promotion_type) {
    errors.locked_promotion_type = "You have to decide if you want to lock it to a certain product group";
  }
  if (!values.product && !values.discount) {
    errors.product = "One of these is required.";
    errors.discount = "One of these is required.";
  }
  if (!values.display_modal) {
    errors.display_modal = "Required";
  } else if (values.display_modal === "true") {
    if (!values.header) {
      errors.header = "Required";
    }
    if (!values.button_color) {
      errors.button_color = "Required";
    }
    if (!values.subheader) {
      errors.subheader = "Required";
    }
    if (!values.call_out_header) {
      errors.call_out_header = "Required";
    }
    if (!values.call_to_action) {
      errors.call_to_action = "Required";
    }
    if (!values.background_image_id) {
      errors.background_image_id = "Required";
    }
  }

  return errors;
};

class PromotionFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentfulGuideOpen: false,
      imageGalleryOpen: false
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (!props.product || props.product.length < 2) {
      props.product = null;
    }
    if (this.props.edit) {
      props.promotion_id = this.props.initialValues.promotion_id;
      this.props.editPromotion(props);
    } else {
      this.props.createPromotion(props);
    }
  }

  componentWillMount() {
    if (!this.props.discounts) {
      this.props.fetchAllDiscounts();
    }
  }

  renderDiscounts(discounts) {
    var discountSet = [];
    discountSet.push({
      value: null,
      text: "-- No Discount -- "
    });
    discounts.map(function(discount) {
      if (discount.number_of_redemptions > 1) {
        var discountObject = {
          value: discount.user_facing_discount_id,
          text: discount.user_facing_discount_id
        };
        discountSet.push(discountObject);
      }
      return discount;
    });
    return discountSet;
  }

    // Coipes the curren home page versino
    copyPromotion() {
 
    
      // Fir the home page values do esist
      if (this.props.copyableFormValues.values) {
        let formValues = this.props.copyableFormValues.values
        this.props.setCopiedFormValues(formValues)
        setTimeout(function(){ 
          
          history.push("/portal/");
        
        }, 1000);
        setTimeout(function(){ 
          
          history.push("/portal/promotions/create-promotion");
        
        }, 1000);
      }
    }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Modal
          title="Image Gallery"
          width={520}
          visible={this.state.imageGalleryOpen}
          onCancel={() => this.setState({ imageGalleryOpen: false })}
          onOk={() => this.setState({ imageGalleryOpen: false })}
        >
          <h3>Image (1Eg4pkFd8k4IYXjzN3ZpH3)</h3>
          <img
            src="https://images.ctfassets.net/z3ixs6i8tjtq/1Eg4pkFd8k4IYXjzN3ZpH3/f0ee498d9908a47ef37863f49790878f/new_modal_image.jpg?q=90&fl=progressive&w=961&fit=fill&fm=jpg"
            style={{ width: "100%" }}
            alt="background "
          />
        </Modal>

        <Modal
          title="How To Upload Custom Image"
          width={800}
          visible={this.state.contentfulGuideOpen}
          onCancel={() => this.setState({ contentfulGuideOpen: false })}
          onOk={() => this.setState({ contentfulGuideOpen: false })}
        >
          <h3>Step 1: Download Template</h3>
          <p>
            Use this{" "}
            <a href={ModalTemplate} download>
              template image
            </a>{" "}
            to see the proporations the layout of the text on the background.
            Your background should be plain, however, take note on how the image
            is focussed on the top to not be convered by the email form.
          </p>
          <h3>Step 2: Upload To Contentful</h3>

          <p>
            Go to{" "}
            <a
              href="https://app.contentful.com/spaces/z3ixs6i8tjtq/assets"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contentful <Icon type="link" />
            </a>{" "}
            and upload the png or jpeg
          </p>
          <p>Its a gif so you have to watch it a couple times.</p>
          <img
            src={ModalStep1}
            style={{ width: "100%" }}
            alt="Modal Tutorial"
          />
          <h3>Step 3: Copy Image ID Into Field</h3>
          <p>Its a gif so you have to watch it a couple times.</p>
          <img
            src={ModalStep2}
            style={{ width: "100%" }}
            alt="Modal Tutorial"
          />
        </Modal>
        <Spin
          spinning={this.props.formLoading || this.props.promotionsSoftLoading}
        >
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.promotionsSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Internal Information </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Internal Title"}
                    name="internal_title"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                  <p>
                    Be descriptive with this. You will assign this to a landing
                    page with this title.
                  </p>
                </Col>
                <Col span={24}>
                  <Field
                    label={"Internal Description"}
                    name="internal_description"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={3}
                    component={textAreaField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Active"}
                    name="active"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                  <p>
                    If this is set to False, nothing here will apply to the
                    landing page version you link it to.
                  </p>
                </Col>
              </Row>
              <Divider>Promotion Info </Divider>
              <p style={{ textAlign: "center" }}>
                You can choose between whether you want to assign a free product
                or assign an auto dicount to the hero modal. You can also choose
                to do niether! However, you can't do both.
              </p>
              <Row gutter={6}>
                <Col span={16}>
                  <Field
                    label={"Free Promo Product"}
                    name="product"
                    size={"large"}
                    optionGroups={productGroupArray}
                    options={productArray}
                    component={selectField}
                  />
                   {this.props.formValues &&
                    this.props.formValues.product && (
                    <div>

                      <Field
                        label={"Free Product For Life"}
                        name="free_product_for_life"
                        size={"large"}
                        type={"text"}
                        overRideTouched={true}
                        rows={3}
                        options={trueFalseArray}
                        component={selectField}
                      />
                      <p>If set to TRUE, then the product will be free for all reccuring orders. Use with care. </p>
                      <Field
                        label={"Lock Promotion To Product Type"}
                        name="locked_promotion_type"
                        overRideTouched={true}
                        size={"large"}
                        options={discountLockedTypes}
                        component={selectField}
                      />
                      <p>This will restrict the promotion from being added to orders that do not conform to the lock type. This ONLY applies to free product promos. If you want to do this with discounts you have to edit the discount lock type on the dicount page.</p>

                    </div>

                    ) }

                

                </Col>
                <Col span={8}>
                  {this.props.discounts ? (
                    <Field
                      label={"Discount"}
                      name="discount"
                      size={"large"}
                      showSearch={true}
                      options={this.renderDiscounts(this.props.discounts)}
                      component={selectField}
                    />
                  ) : (
                    <Icon type="sync" spin />
                  )}
                </Col>
              </Row>
              <Divider>Email Modal Properties </Divider>
              <p style={{ textAlign: "center" }}>
                To be clear, the user does not have to put their email in to get
                the promotion. It is auto applied to their session once they hit
                the page.
              </p>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Display Modal Pop Up"}
                    name="display_modal"
                    required={true}
                    size={"large"}
                    type={"text"}
                    rows={3}
                    options={trueFalseArray}
                    component={selectField}
                  />
                  <p>Wheter or not the email modal will pop up </p>
                </Col>

                <Col span={24}>
                  <Field
                    label={"Call Out Header"}
                    name="call_out_header"
                    overRideTouched={true}
                    size={"large"}
                    type={"text"}
                    rows={3}
                    component={textAreaField}
                  />
                  <p>Press [ENTER] to create the line break </p>
                </Col>

                <Col span={24}>
                  <Field
                    label={"Header"}
                    name="header"
                    overRideTouched={true}
                    size={"large"}
                    type={"text"}
                    rows={3}
                    component={textAreaField}
                  />
                  <p>Press [ENTER] to create the line break </p>
                </Col>
                <Col span={24}>
                  <Field
                    label={"SubHeader"}
                    name="subheader"
                    overRideTouched={true}
                    size={"large"}
                    type={"text"}
                    rows={3}
                    component={textAreaField}
                  />
                  <p>Press [ENTER] to create the line break </p>
                </Col>
                <Col span={24}>
                  <Field
                    label={"Modal Image ID"}
                    name="background_image_id"
                    overRideTouched={true}
                    size={"large"}
                    type={"text"}
                    rows={3}
                    component={plainTextField}
                  />
                  {this.props.initialValues &&
                    this.props.initialValues.modal_image && (
                      <p>
                        {" "}
                        View current uploaded image{" "}
                        <a
                          href={this.props.initialValues.modal_image}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here <Icon type="file-image" theme="twoTone" />
                        </a>
                        <br />
                        <br />
                        OR
                        <br />
                      </p>
                    )}

                  <p>
                    If you want to add a new image, please go to contentful and
                    follow this{" "}
                    <a
                      href="#guide"
                      onClick={() =>
                        this.setState({ contentfulGuideOpen: true })
                      }
                    >
                      handy guide
                    </a>{" "}
                    to how to copy the{" "}
                    <Typography.Text code>image id</Typography.Text> into the
                    field.
                  </p>
                  <p>OR</p>
                  <p>
                    You can copy and paste a predefined background image from
                    this{" "}
                    <a
                      href="#imageGallery"
                      onClick={() => this.setState({ imageGalleryOpen: true })}
                    >
                      handy gallery
                    </a>
                    !
                  </p>
                </Col>
                <Col span={12}>
                  <Field
                    label={"Call to Action (Button Text)"}
                    name="call_to_action"
                    size={"large"}
                    overRideTouched={true}
                    type={"text"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Button Color"}
                    name="button_color"
                    size={"large"}
                    overRideTouched={true}
                    options={buttonColorArray}
                    component={selectField}
                  />
                </Col>
              </Row>

              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit ? "Edit Promotion" : "Create Promotion"}
              </Button>
            </Form>
          </Skeleton>
          <br />
          <br />
        </Spin>
        {
          !this.props.createView && (
            <div className="bottom-bar">
              <Button type="primary" style={{ marginRight: 8 }} ghost onClick={() => this.copyPromotion()}>
                  Copy Promotion
                </Button>
              <br />
            </div>
          )
        }
      </div>
    );
  }
}

PromotionFormView = reduxForm({
  form: "PromotionForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(PromotionFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    promotionsSoftLoading: state.promotions.promotions_soft_loading,
    discounts: state.discounts.discounts,
    formValues: getFormValues("PromotionForm")(state),
    copyableFormValues: state.form.PromotionForm
  };
}

export default connect(
  mapStateToProps,
  { createPromotion, fetchAllDiscounts, editPromotion, setCopiedFormValues }
)(PromotionFormView);
