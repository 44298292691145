import React from "react";
import { Alert, Button, Form, Spin, Divider, Row, Col, Drawer, Typography } from "antd";
import { connect } from "react-redux";
import {
  selectField,
  plainNumberField,
  plainTextField,
  textAreaField
} from "../Form/FieldRenders";
import { Field, reduxForm, change } from "redux-form";
import { createShipment } from "../../actions/actionsOrders";
import DeleteItem from "../../components/Common/DeleteItem";
import {
  shippingMethodArray,
  trueFalseArray,
  stateArray,
  productGroupArray,
  productArray,
  getScreenWidth
} from "../../helpers";
import { normalizePhone, normalizeZipcode } from "../Form/Normalize";

var productCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 , 16 , 17, 18];

function renderValidate(values, errors) {
  var valueArray = [];
  productCount.map(function(count) {
    valueArray.push(values[`product_${count}`]);
    return count;
  });
  valueArray = valueArray.filter(function(value) {
    return value;
  });
  let hasDuplicates = new Set(valueArray).size !== valueArray.length;
  if (hasDuplicates) {
    productCount.map(function(count) {
      errors[`product_${count}`] = "Items in sample order need to be unique";
      return count;
    });
  }

  return errors;
}

const validate = values => {
  const errors = {};
  if (!values.full_name) {
    errors.full_name = "Required";
  }
  if (!values.by_pass_address_validation) {
    errors.by_pass_address_validation = "Required";
  }
  if (!values.address) {
    errors.address = "Required";
  }

  if (!values.city) {
    errors.city = "Required";
  }
  if (!values.state) {
    errors.state = "Required";
  }
  if (!values.zip_code) {
    errors.zip_code = "Required";
  }
  if (!values.order_id) {
    errors.order_id = "Required";
  } else {
    if (values.order_id.includes(" ")) {
      errors.order_id = "No spaces ' ' allowed";
    }
  }
  if (values.product_2) {
    if (!values.quantity_2) {
      errors.quantity_2 = "Required";
    }
  }
  if (values.quantity_2) {
    if (!values.product_2) {
      errors.product_2 = "Required";
    }
  }
  if (values.product_3) {
    if (!values.quantity_3) {
      errors.quantity_3 = "Required";
    }
  }
  if (values.quantity_3) {
    if (!values.product_3) {
      errors.product_3 = "Required";
    }
  }
  if (values.product_4) {
    if (!values.quantity_4) {
      errors.quantity_4 = "Required";
    }
  }
  if (values.quantity_4) {
    if (!values.product_4) {
      errors.product_4 = "Required";
    }
  }
  if (values.product_5) {
    if (!values.quantity_5) {
      errors.quantity_5 = "Required";
    }
  }
  if (values.quantity_5) {
    if (!values.product_5) {
      errors.product_5 = "Required";
    }
  }
  if (values.product_6) {
    if (!values.quantity_6) {
      errors.quantity_6 = "Required";
    }
  }
  if (values.quantity_6) {
    if (!values.product_6) {
      errors.product_6 = "Required";
    }
  }
  if (values.product_7) {
    if (!values.quantity_7) {
      errors.quantity_7 = "Required";
    }
  }
  if (values.quantity_7) {
    if (!values.product_7) {
      errors.product_7 = "Required";
    }
  }
// --
  if (values.product_8) {
    if (!values.quantity_8) {
      errors.quantity_8 = "Required";
    }
  }
  if (values.quantity_8) {
    if (!values.product_8) {
      errors.product_8 = "Required";
    }
  }
// --
  if (values.product_9) {
    if (!values.quantity_9) {
      errors.quantity_9 = "Required";
    }
  }
  if (values.quantity_9) {
    if (!values.product_9) {
      errors.product_9 = "Required";
    }
  }
// --
  if (values.product_10) {
    if (!values.quantity_10) {
      errors.quantity_10 = "Required";
    }
  }
  if (values.quantity_10) {
    if (!values.product_10) {
      errors.product_10 = "Required";
    }
  }
// --
  if (values.product_11) {
    if (!values.quantity_11) {
      errors.quantity_11 = "Required";
    }
  }
  if (values.quantity_11) {
    if (!values.product_11) {
      errors.product_11 = "Required";
    }
  }
// --
  if (values.product_12) {
    if (!values.quantity_12) {
      errors.quantity_12 = "Required";
    }
  }
  if (values.quantity_12) {
    if (!values.product_12) {
      errors.product_12 = "Required";
    }
  }
  //  --
  if (values.product_13) {
    if (!values.quantity_13) {
      errors.quantity_13 = "Required";
    }
  }
  if (values.quantity_13) {
    if (!values.product_13) {
      errors.product_13 = "Required";
    }
  }
  //  --
  if (values.product_14) {
    if (!values.quantity_14) {
      errors.quantity_14 = "Required";
    }
  }
  if (values.quantity_14) {
    if (!values.product_14) {
      errors.product_14 = "Required";
    }
  }
  //  --
  if (values.product_15) {
    if (!values.quantity_15) {
      errors.quantity_15 = "Required";
    }
  }
  if (values.quantity_15) {
    if (!values.product_15) {
      errors.product_15 = "Required";
    }
  }
  //  --
  if (values.product_16) {
    if (!values.quantity_16) {
      errors.quantity_16 = "Required";
    }
  }
  if (values.quantity_16) {
    if (!values.product_16) {
      errors.product_16 = "Required";
    }
  }
  //  --
  if (values.product_17) {
    if (!values.quantity_17) {
      errors.quantity_17 = "Required";
    }
  }
  if (values.quantity_17) {
    if (!values.product_17) {
      errors.product_17 = "Required";
    }
  }
  //  --
  if (values.product_18) {
    if (!values.quantity_18) {
      errors.quantity_18 = "Required";
    }
  }
  if (values.quantity_18) {
    if (!values.product_18) {
      errors.product_18 = "Required";
    }
  }


  if (!values.product_1) {
    errors.product_1 = "Required";
  }
  if (!values.quantity_1) {
    errors.quantity_1 = "Required";
  }

  return renderValidate(values, errors);
};

class CreateSampleBoxView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["address_type"] = "shipping_address";
    props["new_shipment"] = true;
    this.props.createShipment(props);
  }

  removeItem(productID, quantityID) {
    this.props.change(productID, null);
    this.props.change(quantityID, null);
  }

  setPreset(bundlePreset) {
    window.location = `/portal/orders/create-sample-shipment?bundle=${bundlePreset}`
  }

  renderProductPreSet() {
    return (
      <select onChange={(e) => this.setPreset(e.target.value)}>
          <option value="complete_home_periwinkle_bundle">complete_home_periwinkle_bundle</option>
          <option value="complete_home_linen_bundle">complete_home_linen_bundle</option>
          <option value="complete_home_black_bundle">complete_home_black_bundle</option>
          <option value="complete_home_mosaic_bundle">complete_home_mosaic_bundle</option>
          <option value="complete_home_periwinkle_floral_bundle">complete_home_periwinkle_floral_bundle</option>
          <option value="complete_home_linen_floral_bundle">complete_home_linen_floral_bundle</option>
          <option value="complete_home_black_floral_bundle">complete_home_black_floral_bundle</option>
          <option value="complete_home_mosaic_floral_bundle">complete_home_mosaic_floral_bundle</option>
          <option value="complete_home_periwinkle_fruity_bundle">complete_home_periwinkle_fruity_bundle</option>
          <option value="complete_home_linen_fruity_bundle">complete_home_linen_fruity_bundle</option>
          <option value="complete_home_black_fruity_bundle">complete_home_black_fruity_bundle</option>
          <option value="complete_home_mosaic_fruity_bundle">complete_home_mosaic_fruity_bundle</option>
          <option value="complete_home_periwinkle_exotic_bundle">complete_home_periwinkle_exotic_bundle</option>
          <option value="complete_home_linen_exotic_bundle">complete_home_linen_exotic_bundle</option>
          <option value="complete_home_black_exotic_bundle">complete_home_black_exotic_bundle</option>
          <option value="complete_home_mosaic_exotic_bundle">complete_home_mosaic_exotic_bundle</option>
      </select>
    )
  }

  renderProductPickers() {
    var count = 0;
    var productNumbers = [];
    while (count <= 17) {
      count += 1;
      productNumbers.push(count.toString());
    }
    var that = this;
    return productNumbers.map(function(productNumber) {
      var required = false;
      if (productNumber === "1") {
        required = true;
      }
      return (
        <Row gutter={6} key={productNumber}>
          <Col span={17}>
            <Field
              label={`Product ${productNumber}`}
              name={`product_${productNumber}`}
              allowClear={true}
              size={"large"}
              showSearch={false}
              optionGroups={productGroupArray}
              overRideTouched={!required}
              required={required}
              options={productArray}
              component={selectField}
              filterOption={(input, option) => (
                option.props.children && option.props.children.includes(input.toLowerCase())
              )}
            />
          </Col>
          <Col span={5}>
            <Field
              label={`Qty ${productNumber}`}
              name={`quantity_${productNumber}`}
              min={1}
              max={100}
              overRideTouched={!required}
              step={1}
              required={required}
              type={"number"}
              size="large"
              component={plainNumberField}
            />
          </Col>
          <Col span={2}>
            <DeleteItem
              removeItem={() =>
                that.removeItem(
                  `product_${productNumber}`,
                  `quantity_${productNumber}`
                )
              }
            />
          </Col>
        </Row>
      );
    });
  }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <Drawer
        placement="right"
        title="Create Free Sample"
        width={getScreenWidth(false)}
        closable={true}
        onClose={() => this.props.closeModal()}
        visible={this.props.createSampleViewOpen}
      >
        <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
     
          <Alert
            message="Head's Up"
            description="You are about to send free product to a customer"
            type="info"
          />
          <Divider>Bundle Preset For Products</Divider>
          {this.renderProductPreSet()}
          <p>This will reset the entire form so do it in the beginging of the process or not at all. It will select the bundle products based on your selection</p>
          <br />

          <Spin spinning={this.props.formLoading}>
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Address Details</Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Full Name"}
                    name={`full_name`}
                    required={true}
                    allowClear={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={16}>
                  <Field
                    label={"Address"}
                    name={`address`}
                    required={true}
                    allowClear={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    label={"Apt. Number"}
                    name={`apt_number`}
                    allowClear={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={16}>
                  <Field
                    label={"City"}
                    name={`city`}
                    required={true}
                    allowClear={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={8}>
                  <Field
                    label={"State"}
                    name={`state`}
                    required={true}
                    allowClear={true}
                    showSearch={true}
                    size={"large"}
                    options={stateArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Zip Code"}
                    name={`zip_code`}
                    required={true}
                    normalize={normalizeZipcode}
                    allowClear={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Phone"}
                    name={`phone`}
                    required={false}
                    normalize={normalizePhone}
                    allowClear={true}
                    showSearch={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
              <Col span={24}>
                <Field
                  label={"By Pass Address Validation"}
                  name={`by_pass_address_validation`}
                  required={true}
                  options={trueFalseArray}
                  allowClear={true}
                  showSearch={true}
                  size={"large"}
                  component={selectField}
                />
              </Col>
              <p>Should be defaulted to <Typography.Text code>FALSE</Typography.Text> , however, if you are getting an address error, but are 100% sure the address is right, set to <Typography.Text code>TRUE</Typography.Text>  and the system will not check if the address is correct or not. </p>
            </Row>

              <Divider>Product Details</Divider>
              {this.renderProductPickers()}
              <Divider>Shipping Details</Divider>
              <Field
                label={"Shipment Method"}
                name="service"
                size={"large"}
                options={shippingMethodArray}
                component={selectField}
              />
              <Field
                label={"Pick Pack Priority"}
                name="pick_pack_priority"
                suffix={
                  "If set to True, the order will jump to the top of the fulfillment que"
                }
                size={"large"}
                options={trueFalseArray}
                component={selectField}
              />
              <Field
                label={"Overbox Order"}
                name="overbox"
                size={"large"}
                options={trueFalseArray}
                component={selectField}
              />
              <p>If set to <Typography.Text code>TRUE</Typography.Text>, the order have another box surounding it to protect it. Always set to  <Typography.Text code>FALSE</Typography.Text>, unless you know what you are doing. </p>
              <br />
              <p className="copy-and-paste-prompt">If this is for a special client - then <Typography.Text code>COPY & PASTE</Typography.Text> this into special instructions</p>
              <div className="copy-and-paste">
                This is for a VIP client - Please have Rick inspect <br />
                  - Please remove all bands from glass bottles <br />
                  - Please clean bottles incl. glue dot from band <br />
                  - Please unscrew all spouts on pump bottles
              </div>
              <Field
                label={"Pick Pack Special Instructions"}
                name="pick_pack_special_instructions"
                hideErrorLabel={false}
                required={false}
                size="large"
                rows={3}
                component={textAreaField}
              />
              <p>Only the warehouse team will these instrucitons. The carrier will not nor will the customer</p>
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                Create Free Sample
              </Button>
            </Form>
          </Spin>
        </div>
      </Drawer>
    );
  }
}

CreateSampleBoxView = reduxForm({
  form: "CreateSampleBox",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateSampleBoxView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { createShipment, change }
)(CreateSampleBoxView);
