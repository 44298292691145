import React from "react";
import { connect } from "react-redux";
import { fetchAllQuestionsAndReviews } from "../../actions/actionsQuestionsAndReviews";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import {  Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import Table from "../../components/Reviews/Table";
import ReviewFormView from "../../components/Reviews/ReviewFormView";

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedReview: null,
      searchFilter: "",
      childModalOpen: false,
      createDiscountViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
  }
  setSelectedReview(nextProps) {
    var reviewID = nextProps.location.pathname.match(/\d+/);
    if (reviewID) {
      reviewID = reviewID[0];
      var that = this;
      nextProps.reviews.map(function(review) {
        if (review.review_id === reviewID) {
          that.setState({ selectedReview: review });
        }
        return review;
      });
    } else {
      this.setState({ selectedReview: null });
    }
  }

  onClose() {
    this.setState({
      selectedReview: null
    });
  }

  //   Go back to the main Reviews page
  closeModal() {
    history.push("/portal/reviews");
    this.setState({
      selectedReview: null
    });
  }

  getTitle() {
    if (this.state.selectedReview) {
      return `Edit Review (${this.state.selectedReview.review_id})`;
    }
  }

  componentWillMount() {
    //   If the Reviews are not already populated, populate them
    if (!this.props.reviews) {
      this.props.fetchAllQuestionsAndReviews();
    } else {
      this.setSelectedReview(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.reviews) {
      this.setSelectedReview(nextProps);
    }
  }

  getInitialValues(review) {
    if (review) {
      var initialValues = Object.assign({}, review);

      initialValues.verified_review = review.verified_review.toString();
      initialValues.display_review = review.display_review.toString();
      return initialValues;
    }
  }
  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.reviews && !this.props.reviewsLoading ? (
            <div>
              <br />
              <Table reviews={this.props.reviews} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedReview ? true : false}
        >
          <ReviewFormView
            initialValues={this.getInitialValues(this.state.selectedReview)}
            edit={true}
          />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reviews: state.questions_and_reviews.reviews,
    reviewsLoading: state.questions_and_reviews.questions_and_reviews_loading,
    loggedInUser: state.auth.logged_in_user,
    productInventory: state.inventory.product_inventory
  };
}

export default connect(
  mapStateToProps,
  { fetchAllQuestionsAndReviews }
)(Reviews);
