import React from "react";
import { Menu, Icon, Typography } from "antd";
import history from "../../history";
const { SubMenu } = Menu;

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "/portal/orders",
      selectedParentMenu: [],
      sideMenuMobileOpen: false
    };
    this.findSelectedMenu = this.findSelectedMenu.bind(this);
  }

  findSelectedMenu(location) {
    var menuKeys = [
      { 
        key: "orders",
        parent: "ecommerce",
      },
      { 
        key: "subscriptions",
        parent: "ecommerce",
      },
      { 
        key: "products",
        parent: "ecommerce",
      },
      { 
        key: "discounts",
        parent: "ecommerce",
      },
      { 
        key: "questions",
        parent: "userFeedback",
      },
      { 
        key: "fulfillment",
        parent: "logistics",
      },
      { 
        key: "home-page-versions",
        parent: "marketing",
      },
      { 
        key: "reviews",
        parent: "userFeedback",
      },
      { 
        key: "redirect-links",
        parent: "marketing",
      },
      { 
        key: "promotions",
        parent: "marketing",
      },
      { 
        key: "customers",
        parent: "ecommerce",
      },
      { 
        key: "addresses",
        parent: "ecommerce",
      },
      { 
        key: "inventory",
        parent: "logistics",
      },
      { 
        key: "replacement-boxes",
        parent: "logistics",
      },
      { 
        key: "user-management",
        parent: "",
      },
      { 
        key: "b2b-orders",
        parent: "",
      },
      { 
        key: "cohorts",
        parent: "analysis",
      },
      { 
        key: "reconciliation",
        parent: "analysis",
      },
    ];
    var that = this;
    menuKeys.map(function(menuKey) {
      if (location.pathname.includes(menuKey.key)) {
        that.setState({
          selectedMenu: `/portal/${menuKey.key}`,
          selectedParentMenu: [menuKey.parent],
        });
      }
      return menuKey;
    });
    
  
  }
  componentWillMount() {
    this.findSelectedMenu(this.props.location);
  }

  componentWillReceiveProps(nextProps) {
    this.findSelectedMenu(nextProps.location);
  }
  handleClick = e => {
    
    history.push(e.key);
  };
  handleMenuClick = e => {
    this.setState({
      selectedParentMenu: e
    })
    
  };

  toggleSideMenuMobileOpen() {
    this.setState({
      sideMenuMobileOpen: !this.state.sideMenuMobileOpen
    })
  }
  getToday() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  renderSideMenuMobileOpenClass(sideMenuOpen) {
    if (sideMenuOpen) {
      return "side-menu-open"
    } else {
      return "side-menu-closed"
    }
  }
  render() {
   var semiRestrictedEmailList = [
      "cleancult@propellerindustries.com",
    ]
    return (
      <div>
        <div className="menu-toggle-container">
          <Icon type={this.state.sideMenuMobileOpen ? "menu-fold" : "menu-unfold"} onClick={() => this.toggleSideMenuMobileOpen()}/>
        </div>
      {
        this.state.sideMenuMobileOpen && (
          <div className="side-menu-mobile-shadow" onClick={() => this.toggleSideMenuMobileOpen()} />
        )
      }
      <div className={"side-menu-container noselect " + this.renderSideMenuMobileOpenClass(this.state.sideMenuMobileOpen)}>
        <div className="greetings-container">
          <Typography.Text>
            Greetings{" "}
            {this.props.loggedInUser && this.props.loggedInUser.first_name}.
          </Typography.Text>
          <br />
          <Typography.Text code>{this.getToday()}</Typography.Text>
        </div>
        <Menu
          onClick={this.handleClick}
          onOpenChange={this.handleMenuClick}
          style={{
            width: 212,
            height: "100vh"
          }}
          selectable={true} 
          openKeys={this.state.selectedParentMenu}
          selectedKeys={[this.state.selectedMenu]}
          mode="inline"
        >
          <SubMenu
            key="ecommerce"
            title={
              <span>
                <Icon type="fund"  />
                <span>Ecommerce</span>
              </span>
            }
          >
            <Menu.Item key="/portal/orders">Orders</Menu.Item>
            <Menu.Item key="/portal/subscriptions">Subscriptions</Menu.Item>
            <Menu.Item key="/portal/customers">Customers</Menu.Item>
            <Menu.Item key="/portal/addresses">Addresses</Menu.Item>
            {!this.props.loggedInUser.customer_service_user &&  !semiRestrictedEmailList.includes(this.props.loggedInUser.email) && (
              <Menu.Item key="/portal/discounts">
                Discounts
              </Menu.Item>
            )}
            <Menu.Item key="/portal/products">Products</Menu.Item>
          </SubMenu>
          {!semiRestrictedEmailList.includes(this.props.loggedInUser.email) &&  (
          <SubMenu
            key="userFeedback"
            title={
              <span>
                <Icon type="like"    />
                <span>User Feedback</span>
              </span>
            }
          >
            <Menu.Item key="/portal/questions">
              Questions
            </Menu.Item>
          </SubMenu>
          )}
          {!this.props.loggedInUser.customer_service_user && !semiRestrictedEmailList.includes(this.props.loggedInUser.email)  && (
              <SubMenu
                key="marketing"
                title={
                  <span>
                    <Icon type="alert"   />
                    <span>Marketing</span>
                  </span>
                }
              >
                <Menu.Item key="/portal/home-page-versions">
                  "Landing" Pages
                </Menu.Item>
                <Menu.Item key="/portal/redirect-links">
                  Redirect Links
                </Menu.Item>
                <Menu.Item key="/portal/promotions">
                  Promotions
                </Menu.Item>
              </SubMenu>
          )}
        {!this.props.loggedInUser.customer_service_user && !semiRestrictedEmailList.includes(this.props.loggedInUser.email)  && (
            <Menu.Item key="/portal/b2b-orders">
              <Icon type="layout"  />
              B2B Orders
            </Menu.Item>
          )}
          {this.props.loggedInUser.is_superuser && (
            <Menu.Item key="/portal/user-management">
              <Icon type="crown"   />
              User Management
            </Menu.Item>
          )}
        </Menu>
      </div>
      </div>
    );
  }
}

export default SideMenu;
