import React from "react";
import {
  Drawer,
  Typography,
  Skeleton,
  Button,
  Divider,
  Card,
  Col,
  Row,
  List,
  Icon,
  Collapse
} from "antd";

import {
  resetUserOrderDetails,
  fetchUserOrderDetails
} from "../../actions/actionsOrders";
import {
  renderOrderStatus,
  convertBooleanToTag,
  renderVDLShipmentStatus,
  convertShippingMethodToTag,
  getScreenWidth,
  renderProductTag,
  boxTypeImageDict,
  renderDelayedReason,
  insertTypeImageDict
} from "../../helpers";
import {
  resetCustomerDetails,
  fetchCustomerAddressesAndPaymentMethods
} from "../../actions/actionsCustomers";
import DescriptionItem from "../Common/DescriptionItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import RefundView from "./RefundView";
import ChangeShipmentStatusView from "./ChangeShipmentStatusView";
import CreateReplacementBoxView from "./CreateReplacementBoxView";
import EditReplacementReasonView from "./EditReplacementReasonView";
import EditRefundReasonView from "./EditRefundReasonView";
import EditAwaitingOrderView from "./EditAwaitingOrderView";
import SplitOrderView from "./SplitOrderView";

var dateFormat = require("dateformat");

const { Text } = Typography;

const Panel = Collapse.Panel;
class DetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.renderReusableCartInformation = this.renderReusableCartInformation.bind(
      this
    );
  }

  componentWillMount() {
    this.props.resetUserOrderDetails();
    if (this.props.orderActive) {
      this.props.fetchUserOrderDetails(this.props.orderID);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.orderActive) {
      if (this.props.orderID !== nextProps.orderID) {
        setTimeout(this.props.resetUserOrderDetails(), 500);
        this.props.fetchUserOrderDetails(nextProps.orderID);
      }
    }

    // If there is an active order reset and fetch the current customer addresses
    if (nextProps.orderDetail) {
            
      this.props.resetCustomerDetails();
      // Fetch the customer details
      this.props.fetchCustomerAddressesAndPaymentMethods(
          nextProps.orderDetail.user.random_user_id
      );
    }
  }

  //   Extract the shipment items into an array
  convertShipmentHistory(shipmentHistory) {
    var segmentArray = [];
    for (var key in shipmentHistory) {
      shipmentHistory[key]["date"] = key;
      segmentArray.push(shipmentHistory[key]);
    }
    segmentArray = segmentArray.sort(function(first, second) {
      if (moment(second.date) > moment(first.date)) {
        return -1;
      } else {
        return 1;
      }
    });
    return segmentArray;
  }

  componentWillUnmount() {
    // When a component unmounts reset the customer details.
    this.props.resetCustomerDetails();
  }

  renderChildModalView(pathname) {
    var initialValues;
    if (pathname.includes("refund-order")) {
      return (
        <RefundView
          orderDetail={this.props.orderDetail}
          renderReusableCartInformation={this.renderReusableCartInformation}
        />
      );
    } else if (pathname.includes("edit-refund-reason")) {
      if (this.props.orderDetail.refund_information) {
        initialValues = {
          refund_type: this.props.orderDetail.refund_information.refund_reason,
          date_refunded: this.props.orderDetail.refund_information.date_refunded,
          additional_refund_details: this.props.orderDetail.refund_information.refund_reason_details,
          other_reason: this.props.orderDetail.refund_information.other_reason,
          items: this.props.orderDetail.refund_information.items,
        };
      }
      console.log("----")
      console.log(initialValues)
      return (
        <EditRefundReasonView
          orderDetail={this.props.orderDetail}
          initialValues={initialValues}
        />
      );

    } else if (pathname.includes("edit-replacement-reason")) {
      if (this.props.orderDetail.replacement_box_information) {
        initialValues = {
          replacement_type: this.props.orderDetail.replacement_box_information.replacement_reason,
          additional_replacement_details: this.props.orderDetail.replacement_box_information.replacement_reason_details,
          other_reason: this.props.orderDetail.replacement_box_information.other_reason,
          items: this.props.orderDetail.replacement_box_information.items,
          lot_codes: this.props.orderDetail.replacement_box_information.lot_codes,

        };


      }
    
      return (
        <EditReplacementReasonView
          orderDetail={this.props.orderDetail}
          initialValues={initialValues}
        />
      );

    } else if (pathname.includes("change")) {
      var service = "ups_surepost_1_lb_or_greater";
      if (this.props.orderDetail.service) {
        service = this.props.orderDetail.service;
      }
      return (
        <ChangeShipmentStatusView
          orderDetail={this.props.orderDetail}
          renderReusableCartInformation={this.renderReusableCartInformation}
          initialValues={{
            shipping_method: service,
            shipment_status: this.props.orderDetail.shipment_status,
            hold_reason: this.props.orderDetail.shipment_hold_reason,
            shipping_address: this.props.orderDetail.shipping_address.address_id,
            pick_pack_priority: this.props.orderDetail.pick_pack_priority.toString(),
            overbox_required: this.props.orderDetail.overbox_required.toString(),
            pick_pack_special_instructions: this.props.orderDetail.pick_pack_special_instructions
          }}
        />
      );
    } else if (pathname.includes("replacement")) {
      initialValues = {
        service: "ups_surepost_1_lb_or_greater",
        pick_pack_priority: "false",
        shipping_address: this.props.orderDetail.shipping_address.address_id,
      };
      // Get the intital values for the products and place them into the form
      var count = 1;
      this.props.orderDetail.cart.cart_item_set.map(function(cartItem) {
        initialValues[`quantity_${count}`] = cartItem.cart_total_item_quantity;
        initialValues[`product_${count}`] =
          cartItem.cart_item_product_information.id;

        count += 1;
        return cartItem;
      });
      return (
        <CreateReplacementBoxView
          orderDetail={this.props.orderDetail}
          renderReusableCartInformation={this.renderReusableCartInformation}
          initialValues={initialValues}
        />
      );
    } else if (pathname.includes("split")) {
      initialValues = {
        service: "fedex_smartpost_parcel_select",
        pick_pack_priority: "false"
      };
      // Get the intital values for the products and place them into the form
      count = 1;
      this.props.orderDetail.cart.cart_item_set.map(function (cartItem) {
        initialValues[`quantity_${count}`] = cartItem.cart_total_item_quantity;
        initialValues[`product_${count}`] =
            cartItem.cart_item_product_information.id;
        initialValues[`promo_item_${count}`] =
            cartItem.promotional_item.toString();
        initialValues[`promotional_item_for_life_${count}`] =
            cartItem.promotional_item_for_life.toString();
        initialValues[`one_time_purchase_${count}`] =
            cartItem.one_time_purchase.toString();
        count += 1;
        return cartItem;
    });
      return (
        <SplitOrderView
          orderDetail={this.props.orderDetail}
          renderReusableCartInformation={this.renderReusableCartInformation}
          initialValues={initialValues}
        />
      );
    }else if (pathname.includes("edit")) {
      initialValues = {
        service: "fedex_smartpost_parcel_select",
        pick_pack_priority: "false"
      };
      // Get the intital values for the products and place them into the form
      count = 1;
      this.props.orderDetail.cart.cart_item_set.map(function (cartItem) {
        initialValues[`quantity_${count}`] = cartItem.cart_total_item_quantity;
        initialValues[`product_${count}`] =
            cartItem.cart_item_product_information.id;
        initialValues[`promo_item_${count}`] =
            cartItem.promotional_item.toString();
        initialValues[`promotional_item_for_life_${count}`] =
            cartItem.promotional_item_for_life.toString();
        initialValues[`one_time_purchase_${count}`] =
            cartItem.one_time_purchase.toString();
        count += 1;
        return cartItem;
    });
      return (
        <EditAwaitingOrderView
          orderDetail={this.props.orderDetail}
          renderReusableCartInformation={this.renderReusableCartInformation}
          initialValues={initialValues}
        />
      );
    }
  }

  getTitle(pathname) {
    if (pathname.includes("refund-order")) {
      return "Refund Order";
    } else if (pathname.includes("change")) {
      return "Change Shipping Status";
    }  else if (pathname.includes("edit-refund-reason")) {
      return "Edit Refund Reason";
    } else if (pathname.includes("edit-replacement-reason")) {
      return "Edit Replacement Reason";
    }else if (pathname.includes("replacement")) {
      return "Send Replacement Box";
    } else if (pathname.includes("edit")) {
      return "Edit Items In Awaiting Shipment";
    }
  }

  // Pass this to refund view to be displayed withint he form
  renderReusableCartInformation() {
    return (
      <Row>
        <Text className="section-header">Cart Details</Text>
        <Col span={12}>
          <DescriptionItem
            title="Sub Total"
            content={"$" + this.props.orderDetail.cart.subtotal}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Total"
            content={"$" + this.props.orderDetail.cart.total}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Shipping Cost"
            content={"$" + this.props.orderDetail.cart.shipping_cost}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Tax"
            content={"$" + this.props.orderDetail.cart.tax}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="Discount Value"
            content={"$" + this.props.orderDetail.cart.total_discount_amount}
          />
        </Col>
        {
          this.props.orderDetail.cart.total_gift_card_amount && (
            <Col span={12}>
              <DescriptionItem
                title="Gift Card Amount Used"
                content={"-$" + this.props.orderDetail.cart.total_gift_card_amount}
              />
            </Col>
          )
        }
        <Col span={12}>
          <DescriptionItem
            title="Discount Name"
            content={this.props.orderDetail.cart.discount}
          />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title="If Referral Credit Used"
            content={convertBooleanToTag(
              this.props.orderDetail.cart.check_if_used_referral_credit
            )}
          />
        </Col>
        {this.props.orderDetail.cart.referral_promotion && (
          <Col span={12}>
            <DescriptionItem
              title="If Referred By Another User (Code)"
              content={
                <Link
                  to={`/portal/customers/${
                    this.props.orderDetail.referrering_user_id
                  }`}
                >
                  {this.props.orderDetail.cart.referral_promotion}
                </Link>
              }
            />
          </Col>
        )}
        {this.props.orderDetail.cart.refund_amount ? (
          <Col span={12}>
            <DescriptionItem
              title="Amount Refunded"
              content={"$" + this.props.orderDetail.cart.refund_amount}
            />
          </Col>
        ) : null}
        {this.props.orderDetail.cart.refund_amount ? (
          <Col span={12}>
            <DescriptionItem
              title="Total Value Left"
              content={` $${(
                this.props.orderDetail.cart.total -
                this.props.orderDetail.cart.refund_amount
              ).toFixed(2)}`}
            />
          </Col>
        ) : null}

        {this.props.orderDetail.cart.refund_reason &&  (
          <Col span={24}>
            <DescriptionItem
              title="Refund Reason"
              content={this.props.orderDetail.cart.refund_reason}
            />
          </Col>
        )}
      </Row>
    );
  }
  renderRelevantProductsToReplacement(items) {

    return items.map(function(item) {
      return renderProductTag(item)
    })
  }
  render() {
    return (
      <Drawer
        placement="right"
        width={getScreenWidth(false)}
        closable={true}
        onClose={() => this.props.closeModal()}
        visible={this.props.orderActive}
      >
        {this.props.orderDetail ? (
          <div className="modal-wrapper">
            <Drawer
              title={this.getTitle(this.props.location.pathname)}
              width={getScreenWidth(true)}
              closable={true}
              onClose={() => this.props.closeChildModal()}
              visible={this.props.childModalOpen}
            >
              {this.renderChildModalView(this.props.location.pathname)}
            </Drawer>
            <Text strong className="main-header">
              Order ({this.props.orderDetail.transaction_id})
            </Text>
            <Text className="section-header">Transaction Details</Text>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Date Charged"
                  content={dateFormat(
                    this.props.orderDetail.date_charged,
                    "mmmm dS yyyy h:MM:ss TT"
                  )}
                />{" "}
              </Col>
            
              <Col span={12}>
                <DescriptionItem
                  title="Status"
                  content={renderOrderStatus(
                    this.props.orderDetail.transaction_status
                  )}
                />
              </Col>

              <Col span={12}>
                <DescriptionItem
                  title="Auto Charge"
                  content={convertBooleanToTag(
                    this.props.orderDetail.cart.auto_generated_by_subscription
                  )}
                />
              </Col>

              <Col span={12}>
                <DescriptionItem
                  title="Indiegogo Order"
                  content={convertBooleanToTag(
                    this.props.orderDetail.indiegogo_order
                  )}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Gifted Products"
                  content={convertBooleanToTag(
                    this.props.orderDetail.cart.gifted_products
                  )}
                />
              </Col>

          
              {this.props.orderDetail.edit_awaiting_order_reason && (
                <Col span={24}>
                  <DescriptionItem
                    title="Edit Awaiting Order Reason"
                    content={ this.props.orderDetail.edit_awaiting_order_reason}
                  />


                </Col>
              )}
              {
                this.props.orderDetail.transaction_status !== "Replacement Box" && (
                  <Col span={24}>
                    <DescriptionItem
                      title="Had To Send A Replacement Box"
                      content={convertBooleanToTag(this.props.orderDetail.sent_replacement_box)}
                    />
                  </Col>
                )
              }
                <Col span={24}>
                  <DescriptionItem
                    title="Profile Order"
                    content={convertBooleanToTag(this.props.orderDetail.cart.created_by_profile)}
                  />
                </Col>
                <Col span={24}>
                  <DescriptionItem
                    title="Order Has Been Split"
                    content={convertBooleanToTag(this.props.orderDetail.converted_into_split_box)}
                  />
                </Col>
                {
                  this.props.orderDetail.shipping_order_id && (
                    <Col span={24}>
                      <DescriptionItem
                        title="GMA Order ID"
                        content={this.props.orderDetail.shipping_order_id}
                      />
                      <p>If you need to refund this order, please reach out to Zach.</p>
                    </Col>
                  )
                }
            
            </Row>
            {
              this.props.orderDetail.split_box_for_id && (
                <Row>
                    <Divider />
                    <Text className="section-header">Split Box Information</Text>
                    <Col span={12}>
                      <DescriptionItem
                        title="Split Box For Order #"
                        content={<Link to={`/portal/orders/${this.props.orderDetail.split_box_for_id}`}>{this.props.orderDetail.split_box_for_id}</Link>}
                      />
                    </Col>
                </Row>
              )
            }
            {
              this.props.orderDetail.transaction_status === "Replacement Box" && (
                <Row>
                  <Divider />
                  <Text className="section-header">Replacement Box Information</Text>
                  {this.props.orderDetail.replacement_box_information && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Replacement Reason Category"
                        content={convertShippingMethodToTag(
                          this.props.orderDetail.replacement_box_information.replacement_reason
                        )}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.replacement_box_information && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Replacement Reason Subcategory"
                        content={convertShippingMethodToTag(
                          this.props.orderDetail.replacement_box_information.replacement_reason_details
                        )}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.replacement_box_information &&  this.props.orderDetail.replacement_box_information.items && this.props.orderDetail.replacement_box_information.items.length > 0 && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Relevant Products"
                        content={this.renderRelevantProductsToReplacement(this.props.orderDetail.replacement_box_information.items)}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.replacement_box_for_id && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Replacement Box For Order #"
                        content={<Link to={`/portal/orders/${this.props.orderDetail.replacement_box_for_id}`}>{this.props.orderDetail.replacement_box_for_id}</Link>}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.replacement_reason && this.props.orderDetail.transaction_status === "Replacement Box" && (
                    <Col span={24}>
                      <DescriptionItem
                        title="Replacement Reason"
                        content={this.props.orderDetail.replacement_reason}
                      />
                    </Col>
                  )}
                 
                </Row>
              )
            }
            {this.props.orderDetail.replacement_boxes &&
              this.props.orderDetail.replacement_boxes.length > 0 && (
                <Row>
                  <Divider />
                  <Text className="section-header">Replacement Boxes</Text>
                  <List
                    itemLayout="horizontal"
                    bordered
                    dataSource={this.props.orderDetail.replacement_boxes}
                    renderItem={item => (
                      <List.Item
                        actions={[
                          <Link
                            to={`/portal/orders/${item.transaction_id}`}
                          >
                            See Details <Icon type="right" />
                          </Link>
                        ]}
                      >
                        <List.Item.Meta
                          title={`ID: ${item.transaction_id}`}
                          description={
                            <div>
                              <DescriptionItem
                                title="Date Created"
                                content={`${ dateFormat(
                                  item.date_charged,
                                  " mmmm dS yyyy"
                                ) }`}
                              />
                              <DescriptionItem
                                title="Reason"
                                content={item.replacement_reason}
                              />
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Row>
              )}

            {this.props.orderDetail.split_boxes &&
              this.props.orderDetail.split_boxes.length > 0 && (
                <Row>
                  <Divider />
                  <Text className="section-header">Split Boxes</Text>
                  <List
                    itemLayout="horizontal"
                    bordered
                    dataSource={this.props.orderDetail.split_boxes}
                    renderItem={item => (
                      <List.Item
                        actions={[
                          <Link
                            to={`/portal/orders/${item.transaction_id}`}
                          >
                            See Details <Icon type="right" />
                          </Link>
                        ]}
                      >
                        <List.Item.Meta
                          title={`ID: ${item.transaction_id}`}
                          description={
                            <div>
                              <DescriptionItem
                                title="Date Created"
                                content={`${ dateFormat(
                                  item.date_charged,
                                  " mmmm dS yyyy"
                                ) }`}
                              />
                            </div>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Row>
              )}
            <Divider />
            {this.renderReusableCartInformation()}
            {
              this.props.orderDetail.refund_information && (
                <Row>
                  <Divider />
                  <Text className="section-header">Refund Information</Text>
                  {this.props.orderDetail.refund_information && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Date Refunded"
                        content={
                          dateFormat(this.props.orderDetail.refund_information.date_refunded, " mmmm dS yyyy")
                          
                        }
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.refund_information && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Refund Reason Category"
                        content={convertShippingMethodToTag(
                          this.props.orderDetail.refund_information.refund_reason
                        )}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.refund_information && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Refund Reason Subcategory"
                        content={convertShippingMethodToTag(
                          this.props.orderDetail.refund_information.refund_reason_details
                        )}
                      />
                    </Col>
                  )}
                  {this.props.orderDetail.refund_information &&  this.props.orderDetail.refund_information.items && this.props.orderDetail.refund_information.items.length > 0 && (
                    <Col span={12}>
                      <DescriptionItem
                        title="Relevant Products"
                        content={this.renderRelevantProductsToReplacement(this.props.orderDetail.refund_information.items)}
                      />
                    </Col>
                  )}

            
                 
                </Row>
              )
            }
            {this.props.orderDetail.stripe_info && (
              <Row>
                <Divider />
                <Text className="section-header">Stripe Details</Text>
                <Col span={12}>
                  <DescriptionItem
                    title="Risk Level"
                    content={this.props.orderDetail.stripe_info.risk_level}
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Risk Score"
                    content={this.props.orderDetail.stripe_info.risk_score}
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Stripe Charged"
                    content={
                      "$" + this.props.orderDetail.stripe_info.amount / 100
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Stripe Refunded"
                    content={
                      "$" +
                      this.props.orderDetail.stripe_info.amount_refunded / 100
                    }
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Stripe ID"
                    content={
                      <a
                        href={
                          "https://dashboard.stripe.com/payments/" +
                          this.props.orderDetail.stripe_id
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.orderDetail.stripe_id}
                      </a>
                    }
                  />
                </Col>
              </Row>
            )}
            {this.props.orderDetail.pay_pal_info && (
              <Row>
                <Divider />
                <Text className="section-header">Pay Pal Details</Text>
                <Col span={12}>
                  <DescriptionItem
                    title="Pay Pal Order Status"
                    content={this.props.orderDetail.pay_pal_info.status}
                  />
                </Col>
              </Row>
            )}
            <Divider />
            <Text className="section-header">Customer Details</Text>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="ID"
                  content={
                    <Link
                      to={`/portal/customers/${
                        this.props.orderDetail.user.random_user_id
                      }`}
                    >
                      {this.props.orderDetail.user.random_user_id}
                    </Link>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Email"
                  content={this.props.orderDetail.user.email}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Date Signed Up"
                  content={dateFormat(
                    this.props.orderDetail.user.date_joined,
                    " mmmm dS yyyy"
                  )}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Stripe ID"
                  content={
                    <a
                      href={
                        "https://dashboard.stripe.com/customers/" +
                        this.props.orderDetail.user.stripe_id
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.orderDetail.user.stripe_id}
                    </a>
                  }
                />
              </Col>
            </Row>
            {
              this.props.orderDetail.cart.gift_card && (
                
                <>
                <Divider />
                  <Text className="section-header">Gift Card Details</Text>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Recipient Full Name"}
                        content={this.props.orderDetail.cart.gift_receiver_first_name + " " + this.props.orderDetail.cart.gift_receiver_last_name}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Recipient Email"}
                        content={this.props.orderDetail.cart.gift_receiver_email}
                      />
                    </Col>
                    {
                      this.props.orderDetail.gift_card_redemption_details && (
                        <>
                          <Col span={12}>
                              <DescriptionItem
                                title={"If Redeemed"}
                                content={convertBooleanToTag(
                                  this.props.orderDetail.gift_card_redemption_details.redeemd_by ? true : false
                                )}
                              />
                            </Col>
                          <Col span={12}>
                              <DescriptionItem
                                title={"Cancelled"}
                                content={convertBooleanToTag(
                                  this.props.orderDetail.gift_card_redemption_details.cancelled ? true : false
                                )}
                              />
                            </Col>
                          <Col span={12}>
                              <DescriptionItem
                                title={"Gift Card ID"}
                                content={this.props.orderDetail.gift_card_redemption_details.gift_card_id.toUpperCase()}
                              />
                            </Col>
                            {
                              !this.props.orderDetail.gift_card_redemption_details.redeemd_by && (
                                <Col span={12}>
                                    <DescriptionItem
                                      title={"Gift Card ID Link"}
                                      content={`https://www.cleancult.com/gifts/redeem-a-gift-card?gcid=${this.props.orderDetail.gift_card_redemption_details.gift_card_id.toUpperCase()}`}
                                    />
                                  </Col>
                              )
                            }
                            {
                              this.props.orderDetail.gift_card_redemption_details.date_redeemed  && (
                              <Col span={12}>
                                  <DescriptionItem
                                    title={"Date Redeemed"}
                                    content={dateFormat(this.props.orderDetail.gift_card_redemption_details.date_redeemed , " mmmm dS yyyy")}
                                  />
                                </Col>
                              )
                            }
                          <Col span={12}>
                              <DescriptionItem
                                  title="Redeemed By"
                                  content={
                                    <Link
                                      to={`/portal/customers/${
                                        this.props.orderDetail.gift_card_redemption_details.redeemd_by
                                      }`}
                                    >
                                      {this.props.orderDetail.gift_card_redemption_details.redeemd_by}
                                    </Link>
                                  }
                                />
                            </Col>
                        </>
                      )
                    }
                  </Row>
                </>
              )
            }
            {
              !this.props.orderDetail.cart.gift_card && (
                
                <>
                <Divider />
                  <Text className="section-header">Address Details</Text>
                  <Row>
                    <Col span={12}>
                      <DescriptionItem
                        title="ID"
                        content={
                          <Link
                            to={`/portal/customers/${
                              this.props.orderDetail.user.random_user_id
                            }/edit-address/${
                              this.props.orderDetail.shipping_address.address_id
                            }`}
                          >
                            {this.props.orderDetail.shipping_address.address_id}
                          </Link>
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Full Name"}
                        content={this.props.orderDetail.shipping_address.full_name}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Street"}
                        content={this.props.orderDetail.shipping_address.address}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Apt. Number"}
                        content={this.props.orderDetail.shipping_address.apt_number}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"City"}
                        content={this.props.orderDetail.shipping_address.city}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"State"}
                        content={this.props.orderDetail.shipping_address.state}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Zip Code"}
                        content={this.props.orderDetail.shipping_address.zip_code}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Phone"}
                        content={this.props.orderDetail.shipping_address.phone}
                      />
                    </Col>
                  </Row>
                </>
              )
            }
            {this.props.orderDetail.payment_method &&
              this.props.orderDetail.payment_method.last_four_digits && (
                <Row>
                  <Divider />
                  <Text className="section-header">Payment Details</Text>
                  <Col span={12}>
                    <DescriptionItem
                      title="ID"
                      content={
                        <Link
                          to={`/portal/customers/${
                            this.props.orderDetail.user.random_user_id
                          }/edit-payment-method/${
                            this.props.orderDetail.payment_method
                              .payment_method_id
                          }`}
                        >
                          {
                            this.props.orderDetail.payment_method
                              .payment_method_id
                          }
                        </Link>
                      }
                    />
                  </Col>

                  <Col span={12}>
                    <DescriptionItem
                      title="Stripe ID"
                      content={
                        <a
                          href={
                            "https://dashboard.stripe.com/cards/" +
                            this.props.orderDetail.payment_method.stripe_id
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {this.props.orderDetail.payment_method.stripe_id}
                        </a>
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Exp. Date"}
                      content={
                        this.props.orderDetail.payment_method.expiration_date
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Last Four Digits"}
                      content={
                        this.props.orderDetail.payment_method.last_four_digits
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Card Type"}
                      content={this.props.orderDetail.payment_method.card_type}
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Zip Code"}
                      content={this.props.orderDetail.payment_method.zip_code}
                    />
                  </Col>
                </Row>
              )}
            {this.props.orderDetail.payment_method &&
              this.props.orderDetail.payment_method.pay_pal_payer_email && (
                <Row>
                  <Divider />
                  <Text className="section-header">Payment Details</Text>
                  <Col span={12}>
                    <DescriptionItem
                      title="ID"
                      content={
                        <Link
                          to={`/portal/customers/${
                            this.props.orderDetail.user.random_user_id
                          }/edit-payment-method/${
                            this.props.orderDetail.payment_method
                              .payment_method_id
                          }`}
                        >
                          {
                            this.props.orderDetail.payment_method
                              .payment_method_id
                          }
                        </Link>
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Pay Pal Email"}
                      content={
                        this.props.orderDetail.payment_method.pay_pal_payer_email
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Billing Agreement ID"}
                      content={
                        this.props.orderDetail.payment_method.pay_pal_billing_agreement_id
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <DescriptionItem
                      title={"Card Type"}
                      content={"Pay Pal"}
                    />
                  </Col>
      
                </Row>
              )}
            {this.props.orderDetail.vanguard_shipment && (
              <Row>
                <Divider />
                <Text className="section-header">Fulfillment Details</Text>
                <Col span={12}>
                  <DescriptionItem
                    title={"Pick Pack Priority"}
                    content={convertBooleanToTag(
                      this.props.orderDetail.pick_pack_priority
                    )}
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title={"Customer Chose Faster Shipping"}
                    content={convertBooleanToTag(
                      this.props.orderDetail.customer_chose_faster_shipping
                    )}
                  />
                </Col>
                {
                    this.props.orderDetail.customer_chose_faster_shipping && (
                      <Col span={12}>
                        <DescriptionItem
                          title={"Promised Delivery Date"}
                          content={ dateFormat(this.props.orderDetail.estimated_delivery_end, " mmmm dS yyyy")}
                        />
                      </Col>
                    )
                }
                {
                    !this.props.orderDetail.customer_chose_faster_shipping && this.props.orderDetail.estimated_delivery_start && this.props.orderDetail.estimated_delivery_end && (
                      <Col span={12}>
                        <DescriptionItem
                          title={"Estiamted Delivery Date Range"}
                          content={`${dateFormat(this.props.orderDetail.estimated_delivery_start, " mmmm dS yyyy")} - ${dateFormat(this.props.orderDetail.estimated_delivery_end, " mmmm dS yyyy")}`}
                        />
                      </Col>
                    )
                }
                <Col span={12}>
                  <DescriptionItem
                    title={"Over Box Required"}
                    content={convertBooleanToTag(
                      this.props.orderDetail.overbox_required
                    )}
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title={"Fulfillment Status"}
                    content={renderVDLShipmentStatus(
                      this.props.orderDetail.shipment_status,
                      this.props.orderDetail.vanguard_shipment
                    )}
                  />
                </Col>
                {
                   this.props.orderDetail.shipment_status === "delayed" && (
                    <Col span={24}>
                    <DescriptionItem
                      title={"Delayed Reason"}
                      content={renderDelayedReason(this.props.orderDetail.cart.cart_item_set, this.props.productInventory)}
                    />
                  </Col>
                   )
                }
                {
                   this.props.orderDetail.shipment_status === "onHold" && (
                <Col span={24}>
                  <DescriptionItem
                    title={"Hold Reason"}
                    content={this.props.orderDetail.shipment_hold_reason}
                  />
                </Col>
                   )
                }
                {
                  this.props.orderDetail.pick_pack_special_instructions && (
                  <Col span={24}>
                    <DescriptionItem
                      title={"Pick Pack Special Instructions"}
                      content={this.props.orderDetail.pick_pack_special_instructions}
                    />
                  </Col>
                  )
                }
                {this.props.orderDetail.service && (
                  <Col span={12}>
                    <DescriptionItem
                      title={"Service"}
                      content={convertShippingMethodToTag(
                        this.props.orderDetail.service
                      )}
                    />
                  </Col>
                )}
                {this.props.orderDetail.shipment_status === "shipped" && (
                  <div>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Date Fulfilled"}
                        content={dateFormat(
                          this.props.orderDetail.date_pick_packed,
                          " mmmm dS yyyy, h:MM:ss TT"
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Tracking Number"}
                        content={
                          <a
                            href={this.props.orderDetail.tracking_page_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.props.orderDetail.tracking_number}
                          </a>
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DescriptionItem
                        title={"Shipping Cost"}
                        content={"$" + this.props.orderDetail.cost_of_shipment}
                      />
                    </Col>

                    <Col span={12}>
                      <DescriptionItem
                        title={"Carrier"}
                        content={convertShippingMethodToTag(
                          this.props.orderDetail.carrier
                        )}
                      />
                    </Col>

                    {this.props.orderDetail.final_box_configuration && (
                      <DescriptionItem
                        title={"Weight"}
                        content={
                          this.props.orderDetail.final_box_configuration
                            .final_box_configuration[0].totalWeight + "Ozs"
                        }
                      />
                    )}
                  </div>
                )}
              </Row>
            )}

            {this.props.orderDetail.shipment_history && (
              <Collapse defaultActiveKey={[]}>
                <Panel header="View Fulfillment Events" key="1</Panel>">
                  <List
                    bordered
                    dataSource={this.convertShipmentHistory(
                      this.props.orderDetail.shipment_history
                    )}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            "Date: " +
                            dateFormat(
                              item.date,
                              " mmmm dS, yyyy h:MM:ss TT "
                            ) +
                            "EST"
                          }
                          description={`Who: ${item.user_name} What: ${
                            item.description
                          }`}
                        />
                      </List.Item>
                    )}
                  />
                </Panel>
              </Collapse>
            )}

            {this.props.orderDetail.tracking_information && (
              <Row>
                <Divider />
                <Text className="section-header">
                  Carrier Information (Direcly From{" "}
                  {this.props.orderDetail.carrier})
                </Text>
                {this.props.orderDetail.tracking_information
                  .estimated_delivery_date && (
                  <Col span={12}>
                    <DescriptionItem
                      title={"Estimated Delivery Date"}
                      content={dateFormat(
                        this.props.orderDetail.tracking_information
                          .estimated_delivery_date,
                        " mmmm dS yyyy, h:MM:ss TT"
                      )}
                    />
                  </Col>
                )}
                {this.props.orderDetail.tracking_information
                  .actual_delivery_date && (
                  <Col span={12}>
                    <DescriptionItem
                      title={"Actual Delivery Date"}
                      content={dateFormat(
                        this.props.orderDetail.tracking_information
                          .actual_delivery_date,
                        " mmmm dS yyyy, h:MM:ss TT"
                      )}
                    />
                  </Col>
                )}
                {this.props.orderDetail.tracking_information
                  .carrier_status_description && (
                  <Col span={12}>
                    <DescriptionItem
                      title={"Carrier Status Description"}
                      content={
                        this.props.orderDetail.tracking_information
                          .carrier_status_description
                      }
                    />
                  </Col>
                )}
                {this.props.orderDetail.tracking_information
                  .exception_description && (
                  <Col span={12}>
                    <DescriptionItem
                      title={"Exception Description"}
                      content={
                        this.props.orderDetail.tracking_information
                          .exception_description
                      }
                    />
                  </Col>
                )}
              </Row>
            )}
            {this.props.orderDetail.tracking_information &&
              this.props.orderDetail.tracking_information.events && (
                <Collapse defaultActiveKey={[]}>
                  <Panel header="View Carrier Events" key="1">
                    <List
                      grid={{ gutter: 4, column: 1 }}
                      dataSource={
                        this.props.orderDetail.tracking_information.events
                      }
                      renderItem={item => (
                        <List.Item>
                          <Card
                            style={{}}
                            title={dateFormat(
                              item.occurred_at,
                              " mmmm dS yyyy, h:MM:ss TT"
                            )}
                          >
                            <DescriptionItem
                              title="Description"
                              content={item.description}
                            />
                            <DescriptionItem
                              title="Location"
                              content={
                                item.city_locality + " " + item.postal_code
                              }
                            />
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Panel>
                </Collapse>
              )}
            {this.props.orderDetail.cart.cart_item_set && (
              <Row className="cart-items">
                <Divider />
                <Text className="section-header">Cart Items</Text>
                <List
                  grid={{ gutter: 4, column: 2 }}
                  dataSource={this.props.orderDetail.cart.cart_item_set}
                  renderItem={item => (
                    <List.Item className="product-listing">
                      <Card
                        style={{}}
                        cover={
                          <div style={{
                            background: item.gift_card_details ? item.gift_card_details.background_color : item.cart_item_product_information.category_color,
                            minHeight: "150px"
                          }}>
                            {
                              item.gift_card_details ? (
                                  <img
                                    alt="example"
                                    src={
                                      item.gift_card_details.cart_image
                                    }
                                  />
                                ) : (

                                    <img
                                      alt="example"
                                      src={
                                        item.cart_item_product_information
                                          .cart_image.url
                                      }
                                    />
                                )
                            }
                          </div>
                        }
                        title={
                          item.cart_item_product_information.marketing_name
                        }
                      >
                        {
                          item.gift_card_details ? (
                            <DescriptionItem
                              title="Sku"
                              content={"E-Gift Card"}
                            />
                          ) : (
                            <DescriptionItem
                              title="Sku"
                              content={item.cart_item_product_information.dear_sku_display}
                            />
                          )
                        }
                        <DescriptionItem
                          title="Total"
                          content={"$" + item.cart_item_total.toString()}
                        />
                        <DescriptionItem
                          title="Quantity"
                          content={item.cart_total_item_quantity.toString()}
                        />
                        <DescriptionItem
                          title="One Time Promotional Item"
                          content={convertBooleanToTag(item.promotional_item)}
                        />
                    
                        <DescriptionItem
                          title="One Time Purchase"
                          content={convertBooleanToTag(item.one_time_purchase)}
                        />
                        <DescriptionItem
                          title="Promotional Item For Life"
                          content={convertBooleanToTag(item.promotional_item_for_life)}
                        />
            
                      </Card>
                    </List.Item>
                  )}
                />
              </Row>
            )}

            {this.props.orderDetail.utm_history &&           this.props.orderDetail.utm_history.utm_history && (
              <Row>
                <Divider />
                <Text className="section-header">UTM History</Text>
                <List
                  bordered
                  dataSource={JSON.parse(
                    this.props.orderDetail.utm_history.utm_history
                  )}
                  renderItem={item => (
                    <List.Item>
                      {dateFormat(item.time_stamp, " mmmm dS yyyy, h:MM:ss TT")}
                      <List.Item.Meta
                        title={"Source: " + item.utm_source}
                        description={`Medium: ${item.utm_medium} Campaign: ${
                          item.utm_campaign
                        } Content: ${
                          item.utm_content
                        }`}
                      />
                    </List.Item>
                  )}
                />
              </Row>
            )}

            {this.props.orderDetail.final_box_configuration && (
              <Row>
                <Divider />
                <Text className="section-header">Box Configuration</Text>
                <List
                  grid={{ gutter: 4, column: 2 }}
                  dataSource={
                    this.props.orderDetail.final_box_configuration
                      .final_box_configuration[0].box.components
                  }
                  renderItem={item => (
                    <List.Item>
                      <Card
                        style={{}}
                        cover={
                          <div style={{
                            background: "#e8e8e8",
                            minHeight: "150px"
                          }}>
                              <img
                                alt="example"
                                src={boxTypeImageDict[item.sku]}
                              />
                          </div>

                      
                      
                      }
                        title={item.sku}
                      >
                        <DescriptionItem title="Type" content={item.name} />
                      </Card>
                    </List.Item>
                  )}
                />
                {
                  this.props.orderDetail.final_box_configuration.final_box_configuration && this.props.orderDetail.final_box_configuration.final_box_configuration[0].overBox && (
                    <List
                    grid={{ gutter: 4, column: 2 }}
                    dataSource={
                      this.props.orderDetail.final_box_configuration
                        .final_box_configuration[0].overBox.components
                    }
                    renderItem={item => (
                      <List.Item>
                        <Card
                          style={{}}
                          cover={
                            <div style={{
                              background: "#e8e8e8",
                              minHeight: "150px"
                            }}>
                                <img
                                  alt="example"
                                  src={boxTypeImageDict[item.sku]}
                                />
                            </div>
  
                        
                        
                        }
                          title={item.sku}
                        >
                          <DescriptionItem title="Type" content={item.name} />
                        </Card>
                      </List.Item>
                    )}
                  />
                  )
                }
              </Row>
            )}
            {this.props.orderDetail.final_box_configuration && (
              <Row>
                <Divider />
                <Text className="section-header">Insert Configuration</Text>
                {
                  this.props.orderDetail.final_box_configuration
                  .final_box_configuration[0].insertType ? (
                      <List
                        grid={{ gutter: 4, column: 2 }}
                        dataSource={[
                          this.props.orderDetail.final_box_configuration
                            .final_box_configuration[0].insertType
                        ]}
                        renderItem={item => (
                          <List.Item>
                            <Card
                              style={{}}
                            
                                cover={
                                  <div style={{
                                    background: "#e8e8e8",
                                    minHeight: "150px"
                                  }}>
                                      <img
                                        alt="example"
                                        src={insertTypeImageDict[item.sku]}
                                      />
                                  </div>
                              }
                              
                              title={item.sku}
                            >
                              <DescriptionItem title="Type" content={item.name} />
                            </Card>
                          </List.Item>
                        )}
                      />
                  ) : (
                    <p>N/A</p>
                  )
                }
              </Row>
            )}

            <div className="bottom-bar">
            {/* eslint-disable */}
              {((this.props.orderDetail.transaction_status === "Successful"    &&         !this.props.orderDetail.converted_into_split_box )||
               ( this.props.orderDetail.transaction_status ===
                  "Partially Refunded") &&  !this.props.orderDetail.cart.gift_card &&  this.props.orderDetail.cart.total > 0 &&             !this.props.orderDetail.converted_into_split_box )&&  (
                <Link
                  to={`/portal/orders/${
                    this.props.orderDetail.transaction_id
                  }/refund-order`}
                >
                  <Button
                    type="danger"
                    onClick={this.closeModal}
                    style={{ marginRight: 8 }}
                    ghost
                  >
                    Refund
                  </Button>
                </Link>
              )}
              {(this.props.orderDetail.transaction_status === "Successful" ||
                this.props.orderDetail.transaction_status ===
                  "Partially Refunded") &&  this.props.orderDetail.cart.gift_card && (

                    <>
                      {
                        this.props.orderDetail.gift_card_redemption_details && !this.props.orderDetail.gift_card_redemption_details.redeemd_by && (
                          <Link
                            to={`/portal/orders/${
                              this.props.orderDetail.transaction_id
                            }/refund-order`}
                          >
                            <Button
                              type="danger"
                              onClick={this.closeModal}
                              style={{ marginRight: 8 }}
                              ghost
                            >
                              Cancel Gift Card & Refund
                            </Button>
                          </Link>
                        )
                      }
                    </>
              )}
            
              {(this.props.orderDetail.transaction_status ===
                "Refunded" || this.props.orderDetail.transaction_status ===
                "Partially Refunded") && (
                <Link
                  to={`/portal/orders/${
                    this.props.orderDetail.transaction_id
                  }/edit-refund-reason`}
                >
                  <Button
                    ghost
                    type="primary"
                    
                    onClick={this.closeModal}
                    style={{ marginRight: 8 }}
                  >
                    Edit Refund Reason
                  </Button>
                </Link>
              )}
              {this.props.orderDetail.transaction_status !==
                "Replacement Box" &&  
                !this.props.orderDetail.cart.gift_card &&
                !this.props.orderDetail.converted_into_split_box &&
                !this.props.orderDetail.transaction_status.includes("free") &&
                this.props.orderDetail.shipment_status !== "shipped" &&
                this.props.orderDetail.shipment_status !== "cancelled" &&
                
                (
                <Link
                  to={`/portal/orders/${
                    this.props.orderDetail.transaction_id
                  }/split-order`}
                >
                  <Button
                    ghost
                    type="primary"

                    onClick={this.closeModal}
                    style={{ marginRight: 8 }}
                  >
                    Split Order Up
                  </Button>
                </Link>
              )}
              {this.props.orderDetail.transaction_status !==
                "Replacement Box" &&  !this.props.orderDetail.cart.gift_card && !this.props.orderDetail.converted_into_split_box && (
                <Link
                  to={`/portal/orders/${
                    this.props.orderDetail.transaction_id
                  }/send-replacement-box`}
                >
                  <Button
                    ghost
                    type="danger"
                    className="warning-button"
                    onClick={this.closeModal}
                    style={{ marginRight: 8 }}
                  >
                    Send Replacement Box
                  </Button>
                </Link>
              )}
              {this.props.orderDetail.transaction_status ===
                "Replacement Box" && (
                <Link
                  to={`/portal/orders/${
                    this.props.orderDetail.transaction_id
                  }/edit-replacement-reason`}
                >
                  <Button
                    ghost
                    type="primary"
                    
                    onClick={this.closeModal}
                    style={{ marginRight: 8 }}
                  >
                    Edit Replacement Reason
                  </Button>
                </Link>
              )}
         
              {this.props.orderDetail.shipment_status !== "shipped" &&
                this.props.orderDetail.vanguard_shipment && !this.props.orderDetail.converted_into_split_box && (
                  <Link
                    to={`/portal/orders/${
                      this.props.orderDetail.transaction_id
                    }/change-shipment-status`}
                  >
                    <Button ghost type="primary" onClick={this.closeModal} style={{ marginRight: 8 }}>
                      Change Shipping Status
                    </Button>
                  </Link>
                )}
              {this.props.orderDetail.shipment_status !== "shipped" &&
                this.props.orderDetail.vanguard_shipment && !this.props.orderDetail.converted_into_split_box &&  (
                  <Link
                    to={`/portal/orders/${
                      this.props.orderDetail.transaction_id
                    }/edit-items-in-box`}
                  >
                    <Button ghost type="primary" onClick={this.closeModal} className="danger-ish-button">
                      Edit Items In Box
                    </Button>
                  </Link>
                )}
            </div>
          </div>
        ) : (
          <div className="modal-wrapper">
            <Skeleton active paragraph />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </Drawer>
    );
  }
}

function mapStateToProps(state) {
  return {
    orderDetail: state.orders.orderDetail,
    orderDetailLoading: state.orders.orderDetailLoading,
    productInventory: state.inventory.product_inventory
  };
}

export default connect(
  mapStateToProps,
  { 
      resetUserOrderDetails, 
      fetchUserOrderDetails,
      resetCustomerDetails,
      fetchCustomerAddressesAndPaymentMethods
  }
)(DetailView);
