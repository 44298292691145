import React from "react";
import {Tag, Select } from "antd";
import moment from "moment";
const { Option, OptGroup } = Select;

// Extracts the id from the url path
export function extractIDFromPath(location) {
  if (location.length > 1) {
    var objectID = location.match(/\d+/);
    if (objectID) {
      return objectID[0];
    } else {
      return null;
    }
  } else {
    return null;
  }
}

// Takes in an order status and returns out a pretty ant design label
export function renderOrderStatus(status) {
  status = status.toLowerCase();
  if (status.includes("partially")) {
    return <Tag color="volcano">Partially Refunded</Tag>;
  } else if (status.includes("refunded")) {
    return <Tag color="red">Refunded</Tag>;
  } else if (status.includes("replacement")) {
    return <Tag color="gold">Replacement Box</Tag>;
  } else if (status.includes("free")) {
    return <Tag color="cyan">Free Sample</Tag>;
  } else if (status.includes("gma")) {
    return <Tag color="cyan">GMA ORDER</Tag>;
  } else {
    return <Tag color="green">Successful</Tag>;
  }
}

// Takes in an shipment status and converts it to a ncie tag
export function renderVDLShipmentStatus(status, vanguardShipment) {
  status = status.toLowerCase();
  console.log(status)
  if (status.includes("gift_card")) {
    return <Tag color="blue">Gift Card</Tag>;
  }

  if (vanguardShipment === "False" || !vanguardShipment) {
    return <Tag>N/A</Tag>;
  }
  if (status.includes("awaiting")) {
    return <Tag color="gold">Awaiting Shipment</Tag>;
  } else if (status.includes("cancelled")) {
    return <Tag color="red">Cancelled</Tag>;
  } else if (status.includes("hold")) {
    return <Tag color="magenta">On Hold</Tag>;
  } else if (status.includes("delayed")) {
    return <Tag color="red">Delayed</Tag>;
  } else {
    return <Tag color="green">Shipped</Tag>;
  }
}

// Add days to a specific date
export function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

// Converts a boolean into a more pleasent look
export function convertBooleanToTag(boolean) {
  if (typeof boolean === "string" || boolean instanceof String) {
    if (boolean.toLowerCase().includes("true")) {
      return <Tag color="green">True</Tag>;
    } else {
      return <Tag color="red">False</Tag>;
    }
  } else {
    if (boolean) {
      return <Tag color="green">True</Tag>;
    } else {
      return <Tag color="red">False</Tag>;
    }
  }
}

// Converts a shipping method to a pretty tag
export function convertShippingMethodToTag(service, justText) {
  if (service) {
    service = service.replace(/_/g, " ");
    if (justText) {
      return service
    }
    service = service.toUpperCase();
    return <Tag>{service}</Tag>;
  }
}

// Converts a boolean into a more pleasent look
export function renderSubscriptionInformation(status) {
  status = status.toLowerCase();
  if (status.includes("cancelled")) {
    return <Tag color="red">Cancelled</Tag>;
  } else if (status.includes("hold")) {
    return <Tag color="gold">On Hold</Tag>;
  }
  if (status.includes("running")) {
    return <Tag color="green">Running</Tag>;
  }
}

// Renders why the order had been delayed and when it will be available again
export function renderDelayedReason(cartItems, productInventory) {
  var itemsOutOfStock =  ""
  var latestPossibleDate = moment()
  cartItems.map(function(cartItem) {
    var product = productInventory[cartItem.cart_item_product_information.id]
    if (product.out_of_stock) {
      itemsOutOfStock += product.marketing_name + ", "
      if (product.restock_date) {
        var restockDate = moment(product.restock_date)
        if (restockDate > latestPossibleDate) {
          latestPossibleDate = restockDate
        }
      }
    }
    return cartItem
  })

  if (itemsOutOfStock === "") {
    return "Nothing in this order is out of stock and it should not be on delay."
  }
  return "This order is on delay because the following items are maked out of stock:  " + itemsOutOfStock + ". They should be availabe again on: " + latestPossibleDate.format("MM / DD / YYYY")
}


// Converts a boolean into a more pleasent look
export function renderSubscriptionStatusFromSubscription(status, onHold) {
  status = status.toLowerCase();
  if (status.includes("cancelled")) {
    return <Tag color="red">Cancelled</Tag>;
  } else if (onHold) {
    return <Tag color="gold">On Hold</Tag>;
  } else {
    return <Tag color="green">Running</Tag>;
  }
}

// Gets the current screen width to create the modal size
export function getScreenWidth(childModal) {
  var windowWidth = window.innerWidth;

  var childModalSubscription = 0;

  if (childModal) {
    childModalSubscription = 620;
  }
  if (windowWidth > 768) {
    return 850 + childModalSubscription;
  } else {
    return windowWidth;
  }
}

// Render Colors for Charting functions
export function renderLineColor(productID, ifJustColor) {
  var backgroundColor = "";
  if (productID.includes("laundry_detergent_refill")) {
    backgroundColor = "#41c6dc";
  } else if (productID.includes("foaming_hand_soap_refill")) {
    backgroundColor = "#c39cdb";
  } else if (productID.includes("all_purpose_cleaner_refill")) {
    backgroundColor = "#faad14";
  } else if (productID.includes("dish_soap_refill")) {
    backgroundColor = "#FDD835";
  } else if (productID.includes("dishwasher_detergent_refill")) {
    backgroundColor = "#a0d911";
  } else if (productID.includes("laundry_detergent")) {
    backgroundColor = "#2f54eb";
  } else if (productID.includes("ball")) {
    backgroundColor = "#f5222d";
  } else if (productID.includes("purpose")) {
    backgroundColor = "#fa8c16";
  } else if (productID.includes("dish_soap")) {
    backgroundColor = "#FDD835";
  } else if (productID.includes("foaming")) {
    backgroundColor = "#722ed1";
  } else if (productID.includes("bar")) {
    backgroundColor = "#a1d798";
  } else if (productID.includes("funnel")) {
    backgroundColor = "#000";
  } else if (productID.includes("liquid_hand")) {
    backgroundColor = "#3aa904";
  } else if (productID.includes("dishwasher_detergent")) {
    backgroundColor = "#52c41a";
  } else {
    backgroundColor = "#65eac1";
  }

  if (ifJustColor) {
    return backgroundColor;
  }
  return {backgroundColor: backgroundColor};
}

// Valiudate as url
export function validateURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

// Validates an email
export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Render Colors for Charting functions
export function renderProductTag(productID) {
  if (!productID) {
    return <Tag color={"grey"}>{"N/A"}</Tag>;
  }
  var color = "";
  if (productID.includes("laundry_detergent_refill")) {
    color = "cyan";
  } else if (productID.includes("foaming_hand_soap_refill")) {
    color = "purple";
  } else if (productID.includes("all_purpose_cleaner_refill")) {
    color = "red";
  } else if (productID.includes("dish_soap_refill")) {
    color = "volcano";
  } else if (productID.includes("dishwasher_detergent_refill")) {
    color = "green";
  } else if (productID.includes("laundry_detergent")) {
    color = "geekblue";
  } else if (productID.includes("ball")) {
    color = "red";
  } else if (productID.includes("purpose")) {
    color = "orange";
  } else if (productID.includes("dish_soap")) {
    color = "gold";
  } else if (productID.includes("hand_soap")) {
    color = "green";
  }  else if (productID.includes("foaming")) {
    color = "purple";
  } else if (productID.includes("bar")) {
    color = "green";
  } else if (productID.includes("dishwasher_detergent")) {
    color = "lime";
  } else if (productID.includes("foaming")) {
    color = "volcano";
  } else {
    color = "grey";
  }
  
  var productName = productID
  productArray.map(function(productObject) {
    if (productObject.value === productID) {
      productName = productObject.text
    }

    return productObject
  })
  return <Tag key={productID} color={color}>{productName}</Tag>;
}

// Refund Helpers Funtions
export function   getRefundType(selectedRefundType) {
  var refundType = {}
  refundReasonTypes.map(function(refundReasonType) {
    if (refundReasonType.value === selectedRefundType) {
      refundType = refundReasonType
    }
    return refundReasonType
  })
  
  return refundType
}

export function getRefundItemSelectionDetails(
    selectedRefundType, 
    selectedRefundTypeDetails, 
    ifLabel
) {
  var label = ""
  var shouldShowProducts = false
  
  // If they have an issue with one of the products
  if (selectedRefundType === "shipping_or_inventory") {
    if (selectedRefundTypeDetails  === "missing_products") {
      shouldShowProducts =  true
      label = "What Product(s) Were Missing"
    }
    if (selectedRefundTypeDetails  === "item_out_of_stock") {
      shouldShowProducts =  true
      label = "What Item(s) Were Out Of Stock"
    }
  }

  // If they have an issue with one of the products
  if (selectedRefundType === "product_isses") {
    if (selectedRefundTypeDetails  === "leakage") {
      shouldShowProducts =  true
      label = "What Product(s) Leaked"
    }
    if (selectedRefundTypeDetails  === "leakage_due_to_box_damage") {
      shouldShowProducts =  true
      label = "What Product(s) Leaked"
    }
    if (selectedRefundTypeDetails  === "broken_bottle") {
      shouldShowProducts =  true
      label = "What Item(s) Broke"
    }
    if (selectedRefundTypeDetails  === "not_happy_with_products") {
      shouldShowProducts =  true
      label = "What Product(s) Were They UnHappy With"
    }
  }

  // Just in case they missed a certain product in their cart
  if (selectedRefundType === "user_error") {
    if (selectedRefundTypeDetails  === "did_not_want_a_product") {
      shouldShowProducts =  true
      label = "What Product(s) Did They Not Want"
    }
  }

  
  if (ifLabel) {
    return label
  } else {
    return shouldShowProducts
  }
}

// Replacement Reason Builder OutPut
export function buildOutRefundReason(
    refundType,
    additionalDetails,
    items,
    otherReason,
    date_refunded,
    user,
    jsonOutput
) {
  
  if (jsonOutput) {
    var json = {
      refund_reason: refundType,
      refund_reason_details: additionalDetails,
      other_reason: otherReason,
      date_refunded: date_refunded,
      items: items,
    }
    return json
  }

  var userName = `${user.first_name} ${user.last_name}`
  var mainReason = ""
  var replacementSubReasonText = ""

  var itemString = ""
  if (items) {
    items.map(function(item) {
      
      if (item === "cantDetermineWhichProduct") {
        itemString += "an unknown product"
      }
      productArray.map(function(product) {
        if (item === product.value) {
          itemString += `the ${product.text} `
          if (items.length > 1) {
            itemString += ", "
          }
        }
        return product
      })
      return item
    })
  }

  // Get the text form main reason and sub reason
  refundReasonTypes.map(function (refundReason) {
    if (refundReason.value === refundType) {
      if (refundReason.subOptions) {
        refundReason
          .subOptions
          .map(function (subOption) {
            if (subOption.value === additionalDetails) {
              replacementSubReasonText = subOption.text
            }
            return subOption
          })
      }
    }
    return refundReason
  })

  if (refundType === "other") {
    if (otherReason) {
      mainReason = otherReason
    }
  } else {
    if (refundType === "shipping_or_inventory") {
      mainReason = "there was the issue with shipping or inventory"

      // Additional Details
      if (additionalDetails === "missing_products") {
        mainReason += ` because ${itemString} were missing from the order and they requested a refund`
      } else if (additionalDetails === "item_out_of_stock") {
        mainReason += ` because ${itemString} were out of stock, after the item was ordered`
      } else {
        mainReason += ` because ${replacementSubReasonText}`
      }
    }

    if (refundType === "product_isses") {
      mainReason = "the customer had an issue with the products"

      // Additional Details
      if (additionalDetails === "leakage") {
        mainReason += ` because ${itemString}  leaked, but there was no sign of the bottle breaking`
      } if (additionalDetails === "leakage_due_to_box_damage") {
        mainReason += ` because ${itemString}  leaked, but there was no sign of the bottle breaking`
      }  else if (additionalDetails === "broken_bottle") {
        mainReason += ` because ${itemString} broke or cracked`
      } else if (additionalDetails === "not_happy_with_products") {
        mainReason += ` because the customer was not happy with the ${itemString} `
      } else {
        mainReason += ` because ${replacementSubReasonText}`
      }
    }

    if (refundType === "subscription_issues") {
      mainReason = "there was an issue with the subscription order"

      // Addtional Details
      if (additionalDetails) {
        mainReason += ` because ${replacementSubReasonText}`
      }
    }

    if (refundType === "user_error") {
      mainReason = "the customer made a mistake when creating their order"

      // Additional Details
      if (additionalDetails === "did_not_want_a_product") {
        mainReason += ` because ${itemString} was mistakenly added to the cart`
      } else {
        mainReason += ` because ${replacementSubReasonText}`
      }
    }

    // Add in the refund other option to the end if none abolvoe all
    if (additionalDetails === "other") {
      if (otherReason) {
        mainReason += ` because ${otherReason}`
      }
    }

  }

  return `${userName} Refunded on ${date_refunded}: ${mainReason.toLowerCase()}.`
}


export const refundReasonTypes = [
  {
    value: "shipping_or_inventory",
    text: "Shipping or inventory issue",
    subOptionsLabel: "What was the issue with shipping or inventory?",
    subOptions: [
      {
        value: "fulfillment_delays",
        text: "There were delays with the fulfillment of the product"
      }, {
        value: "order_never_arrived",
        text: "The order never arrived, but was shipped out"
      },  {
        value: "missing_products",
        text: "One of the items were missing and they requested a refund"
      },{
        value: "item_out_of_stock",
        text: "The item went out of stock, after the item was ordered"
      },  {
        value: "other",
        text: "Other"
      }
    ]
  }, {
    value: "product_isses",
    text: "They customer had an issue with the products",
    subOptionsLabel: "Produt Issue Reason",
    subOptions: [
      {
        value: "leakage",
        text: "One or more of the products leaked, but there was no sign of the bottle breaking"
      }, 
      {
        value: "broken_bottle",
        text: "One or of the products broke or cracked"
      }, {
        value: "just_damage_no_sign_of_leakage",
        text: "The box or products were damaged, but with no sign of leakage or broken bottles"
      },  {
        value: "not_happy_with_products",
        text: "The customer was not happy with the products"
      }, {
        value: "other",
        text: "Other"
      }
    ]
  },  {
    value: "subscription_issues",
    text: "There was an issue with a subscription order",
    subOptionsLabel: "Subscription Issue Type",
    subOptions: [
      {
        value: "duplicate_charge",
        text: "There was a duplicate charge with a subscription"
      }, {
        value: "cancel_subscription",
        text: "The customer wants to cancel their subscription and get a refund on their last order"
      }, {
        value: "charged_a_cancelled_subscription",
        text: "The customer was charged even through they had cancelled their subscription already"
      },{
        value: "other",
        text: "Other"
      }
    ]
  }, {
    value: "user_error",
    text: "The customer made a mistake when creating their order",
    subOptionsLabel: "User Error Type",
    subOptions: [
      {
        value: "did_not_want_a_product",
        text: "The customer requested that an item be removed from their order and they be refunded"
      }, {
        value: "forgot_discount",
        text: "The customer forgot to add a discount code and wants the % discount refunded"
      }, {
        value: "other",
        text: "Other"
      }
    ]
  }, {
    value: "other",
    text: "Other"
  }
];

// Replacement Box Reason Helper Funtions
export function   getReplacementType(selectedReplacementType) {
  var replacementType = {}
  replacementReasonTypes.map(function(replacementReasonType) {
    if (replacementReasonType.value === selectedReplacementType) {
      replacementType = replacementReasonType
    }
    return replacementReasonType
  })

  console.log(replacementType)
  
  return replacementType
}

export function PruneProductArray(productArray, orderDetails) {
  console.clear();
  let cartItems = orderDetails.cart.cart_item_set;

  let prunedArray = []
  
  prunedArray.push(productArray[0])

  productArray.map(function(productOption) {

    let inOrder = false;

    cartItems.map(function(cartItemObject) {
      
      if (productOption.value === cartItemObject.cart_item_product_information.id) {
        inOrder = true;
      }
      
      return cartItemObject;
    })
    

    if (inOrder) {
      prunedArray.push(productOption);
    }

    return productOption;
  })

  return prunedArray;
}

export function getNewProductArray(additionalReplacementDetails) {
  var newProductArray = productArray.slice(0) 
  newProductArray.shift()
  if (additionalReplacementDetails !== "missing_products") {
    newProductArray[0] = {
      value: "cantDetermineWhichProduct",
      text: "Cant Determine Which Product",
      group: "N/A"
    }
  }
  return newProductArray
}
export function   getItemSelectionDetails(selectedReplacementType, selectedReplacementTypeDetails, ifLabel) {
  var label = ""
  var shouldShowProducts = false
  if (selectedReplacementType === "missing_products") {
    shouldShowProducts =  true
    label = "What Product(s) Were Missing"
  }
  if (selectedReplacementType === "defective_products") {
    shouldShowProducts =  true
    label = "What Product(s) Did Not Work"
  }
  if (selectedReplacementType === "products_damaged" && selectedReplacementTypeDetails !== "other") {
    shouldShowProducts =  true
    if (selectedReplacementTypeDetails === "broken_bottle") {
      label = "What Product(s) Broke"
      
    }
    if (selectedReplacementTypeDetails === "leakage") {
      label = "What Product(s) Leaked"

    }
    if (selectedReplacementTypeDetails === "leakage_due_to_box_damage") {
      label = "What Product(s) Leaked"
  
    }
  }
  if (selectedReplacementTypeDetails === "just_damage_no_sign_of_leakage") {
    shouldShowProducts = false
  }
  if (ifLabel) {
    return label
  } else {
    return shouldShowProducts
  }
}

// Replacement Reason Builder OutPut
export function buildOutReplacementReason(replacementType, additionalDetails, items, otherReason, user, jsonOutput, lotCodes) {
  
  if (jsonOutput) {
    var json = {
      replacement_reason: replacementType,
      replacement_reason_details: additionalDetails,
      other_reason: otherReason,
      lot_codes: lotCodes,
      items: items,
    }
    return json
  }
  var userName = `${user.first_name} ${user.last_name}`
  var mainReason = ""
  var replacementSubReasonText = ""

  var itemString = ""
  if (items) {
    items.map(function(item) {
      
      if (item === "cantDetermineWhichProduct") {
        itemString += "an unknown product"
      }
      productArray.map(function(product) {
        if (item === product.value) {
          itemString += `the ${product.text} `
          if (items.length > 1) {
            itemString += ", "
          }
        }
        return product
      })
      return item
    })
  }
  // Get the text form main reason and sub reason
  replacementReasonTypes.map(function (replacementReason) {
    if (replacementReason.value === replacementType) {
      if (replacementReason.subOptions) {
        replacementReason
          .subOptions
          .map(function (subOption) {
            if (subOption.value === additionalDetails) {
              replacementSubReasonText = subOption.text
            }
            return subOption
          })
      }
    }
    return replacementReason
  })

  if (replacementType === "other") {
    if (otherReason) {
      mainReason = otherReason
    }
  } else {
    if (replacementType === "shipment_never_received") {
      mainReason = "the shipment was never received"
      if (additionalDetails !== "other") {
        mainReason += ` because ${replacementSubReasonText}`
      }
    } else if (replacementType === "products_damaged") {
        mainReason += " the products or box was damaged  "
        if (additionalDetails !== "other") {
          if (additionalDetails === "leakage") {
            mainReason += ` because ${itemString} leaked`
          } else if (additionalDetails === "leakage_due_to_box_damage") {
            mainReason += ` because ${itemString} leaked`
          } else if (additionalDetails === "broken_bottle") {
            mainReason += ` because ${itemString} broke in shipment`
          } else if (additionalDetails === "just_damage_no_sign_of_leakage") {
            mainReason += ` from shipment with no visible sign that a specific product leaked or broke`

          }
        }
    } else if (replacementType === "missing_products") {
      var descriptionWord = "were"
      if (items) {
        if (items.length === 1) {
          descriptionWord = "was"
        }
      }
      itemString = itemString.slice(0, -1)
      mainReason += ` ${itemString} ${descriptionWord} missing from the shipment`
    } else if (replacementType === "defective_products") {
    
      itemString = itemString.slice(0, -1)
      mainReason += ` ${itemString} did not work`
    }



    // Add in the replacement other option to the end if none abolvoe all
    if (additionalDetails === "other") {
      if (otherReason) {
        mainReason += ` because ${otherReason}`
      }
    }

  }

  return `${userName}: ${mainReason.toLowerCase()}.`
}


// Renders a set of options for the dropdowns based on the main product array
function renderGroupOptions(options, groupName) {

  options = options.sort((a, b) => (a.text > b.text) ? 1 : -1)

  return options.map(function(option) {
    if (groupName === option.group) {
      return (
        <Option key={option.value} value={option.value}>
          {option.text}
        </Option>
      )
    } else {
      return null
    }
  })
}

export function renderProductOptions() {
  if (productGroupArray) {
    return productGroupArray.map(function(optionGroup) {
      return (
        <OptGroup label={optionGroup} key={optionGroup}>
            {renderGroupOptions(productArray, optionGroup)}
        </OptGroup>
      )
    })
}
}

export var boxTypeImageDict = {
  "Starter Box": "//images.ctfassets.net/z3ixs6i8tjtq/2zpnh6BF5mmh8jFr4Cv0h8/9f2c6fb2181cce2cd9ac32a23ef46716/BOX_2.png?q=95&fl=progressive&w=400&fit=fill",
  "Refill Box Outside": "//images.ctfassets.net/z3ixs6i8tjtq/6YFXLBUOKPm2i3imzVL225/77172a28c1d9220b3280334f6419e2f8/BOX_5.png?q=95&fl=progressive&w=400&fit=fill",
  "Refill Box Inside": "//images.ctfassets.net/z3ixs6i8tjtq/5uFUmv4NMJPpcnWp5wjZqW/99eeb453cd4cfdcc77e8277bbdba4d0f/BOX_4.png?q=95&fl=progressive&w=400&fit=fill",
  "Really Big Starter Box": "//images.ctfassets.net/z3ixs6i8tjtq/fSJKIGuO59s67iKMlGJY4/c054c33b960cac142360b0fc52e5952b/BOX_1.png?q=95&fl=progressive&w=400&fit=fill",
  "Small Light Blue Box": "//images.ctfassets.net/z3ixs6i8tjtq/36rkV5dyjCkt3CH9qfyf6v/3542c1bf170dcfe907c904499dfcf628/BOX_3.png?q=95&fl=progressive&w=400&fit=fill",
  "Starter Box for 8 Glass Products Outside": "//images.ctfassets.net/z3ixs6i8tjtq/ESeAouhNkXZBXvAi7v1IE/66757d00147cf539906351ee2521bcc1/Glass_Box.png?q=95&fl=progressive&w=961&fit=fill",
  "Starter Box Box 4 Glass Products": "//images.ctfassets.net/z3ixs6i8tjtq/5EF2b8iiKfRYfRp9MwTPDL/55a3435cdaf0eafcbe7f91868063a81d/4_Bed_Box.png?q=95&fl=progressive&w=961&fit=fill",
  "Starter Kit Box 4 Glass Products": "//images.ctfassets.net/z3ixs6i8tjtq/5EF2b8iiKfRYfRp9MwTPDL/55a3435cdaf0eafcbe7f91868063a81d/4_Bed_Box.png?q=95&fl=progressive&w=961&fit=fill",
  "Over Kit Box For 4 Glass Product Box Outside": "//images.ctfassets.net/z3ixs6i8tjtq/44iSLpss9L0Zs0phV9siGx/557240a2eb63c3afbf08a88eb44d5b8a/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  "Starter Kit Box 12 Glass Products": "//images.ctfassets.net/z3ixs6i8tjtq/7kLF7CkHyaBbJQ4oH8BpfI/2074ce3b047c0f5891a1453da5f4340d/12_Bed_Box.png?q=95&fl=progressive&w=961&fit=fill",
  "Starter Box for 1 Glass Product Outside": "//images.ctfassets.net/z3ixs6i8tjtq/2ZM7h7R6NeazGTPn15gVCb/74ad17f0c5755cf027f91ef1e7880d20/Single_Glas_Box.png?q=95&fl=progressive&w=961&fit=fill",
  "Over Kit Box For 8 Glass Product Box Outside": "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  "Over Kit Box For 9 Glass Product Box Outside": "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  "Over Kit Box For 6 Glass Product Box Outside": "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  "Uline Over Box for 4 and under Starter Box for 1 Glass Product": "https://images.ctfassets.net/z3ixs6i8tjtq/h0IHDbL5XLtU1VePVtVkR/5f459abc4f156cd76a23cd1093b3c85e/S-22169.jpg?q=95&fl=progressive&w=400&fit=fill",
  "Padded Mailer": "https://images.ctfassets.net/z3ixs6i8tjtq/6vORhEltHV3qmRyLl4A1OK/6c03dbe1063cd15a31e467b2c17b9272/Mailer.png?q=95&fl=progressive&w=400&fit=fill",
  
  "Refill Box (Extra Large)": "https://images.ctfassets.net/z3ixs6i8tjtq/fSJKIGuO59s67iKMlGJY4/c054c33b960cac142360b0fc52e5952b/BOX_1.png?q=95&fl=progressive&w=400&fit=fill",
  "Refill Box (Small)": "https://images.ctfassets.net/z3ixs6i8tjtq/2iHZiQCX48gBJ1ZpcRefdm/eae65ac04023f6ae829b5f342bc60151/32oz_Refill_Box.png?q=95&fl=progressive&w=400&fit=fill",
  "Refill Box (Medium)": "https://images.ctfassets.net/z3ixs6i8tjtq/3Rt8ZWIIgqfgFbvu7Uc5pn/c36dc76c758b1af1660b63e43b82e191/16oz_Refill.png?q=95&fl=progressive&w=400&fit=fill",
  "Refill Box (Large)": "https://images.ctfassets.net/z3ixs6i8tjtq/v1ZyiToEB3Hq02QS7hEFL/508e5a1fb1bd06de9ec32215a0a9dda2/whiteRefillBox.png?q=95&fl=progressive&w=400&fit=fill",
  "Vertical Box For 16 Items": "https://images.ctfassets.net/z3ixs6i8tjtq/4pBAUV9nJ3aq5SNaaxse0K/b4c3004c2b8ca51e6fc6dc25686de533/16.png?q=95&fl=progressive&w=961&fit=fill",
  "Vertical Box For 12 Items": "https://images.ctfassets.net/z3ixs6i8tjtq/6cY7WQbL0AdiEWpegweBYJ/b154d1ca4dba164af3f17f2b4f45e275/12.jpg?q=95&fl=progressive&w=961&fit=fill",
  "Vertical Box For 9 Items": "https://images.ctfassets.net/z3ixs6i8tjtq/1fXFYYjmOn2IuP1AUhflAH/e996b78535071e19afefe259c4ef4eb9/9.jpg?q=95&fl=progressive&w=961&fit=fill",
  "Vertical Box For 6 Items": "https://images.ctfassets.net/z3ixs6i8tjtq/j0mxWJOn6duKe6XGly9u8/073e6845a1807114ec420b0a14e372dc/6.jpg?q=95&fl=progressive&w=961&fit=fill",
  "Vertical Box For 4 Items": "https://images.ctfassets.net/z3ixs6i8tjtq/nLYAuz6QvIHP32NsIxTVm/99f9cead6384fab395feec989403f284/4.jpg?q=95&fl=progressive&w=961&fit=fill",

  "Single Refill Box": "https://images.ctfassets.net/z3ixs6i8tjtq/2JPwtzYsXQ9OE9laZElEiJ/09d72f7d5fbeb733711c3a42de750fd4/Single_Refill.png?q=95&fl=progressive&w=400&fit=fill",
  "Double Refill Box": "https://images.ctfassets.net/z3ixs6i8tjtq/2yygcq2C6Kmd5vN8EmwRGZ/67eef93c7468c2cb165234e73be9559c/Double_Refill.png?q=95&fl=progressive&w=400&fit=fill",
  "Triple Refill Box": "https://images.ctfassets.net/z3ixs6i8tjtq/6VWEmyKWvOgtJFUseTVNLB/b37b9c1b95181b0c12cec3af1209139b/Triple_Refill.png?q=95&fl=progressive&w=400&fit=fill",
}



export var insertTypeImageDict = {
  "First Order Booklet": "//images.ctfassets.net/z3ixs6i8tjtq/4jm8g9ce6EqxfkoU1muZgN/c5c7f81986fd4ffaa5281f56d8436858/INSERT_1.png?q=95&fl=progressive&w=400&fit=fill",
  "First Order (Glass) Booklet": "//images.ctfassets.net/z3ixs6i8tjtq/16WH0OCwrxyrgwZ6jabzYL/c155ee9b6f486e1c426af1cd3ea65040/GlassStarterBooklet.png?q=95&fl=progressive&w=200&h=200&fit=pad",
  "Recurring Order Booklet": "//images.ctfassets.net/z3ixs6i8tjtq/6H1xPoSb913mXWSqgK2sCa/af04cf7fb7c04aba8414928c16e3ad7d/INSERT_2.png?q=95&fl=progressive&w=400&fit=fill"
}

export const insertCardTypes = [
  {
    dearSKU: "1b6197f4-f71e-4cc5-9795-1554df0f4b72",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/4jm8g9ce6EqxfkoU1muZgN/c5c7f81986fd4ffaa5281f56d8436858/INSERT_1.png?q=95&fl=progressive&w=400&fit=fill"
  },
  {
    dearSKU: "8da91e74-6395-4769-aba7-aa4da3cd0ff92",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/16WH0OCwrxyrgwZ6jabzYL/c155ee9b6f486e1c426af1cd3ea65040/GlassStarterBooklet.png?q=95&fl=progressive&w=200&h=200&fit=pad"
  },
  {
    dearSKU: "8da91e74-6395-4769-aba7-aa4da3cd0ff9",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/6H1xPoSb913mXWSqgK2sCa/af04cf7fb7c04aba8414928c16e3ad7d/INSERT_2.png?q=95&fl=progressive&w=400&fit=fill"
    
  }
];


// Common Select Arrays
export const boxTypeArray = [
  {
    key: "starterKitBox",
    sales: 0,
    text: "White Starter Kit Box (Plastic)",
    dearSKU: "0b95eb2c-38b1-44d1-837f-06adfd468f5a",
    value: "starterKitBox",
    otherSku: "Starter Box",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/2zpnh6BF5mmh8jFr4Cv0h8/9f2c6fb2181cce2cd9ac32a23ef46716/BOX_2.png?q=95&fl=progressive&w=400&fit=fill",
  }, {
    key: "refillBox",
    sales: 0,
    dearSKU: "27030ced-7979-4f99-b916-90469138a6b0",
    text: "Refill Box (Multi Colors)",
    value: "refillBox",
    otherSku: "Refill Box",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/6YFXLBUOKPm2i3imzVL225/77172a28c1d9220b3280334f6419e2f8/BOX_5.png?q=95&fl=progressive&w=400&fit=fill",
  }, {
    key: "bigStarterKitBox",
    sales: 0,
    text: "Really Big Box",
    dearSKU: "de934035-6c70-41db-8164-ef08bd798d05",
    value: "bigStarterKitBox",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/fSJKIGuO59s67iKMlGJY4/c054c33b960cac142360b0fc52e5952b/BOX_1.png?q=95&fl=progressive&w=400&fit=fill",
  }, {
    key: "smallLightBlueBox",
    sales: 0,
    text: "Small Light Blue Box",
    dearSKU: "348be633-967a-4f7d-a727-d220d34fb735",
    value: "smallLightBlueBox",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/36rkV5dyjCkt3CH9qfyf6v/3542c1bf170dcfe907c904499dfcf628/BOX_3.png?q=95&fl=progressive&w=400&fit=fill",
  }, 
  
  {
    key: "starterFor1GlassProduct",
    sales: 0,
    text: "Starter Box for 1 Glass Product",
    dearSKU: "d6963479-0e2c-4f16-a8ed-76db6e040ba0",
    value: "starterFor1GlassProduct",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/2ZM7h7R6NeazGTPn15gVCb/74ad17f0c5755cf027f91ef1e7880d20/Single_Glas_Box.png?q=95&fl=progressive&w=961&fit=fill",
  },
  {
    key: "starterFor4GlassProducts",
    sales: 0,
    text: "Starter Box for 4 Glass Products",
    dearSKU: "",
    value: "glassStarterFor4Products",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/5EF2b8iiKfRYfRp9MwTPDL/55a3435cdaf0eafcbe7f91868063a81d/4_Bed_Box.png?q=95&fl=progressive&w=961&fit=fill",
  }, 
  {
    key: "starterFor8GlassProducts",
    sales: 0,
    text: "Starter Box for 8 Glass Products",
    dearSKU: "555e8f39-ba1e-4b66-b26a-fb2ec4b61519",
    value: "glassStarterFor8Products",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/ESeAouhNkXZBXvAi7v1IE/66757d00147cf539906351ee2521bcc1/Glass_Box.png?q=95&fl=progressive&w=961&fit=fill",
  },
  {
    key: "starterFor12GlassProducts",
    sales: 0,
    text: "Starter Box for 12 Glass Products",
    dearSKU: "",
    value: "glassStarterFor12Products",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/7kLF7CkHyaBbJQ4oH8BpfI/2074ce3b047c0f5891a1453da5f4340d/12_Bed_Box.png?q=95&fl=progressive&w=961&fit=fill",
  }, 

  {
    key: "overkitBoxFor8GlassProductBox",
    sales: 0,
    text: "Over Kit Box For 8 Glass Product Box",
    dearSKU: "e2027950-f877-46b3-bc0d-0744c0a5c794",
    value: "overkitBoxFor8GlassProductBox",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/S0wYXBhqM4cWX7CVwko64/5b297bfd27b4710671f4be81ebbfccc7/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  },
  {
    key: "overkitBoxFor4GlassProductBox",
    sales: 0,
    text: "Over Kit Box For 4 Glass Product Box",
    dearSKU: "e2027950-f877-46b3-bc0d-0744c0a5c794",
    value: "overkitBoxFor4GlassProductBox",
    image: "//images.ctfassets.net/z3ixs6i8tjtq/44iSLpss9L0Zs0phV9siGx/557240a2eb63c3afbf08a88eb44d5b8a/overbox.png?q=95&fl=progressive&w=300&fit=fill",
  },
  {
    key: "starterFor4GlassProductsUlineOverBox",
    sales: 0,
    text: "Uline Over Box for 4 and under Starter Box for 1 Glass Product",
    dearSKU: "e2027950-f877-46b3-bc0d-0744c0a5c794",
    value: "starterFor4GlassProductsUlineOverBox",
    image: "https://images.ctfassets.net/z3ixs6i8tjtq/h0IHDbL5XLtU1VePVtVkR/5f459abc4f156cd76a23cd1093b3c85e/S-22169.jpg?q=95&fl=progressive&w=400&fit=fill",
  },
  
];

export const shippingMethodArray = [
  {
    value: "ups_surepost_1_lb_or_greater",
    text: "UPS Sure Post"
  }, {
    value: "ups_ground",
    text: "UPS Ground"
  },   {
    value: "ups_2nd_day_air",
    text: "UPS 2 Day"
  }, {
    value: "ups_next_day_air",
    text: "UPS Next Day Air"
  },
  {
    value: "ups_worldwide_expedited",
    text: "UPS World Wide (ONLY FOR VI)"
  }
];

export const trueFalseArray = [
  {
    value: "false",
    text: "False"
  }, {
    value: "true",
    text: "True"
  }
];

export const ratingArray = [
  {
    value: "1",
    text: "1 Star"
  }, {
    value: "2",
    text: "2 Stars"
  }, {
    value: "3",
    text: "3 Stars"
  }, {
    value: "4",
    text: "4 Stars"
  }, {
    value: "5",
    text: "5 Stars"
  }
];

export const discountTypes = [
  {
    value: "percentage",
    text: "Percentage"
  }, {
    value: "fixed",
    text: "Fixed Dollar Off"
  }
];

export const replacementReasonTypes = [
  {
    value: "shipment_never_received",
    text: "The shipment was never received",
    subOptionsLabel: "Why Was It Not Received",
    subOptions: [
      {
        value: "no_signer",
        text: "No one was there to pick it up"
      }, {
        value: "could_not_deliver_damaged",
        text: "The carrier could not deliver as the box was too damaged"
      }, {
        value: "could_not_deliver_unknown_reason",
        text: "The carrier could not deliver because of an uknown reason"
      },  {
        value: "stolen_package",
        text: "The package was stolen"
      },  {
        value: "customer_claims_it_did_not_come",
        text: "The customer claims they never received it, however the carrier says it is delivered"
      }, {
        value: "other",
        text: "Other"
      }
    ]
  }, {
    value: "products_damaged",
    text: "The products or box was damaged",
    subOptionsLabel: "Damage Reason",
    subOptions: [
      {
        value: "leakage",
        text: "One or more of the products leaked, but there was NO SIGN OF BOX DAMAGE"
      }, {
        value: "leakage_due_to_box_damage",
        text: "Box damage caused one of more of the products to leak"
      },{
        value: "broken_bottle",
        text: "One or of the products broke or cracked"
      }, {
        value: "just_damage_no_sign_of_leakage",
        text: "The box or products were damaged, but with no sign of leakage or broken bottles"
      }, {
        value: "other",
        text: "Other"
      }
    ]
  }, {
    value: "missing_products",
    text: "Items in the box were missing or wrong products in the box"
  }, {
    value: "defective_products",
    text: "The customer said the products they received did not work "
  },{
    value: "other",
    text: "Other"
  }
];



export const onHoldReasonsArray = [
  {
    value: "bad_card",
    text: "This card was declined in a previous order. "
  }, {
    value: "bad_address",
    text: "Your shipping address is not valid."
  }, {
    value: "no_card",
    text: "You do not have a card on file."
  }, {
    value: "no_address",
    text: "You do not have an address on file."
  }
];

export const discountRedemptionTypes = [
  {
    value: "anytime",
    text: "Anytime"
  }, {
    value: "before_a_specific_date",
    text: "Before A Specific Date"
  }
];

export const discountLockedTypes = [
  {
    value: "any",
    text: "Any Product Or Subscription"
  }, {
    value: "starter_kit_glass_orders",
    text: "Starter Kit Glass Orders"
  },{
    value: "starter_kit_refill_orders",
    text: "Starter Kit Refill Orders"
  },
];

export const shipmentStatusArray = [
  {
    value: "awaiting_shipment",
    text: "Awaiting Shipment"
  }, {
    value: "cancelled",
    text: "Cancelled"
  },{
    value: "delayed",
    text: "Delayed"
  },  {
    value: "on_hold",
    text: "On Hold"
  }
];

export const subscriptionCancellationReasonsArray = [
  { 
    text: "I need more time to evaluate the products." ,
    value: "I need more time to evaluate the products." 
  },
  { 
    text: "I don't clean often enough for the membership" ,
    value: "I don't clean often enough for the membership" 
  },
  { 
    text: "I am not happy with the products." ,
    value: "I am not happy with the products." 
  },
  { 
    text: "My subscription is too expensive." ,
    value: "My subscription is too expensive." 
  },
  { 
    text: "I planned on canceling after my free trial." ,
    value: "I planned on canceling after my free trial." 
  },
  { 
    text: "I still have a stash of another brand's cleaning products" ,
    value: "I still have a stash of another brand's cleaning products" 
  },
  { 
    text: "I'm receiving too many products / I'm stockpiling ",
    value: "I'm receiving too many products / I'm stockpiling "
  },
];

export const subscriptionCancellationSavesReasonsArray = [
  { 
    text: "Edited their frequency" ,
    value: "Edited their frequency" 
  },
  { 
    text: "Discounted their order" ,
    value: "Discounted their order" 
  },
  { 
    text: "Removed items from subscription" ,
    value: "Removed items from subscription" 
  },
  { 
    text: "Pushed back their next shipment" ,
    value: "Pushed back their next shipment" 
  },
  { 
    text: "Removed & replaced items in their next shipment" ,
    value: "Removed & replaced items in their next shipment" 
  },
];

export const subscriptionStatusArray = [
  {
    value: "running",
    text: "Running"
  }, {
    value: "cancelled",
    text: "Cancelled"
  }, {
    value: "onHold",
    text: "On Hold"
  }
];

export const buttonColorArray = [
  {
    value: "button-info",
    text: "Blue"
  }, {
    value: "button-success",
    text: "Green"
  }, {
    value: "button-danger",
    text: "Red"
  }
];

export const frequencyArray = [
  {
    value: 1,
    text: "Every Month"
  }, {
    value: 2,
    text: "Every 2 Months"
  }, {
    value: 3,
    text: "Every 3 Months"
  }, {
    value: 4,
    text: "Every 4 Months"
  }, {
    value: 5,
    text: "Every 5 Months"
  }, {
    value: 6,
    text: "Every 6 Months"
  }
];

export const productGroupArray = [
  "N/A",
  "All Purpose Cleaner Unfilled Glass",
  "Liquid Dish Soap Unfilled Glass",
  "Liquid Hand Soap Unfilled Glass",
  "Liquid Laundry Detergent Unfilled Glass",
  "Foaming Hand Sanitizer Unfilled Glass",
  "Foaming Hand Soap Unfilled Glass",
  "Tablet Jar Unfilled Glass",

  
  
  "All Purpose Cleaner Refills - 32oz",
  "Foaming Hand Soap Refills - 32oz",
  "Liquid Dish Soap Refills - 32oz",
  "Liquid Hand Soap Refills - 32oz",
  "Liquid Laundry Refills - 32oz",
  "Foaming Hand Sanitizer Refills - 32oz",
  "Dishwasher Detergent Tablet Refills - 32ct",

  "Laundry Detergent Tablet Refills - 18ct",



  "Add Ons",
  "Dryer Balls",
  "Funnel",
  "Bar Soap - 5oz",
  "Stain Stick - 2.4oz",

  "All Purpose Cleaner Refills - 16oz",
  "Liquid Dish Soap Refills - 16oz",
  "Liquid Hand Soap Refills - 16oz",
  "Liquid Laundry Refills - 16oz",

  " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------",
]

export const productArray = [
  {
    value: undefined,
    text: "No Product",
    group: "N/A"
  }, 
  {
    value: "laundry_detergent",
    text: "Plastic Laundry Detergent Pouch - 18 ct - Unscented",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 
  {
    value: "all_purpose_cleaner_refill_bamboo_flower_32",
    text: "All Purpose Cleaner - Refill - 32oz - Bamboo Lily",
    dearSku: "",
    group: "All Purpose Cleaner Refills - 32oz"
  },
  {
    value: "all_purpose_cleaner_refill_blue_sage_32",
    text: "All Purpose Cleaner - Refill - 32oz - Blue Sage",
    dearSku: "",
    group: "All Purpose Cleaner Refills - 32oz"
  },
  {
    value: "all_purpose_cleaner_refill_sweet_honeysuckle_32",
    text: "All Purpose Cleaner - Refill - 32oz - Sweet Honeysuckle",
    dearSku: "",
    group: "All Purpose Cleaner Refills - 32oz"
  },

  {
    value: "all_purpose_cleaner_refill_orange_zest_32",
    text: "All Purpose Cleaner - Refill - 32 oz - Orange Zest",
    dearSku: "",
    group: "All Purpose Cleaner Refills - 32oz"
  }, 

  {
    value: "dish_soap_refill_blue_sage_32",
    text: "Liquid Dish Soap - Refill - 32oz - Blue Sage",
    dearSku: "",
    group: "Liquid Dish Soap Refills - 32oz"
  },

  
  {
    value: "dish_soap_refill_grapefruit_basil_32",
    text: "Liquid Dish Soap - Refill - 32oz - Grapefruit Basil",
    dearSku: "",
    group: "Liquid Dish Soap Refills - 32oz"
  },
  {
    value: "dish_soap_refill_lemongrass_32",
    text: "Liquid Dish Soap - Refill - 32oz - Lemongrass",
    dearSku: "",
    group: "Liquid Dish Soap Refills - 32oz"
  },
  {
    value: "dish_soap_refill_sweet_honeysuckle_32",
    text: "Liquid Dish Soap - Refill - 32oz - Sweet Honeysuckle",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },

 
  // FHS
  {
    value: "foaming_hand_sanitizer_refill_fragrence_free_32",
    text: "Foaming Hand Sanitizer - Refill - 32oz - Fragrance Free",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "foaming_hand_sanitizer_unfilled_glass_linen",
    text: "Foaming Hand Sanitizer - Unfilled Glass - Off White",
    dearSku: "",
    group:   " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------",
  },
  {
    value: "foaming_hand_sanitizer_unfilled_glass_periwinkle",
    text: "Foaming Hand Sanitizer - Unfilled Glass - Periwinkle",
    dearSku: "",
    group:   " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------",
  },
  {
    value: "foaming_hand_soap_unfilled_glass_linen",
    text: "Foaming Hand Soap - Unfilled Glass - Off White",
    dearSku: "",
    group: "Foaming Hand Soap Unfilled Glass"
  },
  {
    value: "foaming_hand_soap_unfilled_glass_periwinkle",
    text: "Foaming Hand Soap - Unfilled Glass - Periwinkle",
    dearSku: "",
    group: "Foaming Hand Soap Unfilled Glass"
  },



  {
    value: "liquid_hand_soap_refill_blue_sage_32",
    text: "Liquid Hand Soap - Refill - 32oz - Blue Sage",
    dearSku: "",
    group: "Liquid Hand Soap Refills - 32oz"
  },
  {
    value: "liquid_hand_soap_refill_grapefruit_basil_32",
    text: "Liquid Hand Soap - Refill - 32oz - Grapefruit Basil",
    dearSku: "",
    group: "Liquid Hand Soap Refills - 32oz"
  },
  {
    value: "foaming_hand_soap_refill_tea_tree_32",
    text: "Foaming Hand Soap - Refill - 32oz - Tea Tree",
    dearSku: "",
    group:   "Foaming Hand Soap Refills - 32oz",
  },
  {
    value: "foaming_hand_soap_refill_lavender_32",
    text: "Foaming Hand Soap - Refill - 32oz - Lavendar",
    dearSku: "",
    group:   "Foaming Hand Soap Refills - 32oz",
  },

  {
    value: "liquid_hand_soap_refill_lavendar_32",
    text: "Liquid Hand Soap - Refill - 32oz oz - Lavendar",
    dearSku: "",
    group: "Liquid Hand Soap Refills - 32oz"
  }, 
  {
    value: "liquid_hand_soap_refill_sweet_honeysuckle_32",
    text: "Liquid Hand Soap - Refill - 32oz - Sweet Honeysuckle",
    dearSku: "",
    group: "Liquid Hand Soap Refills - 32oz"
  },

  {
    value: "liquid_laundry_detergent_refill_juniper_sandalwood_32",
    text: "Liquid Laundry - Refill - 32oz - Juniper Sandalwood",
    dearSku: "",
    group: "Liquid Laundry Refills - 32oz"
  }, 

  {
    value: "liquid_laundry_detergent_refill_sea_spray_and_aloe_32",
    text: "Liquid Laundry - Refill - 32oz - Sea Spray Aloe",
    dearSku: "",
    group: "Liquid Laundry Refills - 32oz"
  },
  {
    value: "liquid_laundry_detergent_refill_sweet_honeysuckle_32",
    text: "Liquid Laundry - Refill - 32oz - Sweet Honeysuckle",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "liquid_laundry_detergent_refill_unscented_32",
    text: "Liquid Laundry - Refill - 32oz - Unscented",
    dearSku: "",
    group: "Liquid Laundry Refills - 32oz"
  },
  {
    value: "stain_stick",
    text: "Stain Stick - Bar - 2.4oz - Unscented",
    dearSku: "",
    group: "Stain Stick - 2.4oz"
  },



  {
    value: "liquid_laundry_detergent_refill_juniper_sandalwood_16",
    text: "Liquid Laundry - Refill - 16oz - Juniper Sandalwood",
    dearSku: "",
    group: "Liquid Laundry Refills - 16oz"
  }, 
  {
    value: "liquid_laundry_detergent_refill_sea_spray_and_aloe_16",
    text: "Liquid Liquid Laundry - Refill - 16oz - Sea Spray Aloe",
    dearSku: "",
    group: "Liquid Laundry Refills - 16oz"
  },
  {
    value: "liquid_laundry_detergent_refill_sweet_honeysuckle_16",
    text: "Liquid Liquid Laundry - Refill - 16oz - Sweet Honeysuckle",
    dearSku: "",
    group: "Liquid Laundry Refills - 16oz"
  },
  {
    value: "liquid_laundry_detergent_refill_unscented_16",
    text: "Liquid Laundry - Refill - 16oz - Unscented",
    dearSku: "",
    group: "Liquid Laundry Refills - 16oz"
  },



  {
    value: "all_purpose_cleaner_unfilled_glass_linen",
    text: "All Purpose Cleaner - Unfilled Glass - Linen ",
    dearSku: "",
    group: "All Purpose Cleaner Unfilled Glass"
  }, 
  {
    value: "all_purpose_cleaner_unfilled_glass_clay",
    text: "All Purpose Cleaner - Unfilled Glass - Clay Red ",
    dearSku: "",
    group: "All Purpose Cleaner Unfilled Glass"
  }, 
  {
    value: "all_purpose_cleaner_unfilled_glass_periwinkle",
    text: "All Purpose Cleaner - Unfilled Glass - Periwinkle ",
    dearSku: "",
    group: "All Purpose Cleaner Unfilled Glass"
  }, 
  {
    value: "all_purpose_cleaner_unfilled_glass_black",
    text: "All Purpose Cleaner - Unfilled Glass - Black ",
    dearSku: "",
    group: "All Purpose Cleaner Unfilled Glass"
  }, 

  {
    value: "tablet_jar_unfilled_glass_linen",
    text: "Tablet Jar - Unfilled Glass - Linen",
    dearSku: "",
    group: "Tablet Jar Unfilled Glass"
  }, 
  {
    value: "tablet_jar_unfilled_glass_lime",
    text: "Tablet Jar - Unfilled Glass - Lime Green",
    dearSku: "",
    group: "Tablet Jar Unfilled Glass"
  }, 
  {
    value: "tablet_jar_unfilled_glass_lemon",
    text: "Tablet Jar - Unfilled Glass - Lemon Yellow",
    dearSku: "",
    group: "Tablet Jar Unfilled Glass"
  }, 

  {
    value: "tablet_jar_unfilled_glass_periwinkle",
    text: "Tablet Jar - Unfilled Glass - Periwinkle",
    dearSku: "",
    group: "Tablet Jar Unfilled Glass"
  }, 
  {
    value: "tablet_jar_unfilled_glass_black",
    text: "Tablet Jar - Unfilled Glass - Black",
    dearSku: "",
    group: "Tablet Jar Unfilled Glass"
  }, 



  {
    value: "dish_soap_unfilled_glass_linen",
    text: "Liquid Dish Soap - Unfilled Glass - Linen",
    dearSku: "",
    group: "Liquid Dish Soap Unfilled Glass"
  }, 
  {
    value: "dish_soap_unfilled_glass_charcoal",
    text: "Liquid Dish Soap - Unfilled Glass - Grey Charcoal",
    dearSku: "",
    group: "Liquid Dish Soap Unfilled Glass"
  }, 
  {
    value: "dish_soap_unfilled_glass_periwinkle",
    text: "Liquid Dish Soap - Unfilled Glass - Periwinkle",
    dearSku: "",
    group: "Liquid Dish Soap Unfilled Glass"
  }, 
  {
    value: "dish_soap_unfilled_glass_black",
    text: "Liquid Dish Soap - Unfilled Glass - Black",
    dearSku: "",
    group: "Liquid Dish Soap Unfilled Glass"
  }, 
  {
    value: "liquid_hand_soap_unfilled_glass_linen",
    text: "Liquid Hand Soap - Unfilled Glass - Linen",
    dearSku: "",
    group: "Liquid Hand Soap Unfilled Glass"
  }, 
  {
    value: "liquid_hand_soap_unfilled_glass_midnight_blue",
    text: "Liquid Hand Soap - Unfilled Glass - Midnight Blue",
    dearSku: "",
    group: "Liquid Hand Soap Unfilled Glass"
  }, 
  {
    value: "liquid_hand_soap_unfilled_glass_periwinkle",
    text: "Liquid Hand Soap - Unfilled Glass - Periwinkle",
    dearSku: "",
    group: "Liquid Hand Soap Unfilled Glass"
  }, 
  {
    value: "liquid_hand_soap_unfilled_glass_black",
    text: "Liquid Hand Soap - Unfilled Glass - Black",
    dearSku: "",
    group: "Liquid Hand Soap Unfilled Glass"
  }, 

  {
    value: "liquid_laundry_detergent_unfilled_glass_linen",
    text: "Liquid Laundry Detergent - Unfilled Glass - Linen",
    dearSku: "",
    group: "Liquid Laundry Detergent Unfilled Glass"
  }, 
  {
    value: "liquid_laundry_detergent_unfilled_glass_lime",
    text: "Liquid Laundry Detergent - Unfilled Glass - Lime Green",
    dearSku: "",
    group: "Liquid Laundry Detergent Unfilled Glass"
  }, 
  {
    value: "liquid_laundry_detergent_unfilled_glass_periwinkle",
    text: "Liquid Laundry Detergent - Unfilled Glass - Periwinkle",
    dearSku: "",
    group: "Liquid Laundry Detergent Unfilled Glass"
  }, 

  {
    value: "liquid_laundry_detergent_unfilled_glass_black",
    text: "Liquid Laundry Detergent - Unfilled Glass - Black",
    dearSku: "",
    group: "Liquid Laundry Detergent Unfilled Glass"
  }, 

  {
    value: "tote_bag",
    text: "Free Reusable Tote Bag",
    dearSku: "",
    group: "Add Ons"
  }, 
  {
    value: "mini_tote_bag",
    text: "Mini Grocery Tote Bag",
    dearSku: "",
    group: "Add Ons"
  }, 
  {
    value: "cleancult_hat",
    text: "Cleancult Hat",
    dearSku: "",
    group: "Add Ons"
  }, 
  {
    value: "zippered_pouch",
    text: "Free Zippered Pouch",
    dearSku: "",
    group: "Add Ons"
  }, 
  {
    value: "laundry_bag",
    text: "Free Laundry Bag",
    dearSku: "",
    group: "Add Ons"
  }, 
  {
    value: "pumice_stone",
    text: "Pumice Stone",
    dearSku: "",
    group: "Add Ons"
  }, 

  {
    value: "all_purpose_cleaner",
    text: "Plastic All Purpose Cleaner - 16 oz - Orange Zest ",
    dearSku: "17e426c3-0202-42e7-86ac-4626394f7655",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 
 

  {
    value: "all_purpose_cleaner_glass_orange_zest_linen",
    text: "All Purpose Cleaner - Linen Glass - 16oz - Orange Zest",
    dearSku: "d23ed675-66bb-4f6b-b868-63300244a5df",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "all_purpose_cleaner_glass_orange_zest_clay",
    text: "All Purpose Cleaner - Clay Glass - 16oz - Orange Zest",
    dearSku: "1a8b9ac8-3570-4447-8935-b25bdfb79837",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },


  
  {
    value: "dishwasher_detergent",
    text: "Dishwasher Detergent Tablets Plastic - 18 ct - Lemongrass",
    dearSku: "e306b0ff-1c75-44f4-9114-279d082eaafb",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 
  {
    value: "dishwasher_detergent_refill",
    text: "Dish Tablet Refill Mailer - 18 ct - Lemongrass",
    dearSku: "0b4b9354-1644-4998-b989-698b429389af",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 
  {
    value: "dishwasher_detergent_refill_box",
    text: "Dish Tablet Refill Box - 32 ct - Lemongrass",
    dearSku: "",
    group: "Dishwasher Detergent Tablet Refills - 32ct"
  }, 
  {
    value: "dishwasher_detergent_tablets_glass_lemongrass_linen",
    text: "Dishwasher Detergent Tablets - Linen Glass - 18ct - Lemongrass",
    dearSku: "44a79436-cc9b-43be-8afe-2d9888edeeb7",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "dishwasher_detergent_tablets_glass_lemongrass_lemon",
    text: "Dishwasher Detergent Tablets - Lemon (Yellow) Glass - 18ct - Lemongrass",
    dearSku: "96952eb6-0f1b-4a60-8f8d-dc43da24ce82",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "dryer_balls",
    text: "Dryer Balls - 3 ct - Unscented",
    dearSku: "ad5f45f0-cbbe-4ecf-bc91-cf6f5624ed3a",
    group: "Dryer Balls"
  }, 
  {
    value: "foaming_hand_soap",
    text: "Plastic Foaming Hand Soap - 12 oz - Lavender",
    dearSku: "42e9b26f-ccc0-4c0b-b964-7b0e40f9e306",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 
  {
    value: "foaming_hand_soap_refill",
    text: "Foaming Hand Soap - Refill - 16 oz - Lavender",
    dearSku: "f7b41d86-5f2e-4009-a158-1fbd8f414827",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "laundry_detergent_refill",
    text: "Laundry Detergent Tablets - Refill - 18 ct - Unscented",
    dearSku: "39a9159b-0bde-471d-9949-d731e0660c83",
    group: "Laundry Detergent Tablet Refills - 18ct"
  }, 
  {
    value: "laundry_detergent_tablets_glass_unscented_linen",
    text: "Laundry Detergent Tablets - Linen Glass  - 18ct - Unscented",
    dearSku: "4d22f1c6-1b16-41d3-8fd5-0780420d8e46",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "laundry_detergent_tablets_glass_unscented_lime",
    text: "Laundry Detergent Tablets - Lime (Green) Glass  - 18ct - Unscented",
    dearSku: "95f92693-83e5-45b4-a359-ae8b891d09ae",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "dish_soap",
    text: "Plastic Liquid Dish Soap - 16 oz - Lemongrass",
    dearSku: "a92dfc45-d981-4c2c-b220-06ecccd06cdc",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  }, 


  {
    value: "dish_soap_glass_lemongrass_linen",
    text: "Liquid Dish Soap - Linen Glass  - 14oz - Lemongrass",
    dearSku: "5bd224f1-e4df-415e-9282-34757ef8dc36",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "dish_soap_glass_lemongrass_charcoal",
    text: "Liquid Dish Soap - Charcoal Glass  - 14oz - Lemongrass",
    dearSku: "9a6a5a35-6edc-42f4-a935-e759193ee364",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },


  {
    value: "liquid_hand_soap_glass_lavendar_linen",
    text: "Liquid Hand Soap - Linen Glass  - 16oz - Lavender",
    dearSku: "4a0713d6-8a85-44a6-a903-4b1616b72028",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "liquid_hand_soap_glass_lavendar_midnight_blue",
    text: "Liquid Hand Soap - Midnight Blue Glass  - 16oz - Lavender",
    dearSku: "5669a14e-b8a6-476a-b240-91194ce69e7f",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "silicon_funnel_linen",
    text: "Silicone Funnel-Linen",
    dearSku: "0907dab7-2c36-4990-93f1-c34e007e2007",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },

  {
    value: "silicon_funnel_periwinkle",
    text: "Silicone Funnel-Periwinkle",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "silicon_funnel_black",
    text: "Silicone Funnel-Black",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },

  // New Products Come 8/1 
  {
    value: "all_purpose_cleaner_refill_bamboo_flower_16",
    text: "All Purpose Cleaner - Refill - 16oz - Bamboo Flower",
    dearSku: "",
    group: "All Purpose Cleaner Refills - 16oz"
  },
  {
    value: "all_purpose_cleaner_refill_blue_sage_16",
    text: "All Purpose Cleaner - Refill - 16oz - Blue Sage",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },
  {
    value: "all_purpose_cleaner_refill",
    text: "All Purpose Cleaner - Refill - 16 oz - Orange Zest",
    dearSku: "ae7d9b4f-ca13-45d9-a786-5f3f03214343",
    group: "All Purpose Cleaner Refills - 16oz"
  },  
  {
    value: "all_purpose_cleaner_refill_sweet_honeysuckle_16",
    text: "All Purpose Cleaner - Refill - 16oz - Sweet Honeysuckle",
    dearSku: "",
    group: " ------- DISCONTINUED (DO NOT ADD TO ORDERS) -------"
  },


  // {
  //   value: "liquid_hand_soap_refill_blue_sage_16",
  //   text: "Liquid Hand Soap - Refill - 16oz - Blue Sage",
  //   dearSku: "",
  //   group: "Liquid Hand Soap Refills - 16oz"
  // },

  // {
  //   value: "liquid_hand_soap_refill_grapefruit_basil_16",
  //   text: "Liquid Hand Soap - Refill - 16oz - Grapefruit Basil",
  //   dearSku: "",
  //   group: "Liquid Hand Soap Refills - 16oz"
  // },

  {
    value: "liquid_hand_soap_refill_lavendar_16",
    text: "Liquid Hand Soap - Refill - 16 oz - Lavendar",
    dearSku: "03b20061-71c2-4b84-b49c-fe5e208bb10a",
    group: "Liquid Hand Soap Refills - 16oz"
  }, 
  // {
  //   value: "liquid_hand_soap_refill_sweet_honeysuckle_16",
  //   text: "Liquid Hand Soap - Refill - 16oz - Sweet Honeysuckle",
  //   dearSku: "",
  //   group: "Liquid Hand Soap Refills - 16oz"
  // },


  // {
  //   value: "dish_soap_refill_blue_sage_16",
  //   text: "Liquid Dish Soap - Refill - 16oz - Blue Sage",
  //   dearSku: "",
  //   group: "Liquid Dish Soap Refills - 16oz"
  // },
  // {
  //   value: "dish_soap_refill_grapefruit_basil_16",
  //   text: "Liquid Dish Soap - Refill - 16oz - Grapefruit Basil",
  //   dearSku: "",
  //   group: "Liquid Dish Soap Refills - 16oz"
  // },
  {
    value: "dish_soap_refill",
    text: "Liquid Dish Soap - Refill - 16oz - Lemongrass",
    dearSku: "fe1abc97-485f-4c9b-bd10-831b30df4b96",
    group: "Liquid Dish Soap Refills - 16oz"
  }, 
  // {
  //   value: "dish_soap_refill_sweet_honeysuckle_16",
  //   text: "Liquid Dish Soap - Refill - 16oz - Sweet Honeysuckle",
  //   dearSku: "",
  //   group: "Liquid Dish Soap Refills - 16oz"
  // },


  {
    value: "bar_soap_bamboo_flower_5",
    text: "Bar Soap - 5 oz - Bamboo Lily",
    dearSku: "",
    group: "Bar Soap - 5oz"
  },
  {
    value: "bar_soap_blue_sage_5",
    text: "Bar Soap - 5 oz - Blue Sage",
    dearSku: "",
    group: "Bar Soap - 5oz"
  }, 
  {
    value: "bar_soap_grapefruit_basil_5",
    text: "Bar Soap - 5 oz - Grapefruit Basil",
    dearSku: "",
    group: "Bar Soap - 5oz"
  }, 
  {
    value: "bar_soap_juniper_sandalwood_5",
    text: "Bar Soap - 5 oz - Juniper Sandalwood",
    dearSku: "",
    group: "Bar Soap - 5oz"
  }, 
  {
    value: "bar_soap",
    text: "Bar Soap - 5 oz - Lemongrass",
    dearSku: "13894c46-769b-4e89-bfdc-701e1069c133",
    group: "Bar Soap - 5oz"
  }, 

 


  {
    value: "bar_soap_sea_spray_and_aloe_5",
    text: "Bar Soap - 5 oz - Sea Spray & Aloe",
    dearSku: "",
    group: "Bar Soap - 5oz"
  }, 
  {
    value: "bar_soap_sweet_honeysuckle_5",
    text: "Bar Soap - 5 oz - Sweet Honeysuckle",
    dearSku: "",
    group: "Bar Soap - 5oz"
  }, 
  
  


];

export const stateArray = [
  {
    value: "AA",
    text: "AA"
  }, {
    value: "AE",
    text: "AE"
  }, {
    value: "AP",
    text: "AP"
  }, {
    value: "AL",
    text: "AL"
  }, {
    value: "AK",
    text: "AK"
  }, {
    value: "AZ",
    text: "AZ"
  }, {
    value: "AR",
    text: "AR"
  }, {
    value: "CA",
    text: "CA"
  }, {
    value: "CO",
    text: "CO"
  }, {
    value: "CT",
    text: "CT"
  }, {
    value: "DC",
    text: "DC"
  }, {
    value: "DE",
    text: "DE"
  }, {
    value: "FL",
    text: "FL"
  }, {
    value: "GA",
    text: "GA"
  }, {
    value: "HI",
    text: "HI"
  }, {
    value: "ID",
    text: "ID"
  }, {
    value: "IL",
    text: "IL"
  }, {
    value: "IN",
    text: "IN"
  }, {
    value: "IA",
    text: "IA"
  }, {
    value: "KS",
    text: "KS"
  }, {
    value: "KY",
    text: "KY"
  }, {
    value: "LA",
    text: "LA"
  }, {
    value: "ME",
    text: "ME"
  }, {
    value: "MD",
    text: "MD"
  }, {
    value: "MA",
    text: "MA"
  }, {
    value: "MI",
    text: "MI"
  }, {
    value: "MN",
    text: "MN"
  }, {
    value: "MS",
    text: "MS"
  }, {
    value: "MO",
    text: "MO"
  }, {
    value: "MT",
    text: "MT"
  }, {
    value: "NE",
    text: "NE"
  }, {
    value: "NV",
    text: "NV"
  }, {
    value: "NH",
    text: "NH"
  }, {
    value: "NJ",
    text: "NJ"
  }, {
    value: "NM",
    text: "NM"
  }, {
    value: "NY",
    text: "NY"
  }, {
    value: "NC",
    text: "NC"
  }, {
    value: "ND",
    text: "ND"
  }, {
    value: "OH",
    text: "OH"
  }, {
    value: "OK",
    text: "OK"
  }, {
    value: "OR",
    text: "OR"
  }, {
    value: "PA",
    text: "PA"
  }, {
    value: "PR",
    text: "PR"
  }, {
    value: "RI",
    text: "RI"
  }, {
    value: "SC",
    text: "SC"
  }, {
    value: "SD",
    text: "SD"
  }, {
    value: "TN",
    text: "TN"
  }, {
    value: "TX",
    text: "TX"
  }, {
    value: "UT",
    text: "UT"
  }, {
    value: "VT",
    text: "VT"
  },  {
    value: "VI",
    text: "VI"
  },{
    value: "VA",
    text: "VA"
  }, {
    value: "WA",
    text: "WA"
  }, {
    value: "WV",
    text: "WV"
  }, {
    value: "WI",
    text: "WI"
  }, {
    value: "WY",
    text: "WY"
  }
];
