import React from "react";
import IdleTimer from "react-idle-timer";
import { logOutUser } from "../../actions/actionsAuth";
import { connect } from "react-redux";
let inDEV = (process.env.NODE_ENV === "development")

class UserIdler extends React.Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 20}
        />
      </div>
    );
  }

  _onIdle() {
    if (!inDEV) {
      this.props.logOutUser(true);
    }
  }
}

export default connect(
  null,
  { logOutUser }
)(UserIdler);
