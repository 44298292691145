import React from "react";
import { Alert, Divider, Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  datePickerField,
  selectField,
  plainNumberField
} from "../Form/FieldRenders";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { refundOrder } from "../../actions/actionsOrders";
import {
  refundReasonTypes,
  buildOutRefundReason,
  productGroupArray,
  getRefundType,
  getNewProductArray,
  getRefundItemSelectionDetails
} from "../../helpers";

const validate = values => {
  const errors = {};
  if (!values.refund_amount) {
    errors.refund_amount = "Required";
  }
  if (!values.refund_type) {
    errors.refund_type = "Required"
}
if (!values.date_refunded) {
    errors.date_refunded = "Required"
}
if (!values.additional_refund_details) {
    errors.additional_refund_details = "Required"
}
if (!values.items || values.items.length === 0) {
    errors.items = "Required"
}
if (!values.other_reason) {
    errors.other_reason = "Required"
}
  return errors;
};

class RefundView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["order_id"] = this.props.orderDetail.transaction_id;


    var refund_reason = buildOutRefundReason(
        this.props.formValues.refund_type,
        this.props.formValues.additional_refund_details, 
        this.props.formValues.items, 
        this.props.formValues.other_reason,
        this.props.formValues.date_refunded,
        this.props.loggedInUser,
        false
    )
    var refund_information =  buildOutRefundReason(
        this.props.formValues.refund_type,
        this.props.formValues.additional_refund_details, 
        this.props.formValues.items, 
        this.props.formValues.other_reason,
        new Date(this.props.formValues.date_refunded),
        this.props.loggedInUser,
        true
    )

    props["refund_reason"] = refund_reason;
    props["refund_information"] = refund_information;
    this.props.refundOrder(props);
  }

  componentWillReceiveProps(nextProps) {
    // Change the replacemebnt reason details if the main reason is not the same
    if (this.props.formValues && this.props.formValues.refund_type) {
        if (this.props.formValues.refund_type !== nextProps.formValues.refund_type) {
            this.props.change("additional_refund_details", "")
            this.props.change("items", [])
        }
    }
}

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        {
          this.props.orderDetail.cart.gift_card ? (
            <Alert
              message="Danger Zone"
              description="When refunding a gift card you can ONLY refund the entire amount of the gift card. As well, you cannot refund a gift card if it already has been redeemd. Please write in the FULL amount of the refund value because of the gift card is getting cancelled either way."
              type="error"
            />
          ) : (
            <Alert
              message="Danger Zone"
              description="You are about to return money back to a customer"
              type="error"
            />
          )
        }
        <br />
        {this.props.renderReusableCartInformation()}
        <Divider />
        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Field
              label={"Refund Value"}
              name="refund_amount"
              required={true}
              addonBefore="Http://"
              step={0.01}
              type={"number"}
              size="large"
              component={plainNumberField}
            />
            {
              this.props.orderDetail.cart.total_gift_card_amount && (
                <p>* When refunding an order with a gift card value applied, the credit card purchase or paypal charge will be refunded only. To refund the gift card purchase just go to the customer profile and add in the gift card value directly to their profile to make up the difference. </p>
              )
            }
              <Divider>Refund Details</Divider>
                <Field
                    label={"Refund Date"}
                    name="date_refunded"
                    size={"large"}
                    overRideTouched={true}
                    required={true}
                    component={datePickerField}
                />
                <p>Select today.</p>
                {this.props.formValues &&
                    this.props.formValues.date_refunded && (
                    <Field
                        label={"Refund Type"}
                        name="refund_type"
                        size={"large"}
                        overRideTouched={true}
                        required={true}
                        options={refundReasonTypes}
                        component={selectField}
                    />
                )}
            {this.props.formValues &&
                this.props.formValues.refund_type && 
                getRefundType(this.props.formValues.refund_type).subOptions && (
                    <Field
                        label={getRefundType(this.props.formValues.refund_type).subOptionsLabel}
                        name="additional_refund_details"
                        size={"large"}
                        overRideTouched={true}
                        required={true}
                        options={getRefundType(this.props.formValues.refund_type).subOptions}
                        component={selectField}
                    />
                )}
            {this.props.formValues &&
                this.props.formValues.refund_type && 
                (this.props.formValues.additional_refund_details || this.props.formValues.refund_type === "product_isses"  )&&
                    getRefundItemSelectionDetails(this.props.formValues.refund_type, this.props.formValues.additional_refund_details, false) && (
                    <Field
                        label={getRefundItemSelectionDetails(this.props.formValues.refund_type, this.props.formValues.additional_refund_details, true)}
                        name="items"
                        size={"large"}
                        required={true}
                        overRideTouched={true}
                        optionGroups={productGroupArray}
                        mode={"multiple"}
                        options={getNewProductArray(this.props.formValues.refund_type)}
                        component={selectField}
                    />
                )}
                {this.props.formValues &&
                    (this.props.formValues.refund_type === "other" || 
                    this.props.formValues.additional_refund_details === "other") && (
                        <Field
                            label={"Other Reason"}
                            name="other_reason"
                            required={true}
                            hideErrorLabel={false}
                            overRideTouched={true}
                            size="large"
                            rows={8}
                            component={textAreaField}
                        />
                    )}
                    <br />
                {
                    this.props.formValues && this.props.formValues.refund_type && (
                    <p><b>New Refund Reason: </b> {buildOutRefundReason(
                        this.props.formValues.refund_type,
                        this.props.formValues.additional_refund_details, 
                        this.props.formValues.items, 
                        this.props.formValues.other_reason,
                        this.props.formValues.date_refunded,
                        this.props.loggedInUser,
                        false
                    )}</p>
                    )
                }

            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="danger"
            >
              Refund Order
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

RefundView = reduxForm({
  form: "RefundForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(RefundView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    orderDetail: state.orders.orderDetail,
    loggedInUser: state.auth.logged_in_user,
    formValues: getFormValues("RefundForm")(state)
  };
}

export default connect(
  mapStateToProps,
  { refundOrder, change }
)(RefundView);
