import React from "react";
import { connect } from "react-redux";
import SearchView from "../../components/Addresses/SearchView";
import Table from "../../components/Addresses/Table";

class Addresses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: "",
    };
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
            <div>
                <SearchView />
              <Table addresses={this.props.addresses ? this.props.addresses : []} />
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    addresses: state.customers.addresses,
    customersLoading: state.customers.customers_loading,
    loggedInUser: state.auth.logged_in_user,
  };
}

export default connect(
  mapStateToProps,
  {  }
)(Addresses);
