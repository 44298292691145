import React from "react";
import Confetti from "react-dom-confetti";

function generateConfig(angle) {
  return {
    angle: angle.toString(),
    spread: "255",
    startVelocity: 45,
    elementCount: "150",
    dragFriction: 0.1,
    duration: 8000,
    stagger: "5",
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };
}

class ConfettiBomb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }
  explodeCannon() {
    this.setState({ active: true });
    var that = this;

    setTimeout(function() {
      that.setState({ active: false });
    }, 3000);
  }
  render() {
    return (
      <div>
        <div className="confetti-bomb" onClick={() => this.explodeCannon()}>
          <Confetti active={this.state.active} config={generateConfig(120)} />
          <span role="img" aria-label="Confetti bomb">
            🎊
          </span>
        </div>
        <div
          style={{
            left: "20px",
            top: "20px",
            position: "fixed",
            zIndex: "999"
          }}
        >
          <Confetti active={this.state.active} config={generateConfig(240)} />
        </div>
        <div
          style={{
            left: "20px",
            bottom: "20px",
            position: "fixed",
            zIndex: "999"
          }}
        >
          <Confetti active={this.state.active} config={generateConfig(90)} />
        </div>
        <div
          style={{
            right: "20px",
            top: "20px",
            position: "fixed",
            zIndex: "999"
          }}
        >
          <Confetti active={this.state.active} config={generateConfig(300)} />
        </div>
      </div>
    );
  }
}

export default ConfettiBomb;
