import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { fetchUserOrderDetails } from "./actionsOrders";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Sets All the subscriptions intot eh reduc state
export function setAllSubscriptionsSuccess(json) {
  return {
    type: types.FETCH_ALL_SUBSCRIPTIONS_SUCCESS,
    payload: json
  };
}

export function setAllSubscriptionsLoading() {
  return {
    type: types.FETCH_ALL_SUBSCRIPTIONS_LOADING,
    payload: null
  };
}

// Retrives All Of The User Order History
export function fetchAllSubscriptions(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllSubscriptionsLoading());
    } else {
      message.loading("Loading in updated subscriptions");
    }

    return axios({
      url: `${BASE_API_URL}get-all-subscribers/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the subscriptions"
        );
      });
  };
}

// Searches All Of The User Order History
export function searchAllSubscriptions(params) {
  return function(dispatch) {
    
    dispatch(setAllSubscriptionsLoading());

    return axios({
      url: `${BASE_API_URL}search-for-all-user-subscriptions${params}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllSubscriptionsSuccess(response.data.subscriptions));
      })
      .catch(function(error) {
        dispatch(setAllSubscriptionsLoading());
        message.error(
          "Hmmm, there seems to be an error retrieving the subscriptions"
        );
      });
  };
}

// discount subscription by a certain percentage
export function discountSubscription(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-subsciption-discount`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Subscription Discounted");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage("Success"));
        history.push("/portal/subscriptions/" + data.transaction_id);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        message.error(
          "Hmmm, there seems to be an error discounting this subscription"
        );
      });
  };
}

// Completely edit a user subscription
export function editSubscription(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-user-subscription`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Subscription Saved");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage("Success"));
        history.push("/portal/subscriptions/" + data.transaction_id);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error editing this subscription"
          );
        }
      });
  };
}


// Sets All the subscriptions intot eh reduc state
export function setAllUpcomingSubscriptionChargesSuccess(json) {
  return {
    type: types.FETCH_ALL_UPCOMING_SUBSCRIPTION_CHARGES_SUCCESS,
    payload: json
  };
}

// Retrives All Of The upcmonig subcsription charges
export function fetchAllUpcomingSubscriptionCharges() {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-upcoming-subscription-charges`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllUpcomingSubscriptionChargesSuccess(response.data.subscription_data));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the upcoming subscription charges"
        );
      });
  };
}