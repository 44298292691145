import React from "react";
import ReactTable from "react-table";
import DownloadCSV from "../Common/DownloadCSV";
import { Link } from "react-router-dom";
import { Button, Tag } from "antd";
import {convertShippingMethodToTag} from "../../helpers"
var dateFormat = require("dateformat");

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this.reactTable.getResolvedState().sortedData;
    this.setState({ data: data });
  }

  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right" }}>
          <Link to={"/portal/discounts/create-discount"}>
            <Button
              ghost
              icon="file-add"
              type="primary"
              style={{ marginRight: "5px" }}
            >
              Create Discount
            </Button>
          </Link>

          <DownloadCSV itemName={"Discounts"} data={this.state.data} />
        </div>
        <ReactTable
          data={this.props.discounts}
          noDataText="No Discounts Found"
          filterable
          ref={r => (this.reactTable = r)}
          columns={[
            {
              Header: "ID",
              id: "discount_id",
              width: 120,
              accessor: d => d.discount_id,
              Cell: row => (
                <Link to={"/portal/discounts/" + row.value}>{row.value}</Link>
              )
            },
            {
              Header: "User ID",
              id: "user_facing_discount_id",
              accessor: d => d.user_facing_discount_id
            },
            {
              Header: "Date Created",
              id: "date_created",
              accessor: d => d.date_created,
              Cell: row => dateFormat(row.value, " mmmm dS yyyy")
            },
            {
              Header: "Internal ID",
              id: "internal_name",
              accessor: d => d.internal_name
            },
            {
              Header: "Locked To Product Type",
              width: 200,
              id: "locked_discount_type",
              accessor: d => d.locked_discount_type,
              Cell: row => convertShippingMethodToTag(row.value)
            },
            {
              Header: "Discount Type",
              id: "discount_type",
              accessor: d => d.discount_type,
              Cell: row => <Tag>{row.value.toUpperCase()}</Tag>
            },
            {
              Header: "Percentage Off",
              id: "percentage_off",
              accessor: d => d.percentage_off
            },
            {
              Header: "Fixed Amount Off",
              id: "fixed_amount_off",
              accessor: d => d.fixed_amount_off
            },
            {
              Header: "Redemption Type",
              id: "redemption_type",
              accessor: d => d.redemption_type,
              Cell: row => <Tag>{row.value.toUpperCase()}</Tag>
            },
            {
              Header: "Use Before",
              id: "redemption_time_frame",
              accessor: d => d.redemption_time_frame
            },
            {
              Header: "Number of Redemptions",
              id: "number_of_redemptions",
              accessor: d => d.number_of_redemptions
            },
            {
              Header: "Redemptions Per Account",
              id: "redemptions_per_account",
              accessor: d => d.redemptions_per_account
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
