import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Sets All the subscriptions intot eh reduc state
export function setAllCustomersSuccess(json) {
  return {
    type: types.FETCH_ALL_CUSTOMERS_SUCCESS,
    payload: json
  };
}

export function setAllCustomersLoading() {
  return {
    type: types.FETCH_ALL_CUSTOMERS_LOADING,
    payload: null
  };
}

// Retrives All Of customers
export function fetchAllCustomers(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllCustomersLoading());
    } else {
      message.loading("Loading in updated customers");
    }

    return axios({
      url: `${BASE_API_URL}get-all-customers/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllCustomersSuccess(response.data.customers));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the customers"
        );
      });
  };
}

// Customer Details
export function resetCustomerDetailsDispatched() {
  return {
    type: types.RESET_CUSTOMER_DETAILS,
    payload: null
  };
}

// Reset customer details
export function resetCustomerDetails() {
  return function(dispatch) {
    dispatch(resetCustomerDetailsDispatched());
  };
}

// Load the customers details into the redux state
export function fetchCustomerDetailsSuccess(customerDetails) {
  return { type: types.FETCH_CUSTOMER_DETAILS, payload: customerDetails };
}

// Retrives Specifc Data on a user order
export function fetchCustomerDetails(customerID) {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-customer-details/?customer_id=${customerID}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        if (response.status === 302) {
          window.location.reload();
        }
        dispatch(fetchCustomerDetailsSuccess(response.data.customer_details));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the customer details"
        );
      });
  };
}

// REset Password Action
export function resetPassword(data, adminReset) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}reset-user-password/`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Password Has Been Reset");
        dispatch(setFormSuccessMessage("Success"));
        if (adminReset) {
          history.push("/portal/user-management/" + data.random_user_id);
        } else {
          history.push("/portal/customers/" + data.random_user_id);
        }
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error reseting this password"
          );
        }
      });
  };
}

// Generalist function to handle Account Detail edits
export function editUserProfileAdmin(data, method) {
  console.log(data)
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-user-profile-admin`,
      timeout: 20000,
      method: method,
      responseType: "json",
      data: data,
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success(response.data.message);
        dispatch(setFormSuccessMessage("Success"));
        dispatch(fetchCustomerDetails(data.random_user_id));
        history.push("/portal/customers/" + data.random_user_id);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error("There was an error performing this action");
        }
      });
  };
}

// Load the customers details into the redux state
export function fetchCustomerAddressesAndPaymentMethodsSuccess(
  customerDetails
) {
  return {
    type: types.FETCH_CUSTOMER_ADDRESSES_AND_PAYMENT_METHODS,
    payload: customerDetails
  };
}

// Retrives only a users addresses and payment ethods
export function fetchCustomerAddressesAndPaymentMethods(customerID) {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-customer-addresses-and-payment-methods/?customer_id=${customerID}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        if (response.status === 302) {
          window.location.reload();
        }
        dispatch(
          fetchCustomerAddressesAndPaymentMethodsSuccess(
            response.data.customer_details
          )
        );
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the customer details"
        );
      });
  };
}


// Retrives All Of customers
export function searchAllCustomers(params) {
  return function(dispatch) {
    dispatch(setAllCustomersLoading());
  
    return axios({
      url: `${BASE_API_URL}search-for-all-customers${params}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllCustomersSuccess(response.data.customers));
      })
      .catch(function(error) {
        dispatch(setAllCustomersLoading());
        message.error(
          "Hmmm, there seems to be an error retrieving the customers"
        );
      });
  };
}





// Sets All the subscriptions intot eh reduc state
export function setAllAddressesSuccess(json) {
  return {
    type: types.FETCH_ALL_ADDRESSES_SUCCESS,
    payload: json
  };
}

// Retrives All Of customers
export function searchAllAddresses(params) {
  return function(dispatch) {
    dispatch(setAllCustomersLoading());
  
    return axios({
      url: `${BASE_API_URL}search-for-all-addresses${params}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllAddressesSuccess(response.data.addresses));
      })
      .catch(function(error) {
        dispatch(setAllCustomersLoading());
        message.error(
          "Hmmm, there seems to be an error retrieving the customers"
        );
      });
  };
}
