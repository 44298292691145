import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { renderProductTag, convertBooleanToTag, renderProductOptions } from "../../helpers";
import { Select } from "antd";

const { Option } = Select;

class Table extends React.Component {
  render() {
    return (
      <div className="table-container ">
        <ReactTable
          data={this.props.reviews}
          noDataText="No Reviews Found"
          filterable
          columns={[
            {
              Header: "Review ID",
              id: "review_id",
              width: 120,
              accessor: d => d.review_id,
              Cell: row => (
                <Link to={"/portal/reviews/" + row.value}>{row.value}</Link>
              )
            },
            {
              Header: "Rating",
              id: "rating",
              width: 80,
              accessor: d => d.rating,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value !== "all") {
                  return row["rating"] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  onChange={onChange}
                  style={{ width: "100%" }}
                  size="small"
                  value={filter ? filter.value : "all"}
                >
                  <Option value="all">All</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              )
            },
            {
              Header: "Date Created",
              id: "date_created",
              accessor: d => d.date_created,
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            },
            {
              Header: "Product",
              id: "product",
              width: 430,
              accessor: d => d.product,
              Cell: row => renderProductTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return row["product"] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  showSearch={false}
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>

                  {renderProductOptions()}
                </Select>
              )
            },
            {
              Header: "Date Responded",
              id: "date_responded",
              accessor: d => d.date_responded,
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            },
            {
              Header: "Order",
              id: "linked_order",
              accessor: d => d.linked_order,
              Cell: row => (
                <Link to={"/portal/orders/" + row.value}>{row.value}</Link>
              )
            },
            {
              Header: "Verified",
              id: "verified_review",
              accessor: d => d.verified_review,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["verified_review"] === true;
                }
                return row["verified_review"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "Display",
              id: "display_review",
              accessor: d => d.display_review,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["display_review"] === true;
                }
                return row["display_review"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
            {
              Header: "User",
              id: "linked_user",
              accessor: d => d.linked_user,
              Cell: row => (
                <Link to={"/portal/customers/" + row.value}>{row.value}</Link>
              )
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
