import React from "react";
import { connect } from "react-redux";
import { fetchAllAdminUsers } from "../../actions/actionsAdminUsers";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/AdminUsers/DashboardRow";
import Table from "../../components/AdminUsers/Table";

import CreateAdminUserFormView from "../../components/AdminUsers/CreateAdminUserFormView";
// import DetailView from "../../components/Customers/DetailView";

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seletedAdminUser: null,
      searchFilter: "",
      childModalOpen: false,
      createAdminUserModalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }

  setSelectedAdminUser(nextProps) {
    var adminUserID = nextProps.location.pathname.match(/\d+/);
    if (adminUserID) {
      adminUserID = adminUserID[0];
      var that = this;
      nextProps.adminUsers.map(function(adminUser) {
        if (adminUser.random_user_id === adminUserID) {
          that.setState({ seletedAdminUser: adminUser });
        }
        return adminUser;
      });
    } else {
      this.setState({ seletedAdminUser: null });
    }
  }

  // Checks to see whether or not to open the secondery modal inside of the admin user detail view
  checkIfChildModalOpen(pathname) {
    var childModalOpen = false;
    var createAdminUserModalOpen = false;

    if (pathname.includes("reset")) {
      childModalOpen = true;
    }
    if (pathname.includes("create")) {
      createAdminUserModalOpen = true;
    }

    this.setState({
      childModalOpen: childModalOpen,
      createAdminUserModalOpen: createAdminUserModalOpen
    });
  }

  onClose() {
    this.setState({
      seletedAdminUser: null
    });
  }

  //   Go back to the main admin users page
  closeModal() {
    history.push("/portal/user-management");
  }

  closeChildModal() {
    history.push(
      `/portal/user-management/${this.state.seletedAdminUser.random_user_id}`
    );
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the admin users are not already populated, populate them
    if (!this.props.adminUsers) {
      this.props.fetchAllAdminUsers();
    } else {
      this.setSelectedAdminUser(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.adminUsers) {
      this.setSelectedAdminUser(nextProps);
    }
  }

  getInitialValues(adminUser) {
    if (adminUser) {
      var initialValues = Object.assign({}, adminUser);

      initialValues.is_active = initialValues.is_active.toString();
      initialValues.is_superuser = initialValues.is_superuser.toString();
      initialValues.is_staff = initialValues.is_staff.toString();
      initialValues.fulfillment_user = initialValues.fulfillment_user.toString();
      initialValues.customer_service_user = initialValues.customer_service_user.toString();
      initialValues.fulfillment_super_user = initialValues.fulfillment_super_user.toString();

      return initialValues;
    }
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.adminUsers && !this.props.adminUsersLoading ? (
            <div>
              <div>
                <Divider orientation="left">Overall Stats </Divider>
                <DashboardRow adminUsers={this.props.adminUsers} />
              </div>
              <Table adminUsers={this.props.adminUsers} />
            </div>
          ) : (
            <Loader />
          )}

          <Drawer
            placement="right"
            title="Create Admin User"
            width={getScreenWidth(false)}
            closable={true}
            onClose={() => this.closeModal()}
            visible={this.state.createAdminUserModalOpen}
          >
            <CreateAdminUserFormView />
          </Drawer>
          <Drawer
            placement="right"
            title={"Edit Admin User"}
            width={getScreenWidth(false)}
            closable={true}
            onClose={() => this.closeModal()}
            visible={this.state.seletedAdminUser ? true : false}
          >
            <CreateAdminUserFormView
              closeChildModal={this.closeChildModal}
              childModalOpen={this.state.childModalOpen}
              initialValues={this.getInitialValues(this.state.seletedAdminUser)}
              edit={true}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    adminUsers: state.admin_users.admin_users,
    adminUsersLoading: state.admin_users.admin_users_loading,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { fetchAllAdminUsers }
)(AdminUsers);
