import React from "react";
import { connect } from "react-redux";
import { fetchAllPromotions } from "../../actions/actionsPromotions";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/Promotions/DashboardRow";
import Table from "../../components/Promotions/Table";
import PromotionFormView from "../../components/Promotions/PromotionFormView";

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPromotion: null,
      searchFilter: "",
      childModalOpen: false,
      createPromotionViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
  }
  setSelectedPromotion(nextProps) {
    var promotionID = nextProps.location.pathname.match(/\d+/);
    if (promotionID) {
      promotionID = promotionID[0];
      var that = this;
      nextProps.promotions.map(function(promotion) {
        if (promotion.promotion_id === promotionID) {
          that.setState({ selectedPromotion: promotion });
        }
        return promotion;
      });
    } else {
      this.setState({ selectedPromotion: null });
    }
  }

  onClose() {
    this.setState({
      selectedPromotion: null
    });
  }
  // Checks to see whether or not to open the secondery modal inside of the customer detail view
  checkIfChildModalOpen(pathname) {
    var createPromotionViewOpen = false;

    if (pathname.includes("create")) {
      createPromotionViewOpen = true;
    }

    this.setState({
      createPromotionViewOpen: createPromotionViewOpen
    });
  }
  //   Go back to the main Promotions page
  closeModal() {
    history.push("/portal/promotions");
    this.setState({
      selectedPromotion: null
    });
  }

  getTitle() {
    if (this.state.selectedPromotion) {
      return `Edit Promotion (${this.state.selectedPromotion.promotion_id})`;
    }
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the Promotions are not already populated, populate them
    if (!this.props.promotions) {
      this.props.fetchAllPromotions();
    } else {
      this.setSelectedPromotion(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.promotions) {
      this.setSelectedPromotion(nextProps);
    }
  }

  getInitialValues(promotion) {
    if (promotion) {
      var initialValues = Object.assign({}, promotion);

      initialValues.active = initialValues.active.toString();
      initialValues.display_modal = initialValues.display_modal.toString();
      initialValues.free_product_for_life = initialValues.free_product_for_life.toString();
      return initialValues;
    }
  }
  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.promotions && !this.props.promotionsLoading ? (
            <div>
              {!this.props.loggedInUser.customer_service_user && (
                <div>
                  <Divider orientation="left">Overall Stats </Divider>
                  <DashboardRow promotions={this.props.promotions} />
                </div>
              )}
              <Table promotions={this.props.promotions} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title="Create Promotions"
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.createPromotionViewOpen}
        >
          <PromotionFormView 
            initialValues={this.props.copiedValues}
            createView={true}
          />
        </Drawer>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedPromotion ? true : false}
        >
          <PromotionFormView
            initialValues={this.getInitialValues(this.state.selectedPromotion)}
            edit={true}
          />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    promotions: state.promotions.promotions,
    promotions_loading: state.promotions.promotions_loading,
    loggedInUser: state.auth.logged_in_user,
    copiedValues: state.formStatus.copied_values,
  };
}

export default connect(
  mapStateToProps,
  { fetchAllPromotions }
)(Promotions);
