import * as types from "../actions/types";

const INITIAL_STATE = {
  logged_in_user: null,
  logged_in_user_loading: false,
  logged_in_user_failure_message: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.TOGGLE_LOGGED_IN_USER_LOADING:
      return {
        ...state,
        logged_in_user_loading: !state.logged_in_user_loading,
        logged_in_user_failure_message: null
      };
    case types.FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        logged_in_user: action.payload,
        logged_in_user_loading: false,
        logged_in_user_failure_message: null
      };
    case types.FETCH_LOGGED_IN_USER_FAILURE_MESSAGE:
      // console.log(action.payload);
      return {
        ...state,
        logged_in_user_failure_message: action.payload,
        logged_in_user_loading: false,
        logged_in_user: null
      };

    default:
      return state;
  }
}
