import React from "react";
import {
    Drawer,
    Typography,
    Skeleton,
    Button, Empty,
    Divider,
    Tag,
    Card,
    Col,
    Row,
    List
} from "antd";
import {
    resetUserOrderDetails,
    fetchUserOrderDetails
} from "../../actions/actionsOrders";
import {
    renderSubscriptionInformation,
    convertBooleanToTag,
    renderVDLShipmentStatus,
    convertShippingMethodToTag,
    getScreenWidth
} from "../../helpers";
import DescriptionItem from "../Common/DescriptionItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment"
import DiscountView from "./DiscountView";
import EditView from "./EditView";
import {
    resetCustomerDetails,
    fetchCustomerAddressesAndPaymentMethods
} from "../../actions/actionsCustomers";
var dateFormat = require("dateformat");
const { Text } = Typography;

class DetailView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referenceSubscription: {status: ""}
        };
        this.renderReusableCartInformation = this.renderReusableCartInformation.bind(
            this
        );
    }

    componentWillMount() {
        this.props.resetUserOrderDetails();
        if (this.props.subscriptionActive) {
            this.props.fetchUserOrderDetails(this.props.subscriptionID);
        
        }
    }
      setSubscription(orderDetail) {
        var subscription = {"status": ""}
        orderDetail.cart.cart_item_set.map(function(cartItem) {
            if (cartItem.cart_item_subscription) {
                subscription = cartItem.cart_item_subscription
            }
            return cartItem
        })
        subscription.cx_sub_save_remedy = orderDetail.cx_sub_save_remedy
        subscription.cx_sub_save_cancel_reason = orderDetail.cx_sub_save_cancel_reason
        subscription.cx_sub_date_saved = orderDetail.cx_sub_date_saved
        this.setState({referenceSubscription: subscription})
        
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.subscriptionActive) {
            if (this.props.subscriptionID !== nextProps.subscriptionID) {
                setTimeout(this.props.resetUserOrderDetails(), 500);
                this.props.fetchUserOrderDetails(nextProps.subscriptionID);
            }
        }
        if (nextProps.orderDetail) {
            
            this.props.resetCustomerDetails();
            // Fetch the customer details
            this.props.fetchCustomerAddressesAndPaymentMethods(
                nextProps.orderDetail.user.random_user_id
            );
            this.setSubscription(nextProps.orderDetail)
        }
    }

    componentWillUnmount() {
        this.props.resetCustomerDetails();
    }

    renderChildModalView(pathname) {
        if (pathname.includes("edit")) {
            var initialValues = {
                status: this.state.referenceSubscription.status,
                frequency: this.state.referenceSubscription.charge_every,
                next_shipment_date: this.state.referenceSubscription.next_charge,
                cancellation_reason: this.state.referenceSubscription.cancellation_reason,
                hold_reason: this.state.referenceSubscription.hold_reason,
                cx_sub_save_remedy: this.state.referenceSubscription.cx_sub_save_remedy,
                cx_sub_save_cancel_reason: this.state.referenceSubscription.cx_sub_save_cancel_reason,
                cx_sub_date_saved: this.state.referenceSubscription.cx_sub_date_saved,
            }
            if (!this.props.orderDetail.shipping_address.soft_deleted) {
                initialValues.shipping_address =  this.props.orderDetail.shipping_address.address_id
            }
            if (!this.props.orderDetail.payment_method.soft_deleted) {
                initialValues.payment_method =  this.props.orderDetail.payment_method.payment_method_id
            }
            
            // Get the intital values for the products and place them into the form
            var count = 1;
            this.props.orderDetail.cart.cart_item_set.map(function (cartItem) {
                initialValues[`quantity_${count}`] = cartItem.cart_total_item_quantity;
                initialValues[`product_${count}`] =
                    cartItem.cart_item_product_information.id;
                initialValues[`promo_item_${count}`] =
                    cartItem.promotional_item.toString();
                initialValues[`promotional_item_for_life_${count}`] =
                    cartItem.promotional_item_for_life.toString();
                initialValues[`skipped_item_${count}`] =
                    cartItem.skipped_item.toString();
                initialValues[`one_time_purchase_${count}`] =
                    cartItem.one_time_purchase.toString();
                count += 1;
                return cartItem;
            });
            count = 1;
            this.props.orderDetail.cart.delayed_cart_item_set.map(function (cartItem) {
                initialValues[`delayed_quantity_${count}`] = cartItem.cart_total_item_quantity;
                initialValues[`delayed_product_${count}`] =
                    cartItem.cart_item_product_information.id;
                initialValues[`delayed_promo_item_${count}`] =
                    cartItem.promotional_item.toString();
                initialValues[`delayed_promotional_item_for_life_${count}`] =
                    cartItem.promotional_item_for_life.toString();
                initialValues[`delayed_one_time_purchase_${count}`] =
                    cartItem.one_time_purchase.toString();
                count += 1;
                return cartItem;
            });
            
            
            return (
                <EditView
                    initialValues={initialValues}
                    orderDetail={this.props.orderDetail}
                    renderReusableCartInformation={this.renderReusableCartInformation}
                />
            )
        } else if (pathname.includes("discount")) {
            return (
                <DiscountView
                    orderDetail={this.props.orderDetail}
                    renderReusableCartInformation={this.renderReusableCartInformation}
                />
            )
        }
    }

    getTitle(pathname) {
        if (pathname.includes("discount")) {
            return "Discount Subscription";
        } else if (pathname.includes("edit")) {
            return "Edit Subscription";
        } 
    }

    renderSubscriptionListItem(item) {
        return (
            <List.Item>
                <Card
                      style={{}}
                      cover={
                        <div style={{
                          background: item.cart_item_product_information.category_color,
                          minHeight: "150px"
                        }}>
                            <img
                              alt="example"
                              src={
                                item.cart_item_product_information
                                  .cart_image.url
                              }
                            />
                        </div>
                      }
                      title={
                        item.cart_item_product_information.marketing_name
                      }
                >
                     <DescriptionItem
                          title="Sku"
                          content={item.cart_item_product_information.dear_sku_display}
                        />
                    <DescriptionItem
                        title="Total"
                        content={"$" + item.cart_item_total.toString()}
                    />
                    <DescriptionItem
                        title="Quantity"
                        content={item.cart_total_item_quantity.toString()}
                    />
                    <DescriptionItem
                        title="Skipped Item"
                        content={convertBooleanToTag(item.skipped_item)}
                    />
                    <DescriptionItem
                        title="One Time Promotional Item"
                        content={convertBooleanToTag(item.promotional_item)}
                    />
                    <DescriptionItem
                        title="One Time Purchase"
                        content={convertBooleanToTag(item.one_time_purchase)}
                    />
                    <DescriptionItem
                        title="Promotional Item For Life"
                        content={convertBooleanToTag(item.promotional_item_for_life)}
                    />
                    
                </Card>
            </List.Item>
        )
    }
    // Pass this to refund view to be displayed withint he form
    renderReusableCartInformation() {
        return (
            <Row>
                <Text className="section-header">Upcoming Order Details</Text>
                <Col span={12}>
                    <DescriptionItem
                        title="Sub Total"
                        content={"$" + this.props.orderDetail.cart.subtotal}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="Estimated Tax"
                        content={"$" + this.props.orderDetail.cart.tax}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="Total"
                        content={"$" + this.props.orderDetail.cart.total}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="Shipping Cost"
                        content={"$" + this.props.orderDetail.cart.shipping_cost}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="Discount Value"
                        content={"$" + this.props.orderDetail.cart.total_discount_amount}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="Discount Name"
                        content={this.props.orderDetail.cart.discount}
                    />
                </Col>
                <Col span={12}>
                    <DescriptionItem
                        title="If Referral Credit Used"
                        content={convertBooleanToTag(
                            this.props.orderDetail.cart.check_if_used_referral_credit
                        )}
                    />
                </Col>
                {this.props.orderDetail.cart.refund_amount ? (
                    <Col span={24}>
                        <DescriptionItem
                            title="Amount Refunded"
                            content={"$" + this.props.orderDetail.cart.refund_amount}
                        />
                    </Col>
                ) : null}

                {this.props.orderDetail.cart.refund_reason && (
                    <Col span={24}>
                        <DescriptionItem
                            title="Refund Reason"
                            content={this.props.orderDetail.cart.refund_reason}
                        />
                    </Col>
                )}
            </Row>
        );
    }
    getSubscription() {
        var subscription = {"status": ""}
        this.props.orderDetail.cart.cart_item_set.map(function(cartItem) {
            if (cartItem.cart_item_subscription) {
                subscription = cartItem.cart_item_subscription
            }
            return cartItem
        })
        return subscription
        
    }
    render() {
        return (
            <Drawer
                placement="right"
                width={getScreenWidth(false)}
                closable={true}
                onClose={() => this.props.closeModal()}
                visible={this.props.subscriptionActive}
            >
                {this.props.orderDetail ? (
                    <div className="modal-wrapper">
                        <Drawer
                            title={this.getTitle(this.props.location.pathname)}
                            width={getScreenWidth(true)}
                            closable={true}
                            onClose={() => this.props.closeChildModal()}
                            visible={this.props.childModalOpen}
                        >
                            {this.renderChildModalView(this.props.location.pathname)}
                        </Drawer>
                        <Text strong className="main-header">
                            Subscription ({this.props.orderDetail.transaction_id})
                        </Text>
                        <Text className="section-header">Subscription Details</Text>
                        <Row>
                        
                            <Col span={12}>
                                <DescriptionItem
                                    title="Status"
                                    content={renderSubscriptionInformation(
                                        this.state.referenceSubscription.status
                                    )}
                                />
                            </Col>
                        
                            <Col span={12}>
                                <DescriptionItem
                                    title=" Frequency"
                                    content={
                                        <Tag>{"Every " + this.state.referenceSubscription.charge_every + " Month(s)"}</Tag> 
                                    }
                                />
                            </Col>
                            {this.state.referenceSubscription.status === "cancelled" && (
                                <Col span={24}>
                                    <DescriptionItem
                                        title="Cancelation Reason"
                                        content={
                                            this.state.referenceSubscription.cancellation_reason
                                        }
                                    />
                                </Col>
                            )}
                            {this.state.referenceSubscription.status === "cancelled" &&  this.props.orderDetail.date_subscription_cancelled && (
                                <Col span={24}>
                                    <DescriptionItem
                                        title="Date & Time Cancelled"
                                        content={dateFormat(
                                            this.props.orderDetail.date_subscription_cancelled,
                                            " mmmm dS yyyy h:MM:ss TT"
                                        )}
                                    />
                                </Col>
                            )}
                            {this.state.referenceSubscription.status === "onHold" && (
                                <Col span={24}>
                                    <DescriptionItem
                                        title="Hold Reason"
                                        content={
                                            this.state.referenceSubscription.hold_reason
                                        }
                                    />
                                </Col>
                            )}
                            {
                            this.state.referenceSubscription.next_shipment_on && (
                            <Col span={12}>
                                <DescriptionItem
                                    title="Next Charge"
                                    content={
                                        moment(this.state.referenceSubscription.next_shipment_on).format("MM/DD/YYYY")
                                    }
                                />
                            </Col>
                            )
                            }
                            <Col span={12}>
                                <DescriptionItem
                                    title="CX Cancellation"
                                    content={convertBooleanToTag(
                                        this.props.orderDetail.customer_service_subscription_cancellation
                                    )}
                                />
                            </Col>
  

                            {
                                this.state.referenceSubscription.last_charge && (
                                    <Col span={12}>
                                        <DescriptionItem
                                            title="Last Charge"
                                            content={
                                                moment(this.state.referenceSubscription.last_charge).format("MM/DD/YYYY")
                                            }
                                        />
                                    </Col>
                                )
                            }


                            <Col span={12}>
                                <DescriptionItem
                                    title="Indiegogo Order"
                                    content={convertBooleanToTag(
                                        this.props.orderDetail.indiegogo_order
                                    )}
                                />
                            </Col>

                            {this.props.orderDetail.replacement_reason && (
                                <Col span={24}>
                                    <DescriptionItem
                                        title="Replacement Reason"
                                        content={this.props.orderDetail.replacement_reason}
                                    />
                                </Col>
                            )}
                        </Row>
                        <Divider />
                        {this.renderReusableCartInformation()}

                        {this.props.orderDetail.stripe_info && (
                            <Row>
                                <Divider />
                                <Text className="section-header">Stripe Details</Text>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Risk Level"
                                        content={this.props.orderDetail.stripe_info.risk_level}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Risk Score"
                                        content={this.props.orderDetail.stripe_info.risk_score}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Stripe Charged"
                                        content={
                                            "$" + this.props.orderDetail.stripe_info.amount / 100
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Stripe Refunded"
                                        content={
                                            "$" +
                                            this.props.orderDetail.stripe_info.amount_refunded / 100
                                        }
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title="Stripe ID"
                                        content={
                                            <a
                                                href={
                                                    "https://dashboard.stripe.com/payments/" +
                                                    this.props.orderDetail.stripe_id
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {this.props.orderDetail.stripe_id}
                                            </a>
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        <Divider />
                        <Text className="section-header">Customer Details</Text>
                        <Row>
                            <Col span={12}>
                                <DescriptionItem
                                    title="ID"
                                    content={
                                        <Link
                                            to={`/portal/customers/${
                                                this.props.orderDetail.user.random_user_id
                                                }`}
                                        >
                                            {this.props.orderDetail.user.random_user_id}
                                        </Link>
                                    }
                                />
                            </Col>
                            <Col span={12}>
                                <DescriptionItem
                                    title="Email"
                                    content={this.props.orderDetail.user.email}
                                />
                            </Col>
                            {
                                this.props.orderDetail.user.date_joined.length > 1 && (
                                    <Col span={12}>
                                    
                                        <DescriptionItem
                                            title="Date Signed Up"
                                            content={dateFormat(
                                                this.props.orderDetail.user.date_joined,
                                                " mmmm dS yyyy"
                                            )}
                                        />
                                    </Col>
                                )
                            }
                            <Col span={12}>
                                <DescriptionItem
                                    title="Stripe ID"
                                    content={
                                        <a
                                            href={
                                                "https://dashboard.stripe.com/customers/" +
                                                this.props.orderDetail.user.stripe_id
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {this.props.orderDetail.user.stripe_id}
                                        </a>
                                    }
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Text className="section-header">Address Details</Text>
                        {
                            this.props.orderDetail.shipping_address.soft_deleted ? (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                    
                                       "No Address On File"
                                    
                                }/>
                            ) :

                            (
                                    <Row>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title="ID"
                                                content={
                                                    <Link
                                                        to={`/portal/customers/${
                                                            this.props.orderDetail.user.random_user_id
                                                            }/edit-address/${this.props.orderDetail.shipping_address.address_id}`}
                                                    >
                                                        {this.props.orderDetail.shipping_address.address_id}
                                                    </Link>
                                                }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Full Name"}
                                                content={this.props.orderDetail.shipping_address.full_name}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Street"}
                                                content={this.props.orderDetail.shipping_address.address}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Apt. Number"}
                                                content={this.props.orderDetail.shipping_address.apt_number}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"City"}
                                                content={this.props.orderDetail.shipping_address.city}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"State"}
                                                content={this.props.orderDetail.shipping_address.state}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Zip Code"}
                                                content={this.props.orderDetail.shipping_address.zip_code}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Phone"}
                                                content={this.props.orderDetail.shipping_address.phone}
                                            />
                                        </Col>
                                    </Row>
                            )
                        }
                       
                        {this.props.orderDetail.payment_method &&
                            this.props.orderDetail.payment_method.last_four_digits && (
                                <Row>
                                    <Divider />
                                <Text className="section-header">Payment Details</Text>
                                            <Row>
                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title="ID"
                                                        content={
                                                            <Link
                                                                to={`/portal/customers/${
                                                                    this.props.orderDetail.user.random_user_id
                                                                    }/edit-payment-method/${
                                                                    this.props.orderDetail.payment_method
                                                                        .payment_method_id
                                                                    }`}
                                                            >
                                                                {
                                                                    this.props.orderDetail.payment_method
                                                                        .payment_method_id
                                                                }
                                                            </Link>
                                                        }
                                                    />
                                                </Col>

                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title="Stripe ID"
                                                        content={
                                                            <a
                                                                href={
                                                                    "https://dashboard.stripe.com/cards/" +
                                                                    this.props.orderDetail.payment_method.stripe_id
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {this.props.orderDetail.payment_method.stripe_id}
                                                            </a>
                                                        }
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title={"Exp. Date"}
                                                        content={
                                                            this.props.orderDetail.payment_method.expiration_date
                                                        }
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title={"Last Four Digits"}
                                                        content={
                                                            this.props.orderDetail.payment_method.last_four_digits
                                                        }
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title={"Card Type"}
                                                        content={this.props.orderDetail.payment_method.card_type}
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <DescriptionItem
                                                        title={"Zip Code"}
                                                        content={this.props.orderDetail.payment_method.zip_code}
                                                    />
                                                </Col>
                                            </Row>
                                </Row>
                            )}
                                 {this.props.orderDetail.pay_pal_billing_agreement_information &&  (
                                        <Row>
                                        <Divider />
                                        <Text className="section-header">Pay Pal Billing Agreement Details</Text>
                                        <Col span={12}>
                                            <DescriptionItem
                                            title={"Pay Pal Billing Agreement Status"}
                                            content={
                                                this.props.orderDetail.pay_pal_billing_agreement_information.state
                                            }
                                            />
                                        </Col>
                                        <p>If this is INACTIVE then the payment method will not go through.</p>
                            
                                        </Row>
                                    )}
                                 {this.props.orderDetail.payment_method &&
                                    this.props.orderDetail.payment_method.pay_pal_payer_email && (
                                        <Row>
                                        <Divider />
                                        <Text className="section-header">Payment Details</Text>
                                        <Col span={12}>
                                            <DescriptionItem
                                            title="ID"
                                            content={
                                                <Link
                                                to={`/portal/customers/${
                                                    this.props.orderDetail.user.random_user_id
                                                }/edit-payment-method/${
                                                    this.props.orderDetail.payment_method
                                                    .payment_method_id
                                                }`}
                                                >
                                                {
                                                    this.props.orderDetail.payment_method
                                                    .payment_method_id
                                                }
                                                </Link>
                                            }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                            title={"Pay Pal Email"}
                                            content={
                                                this.props.orderDetail.payment_method.pay_pal_payer_email
                                            }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                            title={"Billing Agreement ID"}
                                            content={
                                                this.props.orderDetail.payment_method.pay_pal_billing_agreement_id
                                            }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                            title={"Card Type"}
                                            content={"Pay Pal"}
                                            />
                                        </Col>
                            
                                        </Row>
                                    )}
                        {this.props.orderDetail.vanguard_shipment && (
                            <Row>
                                <Divider />
                                <Text className="section-header">Fulfillment Details</Text>
                                <Col span={12}>
                                    <DescriptionItem
                                        title={"Pick Pack Priority"}
                                        content={convertBooleanToTag(
                                            this.props.orderDetail.pick_pack_priority
                                        )}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DescriptionItem
                                        title={"Shipment Status"}
                                        content={renderVDLShipmentStatus(
                                            this.props.orderDetail.shipment_status,
                                            this.props.orderDetail.vanguard_shipment
                                        )}
                                    />
                                </Col>
                                <Col span={24}>
                                    <DescriptionItem
                                        title={"Hold Reason"}
                                        content={this.props.orderDetail.shipment_hold_reason}
                                    />
                                </Col>
                                {this.props.orderDetail.service && (
                                    <Col span={12}>
                                        <DescriptionItem
                                            title={"Service"}
                                            content={convertShippingMethodToTag(
                                                this.props.orderDetail.service
                                            )}
                                        />
                                    </Col>
                                )}
                                {this.props.orderDetail.shipment_status === "shipped" && (
                                    <div>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Date Shipped Out"}
                                                content={dateFormat(
                                                    this.props.orderDetail.date_pick_packed,
                                                    " mmmm dS yyyy, h:MM:ss TT"
                                                )}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Tracking Number"}
                                                content={
                                                    <a
                                                        href={this.props.orderDetail.tracking_page_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {this.props.orderDetail.tracking_number}
                                                    </a>
                                                }
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Shipping Cost"}
                                                content={"$" + this.props.orderDetail.cost_of_shipment}
                                            />
                                        </Col>

                                        <Col span={12}>
                                            <DescriptionItem
                                                title={"Carrier"}
                                                content={convertShippingMethodToTag(
                                                    this.props.orderDetail.carrier
                                                )}
                                            />
                                        </Col>

                                        {this.props.orderDetail.final_box_configuration && (
                                            <DescriptionItem
                                                title={"Weight"}
                                                content={
                                                    this.props.orderDetail.final_box_configuration
                                                        .final_box_configuration[0].totalWeight + "Ozs"
                                                }
                                            />
                                        )}
                                    </div>
                                )}
                            </Row>
                        )}

                        {this.props.orderDetail.cart.cart_item_set && (
                            <Row className="cart-items">
                                <Divider />
                                <Text className="section-header">Upcoming Products</Text>
                                <List
                                    
                                    grid={{ gutter: 4, column: 2 }}
                                    dataSource={this.props.orderDetail.cart.cart_item_set}
                                    renderItem={item => (
                                       this.renderSubscriptionListItem(item)
                                    )}
                                />
                            </Row>
                        )}
                        {this.props.orderDetail.cart.delayed_cart_item_set && (
                            <Row className="cart-items">
                                <Divider />
                                <Text className="section-header">Delayed Products</Text>
                                <List
                                    grid={{ gutter: 4, column: 2 }}
                                    dataSource={this.props.orderDetail.cart.delayed_cart_item_set}
                                    renderItem={item => (
                                        this.renderSubscriptionListItem(item)
                                    )}
                                />
                            </Row>
                        )}
                        <div
                          className="bottom-bar"
                        >
                                <Link
                                    to={`/portal/subscriptions/${
                                        this.props.orderDetail.transaction_id
                                    }/discount-subscription`}
                                >
                                    <Button
                                        type="danger"
                                        onClick={this.closeModal}
                                        style={{ marginRight: 8 }}
                                        ghost
                                    >
                                        Discount
                                    </Button>
                                </Link>
                                <Link
                                    to={`/portal/subscriptions/${
                                        this.props.orderDetail.transaction_id
                                        }/edit-subscription`}
                                >
                                    <Button
                                        ghost
                                        type="danger"
                                        className="warning-button"
                                        onClick={this.closeModal}
                                        style={{ marginRight: 8 }}
                                    >
                                        Edit
                                    </Button>
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className="modal-wrapper">
                        <Skeleton active paragraph />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                        <Skeleton active />
                    </div>
                )}
            </Drawer>
        );
    }
}

function mapStateToProps(state) {
    return {
        orderDetail: state.orders.orderDetail,
        orderDetailLoading: state.orders.orderDetailLoading,

    };
}

export default connect(
    mapStateToProps,
    { resetUserOrderDetails, fetchUserOrderDetails, resetCustomerDetails, fetchCustomerAddressesAndPaymentMethods }
)(DetailView);
