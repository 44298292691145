import {
  combineReducers
} from "redux";
import AuthReducer from "./reducerAuth";
import GlobalSearchReducer from "./reducerGlobalSearch";
import OrdersReducer from "./reducerOrders";
import SubscriptionReducer from "./reducerSubscriptions";
import FormStatusReducer from "./reducerFormStatus";
import CustomersReducer from "./reducerCustomers";
import HomePageVersionsReducer from "./reducerHomePageVersions";
import DiscountsReducer from "./reducerDiscounts";
import AdminUserReducer from "./reducerAdminUsers";
import QuesionAndReviewReducer from "./reducerQuestionsAndReviews";
import InventoryReducer from "./reducerInventory";
import PromotionsReducer from "./reducerPromotions";
import RedirectLinkReducer from "./reducerRedirectLinks";
import FulfillmentReducer from "./reducerFulfillment";

import {
  reducer as FormReducer
} from "redux-form";

const rootReducer = combineReducers({
  auth: AuthReducer,
  form: FormReducer,
  orders: OrdersReducer,
  redirect_links: RedirectLinkReducer,
  fulfillment: FulfillmentReducer,
  discounts: DiscountsReducer,
  promotions: PromotionsReducer,
  home_page_versions: HomePageVersionsReducer,
  customers: CustomersReducer,
  admin_users: AdminUserReducer,
  subscriptions: SubscriptionReducer,
  global_search: GlobalSearchReducer,
  questions_and_reviews: QuesionAndReviewReducer,
  formStatus: FormStatusReducer,
  inventory: InventoryReducer
});

export default rootReducer;