import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TopMenu from "../../components/Navigation/TopMenu";
import Confetti from "../../components/Common/Confetti";
import SideMenu from "../../components/Navigation/SideMenu";

import {fetchFullProductInventory} from "../../actions/actionsInventory"
import "react-table/react-table.css";

class PortalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.fetchFullProductInventory()
  }

  render() {
    //   If they are on the login page, dont' show the main portal
    if (this.props.location.pathname.includes("login")) {
      return this.props.children;
    } else {
      return (
        <div className="portal-container">
          <SideMenu
            loggedInUser={this.props.loggedInUser}
            location={this.props.location}
          />
          <TopMenu
            location={this.props.location}
            loggedInUser={this.props.loggedInUser}
          />
          <Confetti />
          {this.props.loggedInUser && localStorage.getItem("Token") && (
            <div className="portal-content">{this.props.children}</div>
          )}
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.logged_in_user
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {fetchFullProductInventory}
  )(PortalContainer)
);
