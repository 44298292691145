import React from "react";
import {  Button, Form, Spin, Divider } from "antd";
import { connect } from "react-redux";
import DescriptionItem from "../Common/DescriptionItem";
import {
  textAreaField,
  selectField,
} from "../Form/FieldRenders";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { editReplacementBoxReason } from "../../actions/actionsOrders";
import {
  replacementReasonTypes,
  buildOutReplacementReason,
  productGroupArray,
  getReplacementType,
  getNewProductArray,
  PruneProductArray,
  getItemSelectionDetails
} from "../../helpers";


const validate = values => {
  const errors = { };

  if (!values.replacement_type) {
    errors.replacement_type = "Required"
  }
  if (!values.additional_replacement_details) {
    errors.additional_replacement_details = "Required"
  }
  if (!values.items || values.items.length === 0) {
    errors.items = "Required"
  }
  if (!values.other_reason) {
    errors.other_reason = "Required"
  }


  return errors
};

class EditReplacementReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["transaction_id"] = this.props.orderDetail.transaction_id;

    var replacement_reason = buildOutReplacementReason(
      this.props.formValues.replacement_type,
      this.props.formValues.additional_replacement_details, 
      this.props.formValues.items, 
      this.props.formValues.other_reason,
      this.props.loggedInUser,
      false,
      this.props.formValues.lot_codes
    )
    var replacement_box_information =  buildOutReplacementReason(
      this.props.formValues.replacement_type,
      this.props.formValues.additional_replacement_details, 
      this.props.formValues.items, 
      this.props.formValues.other_reason,
      this.props.loggedInUser,
      true,
      this.props.formValues.lot_codes
    )

    props["replacement_reason"] = replacement_reason;
    props["replacement_box_information"] = replacement_box_information;
    this.props.editReplacementBoxReason(props);
  }


  componentWillReceiveProps(nextProps) {
    // Change the replacemebnt reason details if the main reason is not the same
    if (this.props.formValues) {
      if (this.props.formValues.replacement_type !== nextProps.formValues.replacement_type) {
        this.props.change("additional_replacement_details", "")
        this.props.change("items", [])
      }
    }
  }

 



  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
       
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <DescriptionItem
                title="Current Replacement Reason"
                content={this.props.orderDetail.replacement_reason}
            />
            <Divider>Replacement Details</Divider>
            <Field
              label={"Replacement Type"}
              name="replacement_type"
              size={"large"}
              overRideTouched={true}
              required={true}
              options={replacementReasonTypes}
              component={selectField}
            />
          {this.props.formValues &&
            this.props.formValues.replacement_type && 
              getReplacementType(this.props.formValues.replacement_type).subOptions && (
                  <Field
                    label={getReplacementType(this.props.formValues.replacement_type).subOptionsLabel}
                    name="additional_replacement_details"
                    size={"large"}
                    overRideTouched={true}
                    required={true}
                    options={getReplacementType(this.props.formValues.replacement_type).subOptions}
                    component={selectField}
                  />
              )}
          {this.props.formValues &&
            this.props.formValues.replacement_type && 
              (this.props.formValues.additional_replacement_details || this.props.formValues.replacement_type === "missing_products" || this.props.formValues.replacement_type === "defective_products" )&&
                getItemSelectionDetails(this.props.formValues.replacement_type, this.props.formValues.additional_replacement_details, false) && (
                  <Field
                    label={getItemSelectionDetails(this.props.formValues.replacement_type, this.props.formValues.additional_replacement_details, true)}
                    name="items"
                    size={"large"}
                    required={true}
                    overRideTouched={true}
                    optionGroups={productGroupArray}
                    mode={"multiple"}
                    options={PruneProductArray(getNewProductArray(this.props.formValues.replacement_type), this.props.orderDetail)}
                    component={selectField}
                  />
              )}
          {this.props.formValues &&
            (this.props.formValues.replacement_type === "other" || 
              this.props.formValues.additional_replacement_details === "other") && (
                  <Field
                    label={"Other Reason"}
                    name="other_reason"
                    required={true}
                    hideErrorLabel={false}
                    overRideTouched={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                />
              )}
          {this.props.formValues &&
            (this.props.formValues.replacement_type === "products_damaged" &&
              this.props.formValues.additional_replacement_details.includes("leakage")) && (
                  <Field
                    label={"Lot Codes"}
                    name="lot_codes"
                    required={false}
                    hideErrorLabel={false}
                    overRideTouched={true}
                    size="large"
                    rows={4}
                    placeholder={"Example: APC - 23423, LDS - 34343"}
                    component={textAreaField}
                />
              )}
              <br />
              {
                this.props.formValues && this.props.formValues.replacement_type && (
                  <p><b>New Replacement Reason: </b> {buildOutReplacementReason(
                    this.props.formValues.replacement_type,
                    this.props.formValues.additional_replacement_details, 
                    this.props.formValues.items, 
                    this.props.formValues.other_reason,
                    this.props.loggedInUser,
                    false
                  )}</p>
                )
              }
              <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Edit Replacement Reason
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

EditReplacementReason = reduxForm({
  form: "EditReplacementReason",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditReplacementReason);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    loggedInUser: state.auth.logged_in_user,
    formValues: getFormValues("EditReplacementReason")(state)
  };
}

export default connect(
  mapStateToProps,
  { editReplacementBoxReason, change }
)(EditReplacementReason);
