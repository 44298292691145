import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./routes";

import "./assets/css/normalize.css";
import "./assets/css/styles.css";
// import "./assets/css/invert.css";

class IndexHot extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    );
  }
}
export default IndexHot;
