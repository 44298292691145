import React from "react";
import { Divider, Button, Form, Col } from "antd";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import {
    plainTextField,
} from "../Form/FieldRenders";
import {
    searchAllAddresses
} from "../../actions/actionsCustomers";

function serialize(obj) {
    var str = [];
    for(var p in obj)
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
}
class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(props) {
        
        let params = serialize(props)
        
        if (params) {

            params = "?" + params
        }


        this.props.searchAllAddresses(params)
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="chart-row">
                <Divider>Address Search </Divider>
                <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                    <div style={{alignItems: "end", display: "flex", justifyContent: "center"}}>
                        <Col span={4}>
                            <Field
                                label={"Full Name"}
                                name="full_name"
                                required={false}
                                size={"large"}
                                component={plainTextField}
                            />
                        
                        </Col>
                        <Col span={4}>
                            <Field
                                label={"Street Address"}
                                name="address"
                                required={false}
                                size={"large"}
                                component={plainTextField }
                            />
                        </Col>
                        <Col span={4}>
                            <Field
                                label={"City"}
                                name="city"
                                required={false}
                                size={"large"}
                                component={plainTextField }
                            />
                        </Col>
                        <Col span={4}>
                            <Field
                                label={"Zip Code"}
                                name="zip_code"
                                required={false}
                                size={"large"}
                                component={plainTextField }
                            />
                        </Col>
                        
                        <Col span={4}>
                            <br />
                            <br />
                            <Button
                                loading={this.props.formLoading || this.props.customersLoading}
                                htmlType="submit"
                                block
                                size="large"
                                type="primary"
                                icon="search"
                            >
                                Search
                            </Button>
                        </Col>
                    </div>
                </Form>
            </div>
        );
    }
}


SearchView = reduxForm({
    form: "AddressesSearchViewForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: false
})(SearchView);

function mapStateToProps(state) {
    return {
        customersLoading: state.customers.customers_loading,
        formValues: getFormValues("AddressesSearchViewForm")(state)
    };
}

export default connect(
    mapStateToProps,
    { searchAllAddresses }
)(SearchView);
