import React from "react";
import { connect } from "react-redux";
import { fetchAllHomePageVersions } from "../../actions/actionsHomePageVersions";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer, Alert } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/HomePageVersions/DashboardRow";
import Table from "../../components/HomePageVersions/Table";
import HomePageVersionFormView from "../../components/HomePageVersions/HomePageVersionFormView";

class HomePageVersions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedHomePageVersion: null,
      searchFilter: "",
      childModalOpen: false,
      createHomePageVersionViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }
  setSelectedHomePageVersion(nextProps) {
    var homePageVersionID = nextProps.location.pathname.match(/\d+/);
    if (homePageVersionID) {
      homePageVersionID = homePageVersionID[0];
      var that = this;
      nextProps.homePageVersions.map(function(homePageVersion) {
        if (homePageVersion.version_id === homePageVersionID) {
          that.setState({ selectedHomePageVersion: homePageVersion });
        }
        return homePageVersion;
      });
    } else {
      this.setState({ selectedHomePageVersion: null });
    }
  }

  onClose() {
    this.setState({
      selectedHomePageVersion: null
    });
  }
  closeChildModal() {
    history.push(
      `/portal/home-page-versions/${
        this.state.selectedHomePageVersion.version_id
      }`
    );
  }
  //   Go back to the main HomePageVersions page
  closeModal() {
    history.push("/portal/home-page-versions");
    this.setState({
      selectedHomePageVersion: null
    });
  }

  getTitle() {
    if (this.state.selectedHomePageVersion) {
      return `Edit Landing Page (${
        this.state.selectedHomePageVersion.version_id
      })`;
    }
  }

  checkIfChildModalOpen(pathname) {
    var createHomePageVersionViewOpen = false;
    var childModalOpen = false;

    if (pathname.includes("live")) {
      childModalOpen = true;
    }

    if (pathname.includes("create")) {
      createHomePageVersionViewOpen = true;
    }

    this.setState({
      createHomePageVersionViewOpen: createHomePageVersionViewOpen,
      childModalOpen: childModalOpen
    });
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the HomePageVersions are not already populated, populate them
    if (!this.props.homePageVersions) {
      this.props.fetchAllHomePageVersions();
    } else {
      this.setSelectedHomePageVersion(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.homePageVersions) {
      this.setSelectedHomePageVersion(nextProps);
    }
  }

  getInitialValues(homePageVersion) {
    if (homePageVersion) {
      var initialValues = Object.assign({}, homePageVersion);
      initialValues.display_nano_bar = homePageVersion.display_nano_bar.toString();
      initialValues.default = homePageVersion.default.toString();
      initialValues.active = homePageVersion.active.toString();
      return initialValues;
    }
  }



  renderWarningBar() {
    if (this.props.homePageVersions) {
      var defaultConflicts = this.props.homePageVersions;
      defaultConflicts = this.props.homePageVersions.filter(function(
        homePageVersion
      ) {
        return homePageVersion.default;
      });

      if (defaultConflicts.length > 1) {
        return (
          <Alert
            type="warning"
            message={`You have ${
              defaultConflicts.length
            } home page versions set as default. Please only make one a default.`}
            showIcon
          />
        );
      }
    } else {
      return "";
    }
  }

  render() {
    return (
      <div className="page-container">
        {this.renderWarningBar()}
        <div className="table-view">
          {this.props.homePageVersions &&
          !this.props.homePageVersionsLoading ? (
            <div>
              {!this.props.loggedInUser.customer_service_user && (
                <div>
                  <Divider orientation="left">Overall Stats </Divider>
                  <DashboardRow
                    homePageVersions={this.props.homePageVersions}
                  />
                </div>
              )}
              <br />
              <Table homePageVersions={this.props.homePageVersions} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        

        <Drawer
          placement="right"
          title="Create Landing Page"
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.createHomePageVersionViewOpen}
        >
          <HomePageVersionFormView 
              initialValues={this.props.copiedValues}
              createView={true}
          />
        </Drawer>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedHomePageVersion ? true : false}
        >
          <HomePageVersionFormView
            closeChildModal={this.closeChildModal}
            childModalOpen={this.state.childModalOpen}
            initialValues={this.getInitialValues(
              this.state.selectedHomePageVersion
            )}
            edit={true}
          /> 
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homePageVersions: state.home_page_versions.home_page_versions,
    homePageVersionsLoading:
      state.home_page_versions.home_page_versions_loading,
    loggedInUser: state.auth.logged_in_user,
    copiedValues: state.formStatus.copied_values,
    
    
  };
}

export default connect(
  mapStateToProps,
  { fetchAllHomePageVersions }
)(HomePageVersions);
