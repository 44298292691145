import React from "react";
import { Alert, Button, Form, Spin, Divider, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  
  plainNumberField
} from "../Form/FieldRenders";
import { Field, reduxForm, change } from "redux-form";
import { editOrder } from "../../actions/actionsOrders";
import DeleteItem from "../../components/Common/DeleteItem";
import {
  productArray,
  productGroupArray,
  trueFalseArray,
} from "../../helpers";

var productCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

function renderValidate(values, errors) {
 

  productCount.map(function(count) {
    if (values[`product_${count}`]) {
      if (!values[`quantity_${count}`]) {
        errors[`quantity_${count}`] = "Required";
      }
      if (!values[`promo_item_${count}`]) {
        errors[`promo_item_${count}`] = "Required";
      }
      if (!values[`promotional_item_for_life_${count}`]) {
        errors[`promotional_item_for_life_${count}`] = "Required";
      }
      if (!values[`one_time_purchase_${count}`]) {
        errors[`one_time_purchase_${count}`] = "Required";
      }
    }
    return count;
  });

  var valueArray = [];
  productCount.map(function(count) {
    valueArray.push(values[`product_${count}`]);
    return count;
  });
  valueArray = valueArray.filter(function(value) {
    return value;
  });
  let hasDuplicates = new Set(valueArray).size !== valueArray.length;
  if (hasDuplicates) {
    productCount.map(function(count) {
      errors[`product_${count}`] = "Items in order need to be unique";
      return count;
    });
  }

  return errors;
}
const validate = values => {
  const errors = {};

   // The default products must be always their
   if (!values.product_1) {
    errors.product_1 = "Required";
  }
  if (!values.quantity_1) {
    errors.quantity_1 = "Required";
  }
  if (!values.one_time_purchase_1) {
    errors.one_time_purchase_1 = "Required";
  }
  if (!values.promo_item_1) {
    errors.promo_item_1 = "Required";
  }
  if (!values.promotional_item_for_life_1) {
    errors.promotional_item_for_life_1 = "Required";
  }
  // Return extra validation for products and delayed products
  if (!values.edit_awaiting_order_reason) {
    errors.edit_awaiting_order_reason = "Required";
  }
  
  return renderValidate(values, errors);
  
};

class EditAwaitingOrderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderProductPickers = this.renderProductPickers.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }
  onFormSubmit(props) {
    props["address_type"] = "shipping_address";
    props["transaction_id"] = this.props.orderDetail.transaction_id;
    props.cartTotal = this.props.orderDetail.cart.total
    this.props.editOrder(props);
  }

  removeItem(productID, quantityID, oneTimePurchase, promoItem) {
    this.props.change(productID, null);
    this.props.change(quantityID, null);
    this.props.change(oneTimePurchase, null);
    this.props.change(promoItem, null);
  }
  renderProductPickers() {
    var count = 0;
    var productNumbers = [];
    while (count <= 17) {
      count += 1;
      productNumbers.push(count.toString());
    }
    var that = this;
    return productNumbers.map(function(productNumber) {
      var required = false;
      if (productNumber === "1") {
        required = true;
      }
      return (
        <Row gutter={6} key={productNumber}>
          <Col span={11}>
            <Field
              label={`Product ${productNumber}`}
              name={`product_${productNumber}`}
              allowClear={true}
              required={required}
              optionGroups={productGroupArray}
              overRideTouched={!required}
              size={"large"}
              showSearch={false}
              options={productArray}
              component={selectField}
            />
          </Col>
          <Col span={2}>
            <Field
              label={`Qty ${productNumber}`}
              name={`quantity_${productNumber}`}
              min={1}
              max={20}
              overRideTouched={!required}
              step={1}
              required={required}
              type={"number"}
              size="large"
              component={plainNumberField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`One Off Item`}
              name={`one_time_purchase_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={
                "The product will not be added to their next subscription"
              }
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`Promo Item`}
              name={`promo_item_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={"The first product will be free"}
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={3}>
            <Field
              label={`Promo Item For Life`}
              name={`promotional_item_for_life_${productNumber}`}
              allowClear={true}
              size={"large"}
              suffix={"The first product will be free forever"}
              showSearch={true}
              overRideTouched={!required}
              required={required}
              options={trueFalseArray}
              component={selectField}
            />
          </Col>
          <Col span={2}>
          <DeleteItem
              removeItem={() =>
                that.removeItem(
                  `product_${productNumber}`,
                  `quantity_${productNumber}`,
                  `one_time_purchase_${productNumber}`,
                  `promo_item_${productNumber}`,
                  `promotional_item_for_life_${productNumber}`
                )
              }
            />
          </Col>
        </Row>
      );
    });
  }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Danger Zone"
          description="You are about to remove, add, or change items in a customers order. Please make sure to refund the customer if you are removing items. We cannot charge the customer more money if they want more products added so try suggesting that they add this item to their next subscription box or that they create a one off order inside of their profile."
          type="error"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Divider>Product Details</Divider>
            {this.renderProductPickers()}
            <Divider>Order Details</Divider>
           
            <Field
              label={"Edit Reason"}
              name="edit_awaiting_order_reason"
              hideErrorLabel={false}
              required={true}
              size="large"
              rows={8}
              component={textAreaField}
            />

            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Edit Items In Box
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

EditAwaitingOrderView = reduxForm({
  form: "EditAwaitingOrderViewForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(EditAwaitingOrderView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editOrder, change }
)(EditAwaitingOrderView);
