import React from "react";
import { Alert, Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import { plainTextField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { editUserProfileAdmin } from "../../actions/actionsCustomers";

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  }
  if (!values.confirm_email) {
    errors.confirm_email = "Required";
  } else {
    if (values.email !== values.confirm_email) {
      errors.confirm_email = "The emails need to match";
    }
  }

  if (values.email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(values.email)) {
      console.log("");
    } else {
      errors.email = "Please enter in a proper email";
    }
  }
  return errors;
};

class ChangeUserEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props.random_user_id = this.props.customerDetail.details.random_user_id;
    props.type = "email_change";
    this.props.editUserProfileAdmin(props, "put");
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Head's Up"
          description="You are about to reset a customer's email"
          type="info"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Field
              label={"Email"}
              name="email"
              required={true}
              size="large"
              type="email"
              component={plainTextField}
            />
            <Field
              label={"Confirm Email"}
              name="confirm_email"
              required={true}
              size="large"
              type="email"
              component={plainTextField}
            />
            <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Change Email
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

ChangeUserEmail = reduxForm({
  form: "ChangeUserEmailForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ChangeUserEmail);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editUserProfileAdmin }
)(ChangeUserEmail);
