import React from "react";
import { connect } from "react-redux";

import Table from "../../components/Orders/Table";
import SearchView from "../../components/Orders/SearchView";
import CreateSampleBoxView from "../../components/Orders/CreateSampleBoxView";
import DetailView from "../../components/Orders/DetailView";
import history from "../../history";
import Copyright from "../../components/Common/Copyright";


const PRODUCTS = {
  refills: {
      dish_soap: {
          sweet_honeysuckle: "dish_soap_refill_sweet_honeysuckle_32",
          blue_sage: "dish_soap_refill_blue_sage_32",
          grapefruit_basil: "dish_soap_refill_grapefruit_basil_32",
          lemongrass: "dish_soap_refill_lemongrass_32",
      },
      all_purpose_cleaner: {
          sweet_honeysuckle: "all_purpose_cleaner_refill_sweet_honeysuckle_16",
          blue_sage: "all_purpose_cleaner_refill_blue_sage_16",
          orange_zest: "all_purpose_cleaner_refill",
          bamboo_lily: "all_purpose_cleaner_refill_bamboo_flower_16"
      },
      liquid_hand_soap: {
          sweet_honeysuckle: "liquid_hand_soap_refill_sweet_honeysuckle_32",
          blue_sage: "liquid_hand_soap_refill_blue_sage_32",
          lavendar: "liquid_hand_soap_refill_lavendar_32",
          grapefruit_basil: "liquid_hand_soap_refill_grapefruit_basil_32"
      },
      dishwasher_tablets: {
          lemongrass: "dishwasher_detergent_refill_box",

      },
      liquid_laundry_detergent: {
          sweet_honeysuckle: "liquid_laundry_detergent_refill_sweet_honeysuckle_32",
          juniper_sandalwood: "liquid_laundry_detergent_refill_juniper_sandalwood_32",
          unscented: "liquid_laundry_detergent_refill_unscented_32",
          sea_spray_aloe: "liquid_laundry_detergent_refill_sea_spray_and_aloe_32"
      }
  },
  glass: {
      all_purpose_cleaner: {
          mosaic: "all_purpose_cleaner_unfilled_glass_clay",
          linen: "all_purpose_cleaner_unfilled_glass_linen",
          periwinkle: "all_purpose_cleaner_unfilled_glass_periwinkle",
          black: "all_purpose_cleaner_unfilled_glass_black"
      },
      dish_soap: {
          mosaic: "dish_soap_unfilled_glass_charcoal",
          linen: "dish_soap_unfilled_glass_linen",
          periwinkle: "dish_soap_unfilled_glass_periwinkle",
          black: "dish_soap_unfilled_glass_black"
      },
      liquid_hand_soap: {
          mosaic: "liquid_hand_soap_unfilled_glass_midnight_blue",
          linen: "liquid_hand_soap_unfilled_glass_linen",
          periwinkle: "liquid_hand_soap_unfilled_glass_periwinkle",
          black: "liquid_hand_soap_unfilled_glass_black"
      },
      tablet_jar: {
          mosaic: "tablet_jar_unfilled_glass_lemon",
          green: "tablet_jar_unfilled_glass_lime",
          linen: "tablet_jar_unfilled_glass_linen",
          periwinkle: "tablet_jar_unfilled_glass_periwinkle",
          black: "tablet_jar_unfilled_glass_black"
      },
      liquid_laundry_detergent: {
          mosaic: "liquid_laundry_detergent_unfilled_glass_lime",
          linen: "liquid_laundry_detergent_unfilled_glass_linen",
          periwinkle: "liquid_laundry_detergent_unfilled_glass_periwinkle",
          black: "liquid_laundry_detergent_unfilled_glass_black"
      }
  },
  essentials: {
      dryer_balls: "dryer_balls",
      funnel: {
          linen: "silicon_funnel_linen",
          periwinkle: "silicon_funnel_periwinkle",
          black: "silicon_funnel_black"
      },
      bar_soap: {
          sweet_honeysuckle: "bar_soap_sweet_honeysuckle_5",
          juniper_sandalwood: "bar_soap_juniper_sandalwood_5",
          sea_spray_aloe: "bar_soap_sea_spray_and_aloe_5",
          lemongrass: "bar_soap",
          bamboo_lily: "bar_soap_bamboo_flower_5",
          grapefruit_basil: "bar_soap_grapefruit_basil_5",
          blue_sage: "bar_soap_blue_sage_5",

      }
  }
}

const bundlePreSets = {
  complete_home_periwinkle_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.periwinkle,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.periwinkle,
    PRODUCTS.glass.liquid_laundry_detergent.periwinkle,
    PRODUCTS.glass.tablet_jar.periwinkle,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.lemongrass,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
  ],
  complete_home_linen_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.linen,
    PRODUCTS.glass.liquid_hand_soap.linen,
    PRODUCTS.glass.dish_soap.linen,
    PRODUCTS.glass.liquid_laundry_detergent.linen,
    PRODUCTS.glass.tablet_jar.linen,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.lemongrass,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
  ],
  complete_home_black_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.black,
    PRODUCTS.glass.liquid_hand_soap.black,
    PRODUCTS.glass.dish_soap.black,
    PRODUCTS.glass.liquid_laundry_detergent.black,
    PRODUCTS.glass.tablet_jar.black,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.lemongrass,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
  ],
  complete_home_mosaic_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.mosaic,
    PRODUCTS.glass.liquid_hand_soap.mosaic,
    PRODUCTS.glass.dish_soap.mosaic,
    PRODUCTS.glass.liquid_laundry_detergent.mosaic,
    PRODUCTS.glass.tablet_jar.mosaic,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.lemongrass,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
  ],
  // ---------
  complete_home_periwinkle_floral_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.periwinkle,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.periwinkle,
    PRODUCTS.glass.liquid_laundry_detergent.periwinkle,
    PRODUCTS.glass.tablet_jar.periwinkle,
    
    PRODUCTS.essentials.dryer_balls,

    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.sweet_honeysuckle,
    PRODUCTS.refills.liquid_laundry_detergent.sea_spray_aloe,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,

  ],
  complete_home_linen_floral_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.linen,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.linen,
    PRODUCTS.glass.liquid_laundry_detergent.linen,
    PRODUCTS.glass.tablet_jar.linen,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.sweet_honeysuckle,
    PRODUCTS.refills.liquid_laundry_detergent.sea_spray_aloe,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
  ],
  complete_home_black_floral_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.black,
    PRODUCTS.glass.liquid_hand_soap.black,
    PRODUCTS.glass.dish_soap.black,
    PRODUCTS.glass.liquid_laundry_detergent.black,
    PRODUCTS.glass.tablet_jar.black,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.sweet_honeysuckle,
    PRODUCTS.refills.liquid_laundry_detergent.sea_spray_aloe,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
    
  ],
  complete_home_mosaic_floral_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.mosaic,
    PRODUCTS.glass.liquid_hand_soap.mosaic,
    PRODUCTS.glass.dish_soap.mosaic,
    PRODUCTS.glass.liquid_laundry_detergent.mosaic,
    PRODUCTS.glass.tablet_jar.mosaic,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.lavendar,
    PRODUCTS.refills.dish_soap.sweet_honeysuckle,
    PRODUCTS.refills.liquid_laundry_detergent.sea_spray_aloe,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sea_spray_aloe,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
  ],
  // ---------
  complete_home_periwinkle_fruity_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.periwinkle,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.periwinkle,
    PRODUCTS.glass.liquid_laundry_detergent.periwinkle,
    PRODUCTS.glass.tablet_jar.periwinkle,
    
    PRODUCTS.essentials.dryer_balls,

    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.grapefruit_basil,
    PRODUCTS.refills.dish_soap.grapefruit_basil,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,

  ],
  complete_home_linen_fruity_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.linen,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.linen,
    PRODUCTS.glass.liquid_laundry_detergent.linen,
    PRODUCTS.glass.tablet_jar.linen,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.grapefruit_basil,
    PRODUCTS.refills.dish_soap.grapefruit_basil,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
  ],
  complete_home_black_fruity_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.black,
    PRODUCTS.glass.liquid_hand_soap.black,
    PRODUCTS.glass.dish_soap.black,
    PRODUCTS.glass.liquid_laundry_detergent.black,
    PRODUCTS.glass.tablet_jar.black,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.grapefruit_basil,
    PRODUCTS.refills.dish_soap.grapefruit_basil,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
  ],
  complete_home_mosaic_fruity_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.mosaic,
    PRODUCTS.glass.liquid_hand_soap.mosaic,
    PRODUCTS.glass.dish_soap.mosaic,
    PRODUCTS.glass.liquid_laundry_detergent.mosaic,
    PRODUCTS.glass.tablet_jar.mosaic,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.orange_zest,
    PRODUCTS.refills.liquid_hand_soap.grapefruit_basil,
    PRODUCTS.refills.dish_soap.grapefruit_basil,
    PRODUCTS.refills.liquid_laundry_detergent.sweet_honeysuckle,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.sweet_honeysuckle,
    PRODUCTS.essentials.bar_soap.grapefruit_basil,
  ],
  // ---------
  complete_home_periwinkle_exotic_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.periwinkle,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.periwinkle,
    PRODUCTS.glass.liquid_laundry_detergent.periwinkle,
    PRODUCTS.glass.tablet_jar.periwinkle,
    
    PRODUCTS.essentials.dryer_balls,

    PRODUCTS.refills.all_purpose_cleaner.bamboo_lily,
    PRODUCTS.refills.liquid_hand_soap.blue_sage,
    PRODUCTS.refills.dish_soap.blue_sage,
    PRODUCTS.refills.liquid_laundry_detergent.juniper_sandalwood,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
    PRODUCTS.essentials.bar_soap.blue_sage,

  ],
  complete_home_linen_exotic_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.linen,
    PRODUCTS.glass.liquid_hand_soap.periwinkle,
    PRODUCTS.glass.dish_soap.linen,
    PRODUCTS.glass.liquid_laundry_detergent.linen,
    PRODUCTS.glass.tablet_jar.linen,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.bamboo_lily,
    PRODUCTS.refills.liquid_hand_soap.blue_sage,
    PRODUCTS.refills.dish_soap.blue_sage,
    PRODUCTS.refills.liquid_laundry_detergent.juniper_sandalwood,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
    PRODUCTS.essentials.bar_soap.blue_sage,
  ],
  complete_home_black_exotic_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.black,
    PRODUCTS.glass.liquid_hand_soap.black,
    PRODUCTS.glass.dish_soap.black,
    PRODUCTS.glass.liquid_laundry_detergent.black,
    PRODUCTS.glass.tablet_jar.black,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.bamboo_lily,
    PRODUCTS.refills.liquid_hand_soap.blue_sage,
    PRODUCTS.refills.dish_soap.blue_sage,
    PRODUCTS.refills.liquid_laundry_detergent.juniper_sandalwood,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
    PRODUCTS.essentials.bar_soap.blue_sage,
  ],
  complete_home_mosaic_exotic_bundle: [
    PRODUCTS.glass.all_purpose_cleaner.mosaic,
    PRODUCTS.glass.liquid_hand_soap.mosaic,
    PRODUCTS.glass.dish_soap.mosaic,
    PRODUCTS.glass.liquid_laundry_detergent.mosaic,
    PRODUCTS.glass.tablet_jar.mosaic,
    
    PRODUCTS.essentials.dryer_balls,
    PRODUCTS.refills.all_purpose_cleaner.bamboo_lily,
    PRODUCTS.refills.liquid_hand_soap.blue_sage,
    PRODUCTS.refills.dish_soap.blue_sage,
    PRODUCTS.refills.liquid_laundry_detergent.juniper_sandalwood,
    PRODUCTS.refills.dishwasher_tablets.lemongrass,
    PRODUCTS.essentials.bar_soap.bamboo_lily,
    PRODUCTS.essentials.bar_soap.juniper_sandalwood,
    PRODUCTS.essentials.bar_soap.blue_sage,
  ],
}

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrder: null,
      searchFilter: "",

      childModalOpen: false,
      createSampleViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }
  setSelectedOrder(nextProps) {
    var orderID = nextProps.location.pathname.match(/\d+/);
    if (orderID) {
      orderID = orderID[0];
      this.setState({ selectedOrder: orderID });
    } else {
      this.setState({ selectedOrder: null });
    }
  }

  // Checks to see whether or not to open the secondery modal inside of the order detail view
  checkIfChildModalOpen(pathname) {
    var childModalOpen = false;
    var createSampleViewOpen = false;

    if (pathname.includes("refund")) {
      childModalOpen = true;
    } else if (pathname.includes("change")) {
      childModalOpen = true;
    } else if (pathname.includes("replacement")) {
      childModalOpen = true;
    } else if (pathname.includes("replacement")) {
      childModalOpen = true;
    } else if (pathname.includes("edit")) {
      childModalOpen = true;
    }  else if (pathname.includes("split")) {
      childModalOpen = true;
    }

    if (pathname.includes("create")) {
      createSampleViewOpen = true;
    }

    this.setState({
      childModalOpen: childModalOpen,
      createSampleViewOpen: createSampleViewOpen
    });
  }

  onClose() {
    this.setState({
      selectedOrder: null
    });
  }

  // Go back to the main orders page
  closeModal() {
    history.push("/portal/orders");
  }

  closeChildModal() {
    history.push(`/portal/orders/${this.props.orderDetail.transaction_id}`);
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);

      this.setSelectedOrder(this.props);
    
  }

  componentWillReceiveProps(nextProps) {

    this.checkIfChildModalOpen(nextProps.location.pathname);
    this.setSelectedOrder(nextProps);
  }

  setSampleBoxInititalValues() {
      let initialValues = {
        by_pass_address_validation: "false"
      }

      let queryString = window.location.search
      let urlParams = new URLSearchParams(queryString)
      let bundle = urlParams.get("bundle")

      let count = 1
      if (bundlePreSets[bundle]) {
        bundlePreSets[bundle].map(function(bundleProduct) {
          initialValues[`product_${count}`] = bundleProduct
          initialValues[`quantity_${count}`] = 1
          count += 1
          return bundleProduct
        })
      }

      return initialValues
      
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
 
            <div>
              
              <SearchView />
              <Table
                orders={this.props.orders ? this.props.orders : []}
                customerServiceUser={
                  this.props.loggedInUser.customer_service_user
                }
              />
            </div>
        
          <CreateSampleBoxView
            closeModal={this.closeModal}
            createSampleViewOpen={this.state.createSampleViewOpen}
            location={this.props.location}
            initialValues={this.setSampleBoxInititalValues()}
          />

          <DetailView
            closeModal={this.closeModal}
            closeChildModal={this.closeChildModal}
            orderActive={this.state.selectedOrder ? true : false}
            childModalOpen={this.state.childModalOpen}
            orderID={
              this.state.selectedOrder
                ? this.state.selectedOrder
                : null
            }
            location={this.props.location}
          />
        </div>
        <Copyright />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    orders: state.orders.orders,
    ordersLoading: state.orders.orders_loading,
    orderDetail: state.orders.orderDetail,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  {  }
)(Orders);
