import React from "react";
import { Divider, Button, Form, Col } from "antd";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import {
    selectField,
    plainTextField,
    plainNumberField
} from "../Form/FieldRenders";
import {
    searchAllSubscriptions
  } from "../../actions/actionsSubscriptions";

  function serialize(obj) {
    var str = [];
    for(var p in obj)
       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    return str.join("&");
  }
class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(props) {
        
        let params = serialize(props)
        
        if (params) {

            params = "?" + params
        }


        this.props.searchAllSubscriptions(params)
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="chart-row">
                <Divider>Subscription Search </Divider>
                <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                    <div style={{alignItems: "end", display: "flex", justifyContent: "center"}}>
                        <Col span={4}>
                            <Field
                                label={"Subscription ID"}
                                name="transaction_id"
                                required={false}
                                size={"large"}
                                component={plainNumberField}
                            />
                        
                        </Col>

                        <Col span={4}>
                            <Field
                                label={"Status"}
                                name="status"
                                required={false}
                                size={"large"}
                                options={[
                                    {
                                        text: "None",
                                        value: null
                                    },
                                    {
                                        text: "Running",
                                        value: "running"
                                    },
                                    {
                                        text: "Cancelled",
                                        value: "cancelled"
                                    },
                                    {
                                        text: "On Hold",
                                        value: "on_hold"
                                    },
                                ]}
                                component={selectField }
                            />
                        </Col>
                        <Col span={4}>
                            <Field
                                label={"Email"}
                                name="email"
                                required={false}
                                size={"large"}
                                component={plainTextField }
                            />
                        </Col>
                        
                        <Col span={4}>
                            <br />
                            <br />
                            <Button
                                loading={this.props.formLoading || this.props.subscriptionsLoading}
                                htmlType="submit"
                                block
                                size="large"
                                type="primary"
                                icon="search"
                            >
                                Search
                            </Button>
                        </Col>
                    </div>
                </Form>
            </div>
        );
    }
}


SearchView = reduxForm({
    form: "SubscriptionSearchViewForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    enableReinitialize: false
})(SearchView);

function mapStateToProps(state) {
    return {
        
        subscriptionsLoading: state.subscriptions.subscriptions_loading,
        formValues: getFormValues("SubscriptionSearchViewForm")(state)
    };
}

export default connect(
    mapStateToProps,
    { searchAllSubscriptions }
)(SearchView);
