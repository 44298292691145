import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
  CartesianGrid
} from "recharts";

class DaysToDeliveryByStateChart extends React.Component {
  render() {

    return (
      <ResponsiveContainer width={"100%"} height={700}>
        <BarChart
          width={500}
          height={700}
          data={this.props.data.data}
          margin={{
          right: 140,
          top: 30
        }}>
          <Tooltip
            wrapperClassName={"my-tooltip"}
            wrapperStyle={{
            backgroundColor: "#fff",
            background: "#fff",
            border: "0px",
            fontSize: "15px",
            boxShadow: "0 2px 8px #f0f1f2",
            opactity: 0.5,
            lineHeight: "20px",
            borderRadius: "3px"
          }}/>

          <CartesianGrid strokeDasharray="3 3" stroke="rgba(0, 0, 0, 0.45)"/>
          <XAxis dataKey="name" stroke="rgba(0, 0, 0, 0.45)"/>
          <YAxis  orientation="left" stroke="rgba(0, 0, 0, 0.45)" />
          <Bar dataKey="Average_Days_To_Delivery" fill="#1890ff" />
      
      
          
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default DaysToDeliveryByStateChart;
