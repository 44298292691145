import * as types from "../actions/types";

const INITIAL_STATE = {
  promotions: null,
  promotions_loading: false,
  promotions_soft_loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_PROMOTIONS_LOADING:
      return { ...state, promotions_loading: true };
    case types.FETCH_ALL_PROMOTIONS_SOFT_LOADING:
      return { ...state, promotions_soft_loading: true };
    case types.FETCH_ALL_PROMOTIONS_SUCCESS:
      return {
        ...state,
        promotions: action.payload,
        promotions_loading: false,
        promotions_soft_loading: false
      };

    default:
      return state;
  }
}
