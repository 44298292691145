import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import DownloadCSV from "../Common/DownloadCSV";
import {
  renderSubscriptionStatusFromSubscription,
  convertBooleanToTag
} from "../../helpers";
import { Select } from "antd";

import moment from "moment"
const { Option } = Select;
var dateFormat = require("dateformat");

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this.reactTable.getResolvedState().sortedData;
    this.setState({ data: data });
  }

  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right", height: "31px" }}>
          <DownloadCSV itemName={"Subscriptions"} data={this.state.data} />
        </div>
        <ReactTable
          data={this.props.subscriptions}
          noDataText="No Subscriptions Found"
          ref={r => (this.reactTable = r)}
          filterable
          columns={[
            {
              Header: "Subscription",
              columns: [
                {
                  Header: "Subscription ID",
                  id: "subscription_id",

                  accessor: d => d.subscription.id,
                  Cell: row => (
                    <Link to={"/portal/subscriptions/" + row.value}>
                      {row.value}
                    </Link>
                  )
                },

                {
                  Header: "Status",
                  id: "status",

                  accessor: d => d.subscription,
                  Cell: row => (
                    <div>
                      {renderSubscriptionStatusFromSubscription(
                        row.value.status,
                        row.value.subscription_on_hold
                      )}
                    </div>
                  ),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "auto_generated_by_subscription") {
                      return (
                        row.status.status ===
                          "auto_generated_by_subscription" &&
                        !row.status.subscription_on_hold
                      );
                    }
                    if (
                      filter.value ===
                      "auto_generated_by_subscription_cancelled"
                    ) {
                      return (
                        row.status.status ===
                        "auto_generated_by_subscription_cancelled"
                      );
                    }
                    if (filter.value === "on_hold") {
                      return row.status.subscription_on_hold;
                    }
                    return (
                      row["status"] ===
                      "auto_generated_by_subscription_cancelled"
                    );
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      size="small"
                      value={filter ? filter.value : "all"}
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      <Option value="all">Show All</Option>
                      <Option value="auto_generated_by_subscription">
                        Running
                      </Option>
                      <Option value="on_hold">On Hold</Option>
                      <Option value="auto_generated_by_subscription_cancelled">
                        Cancelled
                      </Option>
                    </Select>
                  )
                },

                {
                  Header: "Email",
                  id: "user_email",
                  accessor: d => d.user,
                  filterMethod: (filter, row) => {
                    // console.log(row.user_email);
                    var email = row.user_email.email;
                    return email
                      .toLowerCase()
                      .includes(filter.value.toLowerCase());
                  },
                  Cell: row => (
                    <Link to={`/portal/customers/${row.value.id}`}>
                      {row.value.email}
                    </Link>
                  )
                },
                {
                  Header: "Last Charge",
                  id: "date_charged",
                  accessor: d => d.subscription.date_created,
                  Cell: row => dateFormat(row.value, " mmmm dS yyyy"),
                  sortMethod: (a, b) => {
                    var d1 = new Date(a);
                    var d2 = new Date(b);
                    return d1 > d2 ? 1 : -1;
                  }
                },
                {
                  Header: "Next Charge",
                  id: "next_subscription_charge",
                  accessor: d => d.subscription.next_subscription_charge,
                  Cell: row => moment(row.value, 'YYYY-MM-DD').format("MM/DD/YYYY"),
                  sortMethod: (a, b) => {
                    var d1 = new Date(a);
                    var d2 = new Date(b);
                    return d1 > d2 ? 1 : -1;
                  }
                },
                {
                  Header: "Indiegogo",
                  id: "indiegogo_order",

                  accessor: d => d.subscription.indiegogo_order.toString(),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row["indiegogo_order"] === "true";
                    }
                    return row["indiegogo_order"] === "false";
                  },
                  Cell: row => convertBooleanToTag(row.value),
                  Filter: ({ filter, onChange }) => (
                    <Select
                      onChange={onChange}
                      style={{ width: "100%" }}
                      size="small"
                      value={filter ? filter.value : "all"}
                    >
                      <Option value="all">Show All</Option>
                      <Option value="true">True</Option>
                      <Option value="false">False</Option>
                    </Select>
                  )
                }
              ]
            },
            {
              Header: "Cart",
              columns: [
                {
                  Header: "Total",
                  id: "total",

                  accessor: d => "$" + d.cart.total
                }
              ]
            },
            {
              Header: "Discount",
              columns: [
                {
                  Header: "Amount",
                  id: "total_discount_amount",

                  accessor: d => "$" + d.cart.total_discount_amount
                }
              ]
            }
          ]}
        />
      </div>
    );
  }
}

export default Table;
