import React from "react";
import DataPoint from "../Common/DataPoint";
import {Select, Divider} from "antd";
import {shippingMethodArray, boxTypeArray} from "../../helpers";
import {DatePicker} from "antd";
import moment from "moment";
const {RangePicker} = DatePicker;
const datePickerFormat = "MM/DD/YYYY";

const Option = Select.Option;

var blendedPostage = {
  value: null,
  text: "Blended"
}

const newShipmentMethodArray = [blendedPostage].concat(shippingMethodArray)

class DasboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeRangeSelected: 0,
      startDate: new Date(),
      endDate: new Date()
    };

    this.handleChange = this
      .handleChange
      .bind(this);
    this.changeSelectedTimeRange = this
      .changeSelectedTimeRange
      .bind(this);
  }
  changeSelectedTimeRange(e) {
    this.setState({timeRangeSelected: e});
  }
  handleChange(dateArray) {
    if (dateArray[0]) {
      this.setState({
        startDate: dateArray[0].toDate(),
        endDate: dateArray[1].toDate()
      });
    }
  }

  getOrderArray(autoCharge) {
    var arrays = {
      currentTimeRange: null,
      pastTimeRange: []
    };
    var pastRange;
    var timeRange;

    if (this.state.timeRangeSelected === "Date Range") {
      pastRange = new Date(this.state.startDate);
      timeRange = new Date(this.state.endDate);

      timeRange = timeRange.setHours(24);
      timeRange = new Date(timeRange);
      timeRange = timeRange.setMinutes(59);
      timeRange = new Date(timeRange);
      timeRange = timeRange.setSeconds(59);
      timeRange = new Date(timeRange);

      pastRange = pastRange.setHours(0);
      pastRange = new Date(pastRange);
      pastRange = pastRange.setMinutes(0);
      pastRange = new Date(pastRange);
      pastRange = pastRange.setSeconds(0);
      pastRange = new Date(pastRange);
    } else {
      var today = new Date();
      var today2 = new Date();
      timeRange = today.setDate(today.getDate() - this.state.timeRangeSelected);

      timeRange = today.setHours(0);
      timeRange = today.setMinutes(0);
      timeRange = today.setSeconds(0);
      timeRange = new Date(timeRange);

      pastRange = null;
      if (this.state.timeRangeSelected === "0" || this.state.timeRangeSelected === 0) {
        pastRange = today2.setDate(today2.getDate() - 1);
      } else {
        pastRange = today2.setDate(today2.getDate() - (parseInt(this.state.timeRangeSelected, 0) + parseInt(this.state.timeRangeSelected, 0)));
      }
      pastRange = today2.setHours(0);
      pastRange = today2.setMinutes(0);
      pastRange = new Date(pastRange);
    }

    var currentTime = this
      .props
      .orders
      .filter(function (order) {
        var dateCharged = new Date(order.order.date_pick_packed);
        if (autoCharge) {
          return (dateCharged >= timeRange && order.cart.auto_generated_by_subscription);
        } else {
          return (dateCharged >= timeRange && !order.cart.auto_generated_by_subscription);
        }
      });
    var pastTime = this
      .props
      .orders
      .filter(function (order) {
        var dateCharged = new Date(order.order.date_pick_packed);

        return (dateCharged < timeRange && !order.cart.auto_generated_by_subscription && dateCharged >= pastRange);
      });

    if (this.state.timeRangeSelected === "Date Range") {
      currentTime = this
        .props
        .orders
        .filter(function (order) {
          var dateCharged = new Date(order.order.date_pick_packed);
          if (autoCharge) {
            return (dateCharged <= timeRange && order.cart.auto_generated_by_subscription && dateCharged >= pastRange);
          } else {
            return (dateCharged <= timeRange && !order.cart.auto_generated_by_subscription && dateCharged >= pastRange);
          }
        });
    }

    arrays.currentTimeRange = currentTime;
    arrays.pastTimeRange = pastTime;
    return arrays;
  }

  getShipmentCount(status) {
    var orders = this
      .props
      .orders
      .filter(function (order) {
        return (order.order.shipment_status === status);
      });

    return orders.length;
  }

  getAveragePostageCost(orderArray, filter) {
    var totalPostage = 0;
    var totalOrders = 0;

    orderArray.map(function (order) {
      if (order.order.cost_of_shipment) {
        if (filter) {
          if (order.order.service === filter) {
            totalPostage = totalPostage + parseFloat(order.order.cost_of_shipment, 10);
            totalOrders += 1;
          }

        } else {
          totalPostage = totalPostage + parseFloat(order.order.cost_of_shipment, 10);
          totalOrders += 1;
        }
      }
      return order;
    });
    if (totalOrders === 0) {
      return {averagePostage: 0, totalOrders: 0};
    } else {
      if (totalPostage / totalOrders) {
        return {
          averagePostage: totalPostage / totalOrders,
          totalOrders: totalOrders
        }
      } else {
        return {averagePostage: 0, totalOrders: 0};
      }
    }
  }

  getAverageTimingToFulfill(orderArray, ifPriority) {
    var averageTime = 0;
    var totalOrders = 0;

    orderArray.map(function (order) {
      if (order.order.date_charged && order.order.date_pick_packed) {

        var dateCharged = moment(order.order.date_charged)
        var datePickPacked = moment(order.order.date_pick_packed)

        var duration = moment.duration(datePickPacked.diff(dateCharged));
        var hours = duration.asHours();
        if (dateCharged.day() !== 6 && dateCharged.day() !== 7) {
          if (ifPriority) {
            if (order.order.pick_pack_priority) {
              averageTime += parseFloat(hours)
              totalOrders += 1
            }

          } else {
            if (!order.order.pick_pack_priority) {
              averageTime += parseFloat(hours)
              totalOrders += 1
            }

          }
        }
      }
      return order;
    });

    return {
      averageTime: averageTime / totalOrders,
      totalOrders: totalOrders
    };
  }
  renderPickPackTiming() {
    var that = this;
    var autoChargeArray = this
      .getOrderArray(true)
      .currentTimeRange;
    var selfCheckoutArray = this
      .getOrderArray(false)
      .currentTimeRange;

    var orderArray = autoChargeArray.concat(selfCheckoutArray);
    var typeArray = [
      {
        value: false,
        text: "Non Pick Pack Priority"
      }, {
        value: true,
        text: "Pick Pack Priority"
      }
    ]

    return typeArray.map(function (pickPackType) {

      var averageTime = that
        .getAverageTimingToFulfill(orderArray, pickPackType.value)
        .averageTime
      var totalOrders = that
        .getAverageTimingToFulfill(orderArray, pickPackType.value)
        .totalOrders

      return (<DataPoint
        key={pickPackType.text}
        data={averageTime}
        label={`${pickPackType.text} TTF (${totalOrders} Orders)`}
        positive={false}
        decimals={2}
        suffix={"Hrs"}/>)

    })

  }

  renderAveragePostageByService() {
    var that = this;
    var autoChargeArray = this
      .getOrderArray(true)
      .currentTimeRange;
    var selfCheckoutArray = this
      .getOrderArray(false)
      .currentTimeRange;

    var orderArray = autoChargeArray.concat(selfCheckoutArray);
    return newShipmentMethodArray.map(function (shipmentMethod) {

      var averagePostage = that
        .getAveragePostageCost(orderArray, shipmentMethod.value)
        .averagePostage
      var totalOrders = that
        .getAveragePostageCost(orderArray, shipmentMethod.value)
        .totalOrders

      return (<DataPoint
        key={shipmentMethod.text}
        data={averagePostage}
        label={`${shipmentMethod.text} Postage (${totalOrders} Orders)`}
        positive={false}
        decimals={2}
        prefix={"$"}/>)
    })

  }
  renderBoxTypeSales() {
    var autoChargeArray = this
      .getOrderArray(true)
      .currentTimeRange;
    var selfCheckoutArray = this
      .getOrderArray(false)
      .currentTimeRange;

    var orderArray = autoChargeArray.concat(selfCheckoutArray);
    var boxSales = boxTypeArray

    var total = 0
    orderArray.map(function (orderObject) {
      if (orderObject.order.final_box_configuration) {

        if (orderObject.order.final_box_configuration.final_box_configuration) {
          var finalBoxConfig = orderObject.order.final_box_configuration.final_box_configuration
          if (finalBoxConfig.length > 0) {
            boxSales = boxSales.map(function (box) {
              var addSale = false
              if (finalBoxConfig[0].box.id) {
                if (box.key === finalBoxConfig[0].box.id) {
                  addSale = true
                }
              } else {
                finalBoxConfig[0]
                  .box
                  .components
                  .map(function (component) {
                    if (box.key === "refillBox") {
                      if (component.sku.includes("Refill")) {
                        addSale = true
                      }
                    }
                    if (box.key === "smallLightBlueBox") {
                      if (component.sku.includes("Small")) {
                        addSale = true
                      }
                    }
                    if (box.key === "starterKitBox") {
                      if (component.sku.includes("Starter")) {
                        addSale = true
                      }
                    }
                  
                    return component
                  })

              }

              if (addSale) {
                box["sales"] += 1
              }
              return box
            })

          }
        }
      }
      return orderObject
    })
    console.log(total)
    return boxSales.map(function (boxObject) {

      return (<DataPoint
        key={boxObject.text}
        image={boxObject.image}
        data={boxObject.sales}
        label={`${boxObject.text} Sales`}
        positive={false}/>)

    })
  }
  render() {
    return (
      <div style={{
        position: "relative"
      }}>
        {this.state.timeRangeSelected === "Date Range"
          ? (
            <div
              className="date-range-select-container"
              style={{
              top: "280px"
            }}>
              <RangePicker
                defaultValue={[moment(), moment()]}
                format={datePickerFormat}
                onChange={this.handleChange}/>
            </div>
          )
          : null}

        <div className="time-select-container">
          <Select
            style={{
            width: "140px",
            top: "230px"
          }}
            value={this.state.timeRangeSelected}
            onChange={this.changeSelectedTimeRange}
            className="black-background green-light-border-color border-full-thin">
            <Option value={"Date Range"}>Date Range</Option>
            <Option value={0}>Today</Option>
            <Option value={7}>7 Days Back</Option>
            <Option value={31}>30 Days Back</Option>
            <Option value={new Date().getDate() - 1}>This Month</Option>
            <Option value={90}>90 Days Back</Option>
            <Option value={365}>365 Days Back</Option>
          </Select>
        </div>
        <Divider orientation="left">Fulfillment Information (Live)
        </Divider>
        <div className="dashboard-row">
          <DataPoint
            data={this.getShipmentCount("awaiting_shipment")}
            label={"Awaiting Shipment (Currently)"}
            positive={false}/>
          <DataPoint
            data={this.getShipmentCount("on_hold")}
            label={"On Hold (Currently)"}
            positive={false}/>
          <DataPoint
            data={this.getShipmentCount("delayed")}
            label={"Delayed (Currently)"}
            positive={false}/>

        </div>
        <div className="dashboard-row">
          <Divider orientation="left">Average Postage (Per Service)</Divider>
          {this.renderAveragePostageByService()}
        </div>
        <div className="dashboard-row">
          <Divider orientation="left">Average Time To Pick Pack (Priority vs Non Priority) TTF = Time To Fulfill</Divider>
          {this.renderPickPackTiming()}
        </div>
        <div className="dashboard-row">
          <Divider orientation="left">Box Type Sales</Divider>
          {this.renderBoxTypeSales()}
        </div>

      </div>
    );
  }
}

export default DasboardRow
