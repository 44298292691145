import React from "react";
import DataPoint from "../Common/DataPoint";

class DasboardRow extends React.Component {
  getPromotionActive(activeStatus) {
    var count = 0;
    this.props.promotions.map(function(promotion) {
      if (promotion.active === activeStatus) {
        count = count + 1;
      }
      return promotion;
    });
    return count;
  }
  render() {
    return (
      <div className="dashboard-row">
        <DataPoint
          data={this.props.promotions.length}
          label={"Promotions"}
          positive={false}
        />
        <DataPoint
          data={this.getPromotionActive(true)}
          label={"Active"}
          positive={false}
        />
        <DataPoint
          data={this.getPromotionActive(false)}
          label={"Not Active"}
          positive={false}
        />
      </div>
    );
  }
}

export default DasboardRow;
