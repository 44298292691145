import React from "react";
import { Button, Form, Spin, Divider, Row, Col } from "antd";
import { connect } from "react-redux";
import { plainTextField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { editUserProfileAdmin } from "../../actions/actionsCustomers";

import { normalizeZipcode, normalizeMonthYear } from "../Form/Normalize";

const validate = values => {
  const errors = {};
  if (!values.expiration_date) {
    errors.expiration_date = "Required";
  }
  if (!values.zip_code) {
    errors.zip_code = "Required";
  }

  return errors;
};

class UserPaymentMethodView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(props) {
    props.random_user_id = this.props.customerDetail.details.random_user_id;
    props.stripe_id = this.props.customerDetail.details.stripe_id;
    props.type = "edit_payment_method";
    this.props.editUserProfileAdmin(props, "put");
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Divider>Payment Method Details</Divider>

            <Row gutter={6}>
              <Col span={12}>
                <Field
                  label={"Exp. Date"}
                  name={`expiration_date`}
                  required={true}
                  allowClear={true}
                  normalize={normalizeMonthYear}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
              <Col span={12}>
                <Field
                  label={"Zip Code"}
                  name={`zip_code`}
                  required={true}
                  allowClear={true}
                  size={"large"}
                  normalize={normalizeZipcode}
                  component={plainTextField}
                />
              </Col>
            </Row>

            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Save Payment Method
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

UserPaymentMethodView = reduxForm({
  form: "PaymentMethodForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(UserPaymentMethodView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editUserProfileAdmin }
)(UserPaymentMethodView);
