// Convert Phone Number to proper formatting
export const normalizePhone = value => {
  if (!value) {
    return value;
  }
  var onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    console.log("");
  } else if (onlyNums.length <= 7) {
    onlyNums = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  } else {
    onlyNums = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(
      3,
      6
    )}-${onlyNums.slice(6, 10)}`;
  }

  return onlyNums;
};

// Convert MM/YY to proper formatting
export const normalizeMonthYear = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
};

// Convert Zipcode to proper formatting
export const normalizeZipcode = value => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 10)}`;
};


// Convert slug into proper format
export const normalizeSlug = value => {
  if (!value) {
    return value;
  }
 
  return value
  .toLowerCase()
  .replace(/ /g,'-')
  .replace(/[^\w-]+/g,'')
  ;
 
};