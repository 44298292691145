import React from "react";

const DescriptionItem = ({ title, content }) => (
  <div
    style={{
      fontSize: 14,
      lineHeight: "22px",
      marginBottom: 4,
      color: "rgba(0,0,0,0.65)"
    }}
  >
    <p
      style={{
        marginRight: 8,
        marginBottom: 5,
        display: "inline-block",
        color: "rgba(0,0,0,0.85)",
        whiteSpace: "pre-line"
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

export default DescriptionItem;
