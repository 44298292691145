import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  ReferenceLine,
  YAxis,
  CartesianGrid
} from "recharts";

class PostageByState extends React.Component {
  render() {

    return (
      <ResponsiveContainer width={"100%"} height={700}>
        <BarChart
          width={500}
          height={700}
          data={this.props.data.data}
          margin={{
          right: 140,
          top: 30
        }}>
          <Tooltip
            wrapperClassName={"my-tooltip"}
            wrapperStyle={{
            backgroundColor: "#fff",
            background: "#fff",
            border: "0px",
            fontSize: "15px",
            boxShadow: "0 2px 8px #f0f1f2",
            opactity: 0.5,
            lineHeight: "20px",
            borderRadius: "3px"
          }}/>

          <CartesianGrid strokeDasharray="3 3" stroke="rgba(0, 0, 0, 0.45)"/>
          <XAxis dataKey="name" stroke="rgba(0, 0, 0, 0.45)"/>
          <YAxis yAxisId="right" orientation="left" stroke="#f5222d" />
          <YAxis yAxisId="left" orientation="right" stroke="#222"/>
          <Bar dataKey="Average_Smart_Post_Cost" fill="#ffa39e" yAxisId="right"/>
          <Bar dataKey="Weighted_Average_Smart_Post_Cost" fill="#f5222d" yAxisId="right"/> 
          {this.props.showOrderCounts && (
            <Bar dataKey="Orders_Sent_To_State" fill="#222" yAxisId="left"/>
          )}
          <ReferenceLine
            y={this.props.data.averagePostage}
            alwaysShow={true}
            yAxisId="right"
            label={{
            value: `Average Fedex SP Postage ($${this.props.data.averagePostage})`,
            fill: "#222"
          }}
            stroke="#222"/>
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default PostageByState;
