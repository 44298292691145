import React from "react";
import CountUp from "react-countup";
import { Card, Typography } from "antd";

class DataPoint extends React.Component {
  renderPositiveClass() {
    var className = "red-light-color";
    if (this.props.positive) {
      className = "";
    }
    return className;
  }

  renderImage() {
    if (this.props.image) {
      return (
        <div style={{
          background: "#e8e8e8",
          minHeight: "50px"
        }}>
            <img
              alt="example"
              src={
                this.props.image
              }
            />
        </div>
      )
    } else{
      return null
    }
  }
  render() {
    return (
      <Card className="data-point-container"
      
      cover={
      this.renderImage()
      }
      >
        <Typography.Text level={2} className="data ">
          {this.props.prefix}
          <CountUp
            start={0}
            end={this.props.data}
            useEasing={true}
            separator=","
            redraw={true}
            decimals={this.props.decimals}
            duration={2.75}
            className={"data-point gray-light-color"}
          />
          {this.props.suffix}
          <span className={"change " + this.renderPositiveClass()}>
            {this.props.change}
          </span>
        </Typography.Text>
        <br />
        <Typography.Text
          type="secondary"
          className="label green-light-color"
          style={{ color: this.props.color }}
        >
          {this.props.label}
        </Typography.Text>
        <Card.Meta  description={this.props.smallLabel} />
      
      </Card>
    );
  }
}

export default DataPoint;
