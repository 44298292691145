import React from "react";
import { Spin } from "antd";

class Loader extends React.Component {
  render() {
    return (
      <div className="loading-container" style={this.props.style}>
        <Spin size="large" />
      </div>
    );
  }
}

export default Loader;
