import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { convertBooleanToTag, renderProductTag, renderProductOptions, convertShippingMethodToTag} from "../../helpers";
import { Select, Button } from "antd";
import { Link } from "react-router-dom";
var dateFormat = require("dateformat");

const { Option } = Select;

class Table extends React.Component {
  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right" }}>
          <Link to={"/portal/promotions/create-promotion"}>
            <Button
              ghost
              icon="file-add"
              type="primary"
              style={{ marginRight: "5px" }}
            >
              Create Promotion
            </Button>
          </Link>
        </div>
        <ReactTable
          data={this.props.promotions}
          noDataText="No Promotions Found"
          filterable
          columns={[
            {
              Header: "Internal Information",
              columns: [
                {
                  Header: "ID",
                  id: "promotion_id",
                  width: 120,
                  accessor: d => d.promotion_id,
                  Cell: row => (
                    <Link to={"/portal/promotions/" + row.value}>
                      {row.value}
                    </Link>
                  )
                },
                {
                  Header: "Internal Title",
                  id: "internal_title",
                  accessor: d => d.internal_title
                },
                {
                  Header: "Active",
                  id: "active",
                  accessor: d => d.active,
                  Cell: row =>
                    convertBooleanToTag(row.value.toString().toUpperCase()),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row["active"].toString() === "true";
                    }
                    return !row["active"];
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      size="small"
                      value={filter ? filter.value : "all"}
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      <Option value="all">Show All</Option>
                      <Option value="true">True</Option>
                      <Option value="false">False</Option>
                    </Select>
                  )
                },
                {
                  Header: "Date Created",
                  id: "date_created",
                  accessor: d => d.date_created,
                  Cell: row => dateFormat(row.value, " mmmm dS yyyy"),
                  sortMethod: (a, b) => {
                    var d1 = new Date(a);
                    var d2 = new Date(b);
                    return d1 > d2 ? 1 : -1;
                  }
                }
              ]
            },
            {
              Header: "Promotion Info",
              columns: [
                {
                  Header: "Free Product",
                  id: "product",
                  width: 350,
                  accessor: d => d.product,
                  Cell: row => renderProductTag(row.value),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    } else {
                      return row["product"] === filter.value;
                    }
                  },

                  Filter: ({ filter, onChange }) => (
                    <Select
                      size="small"
                      showSearch={false}
                      value={filter ? filter.value : "all"}
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      <Option value="all">Show All</Option>

                      {renderProductOptions()}
                    </Select>
                  )
                },
                {
                  Header: "Free For Life",
                  id: "free_product_for_life",
                  accessor: d => d.free_product_for_life,
                  Cell: row =>
                    convertBooleanToTag(row.value.toString().toUpperCase()),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row["active"].toString() === "true";
                    }
                    return !row["active"];
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      size="small"
                      value={filter ? filter.value : "all"}
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      <Option value="all">Show All</Option>
                      <Option value="true">True</Option>
                      <Option value="false">False</Option>
                    </Select>
                  )
                },
                {
                  Header: "Locked To Product Type",
                  width: 200,
                  id: "locked_promotion_type",
                  accessor: d => d.locked_promotion_type,
                  Cell: row => convertShippingMethodToTag(row.value)
                },

                {
                  Header: "Discount",
                  id: "discount",
                  accessor: d => d,
                  Cell: row => (
                    <Link to={`/portal/discounts/${row.value.discount_id}`}>
                      {row.value.discount}
                    </Link>
                  )
                }
              ]
            },
            {
              Header: "Modal Information",
              columns: [
                {
                  Header: "Display Modal",
                  id: "display_modal",
                  accessor: d => d.display_modal,
                  Cell: row =>
                    convertBooleanToTag(row.value.toString().toUpperCase()),
                  filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "true") {
                      return row["display_modal"].toString() === "true";
                    }
                    return !row["display_modal"];
                  },
                  Filter: ({ filter, onChange }) => (
                    <Select
                      size="small"
                      value={filter ? filter.value : "all"}
                      style={{ width: "100%" }}
                      onChange={onChange}
                    >
                      <Option value="all">Show All</Option>
                      <Option value="true">True</Option>
                      <Option value="false">False</Option>
                    </Select>
                  )
                },
                {
                  Header: "Modal Header",
                  id: "header",
                  accessor: d => d.header
                },
                {
                  Header: "Modal Subheader",
                  id: "subheader",
                  accessor: d => d.subheader
                },
                {
                  Header: "Modal Call Out",
                  id: "call_out_header",
                  accessor: d => d.call_out_header
                }
              ]
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
