import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the admin users into state
export function fetchAllAdminUsersSuccess(discounts) {
  return { type: types.FETCH_ALL_ADMIN_USERS_SUCCESS, payload: discounts };
}

// Load the admin users into state
export function setAllAdminUsersLoading(discounts) {
  return { type: types.FETCH_ALL_ADMIN_USERS_LOADING, payload: null };
}

// Load the admin users into state
export function setAllAdminUsersSoftLoading(discounts) {
  return { type: types.FETCH_ALL_ADMIN_USERS_SOFT_LOADING, payload: null };
}

// retrievie all admin users
export function fetchAllAdminUsers(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllAdminUsersLoading());
    } else {
      dispatch(setAllAdminUsersSoftLoading());
    }
    return axios({
      url: `${BASE_API_URL}get-admin-users`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllAdminUsersSuccess(response.data.admin_users));
      })
      .catch(function() {
        message.error(
          "Hmmm, there seems to be an error retrieving the admin users"
        );
      });
  };
}

// Create a admin user
export function createAdminUser(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-admin-user`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Admin User Created");
        dispatch(reset("CreateAdminUserForm"));
        dispatch(fetchAllAdminUsers());
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/user-management/${response.data.random_user_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this admin user"
          );
        }
      });
  };
}

// edit a admin user
export function editAdminUser(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-admin-user`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Admin User Saved");
        dispatch(reset("CreateAdminUserForm"));
        dispatch(fetchAllAdminUsers(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/user-management/${response.data.random_user_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this admin user"
          );
        }
      });
  };
}
