import React from "react";
import { connect } from "react-redux";
import { fetchAllQuestionsAndReviews } from "../../actions/actionsQuestionsAndReviews";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/Questions/DashboardRow";
import Table from "../../components/Questions/Table";
import QuestionFormView from "../../components/Questions/QuestionFormView";

class Questions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuestion: null,
      searchFilter: "",
      childModalOpen: false,
      createDiscountViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
  }
  setSelectedQuestion(nextProps) {
    var questionID = nextProps.location.pathname.match(/\d+/);
    if (questionID) {
      questionID = questionID[0];
      var that = this;
      nextProps.questions.map(function(question) {
        if (question.question_id === questionID) {
          that.setState({ selectedQuestion: question });
        }
        return question;
      });
    } else {
      this.setState({ selectedQuestion: null });
    }
  }

  onClose() {
    this.setState({
      selectedQuestion: null
    });
  }
  // Checks to see whether or not to open the secondery modal inside of the customer detail view
  checkIfChildModalOpen(pathname) {
    var createQuestionViewOpen = false;

    if (pathname.includes("create")) {
      createQuestionViewOpen = true;
    }

    this.setState({
      createQuestionViewOpen: createQuestionViewOpen
    });
  }
  //   Go back to the main Questions page
  closeModal() {
    history.push("/portal/questions");
    this.setState({
      selectedQuestion: null
    });
  }

  getTitle() {
    if (this.state.selectedQuestion) {
      return `Edit Question (${this.state.selectedQuestion.question_id})`;
    }
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the Questions are not already populated, populate them
    if (!this.props.questions) {
      this.props.fetchAllQuestionsAndReviews();
    } else {
      this.setSelectedQuestion(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.questions) {
      this.setSelectedQuestion(nextProps);
    }
  }

  getInitialValues(question) {
    if (question) {
      var initialValues = Object.assign({}, question);

      initialValues.display_question = initialValues.display_question.toString();
      return initialValues;
    }
  }
  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.questions && !this.props.discountsLoading ? (
            <div>

                <div>
                  <Divider orientation="left">Overall Stats </Divider>
                  <DashboardRow questions={this.props.questions} />
                </div>
              
              <Table questions={this.props.questions} />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title="Create Question"
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.createQuestionViewOpen}
        >
          <QuestionFormView />
        </Drawer>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedQuestion ? true : false}
        >
          <QuestionFormView
            initialValues={this.getInitialValues(this.state.selectedQuestion)}
            edit={true}
          />
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    questions: state.questions_and_reviews.questions,
    questions_loading:
      state.questions_and_reviews.questions_and_reviews_loading,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { fetchAllQuestionsAndReviews }
)(Questions);
