import React from "react";
import { Alert, Button, Spin, Divider, Row, Col, Badge} from "antd";
import { connect } from "react-redux";
import { createSplitOrders } from "../../actions/actionsOrders";
import { List, Avatar, InputNumber } from 'antd';
import {productArray} from "../../helpers";

class SplitOrderView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            splitOrders: null,
            originalSplitOrder: null
        };
    }

    splitOrderAccept() {
        let data = {
            splitOrders: this.state.splitOrders
        }
        data["transaction_id"] = this.props.orderDetail.transaction_id;
        this.props.createSplitOrders(data);
    }

    splitOrder(orderDetail) {
        let splitOrders = []
        let items = []
        let { cart_item_set } = orderDetail.cart
        let originalSplitOrder = {}


        cart_item_set.map(function(cartItem) {
            originalSplitOrder[cartItem.product] = cartItem.cart_total_item_quantity
            let itemObject = {
                id: cartItem.product,
                quantity: cartItem.cart_total_item_quantity,
                image: cartItem.cart_item_product_information.cart_image.url,
                type: null
            }
            if (itemObject.id.includes("glass")) {
                itemObject.type = "glass"
            } else if (itemObject.id.includes("refill") && itemObject.id.includes("32")) {
                itemObject.type = "refill_32"
            } else if (itemObject.id === "all_purpose_cleaner_refill" || (itemObject.id.includes("refill") && itemObject.id.includes("16"))) {
                itemObject.type = "refill_16"
            } else {
                itemObject.type = "bar_soap_or_dryer_ball"
            }
            
            items.push(itemObject)
            return cartItem
        })
        let itemsCopy = [...items]
        let itemsCopy2 = [...items]

        // Default
        splitOrders = [items, itemsCopy, itemsCopy2]


        this.setState({
            splitOrders: splitOrders,
            originalSplitOrder: originalSplitOrder
        })


    }

    UNSAFE_componentWillMount() {
        this.splitOrder(this.props.orderDetail)
    }

    changeItemQuantity = (quantity, box, itemID) => {
        console.log(box)
        let splitOrdersEdited = JSON.parse(JSON.stringify(this.state.splitOrders));
        let splitBoxBeingEdited = [...splitOrdersEdited[box]]

        splitBoxBeingEdited[splitBoxBeingEdited.findIndex(x => x.id ===itemID)].quantity = quantity
        splitOrdersEdited[box] = splitBoxBeingEdited

        this.setState({
            splitOrders: splitOrdersEdited
        })
    }

    removeAllItemsInBox(index) {
        console.log(index)
        let splitOrdersEdited = JSON.parse(JSON.stringify(this.state.splitOrders));
        let splitBoxBeingEdited = [...splitOrdersEdited[index]]



        this.state.splitOrders[0].map((itemObject) => {

            splitBoxBeingEdited[splitBoxBeingEdited.findIndex(x => x.id ===itemObject.id)].quantity = 0
            splitOrdersEdited[index] = splitBoxBeingEdited
            return itemObject
        })

        this.setState({
            splitOrders: splitOrdersEdited
        })
    }

    renderSplitOrders() {
        let {splitOrders} = this.state;
        var count = 0
        let productDict = {}
        productArray.map(function(productObject) {
            if (productObject.value) {
                productDict[productObject.value] = productObject.text
            }
            return productObject
        })
        return splitOrders.map((splitOrder, index) => {
            count += 1
            // console.log(count)
            return (
                <Col span={8}  key={`${splitOrder[0].id}-${index}`}>
                    <List
                        key={`${splitOrder[0].id}-${count}`}
                        header={<div style={{display: "flex"}}>Box {count}
                            <br />
                            <Button
                                type="danger"
                                ghost
                                style={{marginLeft: "20px"}}
                                onClick={() => this.removeAllItemsInBox(index)}
                                size="small"
                            >
                                Remove All Box Items
                            </Button>
                        
                         </div>}
                        bordered
                        dataSource={splitOrder}
                        renderItem={item => (
                            <div style={{backgroundColor: item.quantity !== 0 && "#defce6", borderBottom: "1px solid gray"}}>
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image} shape="square" size={32} />} 
                                        title={<span style={{fontSize: "10px"}}>{productDict[item.id]}</span>}
                                        description={
                                            <Button
                                                type="danger"
                                                ghost
                                                onClick={() => this.changeItemQuantity(0, index, item.id)}
                                                size="small"
                                            >
                                                Remove
                                            </Button>
                                        }
                                    />
                                    <div style={{textAlign: "right"}}>
                                        <InputNumber 
                                            style={{textAlign: "right"}} 
                                            min={0}  
                                            size="large"
                                            defaultValue={item.quantity}
                                            value={item.quantity}
                                            onChange={(value) => this.changeItemQuantity(value, index, item.id)}
                                        />

                                </div>
                            </List.Item>
                            </div>
                        )}
                    />
                        <br />
                </Col>
            )
        })
        
    }

    renderOverUnder(getDisabledStatus) {
        let items = JSON.parse(JSON.stringify(this.state.splitOrders[0]));
        let productDict = {}
        productArray.map(function(productObject) {
            if (productObject.value) {
                productDict[productObject.value] = productObject.text
            }
            return productObject
        })

        let quantityUsedDict = {}

        items.map(function(itemObject) {
            quantityUsedDict[itemObject.id] =0
            return itemObject
        })
        
        this.state.splitOrders.map(function(splitOrderItems) {
            splitOrderItems.map(function(itemObject) {
                quantityUsedDict[itemObject.id] += itemObject.quantity
                return itemObject
            })
            return splitOrderItems
        })

        let ifShouldBeDisabled = false
        items.map((itemObject) => {
            if (quantityUsedDict[itemObject.id] !== this.state.originalSplitOrder[itemObject.id]) {
                ifShouldBeDisabled = true
            }
            return itemObject
        })

        if (getDisabledStatus) {
            return ifShouldBeDisabled
        }
        

        return items.map((itemObject) => {
            return (
                <div style={{maxWidth: "100px", margin: "10px", dislay: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} key={itemObject.id}>
                    <Badge count={ quantityUsedDict[itemObject.id] - this.state.originalSplitOrder[itemObject.id]}  status={quantityUsedDict[itemObject.id] === this.state.originalSplitOrder[itemObject.id] && "success"} >
                        <Avatar src={itemObject.image} shape="square" size={64} />
                    </Badge>
                    <p style={{fontSize: "9px", textAlign: "center"}}>{productDict[itemObject.id]}</p>
                </div>
            )
        })

        


    }
    render() {
        return (
            <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
                <Alert
                    message="Warning"
                    description="You are about to split a users's order up into smaller parts. Please only do this if this order CANNOT fit into our largest box (currently the 12 verical bed box). The original order will be cancelled and 2 -3 new orders will be created with the products you have specified. The user will get tracking informatino on the new orders and will be able to see them in the profile order history.  "
                    type="warning"
                />
                <br />

                <Spin spinning={this.props.formLoading}>
                    <Divider>Over Under Products Details</Divider>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {this.renderOverUnder()}
                    </div>
                    <Divider>Order Details</Divider>
                    <Row gutter={12}>
                        {this.renderSplitOrders()}
                    </Row>
                    <br />
                    {
                        this.renderOverUnder(true) && (
                            <Alert
                            message="Your Count Is Off - Check Above"
                            type="error"
                        />
                        )
                    }
                    <br />
                    <Button
                        loading={this.props.formLoading}
                        htmlType="submit"
                        block
                        disabled={this.renderOverUnder(true)}
                        onClick={() => this.splitOrderAccept()}
                        size="large"
                        type="primary"
                    >
                        Split Order Up Into Smaller Orders
                    </Button>
                </Spin>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        formLoading: state.formStatus.form_loading
    };
}

export default connect(
    mapStateToProps,
    { createSplitOrders }
)(SplitOrderView);
