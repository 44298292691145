// Authentication Types
export const FETCH_LOGGED_IN_USER_SUCCESS = "FETCH_LOGGED_IN_USER_SUCCESS";
export const FETCH_LOGGED_IN_USER_FAILURE_MESSAGE = "FETCH_LOGGED_IN_USER_FAIL";
export const TOGGLE_LOGGED_IN_USER_LOADING = "TOGGLE_LOGGED_IN_USER_LOADING";

// Global Search Types
export const FETCH_GLOBAL_SEARCH_RESULTS = "FETCH_GLOBAL_SEARCH_RESULTS";

// Orders TYpes
export const FETCH_ALL_USER_ORDER_HISTORY_SUCCESS =
  "FETCH_ALL_USER_ORDER_HISTORY_SUCCESS";
export const FETCH_ALL_REPLACEMENT_BOXES_SUCCESS =
  "FETCH_ALL_REPLACEMENT_BOXES_SUCCESS";
export const FETCH_ALL_MARKETING_DATA_SUCCESS =
  "FETCH_ALL_MARKETING_DATA_SUCCESS";
export const FETCH_ALL_MIXPANEL_DATA_SUCCESS =
  "FETCH_ALL_MIXPANEL_DATA_SUCCESS";
export const FETCH_USER_ORDER_DETAILS = "FETCH_USER_ORDER_DETAILS";
export const RESET_USER_ORDER_DETAILS = "RESET_USER_ORDER_DETAILS";
export const FETCH_ALL_USER_ORDER_HISTORY_LOADING =
  "FETCH_ALL_USER_ORDER_HISTORY_LOADING";
export const SET_USER_ORDER_DETAILS_LOADING = "SET_USER_ORDER_DETAILS_LOADING";

// Reviews & Discounts Types
export const FETCH_ALL_QUESTIONS_AND_REVIEWS_SUCCESS =
  "FETCH_ALL_QUESTIONS_AND_REVIEWS_SUCCESS";
export const FETCH_ALL_QUESTIONS_AND_REVIEWS_LOADING =
  "FETCH_ALL_QUESTIONS_AND_REVIEWS_LOADING";
export const FETCH_ALL_QUESTIONS_AND_REVIEWS_SOFT_LOADING =
  "FETCH_ALL_QUESTIONS_AND_REVIEWS_SOFT_LOADING";

// Referral Types
export const FETCH_ALL_REFERRED_CARTS_SUCCESS =
  "FETCH_ALL_REFERRED_CARTS_SUCCESS";
export const FETCH_REFERRAL_STORE_DETAILS = "FETCH_REFERRAL_STORE_DETAILS";
export const RESET_REFERRAL_STORE_DETAILS = "RESET_REFERRAL_STORE_DETAILS";

// Subscription Tyeps
export const FETCH_ALL_SUBSCRIPTIONS_SUCCESS =
  "FETCH_ALL_SUBSCRIPTIONS_SUCCESS";
export const FETCH_ALL_UPCOMING_SUBSCRIPTION_CHARGES_SUCCESS =
  "FETCH_ALL_UPCOMING_SUBSCRIPTION_CHARGES_SUCCESS";
export const FETCH_ALL_SUBSCRIPTIONS_LOADING =
  "FETCH_ALL_SUBSCRIPTIONS_LOADING";

// Customer Tyupes
export const FETCH_ALL_CUSTOMERS_SUCCESS = "FETCH_ALL_CUSTOMERS_SUCCESS";
export const FETCH_ALL_CUSTOMERS_LOADING = "FETCH_ALL_CUSTOMERS_LOADING";
export const FETCH_CUSTOMER_DETAILS = "FETCH_CUSTOMER_DETAILS";
export const FETCH_CUSTOMER_ADDRESSES_AND_PAYMENT_METHODS =
"FETCH_CUSTOMER_ADDRESSES_AND_PAYMENT_METHODS";
export const RESET_CUSTOMER_DETAILS = "RESET_CUSTOMER_DETAILS";

export const FETCH_ALL_ADDRESSES_SUCCESS = "FETCH_ALL_ADDRESSES_SUCCESS";

// Fulfillment Types
export const FETCH_SHIPMENT_DETAILS = "FETCH_SHIPMENT_DETAILS";
export const RESET_SHIPMENT_DETAILS = "RESET_SHIPMENT_DETAILS";
export const FETCH_ALL_SHIPMENTS_SUCCESS = "FETCH_ALL_SHIPMENTS_SUCCESS";

// Home Page Version Types
export const FETCH_ALL_HOME_PAGE_VERSIONS_SUCCESS =
  "FETCH_ALL_HOME_PAGE_VERSIONS_SUCCESS";
export const FETCH_ALL_HOME_PAGE_VERSIONS_LOADING =
  "FETCH_ALL_HOME_PAGE_VERSIONS_LOADING";
export const FETCH_ALL_HOME_PAGE_VERSIONS_SOFT_LOADING =
  "FETCH_ALL_HOME_PAGE_VERSIONS_SOFT_LOADING";

// Discount Types
export const FETCH_ALL_DISCOUNTS_SUCCESS = "FETCH_ALL_DISCOUNTS_SUCCESS";
export const FETCH_ALL_DISCOUNTS_LOADING = "FETCH_ALL_DISCOUNTS_LOADING";
export const FETCH_ALL_DISCOUNTS_SOFT_LOADING =
  "FETCH_ALL_DISCOUNTS_SOFT_LOADING";

// Form Status Types
export const FORM_FAILURE = "FORM_FAILURE";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const FORM_CLEAR = "FORM_CLEAR";
export const FORM_SUBMITTING = "FORM_SUBMITTING";
export const CLEAR_FORM_MESSAGE = "CLEAR_FORM_MESSAGE";
// MIsc
export const SET_COPIED_VALUES =
"SET_COPIED_VALUES";
export const RESET_COPIED_VALUES =
"RESET_COPIED_VALUES";

// User Admin Stats
export const FETCH_ALL_ADMIN_USERS_SUCCESS = "FETCH_ALL_ADMIN_USERS_SUCCESS";
export const FETCH_ALL_ADMIN_USERS_LOADING = "FETCH_ALL_ADMIN_USERS_LOADING";
export const FETCH_ALL_ADMIN_USERS_SOFT_LOADING =
  "FETCH_ALL_ADMIN_USERS_SOFT_LOADING";

// Promotion Types
export const FETCH_ALL_PROMOTIONS_SUCCESS = "FETCH_ALL_PROMOTIONS_SUCCESS";
export const FETCH_ALL_PROMOTIONS_LOADING = "FETCH_ALL_PROMOTIONS_LOADING";
export const FETCH_ALL_PROMOTIONS_SOFT_LOADING =
  "FETCH_ALL_PROMOTIONS_SOFT_LOADING";


// Inventory Types
export const FETCH_PRODUCTS_SOLD_SUCCESS = "FETCH_PRODUCTS_SOLD_SUCCESS"; 
export const FETCH_ACTUAL_INVENTORY_COUNTS = "FETCH_ACTUAL_INVENTORY_COUNTS"; 
export const FETCH_PRODUCT_INVENTORY = "FETCH_PRODUCT_INVENTORY"; 

// Ffulfillment Orders Types
export const FETCH_ALL_FULFILLMENT_ORDERS_SUCCESS = "FETCH_ALL_FULFILLMENT_ORDERS_SUCCESS"; 


// Redirect Link Types
export const FETCH_ALL_REDIRECT_LINKS_SUCCESS =
  "FETCH_ALL_REDIRECT_LINKS_SUCCESS";
export const FETCH_ALL_REDIRECT_LINKS_LOADING =
  "FETCH_ALL_REDIRECT_LINKS_LOADING";
export const FETCH_ALL_REDIRECT_LINKS_SOFT_LOADING =
  "FETCH_ALL_REDIRECT_LINKS_SOFT_LOADING";

