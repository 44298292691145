import * as types from "../actions/types";

const INITIAL_STATE = {
  fulfillment_orders: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_FULFILLMENT_ORDERS_SUCCESS:
      return { ...state, fulfillment_orders: action.payload };
    default:
      return state;
  }
}
