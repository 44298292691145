import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import DownloadCSV from "../Common/DownloadCSV";
import { renderOrderStatus, renderVDLShipmentStatus, convertBooleanToTag } from "../../helpers";
import { Select, Button } from 'antd';


const { Option } = Select;

var dateFormat = require("dateformat");

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }
    componentDidMount() {
        var data = this.reactTable.getResolvedState().sortedData;
        this.setState({ data: data });
    }

    render() {
        return (
            <div className="table-container ">
            <div style={{margin: "10px", textAlign: "right"}}>
            <Link to={"/portal/orders/create-sample-shipment"}>
            <Button ghost icon="file-add"  type="primary" style={{marginRight: "5px"}}>Create Free Sample</Button>
            </Link>
                <DownloadCSV itemName={"Orders"} data={this.state.data} />
            </div>
                <ReactTable
                    data={this.props.orders}
                    noDataText="No Orders Found"
                    ref={r => (this.reactTable = r)}
                    filterable
                    columns={[
                        {
                            Header: "Order",
                            columns: [
                                {
                                    Header: "Order ID",
                                    id: "order_id",
                                    fixed: 'right',
    
                                    width: 120,
                                    accessor: d => d.order.id,
                                   
                                    Cell: row => (
                                        <Link to={"/portal/orders/" + row.value}>{row.value}</Link>
                                    )
                                },

                                {
                                    Header: "Status",
                                    id: "status",
                                    width: 130,
                                    accessor: d => d.order.status,
                                    Cell: row => <div>{renderOrderStatus(row.value)}</div>,
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "successful") {
                                            return row["status"] === "successful";
                                        } else if (filter.value === "refunded") {
                                            return row["status"] === "refunded";
                                        } else if (filter.value === "partially_refunded") {
                                            return row["status"] === "partially_refunded";
                                        } else if (filter.value === "replacement_box") {
                                            return row["status"] === "replacement_box";
                                        } else if (filter.value === "free_sample") {
                                            return row["status"] === "free_sample";
                                        }
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <Select size="small" value={filter ? filter.value : "all"} style={{ width: "100%" }} onChange={onChange}>
                                            <Option value="all">Show All</Option>
                                            <Option value="successful">Successful</Option>
                                            <Option value="refunded">Refunded</Option>
                                            <Option value="partially_refunded">Partial Refund</Option>                                            
                                            <Option value="free_sample">Free Sample</Option>                                            
                                            <Option value="replacement_box">Replacement Box</Option>
                                        </Select>

                                    )
                                },
                                {
                                    Header: "Date Charged",
                                    id: "date_charged",
                                    accessor: d => d.order.date_charged,
                                    Cell: row => (
                                        dateFormat(row.value, " mmmm dS yyyy, h:MM:ss TT")
                                    ),
                                    width: 240,
                                    sortMethod: (a, b) => {
                                        var d1 = new Date(a);
                                        var d2 = new Date(b);
                                        return d1 > d2 ? 1 : -1;
                                    }
                                },
                                {
                                    Header: "Email",
                                    id: "user_email",
                                    width: 140,
                                    accessor: d => d.user,
                                    filterMethod: (filter, row) => {
                                        // console.log(row.user_email);
                                        var email = row.user_email.email;
                                        return email
                                            .toLowerCase()
                                            .includes(filter.value.toLowerCase());
                                    },
                                    Cell: row => (
                                        <Link to={`/portal/customers/${row.value.id}`}>
                                            {row.value.email}
                                        </Link>
                                    )
                                },
                                {
                                    Header: "Shipping Address",
                                    id: "order_address",
                                    width: 160,
                                    accessor: d => d.address,
                                    filterMethod: (filter, row) => {
                                        // console.log(row.user_email);
                                        if (!row.order_address.full_name) {
                                            return false
                                        }
                                        var shippingAddressFilter = (
                                            row.order_address.full_name.toLowerCase().includes(filter.value.toLowerCase()) ||
                                            row.order_address.street.toLowerCase().includes(filter.value.toLowerCase()) ||
                                            row.order_address.state.toLowerCase().includes(filter.value.toLowerCase()) ||
                                            row.order_address.zip_code.toLowerCase().includes(filter.value.toLowerCase()) ||
                                            row.order_address.city.toLowerCase().includes(filter.value.toLowerCase())

                                        )
                                        return shippingAddressFilter
                                    },
                                    Cell: row => (
                                        <p>
                                            {row.value.full_name} <br />
                                            {row.value.street}<br />
                                            {row.value.city} {row.value.state},  {row.value.zip_code}
                                        </p>
                                    )
                                },
                                {
                                    Header: "Tracking",
                                    id: "tracking_link",
                                    width: 160,
                                    accessor: d => d.address,
                                    Cell: row => (
                                        <a href={row.value.tracking_page_link} target="_blank" rel="noopener noreferrer">
                                            {row.value.tracking_page_link}
                                        </a>
                                    )
                                },
                                {
                                    Header: "GMA / Shipping ID",
                                    id: "shipping_order_id",
                                    fixed: 'right',
    
                                    width: 120,
                                    accessor: d => d.order,
                                   
                                    Cell: row => (
                                        <Link to={"/portal/orders/" + row.value.id}>{row.value.shipping_order_id}</Link>
                                    )
                                },
                                {
                                    Header: "Auto Charge",
                                    maxWidth: 100,
                                    id: "auto_generated_by_subscription",
                                    Cell: row => convertBooleanToTag(row.value),
                                    accessor: d => d.cart.auto_generated_by_subscription.toString().toUpperCase(),
                                        
                                            
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "true") {
                                            return row["auto_generated_by_subscription"] === "TRUE";
                                        }
                                        return row["auto_generated_by_subscription"] === "FALSE";
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <Select size="small" value={filter ? filter.value : "all"}  style={{width: "100%"}} onChange={onChange}>
                                            <Option value="all">Show All</Option>
                                            <Option value="true">True</Option>
                                            <Option value="false">False</Option>
                                        </Select>
                                    )
                                },


                            ]
                        },
                        {
                            Header: "Fulfillment",
                            columns: [
                                {
                                    Header: "Shipment Status",
                                    id: "shipment_status",
                                    width: 170,
                                    accessor: d => d.order,
                                    Cell: row => <div>{renderVDLShipmentStatus(row.value.shipment_status, row.value.vanguard_shipment)}</div>,
                                    filterMethod: (filter, row) => {
                                        
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "shipped") {
                                            return row["shipment_status"]["shipment_status"] === "shipped";
                                        } else if (filter.value === "awaiting_shipment") {
                                            return row["shipment_status"]["shipment_status"] === "awaiting_shipment" && row["shipment_status"]["vanguard_shipment"] !== "False";
                                        } else if (filter.value === "on_hold") {
                                            return row["shipment_status"]["shipment_status"] === "on_hold";
                                        } else if (filter.value === "cancelled") {
                                            return row["shipment_status"]["shipment_status"] === "cancelled";
                                        }  else if (filter.value === "delayed") {
                                            return row["shipment_status"]["shipment_status"] === "delayed";
                                        }else if (filter.value === "gift_card") {
                                            return  row["shipment_status"]["shipment_status"] === "gift_card";
                                         } else if (filter.value === "na") {
                                           return  row["shipment_status"]["vanguard_shipment"] === "False";
                                        }
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <Select size="small" value={filter ? filter.value : "all"} style={{width: "100%"}} onChange={onChange}>
                                            <Option value="all">Show All</Option>
                                            <Option value="shipped">Shipped</Option>
                                            <Option value="awaiting_shipment">Awaiting Shipment</Option>
                                            <Option value="gift_card">Gift Card</Option>
                                            <Option value="on_hold">On Hold</Option>
                                            <Option value="cancelled">Cancelled</Option>
                                            <Option value="delayed">Delayed</Option>
                                            <Option value="na">N/A</Option>
                                        </Select>

                                    )
                                },
                                {
                                    Header: "Date Pick Packed",
                                    id: "date_pick_packed",
                                    accessor: d => d.order.date_pick_packed,
                                    Cell: row => (
                                        <p>
                                            {row.value &&
                                                dateFormat(row.value, " mmmm dS yyyy, h:MM:ss TT")}
                                        </p>
                                    ),
                                    width: 240,
                                    sortMethod: (a, b) => {
                                        var d1 = new Date(a);
                                        var d2 = new Date(b);
                                        return d1 > d2 ? 1 : -1;
                                    }
                                },
                
               
                  
                      
                            ]
                        },
             
                        {
                            Header: "Cart",
                            columns: [
                                {
                                    Header: "Total",
                                    id: "total",
                                    maxWidth: 70,
                                    accessor: d => "$" + d.cart.total
                                },
                                {
                                    Header: "Refunded Amount",
                                    id: "refund_amount",
                                    maxWidth: 70,
                                    accessor: d => d.cart.refund_amount,
                                    Cell: row => row.value ? "$" + row.value : "$0.00"
                                },
                                {
                                    Header: "Has Refund Reason",
                                    maxWidth: 130,
                                    id: "has_refund_reason",
                                    Cell: row => convertBooleanToTag(row.value),
                                    accessor: d => d.order.has_refund_reason.toString().toUpperCase(),
                                        
                                            
                                    filterMethod: (filter, row) => {
                                        if (filter.value === "all") {
                                            return true;
                                        }
                                        if (filter.value === "true") {
                                            return row["has_refund_reason"] === "TRUE";
                                        }
                                        return row["has_refund_reason"] === "FALSE";
                                    },
                                    Filter: ({ filter, onChange }) => (
                                        <Select size="small" value={filter ? filter.value : "all"}  style={{width: "100%"}} onChange={onChange}>
                                            <Option value="all">Show All</Option>
                                            <Option value="true">True</Option>
                                            <Option value="false">False</Option>
                                        </Select>
                                    )
                                },
                            ]
                        },
                      
            
                   
                ]}
                    
                />
            </div>
        );
    }
}

export default Table;
