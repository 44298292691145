import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";


axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Sets producrts sold as success into the system
export function setAllFulfillmentOrdersSuccess(json) {
  return {
    type: types.FETCH_ALL_FULFILLMENT_ORDERS_SUCCESS,
    payload: json
  };
}

// Retrives All products sold in the lst 30 days
export function fetchAllFulfillmentOrders() {
  return function(dispatch) {

    return axios({
      url: `${BASE_API_URL}get-all-fulfillment-orders`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllFulfillmentOrdersSuccess(response.data.fulfillment_orders));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the fulfillment orders"
        );
      });
  };
}
