import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import DownloadCSV from "../Common/DownloadCSV";

var dateFormat = require("dateformat");

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this.reactTable.getResolvedState().sortedData;
    this.setState({ data: data });
  }

  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right", height: "31px" }}>
          <DownloadCSV itemName={"Customers"} data={this.state.data} />
        </div>
        <ReactTable
          data={this.props.customers}
          ref={r => (this.reactTable = r)}
          noDataText="No Customers Found"
          filterable
          columns={[
            {
              Header: "ID",
              id: "order_id",
              width: 120,
              accessor: d => d.random_user_id,
              Cell: row => (
                <Link to={"/portal/customers/" + row.value}>{row.value}</Link>
              )
            },
            {
              Header: "Email",
              id: "email",
              filterMethod: (filter, row) => {
                // console.log(row.user_email);
                var email = row.email;
                return email.toLowerCase().includes(filter.value.toLowerCase());
              },
              accessor: d => d.email
            },
            {
              Header: "First Name",
              id: "first_name",
              accessor: d => d.first_name
            },
            {
              Header: "Last Name",
              id: "last_name",
              accessor: d => d.last_name
            },
            {
              Header: "Date Joined",
              id: "date_joined",
              accessor: d => d.date_joined,
              Cell: row => dateFormat(row.value, " mmmm dS yyyy"),
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            },
            {
              Header: "Gender",
              id: "gender",
              accessor: d => d.gender
            },
            {
              Header: "Phone Number",
              id: "phone_number",
              accessor: d => d.phone_number
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
