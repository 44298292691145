import React from "react";
import { connect } from "react-redux";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer, Row, List, Card } from "antd";
import DescriptionItem from "../../components/Common/DescriptionItem";
import { getScreenWidth, convertBooleanToTag } from "../../helpers";
import moment from "moment"


class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: null,
    };
    this.closeModal = this.closeModal.bind(this);
  }

    onClose() {
    this.setState({
        selectedProduct: null
    });
    }

    //   Go back to the main Reviews page
    closeModal() {
    history.push("/portal/products");
    this.setState({
        selectedProduct: null
    });
    }

    //   Renders out the product iamges
    renderProducts(category) {
        var products = [];
        var productInventory = this.props.productInventory
        for (var key in productInventory) {
        
            if (productInventory.hasOwnProperty(key)) {
                if (productInventory[key].category === category) {
                    products.push(productInventory[key] );
                }
                if (!category) {
                    if (productInventory[key].discontinued && productInventory[key].id !== "dryer_sheets") {
                        products.push(productInventory[key] );
                    }
                }
            }
        }
        products = products.sort((a, b) => (a.id > b.id) ? 1 : -1)
        products = products.filter(function(productObject) {
            return !productObject.id.includes("sanitizer")
        })

        return (
            <List
                grid={{ gutter: 15, column: 4 }}
                dataSource={products}
                renderItem={item => (
                <List.Item className="product-listing">
                    <Card
                    style={{}}
                    cover={
                        <div style={{
                        background: "#fff",
                        minHeight: "150px"
                        }}>
                            <img
                            alt="example"
                            src={
                                item.cart_image.url
                            }
                            />
                        </div>
                    }
                    title={
                        item.marketing_name
                    }
                    >
                    <DescriptionItem
                        title="Sku"
                        content={item.dear_sku_display}
                    />
                    <DescriptionItem
                        title="Price"
                        content={"$" + item.price}
                    />
                    <DescriptionItem
                        title="Membership Price"
                        content={"$" + item.membership_price}
                    />
                    <DescriptionItem
                        title="Size"
                        content={item.size}
                    />
                    <DescriptionItem
                        title="Can Be Subscribed To"
                        content={convertBooleanToTag(item.subscribable)}
                    />
                    <DescriptionItem
                        title="Out Of Stock"
                        content={convertBooleanToTag(item.out_of_stock)}
                    />
                    {
                        item.out_of_stock && (
                            <DescriptionItem
                                title="Restock Date"
                                content={moment(item.restock_date).format("MM/DD/YYYY")}
                            />
                        )
                    }
                    {
                        item.related_product && (
                            <DescriptionItem
                                title="Related Product"
                                content={this.props.productInventory[item.related_product].marketing_name}
                            />
                        )
                    }
                    {
                        item.related_refill && (
                            <DescriptionItem
                                title="Related Refill"
                                content={this.props.productInventory[item.related_refill].marketing_name}
                            />
                        )
                    }
                    <DescriptionItem
                        title="ID"
                        content={item.id}
                    />
                    </Card>
                </List.Item>
                )}
            />
        )
    }



  render() {
    return (
      <div className="page-container">
        <div className="table-view">
          {this.props.productInventory  ? (
            <div>
                <Divider orientation="left">Empty Glass Bottles</Divider>
                <Row className="cart-items">
                    {this.renderProducts("unfilled_glass")}
                </Row>
                <Divider orientation="left">Refills</Divider>
                <Row className="cart-items">
                    {this.renderProducts("refills")}
                </Row>
                <Divider orientation="left">Essentials</Divider>
                <Row className="cart-items">
                    {this.renderProducts("essentials")}
                </Row>
                
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title={"Title"}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedProduct ? true : false}
        >
          <h1>Product</h1>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productInventory: state.inventory.product_inventory
  };
}

export default connect(
  mapStateToProps,
  {  }
)(Products);
