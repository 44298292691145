import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";
import { connect } from "react-redux";

// Common Containers
import LoggedInUserContainer from "./containers/Authentication/LoggedInUserContainer";
import PortalContainer from "./containers/Common/PortalContainer";
import Orders from "./containers/Pages/Orders";
import B2BOrders from "./containers/Pages/B2BOrders";
import Subscriptions from "./containers/Pages/Subscriptions";
import Customers from "./containers/Pages/Customers";
import Addresses from "./containers/Pages/Addresses";
import Discounts from "./containers/Pages/Discounts";
import AdminUsers from "./containers/Pages/AdminUsers";
import Questions from "./containers/Pages/Questions";
import Reviews from "./containers/Pages/Reviews";
import Fulfillment from "./containers/Pages/Fulfillment";

import HomePageVersions from "./containers/Pages/HomePageVersions";
import RedirectLinks from "./containers/Pages/RedirectLinks";
import Promotions from "./containers/Pages/Promotions";
import Products from "./containers/Pages/Products";

// Portal Views
import AuthenticationContainer from "./containers/Authentication";

// Idel Timer
import UserIdler from "./components/Common/UserIdler";

class Routes extends React.Component {
  render() {
    return (
      <Router history={history} >
        <LoggedInUserContainer>
          <PortalContainer>
            {this.props.loggedInUser && <UserIdler />}
            <Switch>
              <Route path="/login" component={AuthenticationContainer} />
              <Route path="/portal/orders" component={Orders} />
              <Route path="/portal/subscriptions" component={Subscriptions} />
              <Route path="/portal/products" component={Products} />
                            <Route path="/portal/f" component={Fulfillment} />
              <Route path="/portal/customers" component={Customers} />
              <Route path="/portal/addresses" component={Addresses} />
              {this.props.loggedInUser &&
                !this.props.loggedInUser.customer_service_user && (
                  <Route path="/portal/discounts" component={Discounts} />
                )}
              {this.props.loggedInUser &&  (
                  <Route path="/portal/questions" component={Questions} />
                )}
              {this.props.loggedInUser &&  (
                  <Route path="/portal/reviews" component={Reviews} />
                )}
              {this.props.loggedInUser &&
                !this.props.loggedInUser.customer_service_user && (
                  <Route
                    path="/portal/home-page-versions"
                    component={HomePageVersions}
                  />
                )}
              {this.props.loggedInUser &&
                !this.props.loggedInUser.customer_service_user && (
                  <Route
                    path="/portal/b2b-orders"
                    component={B2BOrders}
                  />
                )}
              {this.props.loggedInUser &&
                !this.props.loggedInUser.customer_service_user && (
                  <Route path="/portal/promotions" component={Promotions} />
                )}
              {this.props.loggedInUser &&
                this.props.loggedInUser.is_superuser && (
                  <Route
                    path="/portal/user-management"
                    component={AdminUsers}
                  />
                )}

              {this.props.loggedInUser &&
                !this.props.loggedInUser.customer_service_user && (
                  <Route
                    path="/portal/redirect-links"
                    component={RedirectLinks}
                  />
                )}
    
 
              <Redirect from="*" to="/portal/orders" />
            </Switch>
          </PortalContainer>
        </LoggedInUserContainer>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  null
)(Routes);
