import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the discounts into state
export function fetchAllQuestionsAndReviewsSucccess(data) {
  return { type: types.FETCH_ALL_QUESTIONS_AND_REVIEWS_SUCCESS, payload: data };
}

// Load the discounts into state
export function setAllQuestionsAndReviewsLoading() {
  return { type: types.FETCH_ALL_QUESTIONS_AND_REVIEWS_LOADING, payload: null };
}

// Load the discounts into state
export function setAllQuestionsAndReviewsSoftLoading() {
  return {
    type: types.FETCH_ALL_QUESTIONS_AND_REVIEWS_SOFT_LOADING,
    payload: null
  };
}

// retrievie all discounts
export function fetchAllQuestionsAndReviews(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllQuestionsAndReviewsLoading());
    } else {
      dispatch(setAllQuestionsAndReviewsSoftLoading());
    }
    return axios({
      url: `${BASE_API_URL}get-all-reviews-and-questions`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllQuestionsAndReviewsSucccess(response.data));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the questions and reviews"
        );
      });
  };
}

// Create a question
export function createQuestion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-question`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Question Created");
        dispatch(reset("QuestionForm"));
        dispatch(fetchAllQuestionsAndReviews(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/questions/${response.data.question_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this question"
          );
        }
      });
  };
}

// Edit a speific discount
export function editQuestion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-question`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Question Saved");
        dispatch(reset("QuestionForm"));
        dispatch(fetchAllQuestionsAndReviews(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/questions/${data.question_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this question"
          );
        }
      });
  };
}

// Edit a sepecific review
export function editReview(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-review`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Review Saved");
        dispatch(fetchAllQuestionsAndReviews(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/reviews/${data.review_id}`);
      })
      .catch(function() {
        dispatch(setFormFailureMessage("Fail"));
        message.error("Hmm, seems to be an error editing this review");
      });
  };
}

// Delete review
export function deleteReview(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}delete-review`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Review Deleted");
        dispatch(fetchAllQuestionsAndReviews());
        dispatch(setFormSuccessMessage("Success"));
        history.push("/portal/reviews/");
      })
      .catch(function() {
        dispatch(setFormFailureMessage("Fail"));
        message.error("Hmm, seems to be an error deleting this review");
      });
  };
}

// Delete question
export function deleteQuestion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}delete-question`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Question Deleted");
        dispatch(fetchAllQuestionsAndReviews());
        dispatch(setFormSuccessMessage("Success"));
        history.push("/portal/questions/");
      })
      .catch(function() {
        dispatch(setFormFailureMessage("Fail"));
        message.error("Hmm, seems to be an error deleting this question");
      });
  };
}
