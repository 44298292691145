import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

class FulfillmentPerformanceChart extends React.Component {

  renderWorkerLines(workerArray) {
    return workerArray.map(function(workerObject) {
      return (
        <Line key={workerObject.lineKey} type="monotone" dataKey={workerObject.lineKey} stroke={workerObject.color} />
      )

    })
  }
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={400}>
        <LineChart
          data={this.props.data.data}
          margin={{
            right: 170,
            top: 30
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(0, 0, 0, 0.45)" />
          <XAxis dataKey="date" stroke="rgba(0, 0, 0, 0.45)" scale="point" />

          <YAxis orientation="left" stroke="rgba(0, 0, 0, 0.45)" />
          <Tooltip
            wrapperClassName={"my-tooltip"}
            wrapperStyle={{
              backgroundColor: "#fff",
              background: "#fff",
              color: "rgba(0, 0, 0, 0.45)",
              border: "0px",
              fontSize: "15px",
              boxShadow: "0 2px 8px #f0f1f2",
              opactity: 0.5,
              lineHeight: "20px",
              borderRadius: "3px"
            }}
          />
          <Legend />
          <Line type="monotone" dataKey="Orders_Recieved" stroke="#52c41a" />
         {this.renderWorkerLines(this.props.data.workerArray)}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default FulfillmentPerformanceChart;
