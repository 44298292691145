import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message, notification } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Sets All Of The User Order History to Redux
export function setAllUserOrderHistorySuccess(json) {
  return {
    type: types.FETCH_ALL_USER_ORDER_HISTORY_SUCCESS,
    payload: json
  };
}

export function setAllUserOrderHistoryLoading() {
  return {
    type: types.FETCH_ALL_USER_ORDER_HISTORY_LOADING,
    payload: null
  };
}

// Retrives All Of The User Order History
export function fetchAllUserOrderHistory(dontFullLoad) {
  return function(dispatch) {
    // For quick updates where you dont want to reset state
    if (!dontFullLoad) {
      dispatch(setAllUserOrderHistoryLoading());
    } else {
      message.loading("Loading in updated orders");
    }
    return axios({
      url: `${BASE_API_URL}get-all-user-orders/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllUserOrderHistorySuccess(response.data.orders));
      })
      .catch(function(error) {
        message.error("Hmmm, there seems to be an error retrieving the orders");
      });
  };
}

// Retrives All Of Mixpanel Data
export function fetchMixpanelDataSuccess(json) {
  return { type: types.FETCH_ALL_MIXPANEL_DATA_SUCCESS, payload: json };
}

// Retrives All Mixpanel Data
export function fetchMixpanelData() {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-mixpanel-data`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchMixpanelDataSuccess(response.data));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the mixpanel data"
        );
      });
  };
}

// Sets the marketing statists into redux state
export function fetchMarketingDataSuccess(json) {
  return { type: types.FETCH_ALL_MARKETING_DATA_SUCCESS, payload: json };
}

// Retrives All marketing information
export function fetchMarketingData() {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-marketing-data`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchMarketingDataSuccess(response.data.marketing_data));
      })
      .catch(function() {
        message.error(
          "Hmmm, there seems to be an error retrieving the marketing data"
        );
      });
  };
}

// Load the order details into the redux state
export function fetchUserOrderDetailsSucces(orderDetails) {
  return {
    type: types.FETCH_USER_ORDER_DETAILS,
    payload: orderDetails
  };
}

// Load the order details into the redux state
export function setUserOrderDetailsLoading() {
  return {
    type: types.SET_USER_ORDER_DETAILS_LOADING,
    payload: null
  };
}

// Retrives Specifc Data on a user order
export function fetchUserOrderDetails(orderID) {
  return function(dispatch) {
    dispatch(setUserOrderDetailsLoading());
    return axios({
      url: `${BASE_API_URL}get-user-order-details?order_id=${orderID}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchUserOrderDetailsSucces(response.data.order_details));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the order data"
        );
      });
  };
}

export function resetUserOrderDetailsDispatched() {
  return {
    type: types.RESET_USER_ORDER_DETAILS,
    payload: null
  };
}

// Reset user order details
export function resetUserOrderDetails() {
  return function(dispatch) {
    dispatch(resetUserOrderDetailsDispatched());
  };
}

// Refund Action
export function refundOrder(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}refund-order`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Order Has Been Refunded");
        dispatch(fetchUserOrderDetails(data.order_id));
        dispatch(setFormSuccessMessage());
        history.push("/portal/orders/" + data.order_id);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage());
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error refunding this order"
          );
        }
      });
  };
}

// Change Shipmnet Action
export function changeShipmentStatus(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}fulfillment/change-shipment-status/`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Shipment Status Changed");
        dispatch(fetchUserOrderDetails(data.order_id));
        dispatch(setFormSuccessMessage("Success"));
        history.push("/portal/orders/" + data.order_id);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error("There was an error changing this order status");
        }
      });
  };
}

// Create Replacement Box
export function createShipment(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-shipment`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Shipment Created");
        dispatch(reset("CreateSampleBox"));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/orders/${response.data.order_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this shipment"
          );
        }
      });
  };
}


// Edit and awaiting  roders Order
export function editOrder(data) {


  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-awaiting-order`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Awaiting Shipment Has Been Edited");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage());
        history.push("/portal/orders/" + data.transaction_id);

          // Determine how much the customer service rep should refund if the edit was 
          // Successful if they shoudl refund at all 
        if (parseFloat(data.cartTotal) > parseFloat(response.data.new_cart_total)) {
          notification.open({
            message: 'Refund Recommendation',
            placement: "topLeft",
            type: "info",
            duration: 100000,
            description:
              `The original cart total was $${data.cartTotal}. The new cart total with these changes are $${(response.data.new_cart_total).toFixed(2)}. We reccomend you refund $${(parseFloat(data.cartTotal) - parseFloat(response.data.new_cart_total)).toFixed(2)}`,
            onClick: () => {
              console.log('Notification Clicked!');
            },
          });

        }

      })
      .catch(function(error) {
        dispatch(setFormFailureMessage());
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error editing this order"
          );
        }
      });
  };
}

// Edit and awaiting  roders Order
export function createSplitOrders(data) {


  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-split-orders`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Order Has Been Split");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage());
        history.push("/portal/orders/" + data.transaction_id);


      })
      .catch(function(error) {
        dispatch(setFormFailureMessage());
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error splitting this order"
          );
        }
      });
  };
}

export function editReplacementBoxReason(data) {

return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-replacement-box-reason`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Replacment Box Reason Has Been Edited");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage());
        history.push("/portal/orders/" + data.transaction_id);

      })
      .catch(function(error) {
        dispatch(setFormFailureMessage());
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error editing this order"
          );
        }
      });
  };
}

export function editRefundReason(data) {

return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-refund-reason`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Refund Has Been Edited");
        dispatch(fetchUserOrderDetails(data.transaction_id));
        dispatch(setFormSuccessMessage());
        history.push("/portal/orders/" + data.transaction_id);

      })
      .catch(function(error) {
        dispatch(setFormFailureMessage());
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmmm, there seems to be an error editing this order"
          );
        }
      });
  };
}

// Sets All Of The Replacement Boxes to Redux
export function fetchAllReplacementBoxesSuccess(json) {
  return {
    type: types.FETCH_ALL_REPLACEMENT_BOXES_SUCCESS,
    payload: json
  };
}

// Retrives All Of The REplacement Boxes
export function fetchAllReplacementBoxes() {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}get-all-replacement-boxes/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllReplacementBoxesSuccess(response.data.replacement_boxes));
      })
      .catch(function(error) {
        message.error("Hmmm, there seems to be an error retrieving the replacement boxes");
      });
  };
}

// Retrives All Of The User Order History
export function searchAllUserOrderHistory(params) {
  return function(dispatch) {
    // For quick updates where you dont want to reset state
    dispatch(setAllUserOrderHistoryLoading());
    return axios({
      url: `${BASE_API_URL}search-for-all-user-orders/${params}`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllUserOrderHistorySuccess(response.data.orders));
      })
      .catch(function(error) {
        dispatch(setAllUserOrderHistoryLoading());
        message.error("Hmmm, there seems to be an error retrieving the orders");
      });
  };
}