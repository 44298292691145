import React from "react";
import DataPoint from "../Common/DataPoint";

class DasboardRow extends React.Component {
  getQuestionTypes(displayType) {
    var count = 0;
    this.props.questions.map(function(question) {
      if (question.display_question === displayType) {
        count = count + 1;
      }
      return question;
    });
    return count;
  }
  render() {
    return (
      <div className="dashboard-row">
        <DataPoint
          data={this.props.questions.length}
          label={"Questions"}
          positive={false}
        />
        <DataPoint
          data={this.getQuestionTypes(true)}
          label={"Answered"}
          positive={false}
        />
        <DataPoint
          data={this.getQuestionTypes(false)}
          label={"Unanswered"}
          positive={false}
        />
      </div>
    );
  }
}

export default DasboardRow;
