import React from "react";
import {  Button, Form, Spin, Divider } from "antd";
import { connect } from "react-redux";
import DescriptionItem from "../Common/DescriptionItem";
import {
    textAreaField,
    datePickerField,
    selectField,
} from "../Form/FieldRenders";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { editRefundReason } from "../../actions/actionsOrders";
import {
    refundReasonTypes,
    buildOutRefundReason,
    productGroupArray,
    getRefundType,
    getNewProductArray,
    getRefundItemSelectionDetails
} from "../../helpers";


const validate = values => {
    const errors = {};

    if (!values.refund_type) {
        errors.refund_type = "Required"
    }
    if (!values.date_refunded) {
        errors.date_refunded = "Required"
    }
    if (!values.additional_refund_details) {
        errors.additional_refund_details = "Required"
    }
    if (!values.items || values.items.length === 0) {
        errors.items = "Required"
    }
    if (!values.other_reason) {
        errors.other_reason = "Required"
    }
    return errors
};


class EditRefundReason extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }
    onFormSubmit(props) {
        props["transaction_id"] = this.props.orderDetail.transaction_id;

        var refund_reason = buildOutRefundReason(
            this.props.formValues.refund_type,
            this.props.formValues.additional_refund_details, 
            this.props.formValues.items, 
            this.props.formValues.other_reason,
            this.props.formValues.date_refunded,
            this.props.loggedInUser,
            false
        )
        var refund_information =  buildOutRefundReason(
            this.props.formValues.refund_type,
            this.props.formValues.additional_refund_details, 
            this.props.formValues.items, 
            this.props.formValues.other_reason,
            new Date(this.props.formValues.date_refunded),
            this.props.loggedInUser,
            true
        )

        props["refund_reason"] = refund_reason;
        props["refund_information"] = refund_information;
        
        this.props.editRefundReason(props);
    }


    componentWillReceiveProps(nextProps) {
        // Change the replacemebnt reason details if the main reason is not the same
        if (this.props.formValues) {
            if (this.props.formValues.refund_type !== nextProps.formValues.refund_type) {
                this.props.change("additional_refund_details", "")
                this.props.change("items", [])
            }
        }
    }



    render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
        <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        
            <br />

            <Spin spinning={this.props.formLoading}>
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
                <DescriptionItem
                    title="Current Refund Reason"
                    content={this.props.orderDetail.cart.refund_reason}
                />
                <Divider>Refund Details</Divider>
                <Field
                    label={"Refund Date"}
                    name="date_refunded"
                    size={"large"}
                    overRideTouched={true}
                    required={true}
                    component={datePickerField}
                />
                <p>If there are two refund dates, just select the most recent.</p>
                {this.props.formValues &&
                    this.props.formValues.date_refunded && (
                    <Field
                        label={"Refund Type"}
                        name="refund_type"
                        size={"large"}
                        overRideTouched={true}
                        required={true}
                        options={refundReasonTypes}
                        component={selectField}
                    />
                )}
            {this.props.formValues &&
                this.props.formValues.refund_type && 
                getRefundType(this.props.formValues.refund_type).subOptions && (
                    <Field
                        label={getRefundType(this.props.formValues.refund_type).subOptionsLabel}
                        name="additional_refund_details"
                        size={"large"}
                        overRideTouched={true}
                        required={true}
                        options={getRefundType(this.props.formValues.refund_type).subOptions}
                        component={selectField}
                    />
                )}
            {this.props.formValues &&
                this.props.formValues.refund_type && 
                (this.props.formValues.additional_refund_details || this.props.formValues.refund_type === "product_isses"  )&&
                    getRefundItemSelectionDetails(this.props.formValues.refund_type, this.props.formValues.additional_refund_details, false) && (
                    <Field
                        label={getRefundItemSelectionDetails(this.props.formValues.refund_type, this.props.formValues.additional_refund_details, true)}
                        name="items"
                        size={"large"}
                        required={true}
                        overRideTouched={true}
                        optionGroups={productGroupArray}
                        mode={"multiple"}
                        options={getNewProductArray(this.props.formValues.refund_type)}
                        component={selectField}
                    />
                )}
                {this.props.formValues &&
                    (this.props.formValues.refund_type === "other" || 
                    this.props.formValues.additional_refund_details === "other") && (
                        <Field
                            label={"Other Reason"}
                            name="other_reason"
                            required={true}
                            hideErrorLabel={false}
                            overRideTouched={true}
                            size="large"
                            rows={8}
                            component={textAreaField}
                        />
                    )}
                    <br />
                {
                    this.props.formValues && this.props.formValues.refund_type && (
                    <p><b>New Refund Reason: </b> {buildOutRefundReason(
                        this.props.formValues.refund_type,
                        this.props.formValues.additional_refund_details, 
                        this.props.formValues.items, 
                        this.props.formValues.other_reason,
                        this.props.formValues.date_refunded,
                        this.props.loggedInUser,
                        false
                    )}</p>
                    )
                }
                <br />
                    <Button
                    loading={this.props.formLoading}
                    htmlType="submit"
                    block
                    size="large"
                    disabled={pristine || submitting || invalid}
                    type="primary"
                    >
                    Edit Refund Reason
                    </Button>
            </Form>
        </Spin>
      </div>
    );
  }
}

EditRefundReason = reduxForm({
    form: "EditRefundReason",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    validate
})(EditRefundReason);

function mapStateToProps(state) {
    return {
        formLoading: state.formStatus.form_loading,
        loggedInUser: state.auth.logged_in_user,
        formValues: getFormValues("EditRefundReason")(state)
    };
}

export default connect(
    mapStateToProps,
    { editRefundReason, change }
)(EditRefundReason);
