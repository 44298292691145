import React from "react";
import { connect } from "react-redux";
import { Form, Spin, Icon } from "antd";
import { Field, reduxForm } from "redux-form";
import { plainTextField } from "../../components/Form/FieldRenders";
import { fetchUserKnoxToken } from "../../actions/actionsAuth";
import { Button } from "antd";

const validate = values => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  return errors;
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(props) {
    this.props.fetchUserKnoxToken(props);
  }

  render() {
    const { handleSubmit, submitting, invalid } = this.props;
    return (
      <Spin spinning={this.props.loggedInUserLoading}>
        <Form onSubmit={handleSubmit(this.onFormSubmit)}>
          <Field
            name="username"
            required={true}
            type="text"
            autoComplete={"username"}
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            size="large"
            placeholder="Username"
            component={plainTextField}
          />
          <Field
            name="password"
            required={true}
            icon="key"
            size="large"
            iconPosition="left"
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            autoComplete={"current-password"}
            placeholder="Password"
            component={plainTextField}
          />
          <br />
          <Button
            disabled={submitting || invalid}
            size="large"
            block
            htmlType="submit"
            type="primary"
            loading={this.props.loggedInUserLoading}
          >
            Log In
          </Button>
        </Form>
      </Spin>
    );
  }
}

LoginForm = reduxForm({
  form: "LoginForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(LoginForm);

function mapStateToProps(state) {
  return {
    loggedInUserLoading: state.auth.logged_in_user_loading
  };
}

export default connect(
  mapStateToProps,
  { fetchUserKnoxToken }
)(LoginForm);
