import React from "react";
import { Alert, Button, Form, Spin, Divider, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainNumberField
} from "../Form/FieldRenders";
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { createShipment } from "../../actions/actionsOrders";
import DeleteItem from "../../components/Common/DeleteItem";
import {
  shippingMethodArray,
  trueFalseArray,
  productArray,
  productGroupArray,
  replacementReasonTypes,
  buildOutReplacementReason,
  getReplacementType,
  PruneProductArray,
  getNewProductArray,
  getItemSelectionDetails
} from "../../helpers";
import { Link } from "react-router-dom";

var productCount = [1, 2, 3, 4, 5, 6, 7, 8, 9 , 10, 11, 12, 13, 14, 15, 16, 17, 18];

function renderValidate(values, errors) {
  var valueArray = [];
  productCount.map(function(count) {
    valueArray.push(values[`product_${count}`]);
    return count;
  });
  valueArray = valueArray.filter(function(value) {
    return value;
  });
  let hasDuplicates = new Set(valueArray).size !== valueArray.length;
  if (hasDuplicates) {
    productCount.map(function(count) {
      errors[`product_${count}`] = "Items in sample order need to be unique";
      return count;
    });
  }

  return errors;
}

const validate = values => {
  const errors = {};

  if (!values.order_id) {
    errors.order_id = "Required";
  } else {
    if (values.order_id.includes(" ")) {
      errors.order_id = "No spaces ' ' allowed";
    }
  }
  if (values.product_2) {
    if (!values.quantity_2) {
      errors.quantity_2 = "Required";
    }
  }
  if (values.quantity_2) {
    if (!values.product_2) {
      errors.product_2 = "Required";
    }
  }
  if (values.product_3) {
    if (!values.quantity_3) {
      errors.quantity_3 = "Required";
    }
  }
  if (values.quantity_3) {
    if (!values.product_3) {
      errors.product_3 = "Required";
    }
  }
  if (values.product_4) {
    if (!values.quantity_4) {
      errors.quantity_4 = "Required";
    }
  }
  if (values.quantity_4) {
    if (!values.product_4) {
      errors.product_4 = "Required";
    }
  }
  if (values.product_5) {
    if (!values.quantity_5) {
      errors.quantity_5 = "Required";
    }
  }
  if (values.quantity_5) {
    if (!values.product_5) {
      errors.product_5 = "Required";
    }
  }
  if (values.product_6) {
    if (!values.quantity_6) {
      errors.quantity_6 = "Required";
    }
  }
  if (values.quantity_6) {
    if (!values.product_6) {
      errors.product_6 = "Required";
    }
  }
  if (values.product_7) {
    if (!values.quantity_7) {
      errors.quantity_7 = "Required";
    }
  }
  if (values.quantity_7) {
    if (!values.product_7) {
      errors.product_7 = "Required";
    }
  }
// --
  if (values.product_8) {
    if (!values.quantity_8) {
      errors.quantity_8 = "Required";
    }
  }
  if (values.quantity_8) {
    if (!values.product_8) {
      errors.product_8 = "Required";
    }
  }
// --
  if (values.product_9) {
    if (!values.quantity_9) {
      errors.quantity_9 = "Required";
    }
  }
  if (values.quantity_9) {
    if (!values.product_9) {
      errors.product_9 = "Required";
    }
  }
// --
  if (values.product_10) {
    if (!values.quantity_10) {
      errors.quantity_10 = "Required";
    }
  }
  if (values.quantity_10) {
    if (!values.product_10) {
      errors.product_10 = "Required";
    }
  }
// --
  if (values.product_11) {
    if (!values.quantity_11) {
      errors.quantity_11 = "Required";
    }
  }
  if (values.quantity_11) {
    if (!values.product_11) {
      errors.product_11 = "Required";
    }
  }
// --
  if (values.product_12) {
    if (!values.quantity_12) {
      errors.quantity_12 = "Required";
    }
  }
  if (values.quantity_12) {
    if (!values.product_12) {
      errors.product_12 = "Required";
    }
  }
  //  --
  if (values.product_13) {
    if (!values.quantity_13) {
      errors.quantity_13 = "Required";
    }
  }
  if (values.quantity_13) {
    if (!values.product_13) {
      errors.product_13 = "Required";
    }
  }
  //  --
  if (values.product_14) {
    if (!values.quantity_14) {
      errors.quantity_14 = "Required";
    }
  }
  if (values.quantity_14) {
    if (!values.product_14) {
      errors.product_14 = "Required";
    }
  }
  //  --
  if (values.product_15) {
    if (!values.quantity_15) {
      errors.quantity_15 = "Required";
    }
  }
  if (values.quantity_15) {
    if (!values.product_15) {
      errors.product_15 = "Required";
    }
  }
  //  --
  if (values.product_16) {
    if (!values.quantity_16) {
      errors.quantity_16 = "Required";
    }
  }
  if (values.quantity_16) {
    if (!values.product_16) {
      errors.product_16 = "Required";
    }
  }
  //  --
  if (values.product_17) {
    if (!values.quantity_17) {
      errors.quantity_17 = "Required";
    }
  }
  if (values.quantity_17) {
    if (!values.product_17) {
      errors.product_17 = "Required";
    }
  }
  //  --
  if (values.product_18) {
    if (!values.quantity_18) {
      errors.quantity_18 = "Required";
    }
  }
  if (values.quantity_18) {
    if (!values.product_18) {
      errors.product_18 = "Required";
    }
  }


  if (!values.product_1) {
    errors.product_1 = "Required";
  }
  if (!values.quantity_1) {
    errors.quantity_1 = "Required";
  }
  if (!values.replacement_reason) {
    errors.replacement_reason = "Required";
  }

  if (!values.replacement_type) {
    errors.replacement_type = "Required"
  }
  if (!values.additional_replacement_details) {
    errors.additional_replacement_details = "Required"
  }
  if (!values.items || values.items.length === 0) {
    errors.items = "Required"
  }
  if (!values.other_reason) {
    errors.other_reason = "Required"
  }


  return renderValidate(values, errors);
};

class CreateReplacementBoxView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: []
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderProductPickers = this.renderProductPickers.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }


 
  
  onFormSubmit(props) {
    props["address_type"] = "shipping_address";
    props["actual_order_id"] = this.props.orderDetail.transaction_id;

    var replacement_reason = buildOutReplacementReason(
      this.props.formValues.replacement_type,
      this.props.formValues.additional_replacement_details, 
      this.props.formValues.items, 
      this.props.formValues.other_reason,
      this.props.loggedInUser,
      false,
      this.props.formValues.lot_codes
    )
    var replacement_box_information =  buildOutReplacementReason(
      this.props.formValues.replacement_type,
      this.props.formValues.additional_replacement_details, 
      this.props.formValues.items, 
      this.props.formValues.other_reason,
      this.props.loggedInUser,
      true,
      this.props.formValues.lot_codes
    )
    console.log(replacement_box_information)
    props["replacement_reason"] = replacement_reason;
    props["replacement_box_information"] = replacement_box_information;
    this.props.createShipment(props);
  }

  removeItem(productID, quantityID) {
    this.props.change(productID, null);
    this.props.change(quantityID, null);
  }

  componentWillReceiveProps(nextProps) {
    // Change the replacemebnt reason details if the main reason is not the same
    if (this.props.formValues) {
      if (this.props.formValues.replacement_type !== nextProps.formValues.replacement_type) {
        this.props.change("additional_replacement_details", "")
        this.props.change("items", [])
      }
    }

    if (nextProps.customerDetail) {
      if (
        this.state.addresses.length === 0
      ) {
        var addressSet = nextProps.customerDetail.addresses;

        var addresses = [];
        addressSet.map(function(address) {
          var addressObject = {
            value: address.address_id,
            text: `${address.full_name} ${address.address} ${address.city} ${address.state}, ${
              address.zip_code
            } `
          };
          addresses.push(addressObject);
          return address;
        });

      

        this.setState({
          addresses: addresses
        });
      }
    }

  }


  renderProductPickers() {
    var count = 0;
    var productNumbers = [];
    while (count <= 17) {
      count += 1;
      productNumbers.push(count.toString());
    }
    var that = this;
    return productNumbers.map(function(productNumber) {
      var required = false;
      if (productNumber === "1") {
        required = true;
      }
      return (
        <Row gutter={6} key={productNumber}>
          <Col span={17}>
            <Field
              label={`Product ${productNumber}`}
              name={`product_${productNumber}`}
              allowClear={true}
              required={required}
              overRideTouched={!required}
              size={"large"}
              showSearch={false}
              optionGroups={productGroupArray}
              options={productArray}
              component={selectField}
            />
          </Col>
          <Col span={5}>
            <Field
              label={`Qty ${productNumber}`}
              name={`quantity_${productNumber}`}
              min={1}
              max={20}
              overRideTouched={!required}
              step={1}
              required={required}
              type={"number"}
              size="large"
              component={plainNumberField}
            />
          </Col>
          <Col span={2}>
            <DeleteItem
              removeItem={() =>
                that.removeItem(
                  `product_${productNumber}`,
                  `quantity_${productNumber}`
                )
              }
            />
          </Col>
        </Row>
      );
    });
  }
  
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Warning Zone"
          description="You are about to send free product to a customer."
          type="warning"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Divider>Product Details</Divider>
            {this.renderProductPickers()}
            <Divider>Shipping Details</Divider>
            {
              this.state.addresses.length > 0 && (
                  <Field
                      label={`Shipping Address`}
                      name={`shipping_address`}
                      allowClear={true}
                      size={"large"}
                      showSearch={true}
                      required={true}
                      options={this.state.addresses}
                      component={selectField}
                  />
              )
            }

          <Link to={`/portal/customers/${this.props.orderDetail.user.random_user_id}/add-address`}>Add New Address For Customer +</Link>
          <br />
            <Field
              label={"Shipment Method"}
              name="service"
              size={"large"}
              options={shippingMethodArray}
              component={selectField}
            />
            <p>If the address is a PO BOX, the shipping method must be UPS Sure Post.</p>
            <Field
              label={"Pick Pack Priority"}
              name="pick_pack_priority"
              size={"large"}
              options={trueFalseArray}
              component={selectField}
            />
             <Field
                label={"Pick Pack Special Instructions"}
                name="pick_pack_special_instructions"
                hideErrorLabel={false}
                required={false}
                size="large"
                rows={3}
                component={textAreaField}
              />
              <p>Only the warehouse team will these instrucitons. The carrier will not nor will the customer</p>
            <Divider>Replacement Details</Divider>
            <Field
              label={"Replacement Type"}
              name="replacement_type"
              size={"large"}
              overRideTouched={true}
              required={true}
              options={replacementReasonTypes}
              component={selectField}
            />
          {this.props.formValues &&
            this.props.formValues.replacement_type && 
              getReplacementType(this.props.formValues.replacement_type).subOptions && (
                  <Field
                    label={getReplacementType(this.props.formValues.replacement_type).subOptionsLabel}
                    name="additional_replacement_details"
                    size={"large"}
                    overRideTouched={true}
                    required={true}
                    options={getReplacementType(this.props.formValues.replacement_type).subOptions}
                    component={selectField}
                  />
              )}
          {this.props.formValues &&
            this.props.formValues.replacement_type && 
              (this.props.formValues.additional_replacement_details || this.props.formValues.replacement_type === "missing_products")&&
                getItemSelectionDetails(this.props.formValues.replacement_type, this.props.formValues.additional_replacement_details, false) && (
                  <Field
                    label={getItemSelectionDetails(this.props.formValues.replacement_type, this.props.formValues.additional_replacement_details, true)}
                    name="items"
                    size={"large"}
                    required={true}
                    overRideTouched={true}
                    mode={"multiple"}
                    optionGroups={productGroupArray}
                    options={PruneProductArray(getNewProductArray(this.props.formValues.replacement_type), this.props.orderDetail)}
                    component={selectField}
                  />
              )}
          {this.props.formValues &&
            (this.props.formValues.replacement_type === "other" || 
              this.props.formValues.additional_replacement_details === "other") && (
                  <Field
                    label={"Other Reason"}
                    name="other_reason"
                    required={true}
                    hideErrorLabel={false}
                    overRideTouched={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                />
              )}
          {this.props.formValues &&
            (this.props.formValues.replacement_type === "products_damaged" &&
            this.props.formValues.additional_replacement_details &&  this.props.formValues.additional_replacement_details.includes("leakage")) && (
                  <Field
                    label={"Lot Codes"}
                    name="lot_codes"
                    required={false}
                    hideErrorLabel={false}
                    overRideTouched={true}
                    size="large"
                    rows={4}
                    placeholder={"Example: APC - 23423, LDS - 34343"}
                    component={textAreaField}
                />
              )}
              <br />
              {
                this.props.formValues && this.props.formValues.replacement_type && (
                  <p><b>Replacement Reason: </b> {buildOutReplacementReason(
                    this.props.formValues.replacement_type,
                    this.props.formValues.additional_replacement_details, 
                    this.props.formValues.items, 
                    this.props.formValues.other_reason,
                    this.props.loggedInUser,
                    false
                  )}</p>
                )
              }
              <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Create Replacement Box
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

CreateReplacementBoxView = reduxForm({
  form: "CreateReplacementBox",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateReplacementBoxView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    loggedInUser: state.auth.logged_in_user,
    customerDetail: state.customers.customer_addresses_and_payment_methods,
    formValues: getFormValues("CreateReplacementBox")(state)
  };
}

export default connect(
  mapStateToProps,
  { createShipment, change }
)(CreateReplacementBoxView);
