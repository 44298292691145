import * as types from "../actions/types";

const INITIAL_STATE = {
  questions: null,
  reviews: null,
  questions_and_reviews_loading: false,
  questions_and_reviews_soft_loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_QUESTIONS_AND_REVIEWS_LOADING:
      return { ...state, questions_and_reviews_loading: true };
    case types.FETCH_ALL_QUESTIONS_AND_REVIEWS_SOFT_LOADING:
      return { ...state, questions_and_reviews_soft_loading: true };
    case types.FETCH_ALL_QUESTIONS_AND_REVIEWS_SUCCESS:
      return {
        ...state,
        questions: action.payload.questions,
        reviews: action.payload.reviews,
        questions_and_reviews_loading: false,
        questions_and_reviews_soft_loading: false
      };

    default:
      return state;
  }
}
