import React from "react";
import { Alert, Button, Form, Spin, Row, Col, } from "antd";
import { connect } from "react-redux";
import { textAreaField, selectField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import {
  shippingMethodArray,
  trueFalseArray,
  shipmentStatusArray
} from "../../helpers";
import { Link } from "react-router-dom";
import { changeShipmentStatus } from "../../actions/actionsOrders";

const validate = values => {
  const errors = {};
  if (!values.shipment_status) {
    errors.shipment_status = "Required";
  }
  if (values.shipment_status) {
    if (values.shipment_status === "on_hold") {
      if (!values.hold_reason) {
        errors.hold_reason = "Required";
      }
    }
  }
  if (!values.shipping_method) {
    errors.shipping_method = "Required";
  }

  return errors;
};

class ChangeShipmentStatusView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: []
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props["order_id"] = this.props.orderDetail.transaction_id;
    this.props.changeShipmentStatus(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customerDetail) {
      if (
        this.state.addresses.length === 0
      ) {
        var addressSet = nextProps.customerDetail.addresses;

        var addresses = [];
        addressSet.map(function(address) {
          var addressObject = {
            value: address.address_id,
            text: `${address.full_name} ${address.address} ${address.city} ${address.state}, ${
              address.zip_code
            } `
          };
          addresses.push(addressObject);
          return address;
        });

      

        this.setState({
          addresses: addresses
        });
      }
    }
  }

  render() {

    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Heads Up"
          description="You are about to edit a customer's shipping status"
          type="info"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            {
              this.state.addresses.length > 0 && (
                  <Field
                      label={`Shipping Address`}
                      name={`shipping_address`}
                      allowClear={true}
                      size={"large"}
                      showSearch={true}
                      required={true}
                      options={this.state.addresses}
                      component={selectField}
                  />
              )
            }

          <Link to={`/portal/customers/${this.props.orderDetail.user.random_user_id}/add-address`}>Add New Address For Customer +</Link>
          <br />
            <Field
              label={"Shipment Status"}
              name="shipment_status"
              required={true}
              size={"large"}
              options={shipmentStatusArray}
              component={selectField}
            />
            <Field
              label={"Shipment Method"}
              name="shipping_method"
              size={"large"}
              required={true}
              options={shippingMethodArray}
              component={selectField}
            />
            <p>If the address is a PO BOX, the shipping method must be UPS Sure Post.</p>
            <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Pick Pack Priority"}
                    name="pick_pack_priority"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Overbox Influencer Order"}
                    name="overbox_required"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                  <p>Check this to yes if you want to the warehouse team to place the box... wihin a box. This way the box is pretty upon arrival!</p>
                </Col>
              </Row>
            <Field
              label={"Hold Reason"}
              name="hold_reason"
              hideErrorLabel={false}
              required={false}
              size="large"
              rows={4}
              component={textAreaField}
            />
            <p>
              If you are placing an order on hold, please write in a hold reason
              <br />
            </p>
            <Field
                label={"Pick Pack Special Instructions"}
                name="pick_pack_special_instructions"
                hideErrorLabel={false}
                required={false}
                size="large"
                rows={3}
                component={textAreaField}
              />
              <p>Only the warehouse team will these instrucitons. The carrier will not nor will the customer</p>
          
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Save Changes
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

ChangeShipmentStatusView = reduxForm({
  form: "ChangeShipmentStatus",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ChangeShipmentStatusView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    customerDetail: state.customers.customer_addresses_and_payment_methods
  };
}

export default connect(
  mapStateToProps,
  { changeShipmentStatus }
)(ChangeShipmentStatusView);
