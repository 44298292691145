import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import DownloadCSV from "../Common/DownloadCSV";



class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentDidMount() {
    var data = this.reactTable.getResolvedState().sortedData;
    this.setState({ data: data });
  }

  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right", height: "31px" }}>
          <DownloadCSV itemName={"Addresses"} data={this.state.data} />
        </div>
        <ReactTable
          data={this.props.addresses}
          ref={r => (this.reactTable = r)}
          noDataText="No Addresses Found"
          filterable
          columns={[
            {
              Header: " Customer ID",
              id: "order_id",
              
              accessor: d => d.user,
              Cell: row => (
                <Link to={"/portal/customers/" + row.value}>{row.value}</Link>
              )
            },
            {
                Header: "Name",
                id: "full_name",
                
                accessor: d => d.full_name,
                
              },
            {
                Header: "Address",
                id: "address",
                
                accessor: d => d.address,
                
              },
            {
                Header: "City",
                id: "city",width: 120,
                accessor: d => d.city,
                
              },
            {
                Header: "Zip",
                id: "zip_code",
                
                accessor: d => d.zip_code,
                
              },
            {
                Header: "State",
                id: "state",
                
                accessor: d => d.state,
                
              },
        
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
