import React from "react";
import { Typography } from "antd";

class Copyright extends React.Component {
  render() {
    return (
      <Typography.Text className="copyright-tag">
        Copyright Bocks Inc. d/b/a{" "}
        <Typography.Text code>cleancult</Typography.Text> 2019
        <a href="http://localhost:3000/profile">Referer</a>
      </Typography.Text>
    );
  }
}

export default Copyright;
