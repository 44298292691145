import React from "react";
import DataPoint from "../Common/DataPoint";
import {Divider} from "antd";
import {boxTypeArray, insertCardTypes} from "../../helpers";



class DashboardRowInventoryCounts extends React.Component {

    renderBoxInventoryCounts() {
    var that = this;
    return boxTypeArray.map(function(boxType) {
        var boxInventoryCount = 0

        that.props.inventoryCounts.map(function(inventoryCount) {
            if (inventoryCount.ID === boxType.dearSKU && inventoryCount.Location === "Memphis VDL") {
                boxInventoryCount = inventoryCount.OnHand
             
            }
            return inventoryCount
        })
        return (
            <DataPoint
                data={boxInventoryCount}
                key={boxType.value}
                label={boxType.text}
                image={boxType.image}
                positive={false}
            />    
        )
    
    })
  }
  renderCollaterolInventoryCounts() {
    var that = this;
    return insertCardTypes.map(function(insertType) {
        var insertTypeCount = 0
        var insertDearObject = {}
        that.props.inventoryCounts.map(function(inventoryCount) {
            if (inventoryCount.ID === insertType.dearSKU && inventoryCount.Location === "Memphis VDL") {
                insertTypeCount = inventoryCount.OnHand
                insertDearObject = inventoryCount
            }
            return inventoryCount
        })
        return (
            <DataPoint
                data={insertTypeCount}
                key={insertDearObject.SKU}
                image={insertType.image}
                label={insertDearObject.SKU}
                positive={false}
            />    
        )
    
    })
  }
  render() {
    return (
      <div>
        <div className="dashboard-row">
          <Divider orientation="left">Actual Box Inventory Counts (Live)</Divider>
          {this.renderBoxInventoryCounts()}
        </div>
        <div className="dashboard-row">
          <Divider orientation="left">Actual Collaterol Inventory Counts (Live)</Divider>
          {this.renderCollaterolInventoryCounts()}
        </div>

      
      </div>
    );
  }
}

export default DashboardRowInventoryCounts;
 