import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";


axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Sets producrts sold as success into the system
export function setAllProductsSoldSuccess(json) {
  return {
    type: types.FETCH_PRODUCTS_SOLD_SUCCESS,
    payload: json
  };
}

// Retrives All products sold in the lst 30 days
export function fetchAllProductsSold() {
  return function(dispatch) {

    return axios({
      url: `${BASE_API_URL}get-products-sold/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setAllProductsSoldSuccess(response.data.products_sold));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the products sold"
        );
      });
  };
}


// Sets the actual inventory
export function setInventoryCountsSuccess(json) {
  return {
    type: types.FETCH_ACTUAL_INVENTORY_COUNTS,
    payload: json
  };
}

// Retrives All actual inventory counts
export function fetchInventoryCounts() {
  return function(dispatch) {

    return axios({
      url: `${BASE_API_URL}get-inventory-counts/`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(setInventoryCountsSuccess(response.data.inventory_counts.ProductAvailabilityList));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the actual inventory counts"
        );
      });
  };
}


// Sets The Full Product Inventory
export function fetchFullProductInventorySuccess(json) {
  return {
    type: types.FETCH_PRODUCT_INVENTORY,
    payload: json
  };
}

// Retrives The Full Product Inventory
export function fetchFullProductInventory() {
  return function(dispatch) {
    return axios({
      url: `${BASE_API_URL}ecomm/get-product-inventory/`,
      timeout: 20000,
      method: "get",
      responseType: "json"
    }).then(function(response) {
      dispatch(fetchFullProductInventorySuccess(response.data));
    });
  };
}