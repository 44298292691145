import React from "react";
import { Button, Form, Spin, Divider, Row, Col, Skeleton, Typography } from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField
} from "../Form/FieldRenders";
import {normalizeSlug} from "../../components/Form/Normalize"
import {trueFalseArray, validateURL} from "../../helpers";
import { Field, reduxForm, getFormValues } from "redux-form";
import { createRedirectLink, editRedirectLink } from "../../actions/actionsRedirectLinks";

const validate = values => {
  const errors = {};

  if (!values.internal_title) {
      errors.internal_title = "Required"
  }
  if (!values.internal_description) {
      errors.internal_description = "Required"
  }
  if (!values.active) {
      errors.active = "Required"
  }
  if (!values.redirect_link) {
      errors.redirect_link = "Required"
  } else {
      if (!validateURL(values.redirect_link)) {
          errors.redirect_link = "Pleas enter a valid url"
      }
  }
  if (!values.link_slug) {
      errors.link_slug = "Required"
  }

  return errors;
};

class RedirectLinkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {

    if (this.props.edit) {
      this.props.editRedirectLink(props);
    } else {
      this.props.createRedirectLink(props);
    }
  }

  renderFinalURL() {
    if (this.props.formValues) {
        var slug = this.props.formValues.link_slug
        var websiteURL = window.location.href;
       var BASE_URL
        if (websiteURL.includes("staging")) {
            BASE_URL = "https://cleancultdevops.com/";
        } else {
            BASE_URL = "https://www.cleancult.com/";
        }
        
        if (process.env.NODE_ENV === "development") {
            BASE_URL = "http://127.0.0.1:8000/";
        }

        return BASE_URL + slug

    }

    return ""
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin
          spinning={this.props.formLoading || this.props.redirectLinkSoftLoading}
        >
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.redirectLinkSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Basic Info </Divider>
              <Row gutter={6}>
            
                <Col span={24}>
                  <Field
                    label={"Internal Name"}
                    name="internal_title"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Internal Description"}
                    name="internal_description"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Active"}
                    name="active"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>

              <Divider>Link Properties </Divider>
              <Row gutter={6}>
                    <Col span={24}>
                        <Field
                            label={"Redirect Link"}
                            name="redirect_link"
                            required={true}
                            size={"large"}
                            component={plainTextField}
                        />
                    <p>This is the link that the user will be directed to.
                      
                      <br />  Note: make to sure to include either a  <Typography.Text code>https://</Typography.Text> or <Typography.Text code>http://</Typography.Text> in the front of the url.</p>
                    </Col>
                    <Col span={24}>
                        <Field
                            label={"Link Slug"}
                            name="link_slug"
                            normalize={normalizeSlug}
                            required={true}
                            size={"large"}
                            component={plainTextField}
                        />
                        <p>This is the link that the user will type in. Please keep it in this format: <Typography.Text code>landing-page</Typography.Text>  or  <Typography.Text code>this-is-a-landing-page</Typography.Text> </p>
                        <br />
                        
                        <p>Your email url will look like this: <a style={{color: "#1890ff"}} href={this.renderFinalURL()} target="_blank" without rel="noopener noreferrer">{this.renderFinalURL()}</a></p>
                    </Col>
              </Row>
              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit ? "Edit Redirect Link" : "Create Redirect Link"}
              </Button>
            </Form>
          </Skeleton>
        </Spin>
      </div>
    );
  }
}

RedirectLinkForm = reduxForm({
  form: "RedirectLinkForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(RedirectLinkForm);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    redirectLinkSoftLoading: state.redirect_links.redirect_links_soft_loading,
    formValues: getFormValues("RedirectLinkForm")(state)
  };
}

export default connect(
  mapStateToProps,
  { createRedirectLink, editRedirectLink }
)(RedirectLinkForm);
