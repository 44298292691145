import React from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import Copyright from "../../components/Common/Copyright";
import { fetchLoggedInUserData } from "../../actions/actionsAuth";

import { Card, Alert } from "antd";


class AuthenticationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    // If there is a logged in user then redirect them to the page
    // If this when they hit the back button.
    if (!this.props.loggedInUser) {
      if (localStorage.getItem("Token")) {
        this.props.fetchLoggedInUserData(true);
      }
    }
  }

  renderInactiveContainer() {
    if (this.props.location.search.includes("inactive")) {
      return (
        <div style={{ textAlign: "left" }}>
          <Alert
            message="You were logged out due to inactivity"
            type="warning"
            showIcon
          />
          <br />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="authentication-global-wrapper">
        <div className="login-form-wrapper">
          <Card>
            <div style={{ textAlign: "center", height: "50px" }}>
              <img src={"//images.ctfassets.net/z3ixs6i8tjtq/1LAavKzDMuzsWbQnrFxMxi/85ca01045628d9dc6489f04b6417bbc3/NewLogo.png?w=200"} alt="cleancult" className="login-logo" style={{ textAlign: "center", height: "25px" }}/>
            </div>
            {this.renderInactiveContainer()}
            <LoginForm />
          </Card>
        </div>
        <Copyright />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { fetchLoggedInUserData }
)(AuthenticationContainer);
