import React from "react";
import {
  Button,
  Form,
  Spin,
  Divider,
  Row,
  Col,
  Skeleton,
  Tag,
  Drawer,
  Tooltip,
  Icon
} from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField
} from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { trueFalseArray } from "../../helpers";
import {
  createHomePageVersion,
  editHomePageVersion
} from "../../actions/actionsHomePageVersions";
import DescriptionItem from "../Common/DescriptionItem";
import { Link } from "react-router-dom";
import { fetchAllPromotions } from "../../actions/actionsPromotions";
import { setCopiedFormValues } from "../../actions/actionsFormStatus";
import history from "../../history";

const validate = values => {
  const errors = {};

  if (!values.internal_title) {
    errors.internal_title = "Required";
  }
  if (!values.internal_description) {
    errors.internal_description = "Required";
  }
  if (!values.default) {
    errors.default = "Required";
  }
  if (!values.active) {
    errors.active = "Required";
  }
  if (!values.header) {
    errors.header = "Required";
  }
  if (!values.subheader) {
    errors.subheader = "Required";
  }
  if (!values.call_to_action) {
    errors.call_to_action = "Required";
  }
  if (!values.text_colors) {
    errors.text_colors = "Required";
  }
  if (!values.button_color) {
    errors.button_color = "Required";
  }

  if (!values.display_nano_bar) {
    errors.display_nano_bar = "Required";
  } else {
    if (values.display_nano_bar === "true") {
      if (!values.nano_bar_text) {
        errors.nano_bar_text = "Required";
      }
    }
  }

  return errors;
};

class HomePageVersionFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (this.props.edit) {
      this.props.editHomePageVersion(props);
    } else {
      this.props.createHomePageVersion(props);
    }
  }

  componentWillMount() {
    if (!this.props.promotions) {
      this.props.fetchAllPromotions();
    }
  }
  renderPromotions(promotions) {
    var promotionSet = [];
    promotionSet.push({
      value: null,
      text: "-- No Promotion -- "
    });
    promotions.map(function(promotion) {
      var promotionObject = {
        value: promotion.promotion_id,
        text: promotion.internal_title
      };
      promotionSet.push(promotionObject);

      return promotion;
    });
    return promotionSet;
  }


  // Coipes the curren home page versino
  copyHomePageVersion() {
    
    // Fir the home page values do esist
    if (this.props.formValues.values) {
      let formValues = this.props.formValues.values
      this.props.setCopiedFormValues(formValues)
      setTimeout(function(){ 
        
        history.push("/portal/");
      
      }, 1000);
      setTimeout(function(){ 
        
        history.push("/portal/home-page-versions/create");
      
      }, 1000);
    }
  }

  render() {

    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin
          spinning={
            this.props.formLoading || this.props.homePageVersionSoftLoading
          }
        >
          {this.props.initialValues && !this.props.createView && (
            <Row>
              <Drawer
                title={`Live View For Version (${
                  this.props.initialValues.version_id
                })`}
                width={window.innerWidth - window.innerWidth * 0.1}
                closable={true}
                className="live-view-modal"
                onClose={() => this.props.closeChildModal()}
                visible={this.props.childModalOpen}
              >
                <iframe
                  title="home page live view"
                  src={`${
                    this.props.initialValues.version_url
                  }&time=${new Date()}`}
                />
              </Drawer>
              <Divider>Basic Info </Divider>

              <Col span={24}>
                <DescriptionItem
                  title="Date Created"
                  content={this.props.initialValues.date_created}
                />
              </Col>
              <Col span={24}>
                <DescriptionItem
                  title="Link"
                  content={
                    <a
                      href={this.props.initialValues.version_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.props.initialValues.version_url}{" "}
                      <Icon type="link" />
                    </a>
                  }
                />
              </Col>
            </Row>
          )}
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.homePageVersionSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Internal Information </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Internal Title"}
                    name="internal_title"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Internal Description"}
                    name="internal_description"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={3}
                    component={textAreaField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Default Version"}
                    name="default"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Active"}
                    name="active"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              <Divider>Promotions </Divider>
              <p style={{ textAlign: "center" }}>
                You can connect a promotion to the home page. This will auto
                apply an email modal pop up and discount or free product to
                their session.
              </p>
              <Row>
                <Col span={24}>
                  {this.props.promotions ? (
                    <Field
                      label={"Promotion"}
                      name="promotion"
                      size={"large"}
                      showSearch={true}
                      options={this.renderPromotions(this.props.promotions)}
                      component={selectField}
                      filterOption={(input, option) => {
                          if (option.props.children) {
                              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                      }
                      }
                    />
                  ) : (
                    <Icon type="sync" spin />
                  )}
                </Col>
              </Row>
              <Divider>Version Properties </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Header"}
                    name="header"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                  Use the <Tag>`</Tag> character to add a line break
                </Col>
                <Col span={24}>
                  <Field
                    label={"Sub Header"}
                    name="subheader"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                Use the <Tag>`</Tag> character to add a line break
              </Row>
              <Row>
                <Col span={24}>
                  <Field
                    label={"Call To Action"}
                    name="call_to_action"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Text Color"}
                    name="text_colors"
                    required={true}
                    size={"large"}
                    options={[
                      {
                        value: "#253644",
                        text: "Black"
                      },
                      {
                        value: "#fff",
                        text: "White"
                      }
                    ]}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Button Color"}
                    name="button_color"
                    required={true}
                    size={"large"}
                    options={[
                      {
                        value: "button-info",
                        text: "Blue"
                      },
                      {
                        value: "button-success",
                        text: "Green"
                      },
                      {
                        value: "button-danger",
                        text: "Red"
                      }
                    ]}
                    component={selectField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Nano Bar Text"}
                    name="nano_bar_text"
                    required={false}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Display Nano Bar"}
                    name="display_nano_bar"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit
                  ? "Save Home Page Version"
                  : "Create Home Page Version"}
              </Button>
              <br />
              <br />
              <br />
            </Form>
          </Skeleton>
        </Spin>
        {this.props.edit && this.props.initialValues && (
          <div className="bottom-bar">
            <Button type="primary" style={{ marginRight: 8 }} ghost onClick={() => this.copyHomePageVersion()}>
                Copy Home Page Version
              </Button>
            <Tooltip
              placement="leftTop"
              title={
                !pristine
                  ? "Make sure to save your changes to see the latest version"
                  : "Changes are all saved! You are good to go."
              }
            >
              <Link
                to={`/portal/home-page-versions/${
                  this.props.initialValues.version_id
                }/live-view`}
              >
                <Button type="primary" style={{ marginRight: 8 }} ghost>
                  See Live View
                </Button>
              </Link>
            </Tooltip>
            <br />
          </div>
        )}
      </div>
    );
  }
}

HomePageVersionFormView = reduxForm({
  form: "HomePageVersionForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(HomePageVersionFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    homePageVersionSoftLoading:
      state.home_page_versions.home_page_versions_soft_loading,
    promotions: state.promotions.promotions,
    formValues: state.form.HomePageVersionForm
  };
}

export default connect(
  mapStateToProps,
  { createHomePageVersion, editHomePageVersion, fetchAllPromotions, setCopiedFormValues }
)(HomePageVersionFormView);
