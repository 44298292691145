import React from "react";
import { Button, Form, Spin, Divider, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import { selectField, plainTextField} from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { editUserProfileAdmin } from "../../actions/actionsCustomers";
import { stateArray, trueFalseArray } from "../../helpers";
import { normalizePhone, normalizeZipcode } from "../Form/Normalize";

const validate = values => {
  const errors = {};
  if (!values.full_name) {
    errors.full_name = "Required";
  }
  if (!values.address) {
    errors.address = "Required";
  }

  if (!values.city) {
    errors.city = "Required";
  }
  if (!values.state) {
    errors.state = "Required";
  }
  if (!values.zip_code) {
    errors.zip_code = "Required";
  }
  if (!values.by_pass_address_validation) {
    errors.by_pass_address_validation = "Required";
  }

  return errors;
};

class UserAddressView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props.random_user_id = this.props.customerDetail.details.random_user_id;

    if (this.props.edit) {
      props.type = "edit_address";
      props.address_type = "shipping_address";
      this.props.editUserProfileAdmin(props, "put");
    } else {
      props.type = "add_address";
      props.address_type = "shipping_address";
      this.props.editUserProfileAdmin(props, "post");
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
            <Divider>Address Details</Divider>
            <Row gutter={6}>
              <Col span={24}>
                <Field
                  label={"Full Name"}
                  name={`full_name`}
                  required={true}
                  allowClear={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={16}>
                <Field
                  label={"Address"}
                  name={`address`}
                  required={true}
                  allowClear={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
              <Col span={8}>
                <Field
                  label={"Apt. Number"}
                  name={`apt_number`}
                  allowClear={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={16}>
                <Field
                  label={"City"}
                  name={`city`}
                  required={true}
                  allowClear={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
              <Col span={8}>
                <Field
                  label={"State"}
                  name={`state`}
                  required={true}
                  allowClear={true}
                  showSearch={true}
                  size={"large"}
                  options={stateArray}
                  component={selectField}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={12}>
                <Field
                  label={"Zip Code"}
                  name={`zip_code`}
                  required={true}
                  normalize={normalizeZipcode}
                  allowClear={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
              <Col span={12}>
                <Field
                  label={"Phone"}
                  name={`phone`}
                  required={false}
                  normalize={normalizePhone}
                  allowClear={true}
                  showSearch={true}
                  size={"large"}
                  component={plainTextField}
                />
              </Col>
            </Row>
            <Row gutter={6}>
              <Col span={24}>
                <Field
                  label={"By Pass Address Validation"}
                  name={`by_pass_address_validation`}
                  required={true}
                  options={trueFalseArray}
                  allowClear={true}
                  showSearch={true}
                  size={"large"}
                  component={selectField}
                />
              </Col>
              <p>Should be defaulted to <Typography.Text code>FALSE</Typography.Text> , however, if you are getting an address error, but are 100% sure the address is right, set to <Typography.Text code>TRUE</Typography.Text>  and the system will not check if the address is correct or not. </p>
            </Row>

            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              {this.props.edit ? "Edit Address" : "Add Address"}
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

UserAddressView = reduxForm({
  form: "UserAddressForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(UserAddressView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editUserProfileAdmin }
)(UserAddressView);
