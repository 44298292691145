import React from "react";
import { connect } from "react-redux";
import { fetchAllRedirectLinks } from "../../actions/actionsRedirectLinks";
import Loader from "../../components/Common/Loader";
import history from "../../history";
import { Divider, Drawer } from "antd";
import { getScreenWidth } from "../../helpers";
import DashboardRow from "../../components/RedirectLinks/DashboardRow";
import Table from "../../components/RedirectLinks/Table";
import RedirectLinkFormView from "../../components/RedirectLinks/RedirectLinkFormView";

class RedirectLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRedirectLink: null,
      searchFilter: "",
      childModalOpen: false,
      createRedirectLinkViewOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }
  setSelectedRedirectVersion(nextProps) {
    var redirectLinkID = nextProps.location.pathname.match(/\d+/);
    if (redirectLinkID) {
      redirectLinkID = redirectLinkID[0];
      var that = this;
      nextProps.redirectLinks.map(function(redirectLink) {
        if (redirectLink.redirect_link_id === redirectLinkID) {
          that.setState({ selectedRedirectLink: redirectLink });
        }
        return redirectLink;
      });
    } else {
      this.setState({ selectedRedirectLink: null });
    }
  }

  onClose() {
    this.setState({
      selectedRedirectLink: null
    });
  }
  closeChildModal() {
    history.push(
      `/portal/redirect-links/${
        this.state.selectedRedirectLink.redirect_link_id
      }`
    );
  }
  //   Go back to the main HomePageVersions page
  closeModal() {
    history.push("/portal/redirect-links");
    this.setState({
      selectedRedirectLink: null
    });
  }

  getTitle() {
    if (this.state.selectedRedirectLink) {
      return `Edit Landing Page (${
        this.state.selectedRedirectLink.redirect_link_id
      })`;
    }
  }

  checkIfChildModalOpen(pathname) {
    var createRedirectLinkViewOpen = false;
    var childModalOpen = false;

    if (pathname.includes("live")) {
      childModalOpen = true;
    }

    if (pathname.includes("create")) {
      createRedirectLinkViewOpen = true;
    }

    this.setState({
      createRedirectLinkViewOpen: createRedirectLinkViewOpen,
      childModalOpen: childModalOpen
    });
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the redirect links are not already populated, populate them
    if (!this.props.redirectLinks) {
      this.props.fetchAllRedirectLinks();
    } else {
      this.setSelectedRedirectVersion(this.props);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    if (nextProps.redirectLinks) {
      this.setSelectedRedirectVersion(nextProps);
    }
  }

  getInitialValues(redirectLink) {
    if (redirectLink) {
      var initialValues = Object.assign({}, redirectLink);
      initialValues.active = redirectLink.active.toString();
      return initialValues;
    }
  }

 
  render() {
    return (
      <div className="page-container">
      
        <div className="table-view">
          {this.props.redirectLinks &&
          !this.props.redirectLinksLoading ? (
            <div>
              {!this.props.loggedInUser.customer_service_user && (
                <div>
                  <Divider orientation="left">Overall Stats </Divider>
                  <DashboardRow
                    redirectLinks={this.props.redirectLinks}
                  />
                </div>
              )}
              <br />
             <Table redirectLinks={this.props.redirectLinks} /> 
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <Drawer
          placement="right"
          title="Create Redirect Link"
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.createRedirectLinkViewOpen}
        >
          <RedirectLinkFormView />
        </Drawer>
        <Drawer
          placement="right"
          title={this.getTitle()}
          width={getScreenWidth(false)}
          closable={true}
          onClose={() => this.closeModal()}
          visible={this.state.selectedRedirectLink ? true : false}
        >
          <RedirectLinkFormView
            closeChildModal={this.closeChildModal}
            childModalOpen={this.state.childModalOpen}
            initialValues={this.getInitialValues(
              this.state.selectedRedirectLink
            )}
            edit={true}
          />
            </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    redirectLinks: state.redirect_links.redirect_links,
    redirectLinksLoading:
      state.redirect_links.redirect_links_loading,
    loggedInUser: state.auth.logged_in_user
  };
}

export default connect(
  mapStateToProps,
  { fetchAllRedirectLinks }
)(RedirectLinks);
