import React from "react";
import { connect } from "react-redux";
import history from "../../history";
import Table from "../../components/Subscriptions/Table";
import DetailView from "../../components/Subscriptions/DetailView";
import SearchView from "../../components/Subscriptions/SearchView";

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptionID: null,
      searchFilter: "",
      subscriptions: null,
      childModalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }
  setSelectedSubscription(nextProps) {
    var subscriptionID = nextProps.location.pathname.match(/\d+/);
    if (subscriptionID) {
      subscriptionID = subscriptionID[0];
      
      this.setState({ selectedSubscriptionID: subscriptionID });
      
    } else {
      this.setState({ selectedSubscriptionID: null });
    }
  }

  // Checks to see whether or not to open the secondery modal inside of the subscription detail view
  checkIfChildModalOpen(pathname) {
    var childModalOpen = false;

    if (pathname.includes("discount")) {
      childModalOpen = true;
    } else if (pathname.includes("edit")) {
      childModalOpen = true;
    }
    this.setState({
      childModalOpen: childModalOpen
    });
  }

  onClose() {
    this.setState({
      selectedSubscription: null
    });
  }

  //   Go back to the main subscriptions page
  closeModal() {
    history.push("/portal/subscriptions");
  }

  closeChildModal() {
    history.push(
      `/portal/subscriptions/${this.props.orderDetail.transaction_id}`
    );
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the subscriptions are not already populated, populate them
   

    this.setSelectedSubscription(this.props);
   
      console.log('giont to resnstate the sunscriptions')

  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    
      this.setSelectedSubscription(nextProps);
    

  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
              <div>
                  <SearchView />
                <Table
                  subscriptions={this.props.subscriptions ? this.props.subscriptions : []}
                  customerServiceUser={this.props.loggedInUser.customer_service_user}/>
              </div>
        </div>
        <DetailView
          closeModal={this.closeModal}
          closeChildModal={this.closeChildModal}
          subscriptionActive={this.state.selectedSubscriptionID ? true : false}
          childModalOpen={this.state.childModalOpen}
          subscriptionID={
            this.state.selectedSubscriptionID
              ? this.state.selectedSubscriptionID
              : null
          }
          location={this.props.location}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    subscriptions: state.subscriptions.subscriptions,
    subscriptionsLoading: state.subscriptions.subscriptions_loading,
    loggedInUser: state.auth.logged_in_user,
    orderDetail: state.orders.orderDetail,
  };
}

export default connect(
  mapStateToProps,
  {  }
)(Subscriptions);
