import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { reset } from "redux-form/es/actions";
import history from "../history";
import { message } from "antd";
import BASE_API_URL from "./env";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

// Retrives Logged In User Information Upon Initial APplication Load
export function loggedInUserSuccess(json) {
  return {
    type: types.FETCH_LOGGED_IN_USER_SUCCESS,
    payload: json
  };
}

// Triggers the user loading function
export function toggleLoggedInUserLoading() {
  return { type: types.TOGGLE_LOGGED_IN_USER_LOADING, payload: null };
}

// User did not load in, either 500 or guest checkout
export function loggedInUserFail(json) {
  return {
    type: types.FETCH_LOGGED_IN_USER_FAILURE_MESSAGE,
    payload: json
  };
}

// Gets the Knox token
export function fetchUserKnoxToken(data) {
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}users/token-login`,
      timeout: 20000,
      method: "post",
      data: data,
      responseType: "json"
    })
      .then(function(response) {
        // Set the Token to local storage, then dreiclty
        localStorage.setItem("Token", response.data.token);
        message.success("Successfully signed in");
        setTimeout(function() {
          // Load the new user into state
          dispatch(loggedInUserSuccess(response.data.user));
          window.location.replace("/portal/orders");
        }, 1300);
      })
      .catch(function(error) {
        dispatch(reset("LoginForm"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
          dispatch(loggedInUserFail());
        } else {
          message.error(
            "Sorry, there was an error on our end of things. The proper people have been notified."
          );
          dispatch(loggedInUserFail());
        }
      });
  };
}

// Retrives the logges in users information up first load of the application
export function fetchLoggedInUserData(redirect) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}users/admin-logged-in-user`,
      timeout: 20000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        // Submits the logged in user data to REdux
        dispatch(loggedInUserSuccess(response.data));
        // Redirects them to the orders page
        if (redirect) {
          history.push("/portal/orders");
        }
      })
      .catch(function() {
        localStorage.removeItem("Token");
        message.error("You need to login before continuing");
        dispatch(loggedInUserFail());
        history.push("/login");
      });
  };
}

// Logout user and invalidates all tokens
export function logOutUser(inActivity) {
  var knoxToken = "Token " + localStorage.getItem("Token");
  return function(dispatch) {
    dispatch(toggleLoggedInUserLoading());
    return axios({
      url: `${BASE_API_URL}knox/logout/`,
      timeout: 20000,
      method: "post",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        dispatch(loggedInUserSuccess(null));
        localStorage.removeItem("Token");
        message.success("Successfully signed out");
        if (inActivity) {
          history.push("/login?inactive=True");
        } else {
          history.push("/login");
        }
      })
      .catch(function(error) {
        dispatch(loggedInUserSuccess(null));
        localStorage.removeItem("Token");
        history.push("/login");
      });
  };
}
