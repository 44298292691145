import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the promotions into state
export function fetchAllPromotionsSucccess(data) {
  return { type: types.FETCH_ALL_PROMOTIONS_SUCCESS, payload: data };
}

// Load the promotions into state
export function setAllPromotionsLoading() {
  return { type: types.FETCH_ALL_PROMOTIONS_LOADING, payload: null };
}

// Load the promotions into state
export function setAllPromotionsSoftLoading() {
  return {
    type: types.FETCH_ALL_PROMOTIONS_SOFT_LOADING,
    payload: null
  };
}

// retrievie all promotions
export function fetchAllPromotions(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllPromotionsLoading());
    } else {
      dispatch(setAllPromotionsSoftLoading());
    }
    return axios({
      url: `${BASE_API_URL}get-all-promotions`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllPromotionsSucccess(response.data.promotions));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the promotions"
        );
      });
  };
}

// Create a promotions
export function createPromotion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-promotion`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Promotion Created");
        dispatch(reset("PromotionForm"));
        dispatch(fetchAllPromotions(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/promotions/${response.data.promotion_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this promotion"
          );
        }
      });
  };
}

// Edit a speific promotion
export function editPromotion(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-promotion`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Promotion Saved");
        dispatch(reset("PromotionForm"));
        dispatch(fetchAllPromotions(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/promotions/${data.promotion_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this promotion"
          );
        }
      });
  };
}
