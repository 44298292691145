import * as types from "../actions/types";

const INITIAL_STATE = {
  customers: null,
  customers_loading: false,
  customer_detail: null,
  addresses: null,
  customer_addresses_and_payment_methods: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_CUSTOMERS_LOADING:
      return { ...state, customers_loading: !state.customers_loading };
    case types.FETCH_ALL_CUSTOMERS_SUCCESS:
      return { ...state, customers: action.payload, customers_loading: false };
    case types.FETCH_ALL_ADDRESSES_SUCCESS:
      return { ...state, addresses: action.payload, customers_loading: false };
    case types.FETCH_CUSTOMER_DETAILS:
      return { ...state, customer_detail: action.payload };
    case types.FETCH_CUSTOMER_ADDRESSES_AND_PAYMENT_METHODS:
      return {
        ...state,
        customer_addresses_and_payment_methods: action.payload
      };
    case types.RESET_CUSTOMER_DETAILS:
      return {
        ...state,
        customer_detail: null,
        customer_addresses_and_payment_methods: null
      };

    default:
      return state;
  }
}
