import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import * as types from "./types";
import { message } from "antd";
import BASE_API_URL from "./env";
import {
  setFormSubmittingTrue,
  setFormSuccessMessage,
  setFormFailureMessage
} from "./actionsFormStatus";
import history from "../history";
import { reset } from "redux-form/es/actions";

axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

var knoxToken = "Token " + localStorage.getItem("Token");

// Load the discounts into state
export function fetchAllDiscountsSucccess(discounts) {
  return { type: types.FETCH_ALL_DISCOUNTS_SUCCESS, payload: discounts };
}

// Load the discounts into state
export function setAllDiscountsLoading(discounts) {
  return { type: types.FETCH_ALL_DISCOUNTS_LOADING, payload: null };
}

// Load the discounts into state
export function setAllDiscountsSoftLoading(discounts) {
  return { type: types.FETCH_ALL_DISCOUNTS_SOFT_LOADING, payload: null };
}

// retrievie all discounts
export function fetchAllDiscounts(dontFullLoad) {
  return function(dispatch) {
    if (!dontFullLoad) {
      dispatch(setAllDiscountsLoading());
    } else {
      dispatch(setAllDiscountsSoftLoading());
    }

    return axios({
      url: `${BASE_API_URL}get-all-discounts`,
      timeout: 60000,
      method: "get",
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        dispatch(fetchAllDiscountsSucccess(response.data.discounts));
      })
      .catch(function(error) {
        message.error(
          "Hmmm, there seems to be an error retrieving the discounts"
        );
      });
  };
}

// Create a discount
export function createDiscount(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}create-discount`,
      timeout: 60000,
      method: "post",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function(response) {
        message.success("Discount Created");
        dispatch(reset("DiscountForm"));
        dispatch(fetchAllDiscounts());
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/discounts/${response.data.discount_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error creating this discount"
          );
        }
      });
  };
}

// Edit a speific discount
export function editDiscount(data) {
  return function(dispatch) {
    dispatch(setFormSubmittingTrue());
    return axios({
      url: `${BASE_API_URL}edit-discount`,
      timeout: 60000,
      method: "put",
      data: data,
      responseType: "json",
      headers: {
        authorization: knoxToken
      }
    })
      .then(function() {
        message.success("Discount Saved");
        dispatch(reset("DiscountForm"));
        dispatch(fetchAllDiscounts(true));
        dispatch(setFormSuccessMessage("Success"));
        history.push(`/portal/discounts/${data.discount_id}`);
      })
      .catch(function(error) {
        dispatch(setFormFailureMessage("Fail"));
        if (error.response && error.response.data) {
          message.error(error.response.data.error);
        } else {
          message.error(
            "Hmm, there seems to be an error editing this discount"
          );
        }
      });
  };
}
