import React from "react";
import {connect} from "react-redux";
import {logOutUser} from "../../actions/actionsAuth";
import {Button} from "antd";

class TopMenu extends React.Component {
  
  render() {
    return (
      <div className="top-menu-container">

        <img src={"//images.ctfassets.net/z3ixs6i8tjtq/1LAavKzDMuzsWbQnrFxMxi/85ca01045628d9dc6489f04b6417bbc3/NewLogo.png?w=200"} alt="cleancult" className="logo"  style={{height: "17px"}}/>
  
        <Button
          className="logout"
          type="danger"
          ghost
          icon="logout"
          onClick={() => this.props.logOutUser()}
          loading={this.props.loggedInUserLoading}>
          Log out
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {loggedInUserLoading: state.auth.logged_in_user_loading};
}

export default connect(mapStateToProps, {logOutUser})(TopMenu);
