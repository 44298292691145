import React from "react";
import { Button, Form, Spin, Divider, Row, Col, Skeleton } from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField,
  plainNumberField,
  datePickerField
} from "../Form/FieldRenders";
import { Field, reduxForm, getFormValues } from "redux-form";
import { discountTypes, discountRedemptionTypes, trueFalseArray, discountLockedTypes } from "../../helpers";
import { createDiscount, editDiscount } from "../../actions/actionsDiscounts";
import moment from "moment";
import { setCopiedFormValues } from "../../actions/actionsFormStatus";
import history from "../../history";

const validate = values => {
  const errors = {};
  if (!values.user_facing_discount_id) {
    errors.user_facing_discount_id = "Required";
  }
  if (!values.internal_name) {
    errors.internal_name = "Required";
  }
  if (!values.programatic_discount) {
    errors.programatic_discount = "Required";
  }
  if (!values.discount_description) {
    errors.discount_description = "Required";
  }
  if (!values.locked_discount_type) {
    errors.locked_discount_type = "Required";
  }
  if (!values.discount_type) {
    errors.discount_type = "Required";
  } else {
    if (values.discount_type === "percentage") {
      if (!values.percentage_off) {
        errors.percentage_off = "Required";
      }
    } else if (values.discount_type === "fixed") {
      if (!values.fixed_amount_off) {
        errors.fixed_amount_off = "Required";
      }
    }
  }
  if (!values.number_of_redemptions) {
    errors.number_of_redemptions = "Required";
  }
  if (!values.redemptions_per_account) {
    errors.redemptions_per_account = "Required";
  }
  if (!values.redemption_type) {
    errors.redemption_type = "Required";
  } else {
    if (values.redemption_type === "before_a_specific_date") {
      if (!values.redemption_time_frame) {
        errors.redemption_time_frame = "Required";
      }
    }
  }

  return errors;
};

class DiscountFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (props.redemption_time_frame) {
      props.redemption_time_frame = moment(props.redemption_time_frame).format(
        "YYYY-MM-DD"
      );
    }
    if (this.props.edit) {
      this.props.editDiscount(props);
    } else {
      this.props.createDiscount(props);
    }
  }

    // Coipes the curren home page versino
    copyDiscount() {
      console.log("sdf")
      // Fir the home page values do esist
      if (this.props.discountFormValues.values) {
        let formValues = this.props.discountFormValues.values
        this.props.setCopiedFormValues(formValues)
        setTimeout(function(){ 
          
          history.push("/portal/");
        
        }, 1000);
        setTimeout(function(){ 
          
          history.push("/portal/discounts/create-discount");
        
        }, 1000);
      }
    }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin
          spinning={this.props.formLoading || this.props.discountsSoftLoading}
        >
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.discountsSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Basic Info </Divider>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"User Discount ID"}
                    name="user_facing_discount_id"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                  <p>This is what the user types in.</p>
                </Col>
                <Col span={12}>
                  <Field
                    label={"Internal Name"}
                    name="internal_name"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Description"}
                    name="discount_description"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
              </Row>

              <Divider>Discount Properties </Divider>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Discount Type"}
                    name="discount_type"
                    required={true}
                    size={"large"}
                    options={discountTypes}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                {this.props.formValues &&
                    this.props.formValues.discount_type === "percentage" && (
                      <Field
                        label={"Discount Percentage Ex. 70"}
                        name="percentage_off"
                        required={true}
                        step={.01}
                        type={"number"}
                        max={100}
                        size="large"
                        component={plainNumberField}
                      />
                    )}
                  {this.props.formValues &&
                    this.props.formValues.discount_type === "fixed" && (
                      <Field
                        label={"Fixed Amount Off Ex. 12.50"}
                        name="fixed_amount_off"
                        required={true}
                        step={0.01}
                        type={"number"}
                        max={100}
                        size="large"
                        component={plainNumberField}
                      />
                    )}
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Total Allowed Uses Globally"}
                    name="number_of_redemptions"
                    required={true}
                    step={1}
                    min={1}
                    type={"number"}
                    max={9999}
                    size="large"
                    component={plainNumberField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Total Uses Per Account"}
                    name="redemptions_per_account"
                    required={true}
                    step={1}
                    min={1}
                    type={"number"}
                    max={10}
                    size="large"
                    component={plainNumberField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Redemption Type"}
                    name="redemption_type"
                    required={true}
                    size={"large"}
                    options={discountRedemptionTypes}
                    component={selectField}
                  />
                  <p>When can a customer use this code</p>
                </Col>
                <Col span={12}>
                  {this.props.formValues &&
                    this.props.formValues.redemption_type ===
                      "before_a_specific_date" && (
                      <Field
                        label={"Use Before"}
                        name="redemption_time_frame"
                        required={true}
                        showToday={true}
                        placeHolder={"Select The Last Day Of Use"}
                        size="large"
                        component={datePickerField}
                      />
                    )}
                </Col>
              </Row>
              <Row gutter={6}>
              <Col span={12}>
                  <Field
                    label={"Lock Discount To Product Type"}
                    name="locked_discount_type"
                    required={true}
                    size={"large"}
                    options={discountLockedTypes}
                    component={selectField}
                  />
                  <p>This will restrict the discount from being added to orders that do not conform to the lock type. This includes both if the user types the discount in OR if you attach this discount directly to a promotion.</p>
                </Col>
                <Col span={12}>
                  <Field
                    label={"Programatic Discount"}
                    name="programatic_discount"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                  <p>Should always be false unless the tech team says otherwise. It is used for locking in a discount so it cannot be typed in by a user, but rather entered in throught the code.</p>
                </Col>
              </Row>
              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit ? "Edit Discount" : "Create Discount"}
              </Button>
            </Form>
          </Skeleton>
        </Spin>
        {this.props.edit &&  (
          <div className="bottom-bar">
            <Button type="primary" style={{ marginRight: 8 }} ghost onClick={() => this.copyDiscount()}>
                Copy Discount
              </Button>
            <br />
          </div>
        )}
      </div>
    );
  }
}

DiscountFormView = reduxForm({
  form: "DiscountForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(DiscountFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    discountsSoftLoading: state.discounts.discounts_soft_loading,
    formValues: getFormValues("DiscountForm")(state),
    discountFormValues: state.form.DiscountForm
  };
}

export default connect(
  mapStateToProps,
  { createDiscount, editDiscount, setCopiedFormValues }
)(DiscountFormView);
