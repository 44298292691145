import React from "react";
import { Button, Form, Spin, Divider, Row, Col, Skeleton } from "antd";
import { connect } from "react-redux";
import { plainTextField, selectField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { trueFalseArray, validateEmail } from "../../helpers";
import { getScreenWidth } from "../../helpers";
import { Drawer } from "antd";
import ResetPasswordView from "../Customers/ResetPasswordView";
import {
  createAdminUser,
  editAdminUser
} from "../../actions/actionsAdminUsers";
import { Link } from "react-router-dom";

const validate = values => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = "Required";
  }
  if (!values.last_name) {
    errors.last_name = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else {
    if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }
  }

  if (!values.is_superuser) {
    errors.is_superuser = "Required";
  }
  if (!values.is_staff) {
    errors.is_staff = "Required";
  }
  if (!values.fulfillment_user) {
    errors.fulfillment_user = "Required";
  }
  if (!values.customer_service_user) {
    errors.customer_service_user = "Required";
  }
  if (!values.fulfillment_super_user) {
    errors.fulfillment_super_user = "Required";
  }
  if (!values.is_active) {
    errors.is_active = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirm_password) {
    errors.confirm_password = "Required";
  } else {
    if (values.confirm_password !== values.password) {
      errors.confirm_password = "The passwords need to match";
    }
  }
  return errors;
};

class CreateAdminUserFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (this.props.edit) {
      this.props.editAdminUser(props);
    } else {
      this.props.createAdminUser(props);
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin spinning={this.props.formLoading}>
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.adminUserSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Personal Info</Divider>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"First Name"}
                    name="first_name"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Last Name"}
                    name="last_name"
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Email"}
                    name="email"
                    type={"email"}
                    required={true}
                    size={"large"}
                    component={plainTextField}
                  />
                  <p>This will be the users login as well</p>
                </Col>
              </Row>

              <Divider>Website Properties </Divider>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Active"}
                    name="is_active"
                    required={true}
                    size={"large"}
                    suffix={
                      "Has the ability to login to any website or platform"
                    }
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Customer Service User"}
                    name="customer_service_user"
                    required={true}
                    suffix={
                      "Can edit orders, subscriptions, and customers on portal.cleancult.com"
                    }
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Staff User"}
                    name="is_staff"
                    required={true}
                    suffix={
                      "Has access to all levels of the portal.cleancult.com system"
                    }
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Super User"}
                    name="is_superuser"
                    suffix={"God amounst men"}
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Fulfillment User"}
                    name="fulfillment_user"
                    required={true}
                    suffix={"Access to the fulfillment.cleancult.com portal"}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Fulfillment Super User"}
                    name="fulfillment_super_user"
                    required={true}
                    size={"large"}
                    suffix={
                      "Can create fulfillment users and asee dashbaord stats for fulfillment on fulfillment.cleancult.com"
                    }
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>
              {!this.props.edit && (
                <Row gutter={6}>
                  <Divider>Password Properties </Divider>
                  <Col span={12}>
                    <Field
                      label={"Password"}
                      name="password"
                      required={true}
                      size="large"
                      type="password"
                      component={plainTextField}
                    />
                  </Col>
                  <Col span={12}>
                    <Field
                      label={"Confirm Password"}
                      name="confirm_password"
                      required={true}
                      size="large"
                      type="password"
                      component={plainTextField}
                    />
                  </Col>
                </Row>
              )}
              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit ? "Save Admin User" : " Create Admin User"}
              </Button>
            </Form>
          </Skeleton>
        </Spin>

        {this.props.edit && this.props.initialValues && (
          <div className="bottom-bar">
            <Drawer
              title={"Reset Password"}
              width={getScreenWidth(true)}
              closable={true}
              onClose={() => this.props.closeChildModal()}
              visible={this.props.childModalOpen}
            >
              <ResetPasswordView
                adminReset={true}
                customerDetail={this.props.initialValues}
              />
            </Drawer>
            <Link
              to={`/portal/user-management/${
                this.props.initialValues.random_user_id
              }/reset-password`}
            >
              <Button type="primary" style={{ marginRight: 8 }} ghost>
                Reset Password
              </Button>
            </Link>

            <br />
          </div>
        )}
      </div>
    );
  }
}

CreateAdminUserFormView = reduxForm({
  form: "CreateAdminUserForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(CreateAdminUserFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    adminUserSoftLoading: state.admin_users.admin_users_soft_loading
  };
}

export default connect(
  mapStateToProps,
  { createAdminUser, editAdminUser }
)(CreateAdminUserFormView);
