import React from "react";
import { Alert, Button, Form, Spin } from "antd";
import { connect } from "react-redux";
import { plainNumberField } from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { editUserProfileAdmin } from "../../actions/actionsCustomers";

const validate = values => {
  const errors = {};
  if (!values.gift_card_value) {
    errors.gift_card_value = "Required";
  }
  return errors;
};

class AddGiftCardValueView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    props.random_user_id = this.props.customerDetail.details.random_user_id;
    props.type = "add_gift_card_value";
    this.props.editUserProfileAdmin(props, "put");
  }

  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Alert
          message="Danger Zone"
          description="You are about to edit a user's free gift card credit. Please handle with care."
          type="error"
        />
        <br />

        <Spin spinning={this.props.formLoading}>
          <Form onSubmit={handleSubmit(this.onFormSubmit)}>
          <Field
              label={"Gift Card Value"}
              name="gift_card_value"
              required={true}
              addonBefore="$"
              step={0.01}
              type={"number"}
              size="large"
              min={-100000}
              component={plainNumberField}
            />
            <p>Make the number negative to remove credit</p>
            <br />
            <Button
              loading={this.props.formLoading}
              htmlType="submit"
              block
              size="large"
              disabled={pristine || submitting || invalid}
              type="primary"
            >
              Add Credit
            </Button>
          </Form>
        </Spin>
      </div>
    );
  }
}

AddGiftCardValueView = reduxForm({
  form: "AddGiftCardValueForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(AddGiftCardValueView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading
  };
}

export default connect(
  mapStateToProps,
  { editUserProfileAdmin }
)(AddGiftCardValueView);
