import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { convertBooleanToTag } from "../../helpers";
import { Select, Button } from "antd";

const { Option } = Select;
var dateFormat = require("dateformat");

class Table extends React.Component {
  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right" }}>
          <Link to={"/portal/redirect-links/create"}>
            <Button
              ghost
              icon="file-add"
              type="primary"
              style={{ marginRight: "5px" }}
            >
              Create Redirect Link
            </Button>
          </Link>
        </div>
        <ReactTable
          data={this.props.redirectLinks}
          noDataText="No Redirect Links Found"
          filterable
          columns={[
            {
              Header: "Redirect Link ID",
              id: "redirect_link_id",
              width: 120,
              accessor: d => d.redirect_link_id,
              Cell: row => (
                <Link to={"/portal/redirect-links/" + row.value}>
                  {row.value}
                </Link>
              )
            },
     
            {
              Header: "Active",
              id: "active",
              width: 120,
              accessor: d => d.active,
              Cell: row => convertBooleanToTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["active"] === true;
                }
                return row["active"] === false;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },
    
            {
              Header: "Internal Title",
              id: "internal_title",
              width: 190,
              accessor: d => d.internal_title
            },
            {
              Header: "URL That Redirects",
              id: "url",
            
              accessor: d => d.url,
              Cell: row => (
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                 {row.value}
                </a>
              )
            },
            {
              Header: "URL That Is Redirected To",
              id: "redirect_link",
          
              accessor: d => d.redirect_link,
              Cell: row => (
                <a href={row.value} target="_blank" rel="noopener noreferrer">
                  {row.value}
                </a>
              )
            },
 
            {
              Header: "Date Created",
              id: "date_created",
              width: 120,
              accessor: d => d.date_created,
              Cell: row => dateFormat(row.value, " mmmm dS yyyy"),
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
