import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { convertBooleanToTag, renderProductTag, renderProductOptions } from "../../helpers";
import { Select, Button } from "antd";
import { Link } from "react-router-dom";

const { Option } = Select;

class Table extends React.Component {
  render() {
    return (
      <div className="table-container ">
        <div style={{ margin: "10px", textAlign: "right" }}>
          <Link to={"/portal/questions/create-question"}>
            <Button
              ghost
              icon="file-add"
              type="primary"
              style={{ marginRight: "5px" }}
            >
              Create Question
            </Button>
          </Link>
        </div>
        <ReactTable
          data={this.props.questions}
          noDataText="No Questions Found"
          filterable
          columns={[
            {
              Header: "ID",
              id: "discount_id",
              width: 120,
              accessor: d => d.question_id,
              Cell: row => (
                <Link to={"/portal/questions/" + row.value}>{row.value}</Link>
              )
            },
            {
              Header: "Product",
              id: "product",
              width: 430,
              accessor: d => d.product,
              Cell: row => renderProductTag(row.value),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                } else {
                  return row["product"] === filter.value;
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  showSearch={false}
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>

                 {renderProductOptions()}
                </Select>
              )
            },
            {
              Header: "Asker Name",
              id: "shortened_name",
              accessor: d => d.shortened_name
            },
            {
              Header: "Asker Email",
              id: "email",
              accessor: d => d.email
            },
            {
              Header: "Has Answer",
              id: "answer",
              accessor: d => d.answer
            },
            {
              Header: "Display Question",
              id: "display_question",
              accessor: d => d.display_question,
              Cell: row =>
                convertBooleanToTag(row.value.toString().toUpperCase()),
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row["display_question"].toString() === "true";
                }
                return !row["display_question"];
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  size="small"
                  value={filter ? filter.value : "all"}
                  style={{ width: "100%" }}
                  onChange={onChange}
                >
                  <Option value="all">Show All</Option>
                  <Option value="true">True</Option>
                  <Option value="false">False</Option>
                </Select>
              )
            },

            {
              Header: "Date Created",
              id: "date_created",
              accessor: d => d.date_created,
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            },
            {
              Header: "Date Responded",
              id: "date_responded",
              accessor: d => d.date_responded,
              sortMethod: (a, b) => {
                var d1 = new Date(a);
                var d2 = new Date(b);
                return d1 > d2 ? 1 : -1;
              }
            }
          ]}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

export default Table;
