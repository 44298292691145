import React from "react";
import DataPoint from "../Common/DataPoint";

class DasboardRow extends React.Component {
  getTypeCount(type) {
    var userByType;
    userByType = this.props.adminUsers.filter(function(adminUser) {
      return adminUser[type];
    });
    return userByType.length;
  }

  render() {
    return (
      <div className="dashboard-row">
        <DataPoint
          data={this.getTypeCount("fulfillment_user")}
          label={"Fulfillment Users"}
          positive={false}
        />
        <DataPoint
          data={this.getTypeCount("fulfillment_super_user")}
          label={"Fulfillment Super Users"}
          positive={false}
        />
        <DataPoint
          data={this.getTypeCount("is_staff")}
          label={"Staff Users"}
          positive={false}
        />
        <DataPoint
          data={this.getTypeCount("customer_service_user")}
          label={"Customer Service Users"}
          positive={false}
        />
        <DataPoint
          data={this.getTypeCount("is_superuser")}
          label={"Super Users"}
          positive={false}
        />
      </div>
    );
  }
}

export default DasboardRow;
