import React from "react";
import { connect } from "react-redux";
import history from "../../history";
import SearchView from "../../components/Customers/SearchView";
import Table from "../../components/Customers/Table";
import DetailView from "../../components/Customers/DetailView";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: null,
      searchFilter: "",
      childModalOpen: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeChildModal = this.closeChildModal.bind(this);
  }
  setSelectedCustomer(nextProps) {
    var customerID = nextProps.location.pathname.match(/\d+/);
    if (customerID) {
      customerID = customerID[0];
      this.setState({ selectedCustomer: customerID });
    } else {
      this.setState({ selectedCustomer: null });
    }
  }

  // Checks to see whether or not to open the secondery modal inside of the customer detail view
  checkIfChildModalOpen(pathname) {
    var childModalOpen = false;

    if (pathname.includes("reset")) {
      childModalOpen = true;
    } else if (pathname.includes("add-address")) {
      childModalOpen = true;
    } else if (pathname.includes("edit-address")) {
      childModalOpen = true;
    } else if (pathname.includes("edit-payment-method")) {
      childModalOpen = true;
    } else if (pathname.includes("change-email")) {
      childModalOpen = true;
    } else if (pathname.includes("gift-card")) {
      childModalOpen = true;
    } else if (pathname.includes("rewards")) {
      childModalOpen = true;
    }
    this.setState({
      childModalOpen: childModalOpen
    });
  }

  onClose() {
    this.setState({
      selectedCustomer: null
    });
  }

  //   Go back to the main Customers page
  closeModal() {
    history.push("/portal/customers");
  }

  closeChildModal() {
    history.push(
      `/portal/customers/${this.props.customerDetail.details.random_user_id}`
    );
  }

  componentWillMount() {
    this.checkIfChildModalOpen(this.props.location.pathname);
    //   If the Customers are not already populated, populate them

    this.setSelectedCustomer(this.props);
  
  }
  componentWillReceiveProps(nextProps) {
    this.checkIfChildModalOpen(nextProps.location.pathname);
    
    this.setSelectedCustomer(nextProps);
  }

  render() {
    return (
      <div className="page-container">
        <div className="table-view">
            <div>
                <SearchView />
              <Table customers={this.props.customers ? this.props.customers : []} />
            </div>
        </div>
        <DetailView
          closeModal={this.closeModal}
          closeChildModal={this.closeChildModal}
          customerActive={this.state.selectedCustomer ? true : false}
          childModalOpen={this.state.childModalOpen}
          customerID={
            this.state.selectedCustomer ? this.state.selectedCustomer : null
          }
          location={this.props.location}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.customers.customers,
    customersLoading: state.customers.customers_loading,
    loggedInUser: state.auth.logged_in_user,
    customerDetail: state.customers.customer_detail
  };
}

export default connect(
  mapStateToProps,
  {  }
)(Customers);
