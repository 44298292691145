import * as types from "../actions/types";

const INITIAL_STATE = {
  form_failure: false,
  form_message: "",
  form_message_large_format: "",
  form_success: false,
  form_loading: false,
  sub_form_loading: false,
  copied_values: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FORM_CLEAR:
      return {
        ...state,
        form_failure: false,
        form_success: false,
        form_message: "",
        form_loading: false,
        sub_form_loading: false
      };
    case types.FORM_FAILURE:
      return {
        ...state,
        form_failure: true,
        form_success: false,
        form_message: action.payload,
        form_loading: false,
        sub_form_loading: false
      };
    case types.FORM_SUCCESS:
      return {
        ...state,
        form_failure: false,
        form_success: true,
        form_message: action.payload,
        form_loading: false,
        sub_form_loading: false
      };
    case types.CLEAR_FORM_MESSAGE:
      return {
        ...state,
        form_failure: false,
        form_success: false,
        form_message: null,
        form_message_large_format: null,
        form_loading: false,
        sub_form_loading: false
      };
    case types.FORM_SUBMITTING:
      return {
        ...state,
        form_loading: true,
        sub_form_loading: true
      };
    case types.SET_COPIED_VALUES:
      return {
        ...state,
        copied_values: action.payload,
      };
    case types.RESET_COPIED_VALUES:
      return {
        ...state,
        copied_values: null,
      };

    default:
      return state;
  }
}
