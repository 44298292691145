import * as types from "../actions/types";

const INITIAL_STATE = {
  admin_users: null,
  admin_users_loading: false,
  admin_users_soft_loading: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_ADMIN_USERS_LOADING:
      return { ...state, admin_users_loading: true };
    case types.FETCH_ALL_ADMIN_USERS_SOFT_LOADING:
      return { ...state, admin_users_soft_loading: true };
    case types.FETCH_ALL_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        admin_users: action.payload,
        admin_users_loading: false,
        admin_users_soft_loading: false
      };

    default:
      return state;
  }
}
