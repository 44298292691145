import React from "react";
import {
  Button,
  Form,
  Spin,
  Divider,
  Row,
  Col,
  Icon,
  Popconfirm,
  Skeleton
} from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField
} from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { trueFalseArray, renderProductTag, ratingArray } from "../../helpers";
import {
  editReview,
  deleteReview
} from "../../actions/actionsQuestionsAndReviews";
import DescriptionItem from "../Common/DescriptionItem";
import { Link } from "react-router-dom";

const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = "Required";
  }
  if (!values.rating) {
    errors.rating = "Required";
  }
  if (!values.shortened_name) {
    errors.shortened_name = "Required";
  }
  if (!values.description) {
    errors.description = "Required";
  }
  if (!values.verified_review) {
    errors.verified_review = "Required";
  }
  if (!values.display_review) {
    errors.display_review = "Required";
  }

  return errors;
};

class ReviewFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    this.props.editReview(props);
  }

  deleteReview() {
    if (this.props.initialValues) {
      var data = {
        review_id: this.props.initialValues.review_id
      };
      this.props.deleteReview(data);
    }
  }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin
          spinning={this.props.formLoading || this.props.reviewsSoftLoading}
        >
          {this.props.initialValues && (
            <Row>
              <Divider>Basic Info </Divider>
              <Col span={12}>
                <DescriptionItem
                  title="Product Review"
                  content={renderProductTag(this.props.initialValues.product)}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Date Created"
                  content={this.props.initialValues.date_created}
                />
              </Col>
              <Col span={12} /> 
              {this.props.initialValues.review_preview_photo_url && (
                <Col span={12}>
                  <DescriptionItem
                    title="Review Main Photo"
                    content={
                      <a
                        href={this.props.initialValues.review_preview_photo_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See Photo <Icon type="link" />
                      </a>
                    }
                  />
                </Col>
              )}
              <Col span={12}>
                <DescriptionItem
                  title="Up Votes"
                  content={this.props.initialValues.up_votes}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Down Votes"
                  content={this.props.initialValues.down_votes}
                />
              </Col>

              <Col span={12}>
                <DescriptionItem
                  title="Linked Order"
                  content={
                    <Link
                      to={`/portal/orders/${
                        this.props.initialValues.linked_order
                      }`}
                    >
                      {this.props.initialValues.linked_order}
                    </Link>
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Linked User"
                  content={
                    <Link
                      to={`/portal/customers/${
                        this.props.initialValues.linked_user
                      }`}
                    >
                      {this.props.initialValues.linked_user}
                    </Link>
                  }
                />
              </Col>
            </Row>
          )}
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.reviewsSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Review Info </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Title"}
                    name="title"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Rating"}
                    name="rating"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    options={ratingArray}
                    component={selectField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Name"}
                    name="shortened_name"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={plainTextField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Description"}
                    name="description"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
              </Row>
              <Divider>Details </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Company Response"}
                    name="company_response"
                    hideErrorLabel={false}
                    required={false}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Verified Review"}
                    name="verified_review"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    label={"Display Review"}
                    name="display_review"
                    required={true}
                    size={"large"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                </Col>
              </Row>

              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                Edit Review
              </Button>
            </Form>
          </Skeleton>
          <br />
          <br />
          <Popconfirm
            title="Are you sure you want to delete this review？"
            okText="Yes"
            onConfirm={() => this.deleteReview()}
            cancelText="No"
            icon={<Icon type="exclamation-circle" style={{ color: "red" }} />}
          >
            <Button ghost type="danger">
              Delete Review
            </Button>
          </Popconfirm>
        </Spin>
      </div>
    );
  }
}

ReviewFormView = reduxForm({
  form: "ReviewForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(ReviewFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    reviewsSoftLoading:
      state.questions_and_reviews.questions_and_reviews_soft_loading
  };
}

export default connect(
  mapStateToProps,
  { editReview, deleteReview }
)(ReviewFormView);
