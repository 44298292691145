import * as types from "../actions/types";

const INITIAL_STATE = {
  discounts: null,
  discounts_loading: false,
  discounts_soft_loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.FETCH_ALL_DISCOUNTS_LOADING:
      return { ...state, discounts_loading: true };
    case types.FETCH_ALL_DISCOUNTS_SOFT_LOADING:
      return { ...state, discounts_soft_loading: true };
    case types.FETCH_ALL_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: action.payload,
        discounts_loading: false,
        discounts_soft_loading: false
      };

    default:
      return state;
  }
}
