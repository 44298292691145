import React from "react";
import {
  Button,
  Form,
  Spin,
  Divider,
  Row,
  Col,
  Popconfirm,
  Icon,
  Skeleton
} from "antd";
import { connect } from "react-redux";
import {
  textAreaField,
  selectField,
  plainTextField
} from "../Form/FieldRenders";
import { Field, reduxForm } from "redux-form";
import { productArray, productGroupArray, trueFalseArray } from "../../helpers";
import {
  editQuestion,
  createQuestion,
  deleteQuestion
} from "../../actions/actionsQuestionsAndReviews";
import moment from "moment";
import DescriptionItem from "../Common/DescriptionItem";

const validate = values => {
  const errors = {};
  if (!values.product) {
    errors.product = "Required";
  }
  if (!values.question) {
    errors.question = "Required";
  }
  if (!values.answer) {
    errors.answer = "Required";
  }
  if (!values.shortened_name) {
    errors.shortened_name = "Required";
  }
  if (!values.display_question) {
    errors.display_question = "Required";
  }
  if (!values.product) {
    errors.product = "Required";
  }

  return errors;
};

class QuestionsFormView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(props) {
    if (props.date_display) {
      props.date_display = moment(props.date_display).format("YYYY-MM-DD");
    }
    if (this.props.edit) {
      this.props.editQuestion(props);
    } else {
      this.props.createQuestion(props);
    }
  }
  deleteQuestion() {
    if (this.props.initialValues) {
      var data = {
        question_id: this.props.initialValues.question_id
      };
      this.props.deleteQuestion(data);
    }
  }
  render() {
    const { handleSubmit, pristine, submitting, invalid } = this.props;
    return (
      <div className="modal-wrapper" style={{ paddingTop: "5px" }}>
        <Spin
          spinning={this.props.formLoading || this.props.questionsSoftLoading}
        >
          {this.props.initialValues && (
            <DescriptionItem
              title="Date Created"
              content={this.props.initialValues.date_created}
            />
          )}
          <Skeleton
            active
            paragraph={{ rows: 20 }}
            loading={this.props.questionsSoftLoading}
          >
            <Form onSubmit={handleSubmit(this.onFormSubmit)}>
              <Divider>Basic Info </Divider>
              <Row gutter={6}>
                <Col span={24}>
                  <Field
                    label={"Product"}
                    name="product"
                    required={true}
                    size={"large"}
                    optionGroups={productGroupArray}
                    options={productArray}
                    component={selectField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Question"}
                    name="question"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
                <Col span={24}>
                  <Field
                    label={"Answer"}
                    name="answer"
                    hideErrorLabel={false}
                    required={true}
                    size="large"
                    rows={8}
                    component={textAreaField}
                  />
                </Col>
              </Row>
              <Divider>Question Properties </Divider>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Shortand Name"}
                    name="shortened_name"
                    required={true}
                    size={"large"}
                    type={"text"}
                    component={plainTextField}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    type={"email"}
                    label={"Email"}
                    name="email"
                    required={false}
                    size={"large"}
                    component={plainTextField}
                  />
                </Col>
              </Row>
              <Row gutter={6}>
                <Col span={12}>
                  <Field
                    label={"Display Question"}
                    name="display_question"
                    required={true}
                    size={"large"}
                    type={"text"}
                    options={trueFalseArray}
                    component={selectField}
                  />
                  <p>The question will be hidden if set to False</p>
                </Col>
              </Row>
              <br />
              <Button
                loading={this.props.formLoading}
                htmlType="submit"
                block
                size="large"
                disabled={pristine || submitting || invalid}
                type="primary"
              >
                {this.props.edit ? "Edit Question" : "Create Question"}
              </Button>
            </Form>
          </Skeleton>
          <br />
          <br />
          {this.props.edit && (
            <Popconfirm
              title="Are you sure you want to delete this question？"
              okText="Yes"
              onConfirm={() => this.deleteQuestion()}
              cancelText="No"
              icon={<Icon type="exclamation-circle" style={{ color: "red" }} />}
            >
              <Button ghost type="danger">
                Delete Question
              </Button>
            </Popconfirm>
          )}
        </Spin>
      </div>
    );
  }
}

QuestionsFormView = reduxForm({
  form: "QuestionForm",
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate
})(QuestionsFormView);

function mapStateToProps(state) {
  return {
    formLoading: state.formStatus.form_loading,
    questionsSoftLoading:
      state.questions_and_reviews.questions_and_reviews_soft_loading
  };
}

export default connect(
  mapStateToProps,
  { createQuestion, editQuestion, deleteQuestion }
)(QuestionsFormView);
