import React from "react";
import DataPoint from "../Common/DataPoint";

class DasboardRow extends React.Component {
  getHomePageVersionTypes(displayType) {
    var count = 0;
    this.props.homePageVersions.map(function(homePageVersion) {
      if (homePageVersion.active === displayType) {
        count = count + 1;
      }
      return homePageVersion;
    });
    return count;
  }
  render() {
    return (
      <div className="dashboard-row">
        <DataPoint
          data={this.props.homePageVersions.length}
          label={"Versions"}
          positive={false}
        />
        <DataPoint
          data={this.getHomePageVersionTypes(true)}
          label={"Active"}
          positive={false}
        />
        <DataPoint
          data={this.getHomePageVersionTypes(false)}
          label={"Not Active"}
          positive={false}
        />
      </div>
    );
  }
}

export default DasboardRow;
